import React, { useState } from 'react';
import { v4 } from 'uuid';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { useRootStore } from 'app/contexts/RootStoreContext';
import NewCustomDashboardPanelModal from 'shared/components/NewCustomDashboardPanelModal';
import * as custDabrdHelpers from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import mainStyles from '../mainDashboardCommon.module.scss';

const SaveDashboardPanel = ({ panelType, groupBy }) => {
  const [dashboardModalIsOpen, setDashboardModalIsOpen] = useState(false);
  const { usageStore, usersStore } = useRootStore();
  const { filters } = useMainDashboardContext();
  const cloudType = usersStore?.currDispUserCloudAccountType;
  const { existingDashboardsNamesAndIds } = usageStore.customDbSubStore.customDashboardModel;
  const [customPanelType, setCustomPanelType] = useState(panelType);
  const getParamsForCustomDashboard = () => ({ id: v4().substring(0, 6).toLowerCase() });
  const savePanel = (panelType) => {
    setDashboardModalIsOpen(true);
    setCustomPanelType(panelType);
  };
  return (
    <>
      <div>
        <Tooltip title="Save as Dashboard Panel">
          <div automation-id="md-create-dashboard-panel">
            <GenerateIcon
              iconName={ICONS.dashboard.name}
              className={mainStyles.dashboardIcon}
              onClick={() => {
                savePanel(panelType);
              }}
            />
          </div>
        </Tooltip>
      </div>

      <NewCustomDashboardPanelModal
        modalIsOpen={dashboardModalIsOpen}
        getCurrentCauParams={getParamsForCustomDashboard}
        onClose={() => {
          setDashboardModalIsOpen(false);
          setCustomPanelType(null);
        }}
        customDashboardStore={usageStore.customDbSubStore}
        existingDashboardsNamesAndIds={existingDashboardsNamesAndIds}
        helpers={custDabrdHelpers}
        type={customPanelType}
        state={{
          currCustomPanelType: customPanelType,
          filters: filters,
          groupBy: groupBy,
        }}
        usageStore={usageStore}
        cloudType={cloudType}
      />
    </>
  );
};

SaveDashboardPanel.propTypes = {
  panelType: PropTypes.string.isRequired,
  groupBy: PropTypes.object,
};

export default SaveDashboardPanel;
