import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper.jsx';
import Button from 'shared/components/andtComponents/Button';
import { COST_CENTER_ACTIONS } from '../consts';
import DeleteCostCenterModal from './costCentersModals/DeleteCostCenter/DeleteCostCenterModal';
import { usePermissions } from 'app/contexts/PermissionsContext';

import styles from './CostCenterActions.module.scss';

const CostCenterActions = ({ costCenter, subTreeCostCenters }) => {
  const [isOpen, setIsOpen] = useState(false);

  // const [isMoveCostCenterModalOpen, setIsMoveCostCenterModalOpen] = useState(false);
  const [isDeleteCostCenterModalOpen, setIsDeleteCostCenterModalOpen] = useState(false);

  const { isHasPermission } = usePermissions();
  const updateCostCentersAllowed = isHasPermission(OrganizationEntityCategory.CostCenters, Action.Update);

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  const onUserActionSelect = (action) => {
    switch (action) {
      // case COST_CENTER_ACTIONS.MOVE.id:
      //   setIsMoveCostCenterModalOpen(true);
      //   break;
      case COST_CENTER_ACTIONS.DELETE.id:
        setIsDeleteCostCenterModalOpen(true);
        break;
      default:
        break;
    }
    setIsOpen(false);
  };

  return updateCostCentersAllowed ? (
    <div className={styles.costCenterActionsContainer} onClick={(e) => toggleOpen(e)}>
      <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <PopoverTrigger as="button" type="button">
          <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.actionsIcon} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={-10} side="left" align="start" alignOffset={20} className={styles.popoverContent}>
            {/*<ReadOnlyDisplayWrapper category={OrganizationEntityCategory.CostCenters} action={Action.Update}>*/}
            {/*  <Button*/}
            {/*    isTextButton*/}
            {/*    text={COST_CENTER_ACTIONS.MOVE.label}*/}
            {/*    automationId={COST_CENTER_ACTIONS.MOVE.automationId}*/}
            {/*    className={{ button: styles.menuItem }}*/}
            {/*    icon={() => <GenerateIcon iconName={COST_CENTER_ACTIONS.MOVE.icon} className={styles.itemIcon} />}*/}
            {/*    onClick={() => onUserActionSelect(COST_CENTER_ACTIONS.MOVE.id)}*/}
            {/*  />*/}
            {/*</ReadOnlyDisplayWrapper>*/}
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.CostCenters} action={Action.Update}>
              <Button
                isTextButton
                text={COST_CENTER_ACTIONS.DELETE.label}
                automationId={COST_CENTER_ACTIONS.DELETE.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={COST_CENTER_ACTIONS.DELETE.icon} className={styles.itemIcon} />}
                onClick={() => onUserActionSelect(COST_CENTER_ACTIONS.DELETE.id)}
              />
            </ReadOnlyDisplayWrapper>
          </PopoverContent>
        </PopoverPortal>
      </Popover>

      {/*{isMoveCostCenterModalOpen && (*/}
      {/*  <MoveCostCenterModal setIsOpen={setIsMoveCostCenterModalOpen} isOpen={isMoveCostCenterModalOpen} user={row} />*/}
      {/*)}*/}
      {isDeleteCostCenterModalOpen && (
        <DeleteCostCenterModal
          setIsOpen={setIsDeleteCostCenterModalOpen}
          isOpen={isDeleteCostCenterModalOpen}
          costCenter={costCenter}
          hasChildren={subTreeCostCenters?.length > 0}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

CostCenterActions.propTypes = {
  costCenter: PropTypes.object.isRequired,
  subTreeCostCenters: PropTypes.array,
};

export default CostCenterActions;
