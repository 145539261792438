import React from 'react';
import DashboardPage from 'app/containers/Dashboard';
import MainDashboardPage from 'app/containers/MainDashboard/index.jsx';
import checkFeatureFlag from 'shared/utils/featureFlagUtil.js';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants.js';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';

const MainDashboardWrapper = () => {
  const { usersStore, appStore } = useRootStore();
  const newMainDashboard =
    checkFeatureFlag(usersStore, ACCOUNT_FEATURES.NEW_MAIN_DASHBOARD) || appStore.isKeyCloakManagement;
  return newMainDashboard ? <MainDashboardPage /> : <DashboardPage />;
};

export default MainDashboardWrapper;
