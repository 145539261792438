import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { toast } from 'react-toastify';

const root = '/api/v1/user-management/accounts';

const fetchBillingAccounts = async () => API.get('billings', root);
export const fetchLinkedAccounts = async (id) => API.get('billings', `${root}/${id}/linked-accounts`);
const updateAccountProperties = async (data) => API.put('billings', `${root}/${data.id}/properties`, { body: data });

export const useAccounts = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.BILLING_ACCOUNTS];

  return {
    reset: () => queryClient.resetQueries({ queryKey }),
    updateAccountProperties: () =>
      useMutation({
        mutationFn: (data) => updateAccountProperties(data),
        onError: () =>
          toast.error('Error updating account properties', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey });
          queryClient.invalidateQueries({ queryKey: [apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS] });
        },
      }),
    fetchBillingAccountsData: (userKey) =>
      useQuery({
        queryKey: [...queryKey, userKey],
        queryFn: fetchBillingAccounts,
        onError: () =>
          toast.error('Error fetching billing accounts data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
    fetchLinkedAccounts: (payerAccountId) =>
      useQuery({
        queryKey: [...queryKey, payerAccountId],
        queryFn: () => fetchLinkedAccounts(payerAccountId),
        onError: () =>
          toast.error('Error fetching linked accounts data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
  };
};
