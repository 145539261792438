import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/usage/idle-instances';

const getIdleInstancesCriteria = () => API.get('billings', `${root}/criteria`);

const createIdleInstancesCriteria = (body) => API.post('billings', `${root}/criteria`, { body });
const deleteIdleCriteria = (id) => API.del('billings', `${root}/criteria?criteriaId=${id}`);

const updateIdleCriteriaRule = (body) => API.put('billings', `${root}/criteria`, { body });

const getIdleInstancesTableData = async (body) => {
  const linkedAccounts = body.linkedAccounts.map((la) => `&linkedAccounts=${la}`).join('');
  const criteriaIds = body.criteriaIds ? body.criteriaIds.map((cn) => `&criteriaIds=${cn}`).join('') : '';
  const apiQuery = `${root}/data?startDate=${body.startDate}&endDate=${body.endDate}${linkedAccounts}${criteriaIds}`;
  const result = await API.get('billings', apiQuery);
  return result;
};

const getIdleInstanceRowData = (criteriaId, instanceId, startDate, endDate) =>
  API.get(
    'billings',
    `${root}/instanceData?criteriaId=${criteriaId}&instanceId=${instanceId}&startDate=${startDate}&endDate=${endDate}`,
  );

export function useIdleInstances() {
  const queryKey = [apiConstants.QUERY_KEYS.IDLE_INSTANCES_CRITERIA];
  const queryClient = useQueryClient();
  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey });
  };
  return {
    fetchIdleInstancesRowData: (criteriaId, instanceId, startDate, endDate) =>
      useQuery({
        queryKey: [apiConstants.QUERY_KEYS.IDLE_INSTANCES_ROW, criteriaId, instanceId, startDate, endDate],
        queryFn: () => getIdleInstanceRowData(criteriaId, instanceId, startDate, endDate),
      }),
    fetchIdleInstancesTableData: (body) =>
      useQuery({
        queryKey: [apiConstants.QUERY_KEYS.IDLE_INSTANCES_TABLE, JSON.stringify(body)],
        queryFn: () => getIdleInstancesTableData(body),
        enabled: false,
      }),
    fetchIdleInstancesCriteria: () =>
      useQuery({
        queryKey,
        queryFn: () => getIdleInstancesCriteria(),
      }),
    useCreateCriteria: () =>
      useMutation({
        mutationFn: createIdleInstancesCriteria,
        onSuccess,
      }),
    useDeleteCriteria: () =>
      useMutation({
        mutationFn: deleteIdleCriteria,
        onSuccess,
      }),
    useUpdateCriteria: () =>
      useMutation({
        mutationFn: updateIdleCriteriaRule,
        onSuccess,
      }),
  };
}
