import { GcpCommonFields } from './gcpConstants';
import { K8S_POD_LABELS } from './appConstants.js';

export class AzureServiceNames {
  static VM = 'Virtual Machine';
  static DB = 'Azure database';
  static AZURE_MANAGED_DISKS = 'Azure managed disks';
  static AZURE_RESERVED_CAPACITY = 'Azure reserved instance';
  static AZURE_DATA_EXPLORER = 'Azure data explorer';
  static AZURE_APP_SERVICE = 'Azure app service';
}

export class AzureCommonFields {
  static NODE_GROUP = 'nodegroup';
  static NODE = 'node';
  static NAMESPACE = 'namespace';
  static USAGE_TYPE = 'usagetype';
  static RESOURCE_ID = 'resourceid';
  static RESOURCE_GROUP = 'resourcegroup';
  static SUBSCRIPTION_NAME = 'linkedaccname';
  static SUBSCRIPTION_ID = 'linkedaccid';
  static NONE = 'none';
  static REGION = 'region';
  static K8S_REGION = 'k8sregion';
  static K8S_CUSTOM_TAGS = 'k8scustomtags';
  static ENVIRONMENT = 'environment';
  static PROJECT = 'project';
  static DEVELOPMENT = 'development';
  static PRODUCTION = 'production';
  static STAGING = 'staging';
  static TESTING = 'testing';
  static SERVICE = 'service';
  static OPERATION = 'operation';
  static METER_MAME = 'operation';
  static METER_CATEGORY = 'metercategory';
  static SUB_METER_CATEGORY = 'familytypeusage';
  static TYPE = 'type';
  static RESOURCE = 'resource';
  static INSTANCE_TYPE = 'instancetype';
  static USAGE_DATE = 'usagedate';
  static GROUP_BY = 'groupby';
  static TOTAL_COST = 'totalcost';
  static TOTAL_USAGE = 'totalhours';
  static FAMILY_TYPE = 'familytype';
  static QUANTITY_TYPE = 'quantitytype';
  static K8S_LABEL_TAGS = 'labels';
  static OS = 'os';
  static DIVISION = 'division';
  static COST_CENTER = 'costcenter';
  static DIVISION_ID = 'divisionid';
  static CUSTOM_TAGS = 'customtags';
  static CATEGORIES = 'categories';
  static BUSINESS_MAPPING = 'businessmapping';
  static BUSINESS_MAPPING_VIEWPOINTS = 'businessmappingviewpoints';
  static CATEGORY = 'category';
  static TAGS = 'tags';
  static TAG = 'tag';
  static ACCOUNT_TAGS = 'accounttags';
  static ACCOUNT_TAGS_KEYS = 'accounttags_keys';
  static COST_TYPE = 'costtype';
  static WORKLOAD = 'workloadvalue';
  static WORKLOAD_TYPE = 'workloadtype';
  static CLUSTER = 'cluster';
  static POD = 'pod';
  static CUSTOM_TAGS_KEYS = 'customtags_keys';
  static PURCHASE_OPTION = 'purchaseoption';
  static PAYER_ACCOUNT = 'payeraccount';
  static PAYER_ACCOUNT_NAME = 'accountName';
  static VIRTUAL_CUSTOM_TAGS = 'virtualcustomtags';
  static SUB_VIEWS_CUSTOM_TAGS = 'subviewscustomtags';
  static K8S_INSTANCE_TYPE = 'k8sinstancetype';
  static BENEFIT = 'benefit';
  static PUBLISHER_NAME = 'publishername';
  static CHARGE_CATEGORY = 'chargecategory';
  static PUBLISHER_CATEGORY = 'publishercategory';
  static AZURE_COST_CENTER = 'azurecostcenter';
  static PARTNER_CREDIT_RATE = 'partnercreditrate';
  static SERVICE_CATEGORY = 'servicecategory';
}

export const azureCommonFieldToDisplayField = new Map([
  [AzureCommonFields.NONE, '- None -'],
  [AzureCommonFields.USAGE_DATE, 'Date'],
  [AzureCommonFields.CLUSTER, 'Cluster'],
  [AzureCommonFields.REGION, 'Region'],
  [AzureCommonFields.K8S_REGION, 'Region'],
  [AzureCommonFields.TYPE, 'Type'],
  [AzureCommonFields.ACCOUNT_TAGS, 'Enrichment Tags'],
  [AzureCommonFields.ACCOUNT_TAGS_KEYS, 'Enrichment Tags'],
  [AzureCommonFields.ENVIRONMENT, 'Environments'],
  [AzureCommonFields.PROJECT, 'Projects'],
  [AzureCommonFields.DEVELOPMENT, 'Development'],
  [AzureCommonFields.PRODUCTION, 'Production'],
  [AzureCommonFields.STAGING, 'Staging'],
  [AzureCommonFields.TESTING, 'Testing'],
  [AzureCommonFields.SERVICE, 'Service'],
  [AzureCommonFields.OPERATION, 'Operation'],
  [AzureCommonFields.METER_MAME, 'Meter'],
  [AzureCommonFields.METER_CATEGORY, 'Meter Category'],
  [AzureCommonFields.SUB_METER_CATEGORY, 'Meter Sub Category'],
  [AzureCommonFields.ITEM_DESCRIPTION, 'Item Description'],
  [AzureCommonFields.RESOURCE, 'Resource'],
  [AzureCommonFields.INSTANCE_TYPE, 'Instance Type'],
  [AzureCommonFields.FAMILY_TYPE, 'Meter Category'],
  [AzureCommonFields.QUANTITY_TYPE, 'Quantity Type'],
  [AzureCommonFields.USAGE_TYPE, 'Usage Type'],
  [AzureCommonFields.RESOURCE_ID, 'Resource'],
  [AzureCommonFields.OS, 'Operation System'],
  [AzureCommonFields.RESOURCE_GROUP, 'Resource Group'],
  [AzureCommonFields.SUBSCRIPTION_NAME, 'Subscription Name'],
  [AzureCommonFields.SUBSCRIPTION_ID, 'Subscription'],
  [AzureCommonFields.DIVISION, 'Cost Center'],
  [AzureCommonFields.COST_CENTER, 'Cost Center'],
  [AzureCommonFields.DIVISION_ID, 'Cost Center ID'],
  [AzureCommonFields.CUSTOM_TAGS, 'Tags'],
  [AzureCommonFields.CUSTOM_TAGS_KEYS, 'Tags'],
  [AzureCommonFields.PURCHASE_OPTION, 'Purchase Option'],
  [AzureCommonFields.PAYER_ACCOUNT, 'Payer Account'],
  [AzureCommonFields.PAYER_ACCOUNT_NAME, 'Payer Account Name'],
  [AzureCommonFields.TAGS, 'Tags'],
  [AzureCommonFields.TAG, 'Tag'],
  [AzureCommonFields.POD, 'Pod'],
  [AzureCommonFields.CATEGORIES, 'Categories'],
  [AzureCommonFields.BUSINESS_MAPPING, 'Business Mapping'],
  [AzureCommonFields.BUSINESS_MAPPING_VIEWPOINTS, 'Business Mapping Viewpoints'],
  [AzureCommonFields.CATEGORY, 'Category'],
  [AzureCommonFields.NAMESPACE, 'Namespace'],
  [AzureCommonFields.NODE, 'Node'],
  [AzureCommonFields.NODE_GROUP, 'Node Group'],
  ['AlertsAllAccounts', 'Any Linked Account'],
  [AzureCommonFields.K8S_CUSTOM_TAGS, K8S_POD_LABELS],
  [AzureCommonFields.WORKLOAD, 'Workload'],
  [AzureCommonFields.WORKLOAD_TYPE, 'Workload Type'],
  [AzureCommonFields.COST_TYPE, 'Cost Type'],
  [AzureCommonFields.K8S_LABEL_TAGS, K8S_POD_LABELS],
  [AzureCommonFields.VIRTUAL_CUSTOM_TAGS, 'Virtual Tags'],
  [AzureCommonFields.SUB_VIEWS_CUSTOM_TAGS, 'Sub Views'],
  [AzureCommonFields.K8S_INSTANCE_TYPE, 'Instance Type'],
  [AzureCommonFields.BENEFIT, 'Benefit'],
  [AzureCommonFields.PARTNER_CREDIT_RATE, 'Partner Credit Rate'],
  [AzureCommonFields.CHARGE_CATEGORY, 'Charge Category'],
  [AzureCommonFields.PUBLISHER_NAME, 'Publisher Name'],
  [AzureCommonFields.PUBLISHER_CATEGORY, 'Publisher Category'],
  [AzureCommonFields.AZURE_COST_CENTER, 'Azure Cost Center'],
  [AzureCommonFields.SERVICE_CATEGORY, 'Service Category'],
]);

export const AzureCommonFieldLabels = new Map([
  ['Linked Account', 'Subscription'],
  [GcpCommonFields.LINKED_ACCOUNT_ID, 'Subscription'],
  [GcpCommonFields.LINKED_ACCOUNT_NAME, 'Subscription'],
]);
