import { ONBOARDING_FIELDS } from '../utils/OnboardingConstants';
import { ReactComponent as BYODIcon } from 'shared/img/cloud-providers/simple/byod.svg';
import { ReactComponent as SnowflakeIcon } from 'shared/img/cloud-providers/vendors/Snowflake.svg';
import { ReactComponent as DatadogIcon } from 'shared/img/cloud-providers/vendors/Datadog.svg';
import { ReactComponent as MongoDBIcon } from 'shared/img/cloud-providers/vendors/MongoDB.svg';
import { ReactComponent as NewRelicIcon } from 'shared/img/cloud-providers/vendors/NewRelic.svg';
import { ReactComponent as AlibabaIcon } from 'shared/img/cloud-providers/vendors/Alibaba.svg';
import { ReactComponent as OracleIcon } from 'shared/img/cloud-providers/vendors/Oracle.svg';
import { ReactComponent as AtlassianIcon } from 'shared/img/cloud-providers/vendors/Atlassian.svg';
import { ReactComponent as ClickHouseIcon } from 'shared/img/cloud-providers/vendors/ClickHouse.svg';
import { ReactComponent as CloudflareIcon } from 'shared/img/cloud-providers/vendors/Cloudflare.svg';
import { ReactComponent as ConfluentIcon } from 'shared/img/cloud-providers/vendors/Confluent.svg';
import { ReactComponent as CopilotIcon } from 'shared/img/cloud-providers/vendors/Copilot.svg';
import { ReactComponent as CoralogixIcon } from 'shared/img/cloud-providers/vendors/Coralogix.svg';
import { ReactComponent as DatabricksIcon } from 'shared/img/cloud-providers/vendors/Databricks.svg';
import { ReactComponent as DockerIcon } from 'shared/img/cloud-providers/vendors/Docker.svg';
import { ReactComponent as ElasticIcon } from 'shared/img/cloud-providers/vendors/Elastic.svg';
import { ReactComponent as ElasticsearchIcon } from 'shared/img/cloud-providers/vendors/Elasticsearch.svg';
import { ReactComponent as EquinixIcon } from 'shared/img/cloud-providers/vendors/Equinix.svg';
import { ReactComponent as FastlyIcon } from 'shared/img/cloud-providers/vendors/Fastly.svg';
import { ReactComponent as GitHubIcon } from 'shared/img/cloud-providers/vendors/GitHub.svg';
import { ReactComponent as OpenAIIcon } from 'shared/img/cloud-providers/vendors/OpenAI.svg';
import { ReactComponent as PagerDutyIcon } from 'shared/img/cloud-providers/vendors/PagerDuty.svg';
import { ReactComponent as TwilioIcon } from 'shared/img/cloud-providers/vendors/Twilio.svg';

export const BYOD_ONBOARDING_STEPS = {
  BYOD_DETAILS: 'BYOD_DETAILS',
  UPLOAD: 'UPLOAD',
  PROCESS_DATA: 'PROCESS_DATA',
};

export const BYOD_ONBOARDING_STEPS_SHORT_TITLES = {
  BYOD_DETAILS: 'Account Details',
  UPLOAD: 'Upload',
  PROCESS_DATA: 'Process Data',
};

export const BYOD_ONBOARDING_FIELDS = {
  ...ONBOARDING_FIELDS,
  IMPORT_TYPE: 'importType',
  VENDOR_TYPE_UUID: 'vendorTypeUuid',
  ACCESS_KEY_URL: 'accessKeyUrl',
  ACCESS_POINT_ALIAS: 'accessPointAlias',
  ORGANIZATION_ID: 'organizationId',
  S3_PATH: 's3Path',
};

export const BYOD_ONBOARDING_STEP_TO_FIELDS = {
  [BYOD_ONBOARDING_STEPS.ACCOUNT_TYPE]: [BYOD_ONBOARDING_FIELDS.RESELLER_CUSTOMER],
  [BYOD_ONBOARDING_STEPS.BYOD_DETAILS]: [BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME],
};

const VENDOR_TYPE_UUIDS = {
  SNOWFLAKE: '37994b0e-0565-4cc2-bf31-92757113155f',
  DATADOG: 'bd8c3b8d-6d76-46a5-a22d-33ffff7c5b29',
  MONGODB: '723c63fb-b275-4547-9a63-f0fccb2058ba',
  NEW_RELIC: '859601b7-3396-4013-86be-c722dbaea259',
  ALIBABA: '9f01ee56-c9b6-49f6-a92c-96566be1c04f',
  ORACLE: '92beb353-57e7-488e-aeea-a229404e31f0',
  ATLASSIAN: '52ef0e83-951f-4baf-8643-1cf7b1d32d71',
  CLICKHOUSE: '91b049cb-4f9b-49fd-8782-8dfb68b1b793',
  CLOUDFLARE: '894a6773-ae6d-4fc6-8a4a-144bc8c60cb4',
  CONFLUENT: 'a70d4c1e-ecf9-4a8e-90d0-c5ad043e3fd7',
  COPILOT: '76e07626-6567-48c9-b9df-a5bcce55f0df',
  CORALOGIX: 'b1b97de4-6e4f-4bae-971b-889b151178c4',
  DATABRICKS: '62a9090a-dc3c-44b0-a157-e9b99703108e',
  DOCKER: '224a07ae-baa3-4315-b3c0-89c6ce87b907',
  ELASTIC: '4fb7cda8-26af-4a92-8b80-888b8f3a6b0e',
  ELASTICSEARCH: 'b871c757-67f6-4c89-9392-9ccd67e11d8b',
  EQUINIX: '6596e680-2f82-40c2-823b-57d28a93325e',
  FASTLY: '23274f77-1764-4d59-8b7d-8c539749f83f',
  GITHUB: 'baeb5345-0ad1-4ed2-a8a9-5f8445aff3dd',
  OPENAI: '3dde4c55-cd6d-437f-a5df-8a5dede31ad5',
  PAGERDUTY: '8945eac1-c726-4b09-9fd6-18e9b974a956',
  TWILIO: '937d569a-7524-49a6-b308-c3640c0ed8df',
};

export const vendorTypeUuidToIcon = {
  default: <BYODIcon />,
  [VENDOR_TYPE_UUIDS.SNOWFLAKE]: <SnowflakeIcon />,
  [VENDOR_TYPE_UUIDS.DATADOG]: <DatadogIcon />,
  [VENDOR_TYPE_UUIDS.MONGODB]: <MongoDBIcon />,
  [VENDOR_TYPE_UUIDS.NEW_RELIC]: <NewRelicIcon />,
  [VENDOR_TYPE_UUIDS.ALIBABA]: <AlibabaIcon />,
  [VENDOR_TYPE_UUIDS.ORACLE]: <OracleIcon />,
  [VENDOR_TYPE_UUIDS.ATLASSIAN]: <AtlassianIcon />,
  [VENDOR_TYPE_UUIDS.CLICKHOUSE]: <ClickHouseIcon />,
  [VENDOR_TYPE_UUIDS.CLOUDFLARE]: <CloudflareIcon />,
  [VENDOR_TYPE_UUIDS.CONFLUENT]: <ConfluentIcon />,
  [VENDOR_TYPE_UUIDS.COPILOT]: <CopilotIcon />,
  [VENDOR_TYPE_UUIDS.CORALOGIX]: <CoralogixIcon />,
  [VENDOR_TYPE_UUIDS.DATABRICKS]: <DatabricksIcon />,
  [VENDOR_TYPE_UUIDS.DOCKER]: <DockerIcon />,
  [VENDOR_TYPE_UUIDS.ELASTIC]: <ElasticIcon />,
  [VENDOR_TYPE_UUIDS.ELASTICSEARCH]: <ElasticsearchIcon />,
  [VENDOR_TYPE_UUIDS.EQUINIX]: <EquinixIcon />,
  [VENDOR_TYPE_UUIDS.FASTLY]: <FastlyIcon />,
  [VENDOR_TYPE_UUIDS.GITHUB]: <GitHubIcon />,
  [VENDOR_TYPE_UUIDS.OPENAI]: <OpenAIIcon />,
  [VENDOR_TYPE_UUIDS.PAGERDUTY]: <PagerDutyIcon />,
  [VENDOR_TYPE_UUIDS.TWILIO]: <TwilioIcon />,
};
