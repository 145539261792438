import React from "react";

type Props = {
  className?: string;
}

const ValidationStep: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      ValidationStep
    </div>
  );
};

export default ValidationStep;
