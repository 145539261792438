import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Input from 'shared/components/andtComponents/Input';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import useRecViews from 'recommendationsNew/hooks/react-query/useRecViews';
import {
  calculateRange,
  calculateStartAndEndDates,
  CLOSED_AND_DONE_RECS_DATES_FILTERS,
  DATE_RANGE_OPTIONS,
  FILTERS,
  VIEWS_TYPE,
} from 'recommendationsNew/consts';
import { useHeatMapContext } from '../contexts/heatMapContext';
import HeatMapActionsContext from '../contexts/heatMapActionsContext';
import { getHistoryFiltersViewStateCopy, getInitialFiltersStateCopy } from '../heatMapFilters/filterStates';
import classes from './heatMapActions.module.scss';
import { ViewActions } from './viewActions';

const VIEW_NAMES = { MainView: 'Main View', SavingTracker: 'Saving Tracker (History)' };

const CreateOrEditViewName = ({ viewName, setViewName, saveView, cancelView, isValid }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <div className={classes.saveViewWrapper}>
      <div className={classes.inputWrapper}>
        <Input
          ref={inputRef}
          className={classes.viewInput}
          value={viewName}
          onChange={(e) => {
            setViewName(e.target.value);
          }}
        />
        {!isValid && <span className={classes.error}>Name already exists</span>}
      </div>
      <GenerateIcon iconName={ICONS.check.name} className={classes.checkIcon} onClick={saveView} />
      <div className={classes.cancel} onClick={cancelView}>
        X
      </div>
    </div>
  );
};
CreateOrEditViewName.propTypes = {
  viewName: PropTypes.string.isRequired,
  setViewName: PropTypes.func.isRequired,
  saveView: PropTypes.func.isRequired,
  cancelView: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};
const ViewsPanel = () => {
  const { setIsSavingsTracker } = useContext(HeatMapActionsContext);
  const recommendationsNewContext = useRecommendationsNewContext();
  const heatMapContext = useHeatMapContext();
  const { recommendationFilters, setRecommendationFilters, initFiltersState, showDoneCompletedOnly } =
    recommendationsNewContext;
  const { isWasteChart, setIsWasteChart, heatMapGroupByOptions } = heatMapContext;
  const { fetchRecViews, useCreateViewPanel, useUpdateViewPanel } = useRecViews(true);
  const { data: views, isLoading } = fetchRecViews();
  const { mutate: createView } = useCreateViewPanel();
  const { mutate: updateView } = useUpdateViewPanel();
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [creatViewName, setCreatViewName] = useState('');
  const [organizationViews, setOrganizationViews] = useState(
    Object.values(VIEW_NAMES).map((view) => ({
      view_name: view,
      is_default: false,
    })),
  );
  const [displayViews, setDisplayViews] = useState([]);
  const [selectedView, setSelectedView] = useState(VIEW_NAMES.MainView);
  const [selectedViewsType, setSelectedViewsType] = useState(VIEWS_TYPE.Organization);
  const [changeViewNameInput, setChangeViewNameInput] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const isViewNameExist = (name) => {
    if (selectedViewsType === VIEWS_TYPE.Personal) {
      return views && (views?.personalViews || []).some((view) => view.view_name === name);
    }
    return (
      views && [...(views.accountViews || []), ...(organizationViews || [])].some((view) => view.view_name === name)
    );
  };
  const getFiltersContext = (view) => {
    const filters = { ...getInitialFiltersStateCopy(), ...view.context_data.recommendationFilters };
    if (!filters?.dateRange || filters?.dateRange.value === DATE_RANGE_OPTIONS.CUSTOM.value) {
      return filters;
    }
    const dates = calculateStartAndEndDates(filters.dateRange);
    const createdDates = filters?.dateRange?.creationDate
      ? calculateStartAndEndDates(filters.dateRange.creationDate)
      : null;
    if (filters.dateRange.isCloseDate) {
      const completeFilters = {
        ...filters,
        [FILTERS.CLOSED_AND_DONE_RECS_DATES.id]: {
          ...filters[FILTERS.CLOSED_AND_DONE_RECS_DATES.id],
          [CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]: dates,
        },
      };

      if (createdDates) {
        completeFilters[FILTERS.CLOSED_AND_DONE_RECS_DATES.id][CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE] =
          createdDates;
      }
      return completeFilters;
    }
    return {
      ...filters,
      [FILTERS.OPEN_RECS_CREATION_DATE.id]: dates,
    };
  };
  const updateContext = (view) => {
    // set dynamic context data for heatMap area
    Object.keys(view.context_data).forEach((key) => {
      const setterFunctionName = `set${key.charAt(0).toUpperCase()}${key.slice(1)}`;
      if (typeof recommendationsNewContext[setterFunctionName] === 'function' && view.context_data[key] !== undefined) {
        const contextData = key === 'recommendationFilters' ? getFiltersContext(view) : view.context_data[key];
        recommendationsNewContext[setterFunctionName](contextData);
      } else if (typeof heatMapContext[setterFunctionName] === 'function' && view.context_data[key] !== undefined) {
        heatMapContext[setterFunctionName](view.context_data[key]);
      }
    });
  };

  useEffect(() => {
    if (!views?.accountViews?.length && !views?.personalViews?.length && !views?.organizationViews?.length) {
      if (!isLoading) {
        setSelectedView(VIEW_NAMES.MainView);
      }
      return;
    }
    const defaultView = views.organizationViews.find((view) => view.is_default);
    const initialViews = Object.values(VIEW_NAMES).map((view) => ({
      view_name: view,
      is_default: !defaultView && view === VIEW_NAMES.MainView,
    }));

    const mergedOrganizationViews = [...initialViews, ...views.organizationViews];

    setOrganizationViews(mergedOrganizationViews);
    // set new view to select after create mode
    const newView = [...views.personalViews, ...views.accountViews, mergedOrganizationViews].find(
      (view) => view.view_name === creatViewName,
    );
    if (newView) {
      setSelectedView(newView.id);
      setCreatViewName('');
      // Create new search parameters based on the selected view's ID
      const searchParams = new URLSearchParams({ viewId: newView.id }).toString();

      // Use navigate to update the URL with the new query parameter
      navigate(
        {
          pathname: location.pathname,
          search: `?${searchParams}`,
        },
        { replace: true },
      );
      return;
    }
    // load last selected view after render data
    const selectView = [...views.personalViews, ...views.accountViews, ...mergedOrganizationViews].find(
      (view) => view.id === selectedView,
    );
    if (selectView) {
      updateContext(selectView);
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    const viewId = searchParams.get('viewId');
    if (viewId) {
      const selectView = [...views.personalViews, ...views.accountViews, ...mergedOrganizationViews].find(
        (view) => view.id === viewId,
      );
      if (selectView) {
        updateContext(selectView);
        setSelectedView(viewId);
        return;
      }
    }
    if (searchParams.get('isSavingTracker')) {
      setRecommendationFilters(getHistoryFiltersViewStateCopy());
      setIsSavingsTracker(true);
      setSelectedView(VIEW_NAMES.SavingTracker);
      return;
    }
    if (searchParams.get('isMainView') || !defaultView) {
      setSelectedView(VIEW_NAMES.MainView);
      setRecommendationFilters(initFiltersState());
    } else if (defaultView) {
      setSelectedView(defaultView?.id);
      updateContext(defaultView);
    }
  }, [views]);

  useEffect(() => {
    if (!views?.accountViews?.length && !views?.personalViews?.length && !views?.organizationViews?.length) {
      if (selectedViewsType === VIEWS_TYPE.Organization) {
        setDisplayViews(organizationViews);
      }
      return;
    }
    switch (selectedViewsType) {
      case VIEWS_TYPE.Account:
        setDisplayViews(views.accountViews);
        break;
      case VIEWS_TYPE.Personal:
        setDisplayViews(views.personalViews);
        break;
      case VIEWS_TYPE.Organization:
      default:
        setDisplayViews(organizationViews);
        break;
    }
  }, [views, organizationViews, selectedViewsType]);

  const isNameValidAndUnique = useMemo(
    () => !invalidName && !isViewNameExist(creatViewName),
    [invalidName, creatViewName],
  );
  const optionSelected = (option) => () => {
    const selectedItem = [...views.personalViews, ...views.accountViews, ...organizationViews].find(
      (view) => view.view_name === option,
    );
    if (option === VIEW_NAMES.MainView) {
      setRecommendationFilters(getInitialFiltersStateCopy());
      setIsWasteChart(false);
      setIsSavingsTracker(false);
      navigate({
        pathname: location.pathname,
        search: `?${new URLSearchParams({ isMainView: true }).toString()}`,
      });
    } else if (option === VIEW_NAMES.SavingTracker) {
      if (!isWasteChart) {
        setRecommendationFilters(getHistoryFiltersViewStateCopy());
        setIsSavingsTracker(true);
      }
      navigate({
        pathname: location.pathname,
        search: `?${new URLSearchParams({ isSavingTracker: true }).toString()}`,
      });
    } else if (selectedItem) {
      updateContext(selectedItem);
      setIsSavingsTracker(false);
      setSelectedView(selectedItem?.id || option);
      const searchParams = new URLSearchParams({ viewId: selectedItem?.id }).toString();
      navigate({
        pathname: location.pathname,
        search: `?${searchParams}`,
      });
    }
    setSelectedView(selectedItem?.id || option);
  };

  const createNewView = () => {
    if (isViewNameExist(creatViewName)) {
      setInvalidName(true);
      return;
    }
    setInvalidName(false);
    setIsCreateMode(false);
    let dateRange = calculateRange(
      showDoneCompletedOnly
        ? recommendationFilters?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[
            CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE
          ]
        : recommendationFilters?.[FILTERS.OPEN_RECS_CREATION_DATE.id],
    );
    if (
      showDoneCompletedOnly &&
      recommendationFilters?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE]
    ) {
      dateRange = {
        ...dateRange,
        creationDate: calculateRange(
          recommendationFilters?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[
            CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE
          ],
        ),
      };
    }

    createView({
      viewName: creatViewName,
      contextData: {
        recommendationFilters: {
          ...recommendationFilters,
          dateRange: { ...dateRange, isCloseDate: showDoneCompletedOnly },
        },
        isWasteChart,
        heatMapGroupByOptions,
      },
      isDefault: false,
      viewType: selectedViewsType,
    });
  };

  const getViewPanelTooltip = () => {
    if ([VIEW_NAMES.MainView, VIEW_NAMES.SavingTracker].includes(selectedView)) {
      return 'Select View';
    }
    const selectViewName = [...views.personalViews, ...views.accountViews, ...organizationViews].find(
      (view) => view.id === selectedView,
    )?.view_name;
    return `You're in the ${selectViewName} view. \n Click the icon to save changes or to create a new view`;
  };

  return (
    <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <PopoverTrigger>
        <Tooltip title={getViewPanelTooltip()} classes={{ tooltip: classes.customTooltip }}>
          <div className={classes.actionsPanelButton} automation-id="heatmap-select-view">
            <GenerateIcon iconName={ICONS.views.name} />
          </div>
        </Tooltip>
      </PopoverTrigger>

      <PopoverPortal>
        <PopoverContent
          align="start"
          className={classes.popoverContent}
          style={{ position: 'absolute', top: '100%', right: '-35px', padding: '12px 0 0 0' }}
        >
          <div className={classes.tabWrapper}>
            <Tooltip title="These views are available to me" classes={{ tooltip: classes.customTooltip }}>
              <button
                className={`${classes.tabHeader} ${selectedViewsType === VIEWS_TYPE.Personal && classes.selected}`}
                onClick={() => setSelectedViewsType(VIEWS_TYPE.Personal)}
                type="button"
              >
                Personal Views
              </button>
            </Tooltip>
            <Tooltip
              title="These views are relevant to all accounts of the same cloud provider"
              classes={{ tooltip: classes.customTooltip }}
            >
              <button
                className={`${classes.tabHeader} ${selectedViewsType === VIEWS_TYPE.Organization && classes.selected}`}
                onClick={() => setSelectedViewsType(VIEWS_TYPE.Organization)}
                type="button"
              >
                Organization Views
              </button>
            </Tooltip>
            <Tooltip
              title="These views are relevant to a specific billing account"
              classes={{ tooltip: classes.customTooltip }}
            >
              <button
                className={`${classes.tabHeader} ${selectedViewsType === VIEWS_TYPE.Account && classes.selected}`}
                onClick={() => setSelectedViewsType(VIEWS_TYPE.Account)}
                type="button"
              >
                Account Views
              </button>
            </Tooltip>
          </div>
          {displayViews.map((view, index) => (
            <div
              className={`${classes.dropDownViewRow} ${
                isWasteChart && view.view_name === VIEW_NAMES.SavingTracker ? classes.disabledOption : ''
              }`}
              key={index}
              onClick={optionSelected(view.view_name)}
            >
              {changeViewNameInput && changeViewNameInput.id === view.id ? (
                <CreateOrEditViewName
                  viewName={changeViewNameInput.name}
                  setViewName={(val) => setChangeViewNameInput({ ...changeViewNameInput, name: val })}
                  saveView={() => {
                    if (isViewNameExist(changeViewNameInput.name)) {
                      setInvalidName(true);
                      return;
                    }
                    const updatedView = { ...view, view_name: changeViewNameInput.name, viewType: selectedViewsType };
                    updateView(updatedView);
                    setDisplayViews((prevViews) => prevViews.map((v) => (v.id === updatedView.id ? updatedView : v)));
                    setChangeViewNameInput(null);
                    setIsOpen(false);
                  }}
                  cancelView={() => {
                    setChangeViewNameInput(null);
                  }}
                  isValid={isNameValidAndUnique}
                />
              ) : (
                <>
                  <div className={classes.viewName}>
                    {view.view_name} {view.is_default && <span className={classes.defaultView}>default</span>}
                  </div>
                  <GenerateIcon
                    iconName={ICONS.eye.name}
                    className={`${classes.viewIcon} ${
                      ![view.view_name, view.id].includes(selectedView) ? classes.viewIconNotSelected : ''
                    }`}
                  />
                  <div className={classes.actionWrapper}>
                    {view.id === selectedView && (
                      <ViewActions
                        view={view}
                        changeViewName={setChangeViewNameInput}
                        setIsOpen={setIsOpen}
                        viewType={selectedViewsType}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
          <div className={classes.createWrapper}>
            {!isCreateMode ? (
              <div onClick={() => setIsCreateMode(!isCreateMode)}>
                <GenerateIcon iconName={ICONS.plus.name} className={classes.plusIcon} />
                <span>Create View</span>
              </div>
            ) : (
              <CreateOrEditViewName
                viewName={creatViewName}
                setViewName={setCreatViewName}
                saveView={createNewView}
                cancelView={() => {
                  setCreatViewName('');
                  setIsCreateMode(false);
                }}
                isValid={isNameValidAndUnique}
              />
            )}
          </div>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

export default ViewsPanel;
