import React, { useMemo } from "react";
import { SelectLinkedAccountHelp } from "./SelectLinkAccountField.tsx";
import commonStyles from "app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss";
import { AddClusterFields } from "../config.ts";

const useGetHelpMap = () => {
  return useMemo(() => ({
    [AddClusterFields.linkedAccountId]: {
      component: () => <SelectLinkedAccountHelp />
    },
    [AddClusterFields.clusterName]: {
      component: () => (
        <p className={commonStyles.helpText}>
          Enter the K8S cluster name, which should match the name in your cloud environment.
        </p>
      )
    },
    [AddClusterFields.agentNamespace]: {
      component: () => (
        <p className={commonStyles.helpText}>Enter the name of the agent namespace for the installation</p>
      )
    },
    [AddClusterFields.prometheusConnectingCreds]: {
      component: () => (
        <p className={commonStyles.helpText}>
          Base URL Prometheus or PromQL-compatible server (e.g. Thanos/Grafana), that has the query API endpoint. This
          is
          the default path for Prometheus installation. Override this to match your environment.
        </p>
      )
    },
    [AddClusterFields.httpHeaders]: {
      component: () => (
        <p className={commonStyles.helpText}>
          In case special HTTP request headers are required by Prometheus server, insert them as a "key":"value" format.
          A
          known case is a multi-tenant mode in some Grafana configurations that require the 'X-Scope-OrgID' header.
          <br /> E.g:
          <br />
          Key = X-Scope-OrgID
          <br />
          Value = YOUR-TENANT-NAME
        </p>
      )
    },
    [AddClusterFields.uniqueJobs]: {
      component: () => (
        <div className={commonStyles.helpText}>
          <p>
          <span>
            Using non-default name for the <span className={commonStyles.notice}>kube-state-metrics</span> job:
          </span>
            <br />
            Prometheus metrics generated by kube-state-metrics (e.g. kube_node_info, kube_node_labels) are labeled with
            "job" whose value is "kube-state-metrics" by default. If you use a non-default value, set it here. If you
            wish
            to ignore the job name when retrieving these metrics (required in case it's inconsistent, but there's only
            one
            single job responsible for each pod's metric), set an empty value.
          </p>
          <p>
          <span>
            Using non-default name for the <span className={commonStyles.notice}>kubelet</span> job:
          </span>
            <br />
            Prometheus metrics generated by kubelet (e.g. container_cpu_usage_seconds_total) are labeled with "job"
            whose
            value is by default "kubelet". If you use a non-default value, set it here. If you wish to ignore the job
            name
            when retrieving these metrics (required in case it's inconsistent, but there's only one single job
            responsible
            for each pod's metric), set an empty value.
          </p>
        </div>
      )
    },
    [AddClusterFields.otherMetrics]: {
      component: () => (
        <p className={commonStyles.helpText}>
          A general condition that matches metrics relevant to this cluster. This is required only in environments where
          metrics are stored from multiple clusters, so this conditions filters out all the rest.
        </p>
      )
    },
    [AddClusterFields.prometheusUrl]: {
      component: () => (
        <p className={commonStyles.helpText}>
          Base URL to Prometheus or PromQL-compatible server (e.g. Thanos/Grafana), that has the query API endpoint.
          This
          is the default path for Prometheus installation. Override this to match your environment.
        </p>
      )
    }
  }), []);
};

export default useGetHelpMap;
