import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {GenerateIcon, ICONS} from '@pileus-cloud/anodot-frontend-common';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import PercentTag from 'app/containers/MainDashboard/components/PercentTag.jsx';
import styles from './forecastChart.module.scss';


const getDateFormat = (date) => {
  return moment(date).format('MMM YYYY');
};
export const MonthlyChartCustomTooltip = ({ payload }) => {
  const {getCurrencyNumber} = useUserSettingsContext();
  if (!payload?.length) {
    return null;
  }
  const dataPoint = payload[0]?.payload;

  return (
    <div className={styles.customizedTooltip}>
      <div className={styles.tooltipTitleContainer}>
        <GenerateIcon iconName={ICONS.calendarDay.name}/>
        <div className={styles.tooltipTitle}>{getDateFormat(dataPoint.usageDate)}</div>
      </div>
      <div className={styles.tooltipDataContainer}>
        <span className={styles.key}>{payload[0]?.dataKey}:</span>
        <div className={styles.valueWrapper}>
          <span className={styles.value}>{getCurrencyNumber(payload[0]?.value)}</span>
          <PercentTag percent={dataPoint.percentChange}/>
        </div>
      </div>
    </div>
  )
};

MonthlyChartCustomTooltip.propTypes = {
  payload: PropTypes.object,
}
