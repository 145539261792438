import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/azure/services/ri-coverage';

const fetchAzureRiCoverage = async (startDate, endDate, isFull) =>
  API.get('billings', `${root}?${isFull ? 'isFull=true&' : ''}startDate=${startDate}&endDate=${endDate}`);

export default function azureRiCoverageProvider({ startDate, endDate }) {
  const queryClient = useQueryClient();

  const queryKey = [apiConstants.QUERY_KEYS.RI_COVERAGE, startDate, endDate];

  return {
    invalidate: () => queryClient.invalidateQueries({ queryKey }),
    reset: () => queryClient.resetQueries({ queryKey }),
    fetchAllAzureRiCoverage: () =>
      queryClient.fetchQuery({
        queryKey: [...queryKey, true],
        queryFn: () => fetchAzureRiCoverage(startDate, endDate, true),
        retry: false,
      }),
    fetchAzureRiCoverage: () =>
      useQuery({
        queryKey,
        queryFn: () => fetchAzureRiCoverage(startDate, endDate),
        retry: false,
      }),
  };
}
