import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import IconButton from '@mui/material/IconButton';
import { MoreVertical } from 'react-feather';
import { Routes } from 'shared/constants/routes';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import { usePermissions } from 'app/contexts/PermissionsContext';
import classes from './recommendationsList.module.scss';

const RecommendationActions = ({ addLabel, recType }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isHasPermission } = usePermissions();
  const toggleMenu = (e, closeMenu = false) => {
    e.stopPropagation();
    setAnchorEl(closeMenu ? null : e.currentTarget);
  };
  return (
    <div className={classes.actionWrapper} onClick={(e) => e.stopPropagation()}>
      <IconButton
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        disabled={!isHasPermission(OrganizationEntityCategory.Organization, Action.Update)}
        onClick={(e) => toggleMenu(e)}
        className={!isHasPermission(OrganizationEntityCategory.Organization, Action.Update) ? classes.disabled : ''}
        size="large"
        automation-id="rec-action"
      >
        <MoreVertical size={22} color="black" />
      </IconButton>
      <Menu
        classes={{ list: classes.menu, paper: classes.menuPaper }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => toggleMenu(e, true)}
      >
        <MenuItem classes={{ root: classes.menuItem }} onClick={addLabel}>
          <div>
            <GenerateIcon iconName={ICONS.hashtagSolid.name} className={classes.iconPadding} />
            <span className={classes.menuItem} automation-id="add-remove-label">
              Add/Remove Label
            </span>
          </div>
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          target="_blank"
          onClick={() => window.open(`${Routes.RECOMMENDATION_PREFERENCES}?search=${recType}`, '_blank')}
        >
          <div className={classes.flexItem}>
            <GenerateIcon iconName={ICONS.gear.name} className={classes.iconPadding} style={{ paddingTop: 11 }} />
            <span className={`${classes.menuItem} ${classes.multiLineText}`} automation-id="prefernces-page">
              <div>
                To disable <b>recommendations or resource of this type</b>, go to the Preferences page
              </div>
            </span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default RecommendationActions;
