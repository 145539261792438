import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const KmsIdle = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      comment: 'Note that as long as the keys are pending deletion, you can reverse this action.',
      instructions: [
        {
          actionText: 'For more information',
          actionLink: ExternalLinks.AWSDeleteKmsKey,
          actionCommand: `aws kms schedule-key-deletion --key- ${recommendation?.resourceId} --pending-window-in-days NUM_BTWEEN_7_TO_30`,
        },
        {
          actionText: 'To reverse the deletion you may use the following command:',
          actionCommand: `aws kms cancel-key-deletion --key-id ${recommendation?.resourceId}`,
        },
      ],
    },
  ];

  const description =
    'You are still being charged for disabled KMS that are no longer activated, and we recommend to schedule deleting them.\n' +
    'By running the following command, these KMS keys will be scheduled to be deleted within 7-30 days.';
  return <BaseRecommendation recommendation={recommendation} description={description} commandsList={commandsList} />;
};

KmsIdle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default KmsIdle;
