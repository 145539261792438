import { BYOD_ONBOARDING_STEPS, BYOD_ONBOARDING_STEPS_SHORT_TITLES } from './ByodOnboardingConstants';

export const handleNextScreen = (screen) => {
  switch (screen) {
    case BYOD_ONBOARDING_STEPS.BYOD_DETAILS:
      return BYOD_ONBOARDING_STEPS.UPLOAD;
    case BYOD_ONBOARDING_STEPS.UPLOAD:
      return BYOD_ONBOARDING_STEPS.PROCESS_DATA;
    default:
      return screen;
  }
};

export const handlePrevScreen = (screen) => {
  switch (screen) {
    case BYOD_ONBOARDING_STEPS.UPLOAD:
      return BYOD_ONBOARDING_STEPS.BYOD_DETAILS;
    case BYOD_ONBOARDING_STEPS.PROCESS_DATA:
      return BYOD_ONBOARDING_STEPS.UPLOAD;
    default:
      return screen;
  }
};

export const getPageForPausedOnboarding = (onboarding) => {
  const {
    validationStatus,
    accountSetupStatus,
    userManagementStatus,
    validationShouldStart,
    invoiceShouldStart,
    invoiceStatus,
    validationStarted,
  } = onboarding;
  if (
    ['SUCCESS', 'FAILED', 'RUNNING'].includes(invoiceStatus?.status) ||
    (userManagementStatus?.status === 'SUCCESS' && invoiceShouldStart)
  ) {
    if (invoiceShouldStart && invoiceStatus?.status === 'RUNNING') {
      return BYOD_ONBOARDING_STEPS.PROCESS_DATA;
    }
    return BYOD_ONBOARDING_STEPS.PROCESS_DATA;
  }
  if (accountSetupStatus?.status === 'RUNNING' || userManagementStatus?.status === 'RUNNING') {
    return BYOD_ONBOARDING_STEPS.PROCESS_DATA;
  }
  const isRunning = validationStatus?.status === 'RUNNING';
  if (validationStatus?.status === 'SUCCESS') {
    return BYOD_ONBOARDING_STEPS.K8S_INTEGRATION;
  }
  if ((validationShouldStart && isRunning) || validationStarted) {
    return BYOD_ONBOARDING_STEPS.K8S_INTEGRATION;
  }
  if (validationShouldStart && ['FAILED'].includes(validationStatus?.status)) {
    return BYOD_ONBOARDING_STEPS.K8S_INTEGRATION;
  }
  return BYOD_ONBOARDING_STEPS.BYOD_DETAILS;
};

export const getPageTitleForPausedOnboarding = (onboarding) =>
  BYOD_ONBOARDING_STEPS_SHORT_TITLES[getPageForPausedOnboarding(onboarding)];
