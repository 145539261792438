import React from 'react';
import { ReactComponent as SecretSVG } from 'shared/img/images/secret.svg';
import { ExternalLinks } from 'shared/enums/external-links.enum';
import styles from './UploadPage.module.scss';

const UploadPageHelp = {
  s3Path: {
    component: () => (
      <div>
        <p className={styles.helpText}>
          Use the S3 path and the Keys in your scripts to upload files to Anodot Cost.
          <br />
          You can copy the path using the copy to clipboard icon.
        </p>
        <a href={ExternalLinks.ByodOnboarding} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      </div>
    ),
  },
  accessKey: {
    component: () => (
      <div className={styles.accessKeyHelp}>
        <p className={styles.helpText}>
          This one time link will provide you the access keys. Store these keys in a safe location and use them to
          access the S3 bucket, together with the S3 path.
        </p>
        <p className={styles.helpText}>
          If you have already requested such keys, and you are unable to retrieve them. Contact Anodot Support to
          regenerate new keys for you.
        </p>
        <a href={ExternalLinks.ByodOnboarding} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
        <SecretSVG />
      </div>
    ),
  },
};

export default UploadPageHelp;
