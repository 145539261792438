import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/azure/services/ri-coverage';

const fetchVmAndStorageCost = async (startDate, endDate, limit) =>
  API.get(
    'billings',
    `${root}/vmAndStorageCost?${limit ? `limit=${limit}&` : ''}startDate=${startDate}&endDate=${endDate}`,
  );

export default function vmAndStorageCostProvider({ startDate, endDate, isPpApplied, limit }) {
  const queryClient = useQueryClient();

  const queryKey = [apiConstants.QUERY_KEYS.AZURE_VM_AND_STORAGE_COST, startDate, endDate, isPpApplied, limit];

  return {
    invalidate: () => queryClient.invalidateQueries({ queryKey }),
    reset: () => queryClient.resetQueries({ queryKey }),
    // This fetch is used for exporting ALL data to CSV, thus no need for limit parameter.
    fetchAllVmAndStorageCost: () =>
      queryClient.fetchQuery({
        queryKey,
        queryFn: () => fetchVmAndStorageCost(startDate, endDate),
        retry: false,
      }),
    fetchVmAndStorageCost: (currentLimit) =>
      useQuery({
        queryKey: [...queryKey, currentLimit || limit],
        queryFn: () => fetchVmAndStorageCost(startDate, endDate, currentLimit || limit),
        retry: false,
      }),
  };
}
