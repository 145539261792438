import React, { useCallback, useMemo } from 'react';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import Policies from './components/Policies';
import GeneralDetails from './components/GeneralDetails';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';

import styles from './Settings.module.scss';

const Settings = () => {
  const { usersStore } = useRootStore();
  const { currDispUserCloudAccountType: cloudAccountType, isCurrentUserReCustomer } = usersStore;

  const checkIfPoliciesTabDisplayed = useCallback(
    () => cloudAccountType === CLOUD_TYPE_IDS.AWS && !isCurrentUserReCustomer,
    [cloudAccountType, isCurrentUserReCustomer],
  );

  const settingsNavItems = useMemo(
    () => ({
      POLICIES: { id: 0, name: 'Policies', disabled: !checkIfPoliciesTabDisplayed },
      GENERAL_INFO: { id: 1, name: 'General Info', disabled: isCurrentUserReCustomer },
    }),
    [checkIfPoliciesTabDisplayed, isCurrentUserReCustomer],
  );
  const arrayOfEnabled = Object.values(settingsNavItems).filter((nav) => !nav.disabled);

  const defaultAccountIndex = useMemo(() => {
    return arrayOfEnabled?.[0]?.id;
  }, [arrayOfEnabled]);

  return (
    <ColoredTabs
      className={styles.tab}
      arrOfNavItems={arrayOfEnabled}
      vertical
      defaultAccountIndex={defaultAccountIndex}
    >
      <Policies usersStore={usersStore} />
      <GeneralDetails usersStore={usersStore} />
    </ColoredTabs>
  );
};

export default Settings;
