import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { ClickAwayListener } from '@mui/material';

import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { formatDateTime } from 'shared/utils/dateUtil';
import Input from 'shared/components/andtComponents/Input';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { useNotifications } from 'usage/hooks/react-query/useNotifications';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ReactComponent as NoSearchDataImg } from 'shared/img/icons/no-options.svg';
import { prepareLinkAccountLabel } from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers';
import { ReactComponent as NoDataImg } from './assets/NoDataImg.svg';
import Notification from './Notification/Notification';
import styles from './Notifications.module.scss';
import NotificationsUtils from './notificaitonsUtils';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const Notifications = ({ onClose, notifications }) => {
  const { usersStore } = useRootStore();
  const { currDispUserCloudAccountType } = usersStore;
  const { excludeLinkedAccounts } = useNotifications();
  const handleExcludeLinkedAccounts = excludeLinkedAccounts();

  const [searchText, setSearchText] = React.useState('');
  const [selected, setSelected] = React.useState({});

  const notificationsFiltered = useMemo(() => {
    const data = notifications
      .map((notification) => {
        const config = NotificationsUtils.getNotificationRenderDetails(notification.type, notification.details, {
          isCurrentUserReseller: usersStore.isCurrentUserReseller,
          cloudType: currDispUserCloudAccountType,
        });
        if (!config) {
          return null;
        }
        return {
          type: notification.type,
          id: notification.details.id,
          details: notification.details,
          title: config.title,
          subTitle: config.subTitle,
          description: config.description,
          icon: config.icon,
          excludable: config.excludable,
        };
      })
      .filter((notification) => {
        if (!notification) {
          return false;
        }
        if (!searchText) {
          return true;
        }
        return (
          notification.title.match(new RegExp(searchText, 'i')) ||
          notification.subTitle.match(new RegExp(searchText, 'i'))
        );
      });
    return data;
  }, [notifications, searchText]);
  const csvData = useMemo(() => {
    const data = notificationsFiltered.map((notification) => ({
      type: notification.type,
      id: notification.details.id,
      details: JSON.stringify(notification.details),
    }));
    return data;
  }, [notificationsFiltered]);
  const renderBody = () => {
    if (searchText && !notificationsFiltered.length) {
      return (
        <div className={styles.noData}>
          <NoSearchDataImg />
          <p>No results</p>
        </div>
      );
    }
    if (!notificationsFiltered.length) {
      return (
        <div className={styles.noData}>
          <NoDataImg />
          <p>You currently have no notifications</p>
        </div>
      );
    }
    const isSelected = notificationsFiltered.every((n) => !n.excludable || selected[n.details.id]);
    return (
      <>
        {searchText && (
          <div className={styles.selectAllListItem}>
            <Checkbox
              isChecked={isSelected}
              text="Select All"
              onChange={() => {
                if (isSelected) {
                  setSelected({});
                  return;
                }
                setSelected({
                  ...selected,
                  ...notificationsFiltered
                    .filter((n) => n.excludable)
                    .reduce((acc, notification) => {
                      acc[notification.details.id] = true;
                      return acc;
                    }, {}),
                });
              }}
              primary
            />
          </div>
        )}
        {notificationsFiltered.map((notification) => (
          <Notification
            excludeLoading={handleExcludeLinkedAccounts.isLoading}
            checked={!!selected[notification.details.id]}
            onChecked={(val) => setSelected({ ...selected, [notification.details.id]: val })}
            notification={notification}
            onExclude={() => {
              handleExcludeLinkedAccounts.mutate({ ids: [notification.details.id] });
            }}
            key={JSON.stringify(notification.details) + notification.type}
          />
        ))}
      </>
    );
  };
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <p>
            <span className={styles.headerIcon}>
              <GenerateIcon iconName={ICONS.bell.name} />
            </span>
            Notifications
            <span className={styles.headerInfoIcon}>
              <InfoPopover isSimple>
                <div className={styles.tooltip}>
                  We’ll list notifications on process completions, failures and other platform related messages that
                  affect our ability to provide cost insights and recommendations.{' '}
                  <a href={ExternalLinks.SystemNotifications} target="_blank" rel="noreferrer noopener">
                    Learn more here
                  </a>
                </div>
              </InfoPopover>
            </span>
          </p>
          <div className={styles.searchContainer}>
            <Input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
              isSearch
              searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
              type="text"
            />
          </div>
        </div>
        <div className={styles.content}>{renderBody()}</div>
        <div className={styles.footer}>
          <Tooltip
            title={
              Object.values(selected).filter(Boolean).length === 0
                ? 'You can exclude each account individually or exclude in bulk by searching a value and clicking the ‘Select All’ button'
                : `Don't show notifications on these ${prepareLinkAccountLabel(
                    currDispUserCloudAccountType,
                    false,
                  ).toLowerCase()} again`
            }
          >
            <span>
              <Button
                text="Don’t show this again"
                isTextButton
                disabled={Object.values(selected).filter(Boolean).length === 0}
                icon={() => <GenerateIcon iconName={ICONS.excluded.name} />}
                onClick={async () => {
                  await handleExcludeLinkedAccounts.mutateAsync({
                    ids: Object.keys(selected).filter((id) => selected[id]),
                  });
                  setSearchText('');
                  setSelected({});
                }}
                isLoading={handleExcludeLinkedAccounts.isLoading}
              />
            </span>
          </Tooltip>
          <div className={styles.right}>
            {notificationsFiltered.length > 0 && (
              <CustomCSVDownload
                isLoading={false}
                filesNumber={1}
                isPrimary
                hideText
                showDownloadIcon
                separator=";"
                data={[
                  {
                    data: csvData,
                    filename: `notifications_${usersStore.getCurrDisplayedAccountId()}_${formatDateTime()}.csv`,
                  },
                ]}
              >
                CSV
              </CustomCSVDownload>
            )}
            <Button text="Close" icon={() => <GenerateIcon iconName={ICONS.xMark.name} />} onClick={() => onClose()} />
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

Notifications.propTypes = {
  onClose: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
};

export default Notifications;
