import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ICONS, UserSettings, UserSettingsContext, ListItem, ListContent } from '@pileus-cloud/anodot-frontend-common';
import UserPicker from 'app/containers/Layout/topNavbar/components/UserPicker';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { Routes } from 'shared/constants/routes';

const UserAccountSettings = ({ handleLogout, classes, currDisplayedAccount = null }) => {
  const { usersStore, appStore } = useRootStore();
  const navigate = useNavigate();
  const showStopImpersonationButton = !!(appStore.isKeyCloakManagement && usersStore.isImpersonate);
  const displayUsersList = !!currDisplayedAccount && !appStore.isKeyCloakManagement;

  const handleMainUserClick = async () => {
    const { authUserKey } = usersStore;
    await usersStore.handleDisplayedUserChange(authUserKey);
    usersStore.rootStore.fetchData(authUserKey);
    navigate(Routes.LANDING);
  };

  const handleStopImpersonation = async () => {
    await usersStore.stopImpersonation();
    navigate(Routes.LANDING);
  };

  const accountClicked = () => {
    navigate(Routes.ACCOUNT);
  };
  const logoutClicked = async () => {
    await handleLogout();
  };

  // eslint-disable-next-line react/prop-types
  function UserSettingListItem({ value, iconName, isActive, onSelect }) {
    const { toggleOpen } = useContext(UserSettingsContext);
    const handleOnSelect = () => {
      onSelect();
      toggleOpen();
    };
    return <ListItem value={value} iconName={iconName} onSelect={handleOnSelect} isActive={isActive} />;
  }

  return (
    <UserSettings tooltip={usersStore.getCurrUserName} value={usersStore.getCurrUserName} id="userSettings">
      <ListContent>
        {showStopImpersonationButton && (
          <UserSettingListItem value="Stop impersonation" onSelect={handleStopImpersonation} />
        )}
        {currDisplayedAccount ? (
          <UserSettingListItem value="Account" onSelect={accountClicked} iconName={ICONS.gear.name} />
        ) : null}
        {displayUsersList && (
          <>
            <UserPicker store={usersStore} navigate={navigate} classes={classes} />
            <UserSettingListItem
              value="Main user"
              onSelect={handleMainUserClick}
              iconName={ICONS.userSecretSlash.name}
            />
          </>
        )}
        <UserSettingListItem value="Log out" onSelect={logoutClicked} iconName={ICONS.arrowRightFromBracket.name} />
      </ListContent>
    </UserSettings>
  );
};

UserAccountSettings.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currDisplayedAccount: PropTypes.object,
};

export default UserAccountSettings;
