import React, { useEffect, useMemo } from 'react';
import { PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import {
  getFirstAndLastDayOfPreviousMonth,
  getSearchParamsPanel,
  sumTotalCost,
} from 'app/containers/MainDashboard/utils.js';
import { Routes } from 'shared/constants/routes.js';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import UsageCost from '../components/UsageCost.jsx';
import styles from './costPanels.module.scss';
const PreviousMonthCost = () => {
  const { updatePanelLoadingState, getDynamicFilters } = useMainDashboardContext();
  const cueHook = useCueData();
  const { appStore } = useRootStore();

  const previousMonthParams = {
    ...PANELS[PANELS_TYPES.PREVIOUS_MONTH_COST].params,
    ...getFirstAndLastDayOfPreviousMonth(),
    ...getDynamicFilters(),
    isPpApplied: appStore.isPpApplied,
  };
  const { data: previousMonthData, isLoading } = cueHook.fetchCueData(previousMonthParams);

  const previousCost = useMemo(() => sumTotalCost(previousMonthData), [previousMonthData]);

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.PREVIOUS_MONTH_COST, isLoading);
  }, [isLoading]);

  return (
    <div className={styles.costPanel}>
      {isLoading ? (
        <Spinner className={styles.spinner} size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <UsageCost
          title={PANELS[PANELS_TYPES.PREVIOUS_MONTH_COST].title}
          value={previousCost}
          infoValue={PANELS[PANELS_TYPES.PREVIOUS_MONTH_COST].info}
          tooltip="Open Cost & Usage Explorer page"
          navigateTo={{
            path: Routes.COST_USAGE_EXPLORER,
            searchParams: getSearchParamsPanel(previousMonthParams, appStore.isPpApplied),
          }}
        />
      )}
    </div>
  );
};

export default PreviousMonthCost;
