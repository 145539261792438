import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useQueryClient } from '@tanstack/react-query';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { DataTypeProvider, IntegratedPaging, PagingState, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, TableColumnResizing, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import Spinner from 'shared/components/andtComponents/Spinner';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import Button from 'shared/components/andtComponents/Button';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { formatDateToLabel } from 'shared/utils/dateUtil';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import useTable from 'shared/hooks/customHooks/useTable';
import { Routes } from 'shared/constants/routes';
import { useMultiAccountsResellerCustomerRoles } from 'shared/hooks/react-query/useRoles';
import { ReactComponent as UserIcon } from 'divisions/containers/ManageCustomers/assets/user.svg';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import UsersTable from 'divisions/containers/ManageCustomers/components/UsersTable';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import apiConstants from 'shared/api/apiConstants';
import { getDivisionUsers } from 'divisions/utils';
import LinkedAccountsTable from '../LinkedAccountsTable/LinkedAccountsTable';
import styles from './CustomersTable.module.scss';
import { useNavigate } from 'react-router-dom';

const CUSTOMERS_COLUMN_EXTENSIONS = [
  { columnName: 'divisionName', width: '50%' },
  { columnName: 'details', width: 'auto' },
];

const CUSTOMERS_TABLE_COLUMNS = [
  {
    name: 'divisionName',
    title: '',
    getCellValue: (row) => (
      <div className={styles.rowTypeNameCell}>
        <UserIcon />
        {row.divisionNameDisplay || row.divisionName}
        {row.divisionCode ? (
          <Tooltip title="Customer code">
            <span>{row.divisionCode}</span>
          </Tooltip>
        ) : null}
      </div>
    ),
  },
  { name: 'details', title: '' },
];

const CustomersTable = ({
  editCustomerHandler,
  editedLinkedAccountsCustomerHandler,
  filters,
  handleUpdateLinkedAccounts,
  addUserHandler,
  expandedRowIds,
  setExpandedRowIds,
  customers,
  renderCSVDownload,
}) => {
  const navigate = useNavigate();
  const { usersStore } = useRootStore();
  const queryClient = useQueryClient();
  const { NewTableRow, NewTableRowDetail, NewTableWrapper } = useTable();
  const [deletedCustomer, setDeletedCustomer] = useState(null);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { data: multiAccountsResellerCustomers = [], isLoading: multiAccountsResellerCustomerLoading } =
    useMultiAccountsResellerCustomerRoles();
  const usersList = usersStore.getSubUsersListByDisplayedType();
  const deleteCustomerButtonClicked = (deletedCustomer) => {
    setDeletedCustomer(deletedCustomer);
  };
  const customerDeleteWarningHandler = async (action) => {
    if (action === 'delete') {
      setIsLoading(true);
      await usersStore.deleteDivisionGroup(deletedCustomer.divisionId, deletedCustomer.divisionName);
      setIsLoading(false);
      queryClient.invalidateQueries({ queryKey: [apiConstants.QUERY_KEYS.DIVISIONS] });
    }
    setDeletedCustomer(null);
  };
  const getIsAddUserDisabled = (row) =>
    !addUserHandler || (row.roleId && multiAccountsResellerCustomers.find(({ roleId }) => roleId === row.roleId));
  const editFormatter = (data) => {
    if (!editedLinkedAccountsCustomerHandler) {
      return null;
    }
    const rowInd = customers.findIndex((r) => r.divisionId === data.row.divisionId);
    const { currDispUserCloudAccountType, getCurrDisplayedAccount } = usersStore;
    return (
      <ReadOnlyDisplayWrapper
        isHide={false}
        category={OrganizationEntityCategory.ResellerCustomers}
        action={Action.Update}
      >
        <Tooltip title={`Add/Edit ${getLinkedAccountName(currDispUserCloudAccountType)}s`}>
          <span className={styles.secondaryButton}>
            <Button
              text=""
              disabled={getCurrDisplayedAccount?.autoAssignLinkedAccounts}
              automationId={`addLinkedAccountsButton-${data.row.divisionName}`}
              isSecondaryWithHover={!expandedRowIds.includes(rowInd)}
              icon={() => <GenerateIcon iconName={ICONS.addLinkedAccounts.name} />}
              onClick={() => editedLinkedAccountsCustomerHandler(data.row)}
            />
          </span>
        </Tooltip>
      </ReadOnlyDisplayWrapper>
    );
  };
  const addFormatter = (data) => {
    if (!addUserHandler) {
      return null;
    }
    const rowInd = customers.findIndex((r) => r.divisionId === data.row.divisionId);
    return (
      <ReadOnlyDisplayWrapper
        isHide={false}
        category={OrganizationEntityCategory.ResellerCustomers}
        action={Action.Update}
      >
        <Tooltip title="Add User">
          <span className={styles.secondaryButton}>
            <Button
              text=""
              automationId={`addUserButton-${data.row.divisionName}`}
              isLoading={multiAccountsResellerCustomerLoading}
              disabled={getIsAddUserDisabled(data.row)}
              isSecondaryWithHover={!expandedRowIds.includes(rowInd)}
              icon={() => <GenerateIcon iconName={ICONS.addUser.name} />}
              onClick={() => addUserHandler(data.row)}
            />
          </span>
        </Tooltip>
      </ReadOnlyDisplayWrapper>
    );
  };
  const actionsFormatter = (data) => (
    <ButtonDropdown
      text=""
      isTextButton
      icon={() => <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.menuIcon} />}
      automationId={`customersactions-${data.row.divisionName}`}
    >
      <ReadOnlyDisplayWrapper
        isHide={false}
        category={OrganizationEntityCategory.ResellerCustomers}
        action={Action.Update}
      >
        <li onClick={() => editCustomerHandler(data.row)} id="edit">
          <span>
            <GenerateIcon iconName={ICONS.edit.name} /> Edit Customer
          </span>
        </li>
      </ReadOnlyDisplayWrapper>
      <ReadOnlyDisplayWrapper
        isHide={false}
        category={OrganizationEntityCategory.ResellerCustomers}
        action={Action.Update}
      >
        <li onClick={() => deleteCustomerButtonClicked(data.row)} id="delete">
          <span>
            <GenerateIcon iconName={ICONS.delete.name} /> Delete Customer
          </span>
        </li>
      </ReadOnlyDisplayWrapper>
    </ButtonDropdown>
  );

  const renderDiveInto = (row) => (
    <Tooltip title="Open Customer scope">
      <span>
        <Button
          isTextButton
          automationId="diveIntoCustomer"
          text=""
          onClick={() => {
            usersStore.deprecatedChangeCustomerUserType();
            usersStore.rootStore.appStore.updatePricingMode(true);
            usersStore.updateCurrDisplayedDivIdAndName(row.divisionId, row.divisionName, row.parentAccountKey);
            usersStore.rootStore.invalidateStores();
            navigate(Routes.COST_USAGE_EXPLORER);
          }}
          icon={() => <GenerateIcon iconName={ICONS.magnifyingGlassDollar.name} />}
        />
      </span>
    </Tooltip>
  );

  const detailsFormatter = (data) => (
    <div className={styles.detailsCell}>
      <div className={styles.dateCell}>Created: {formatDateToLabel(data.row.dbCreationTime)}</div>
      {addFormatter(data)}
      {editFormatter(data)}
      {renderCSVDownload(data.row)}
      {renderDiveInto(data.row)}
      {actionsFormatter(data)}
    </div>
  );

  const renderTableRow = ({ row, children, ...restProps }) => (
    <NewTableRow
      spacerHeight={16}
      expandedRowIds={expandedRowIds}
      setExpandedRowIds={setExpandedRowIds}
      {...restProps}
      automation-id={`customer-row-${row.divisionNameDisplay}`}
    >
      {children}
    </NewTableRow>
  );

  const renderRowDetail = (data) => (
    <NewTableRowDetail>
      <div className={styles.expandedRow}>
        <LinkedAccountsTable customer={data.row} handleUpdateLinkedAccounts={handleUpdateLinkedAccounts} />
        <UsersTable
          customerId={data.row.divisionId}
          addUserDisabled={getIsAddUserDisabled(data.row)}
          addUserHandler={addUserHandler ? () => addUserHandler(data.row) : null}
          users={getDivisionUsers(usersList, data.row.divisionName).filter((user) =>
            filters && filters.userName.length ? filters.userName.some((u) => u.value === user.userName) : true,
          )}
        />
      </div>
    </NewTableRowDetail>
  );

  if (isLoading) {
    return (
      <div style={{ width: '100%', marginTop: '40px' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <NewTableWrapper className={styles.container}>
      <Grid rows={customers} columns={CUSTOMERS_TABLE_COLUMNS}>
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
        <PagingState defaultCurrentPage={0} defaultPageSize={15} currentPage={page} onCurrentPageChange={setPage} />
        <IntegratedPaging />
        <DataTypeProvider for={['details']} formatterComponent={detailsFormatter} />
        <TableWrapper rowComponent={renderTableRow} />
        <TableColumnResizing defaultColumnWidths={CUSTOMERS_COLUMN_EXTENSIONS} resizingMode="nextColumn" />
        <TableRowDetail contentComponent={renderRowDetail} />
        <PagingPanel pageSizes={[5, 10, 15]} />
      </Grid>
      <DeleteWarningModal
        deletedItemName={deletedCustomer && (deletedCustomer.divisionNameDisplay || deletedCustomer.divisionName)}
        isOpen={deletedCustomer}
        handleDelete={customerDeleteWarningHandler}
        warningMessage="Be advised you are about to delete"
        modalTitle="Delete Customer"
      />
    </NewTableWrapper>
  );
};

CustomersTable.propTypes = {
  editCustomerHandler: PropTypes.func.isRequired,
  editedLinkedAccountsCustomerHandler: PropTypes.func.isRequired,
  duplicateCustomerHandler: PropTypes.func.isRequired,
  addUserHandler: PropTypes.func.isRequired,
  handleUpdateLinkedAccounts: PropTypes.func.isRequired,
  renderCSVDownload: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  expandedRowIds: PropTypes.array.isRequired,
  setExpandedRowIds: PropTypes.func.isRequired,
};

export default observer(CustomersTable);
