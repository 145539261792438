import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as NoData } from '../img/no_chart_data.svg';
import DailyCostAreaChart from './DailyCostAreaChart';
import styles from './budgets.module.scss';

const DailyCostAreaChartWrapper = ({
  data,
  customLegend = null,
  budget,
  isDashboardPanel = false,
  scrollToMonth = null,
}) => {
  if (data?.length || !budget.isFutureBudget) {
    return (
      <DailyCostAreaChart
        data={data}
        customLegend={customLegend}
        budget={budget}
        isDashboardPanel={isDashboardPanel}
        scrollToMonth={scrollToMonth}
      />
    );
  }
  return (
    <div className={styles.noChartDataWrapper}>
      <NoData />
      <span className={styles.redText}>This budget is set for the future, so no data is available yet.</span>
      <span className={styles.simpleText}>Data will appear once the budget becomes active in its starting month</span>
    </div>
  );
};

DailyCostAreaChartWrapper.propTypes = {
  data: PropTypes.array.isRequired,
  customLegend: PropTypes.element,
  budget: PropTypes.object.isRequired,
  isDashboardPanel: PropTypes.bool,
  scrollToMonth: PropTypes.func,
};
export default DailyCostAreaChartWrapper;
