import { useQuery } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/users/admin-accounts/aws-account-status';
export const useAccountStatus = (accountId) => {
  const queryKey = [apiConstants.QUERY_KEYS.ADMIN_ACCOUNTS_STATUS, accountId];
  return useQuery({
    queryKey,
    queryFn: () => API.get('billings', `${root}?accountId=${accountId}`),
    refetchInterval: 3 * 1000,
    enabled: !!accountId,
  });
};
