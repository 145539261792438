import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import {
  AZURE_PROPERTIES,
  DB_PROPERTIES,
  ENGINE_PROPERTIES,
  VOLUMES_PROPERTIES,
} from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const AZURE_VM_STOPPED_PROPERTIES = {
  STOP_REASON: {
    label: 'Stop Reason',
    getterFunction: (recommendation) => recommendation?.recData?.stop_reason,
  },
  STOP_TIME: {
    label: 'Stop Time',
    getterFunction: (recommendation) => recommendation?.recData?.stop_time,
  },
};

const AzureVmStopped = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `az vm stop --name ${recommendation?.resourceName} --g ${recommendation?.recData?.resource_group}`,
        },
        {
          actionText: '',
          actionCommand: `az vm deallocate --name ${recommendation?.resourceName} --g ${recommendation?.recData?.resource_group}`,
        },
        {
          actionText: 'Properly Shutdown Azure VM to Save Money',
          actionLink: ExternalLinks.AzureVMShutdownTipsToSaveMoney
        },
      ],
    },
  ];
  const description = 'This VM instance is stopped but carries additional charges. You should consider terminating it.';

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{
        ...ENGINE_PROPERTIES,
        ...AZURE_PROPERTIES,
        ...DB_PROPERTIES,
        ...VOLUMES_PROPERTIES,
        ...AZURE_VM_STOPPED_PROPERTIES,
      }}
    />
  );
};

AzureVmStopped.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureVmStopped;
