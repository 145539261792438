import React from 'react';
import classNames from 'classnames';
import CommitmentData from 'app/containers/MainDashboard/panels/MonitoringData/CommitmentData.jsx';
import AnomaliesData from 'app/containers/MainDashboard/panels/MonitoringData/AnomaliesData.jsx';
import BudgetsData from 'app/containers/MainDashboard/panels/MonitoringData/BudgetsData.jsx';
import styles from './monitoringData.module.scss';
import mainStyles from '../../mainDashboardCommon.module.scss';

const MonitoringDataWrapper = () => {
  return (
    <div className={classNames(styles.monitoringDataWrapper, mainStyles.halfWidthPanelWrapper)}>
      <div className={styles.flexRow}>
        <AnomaliesData />
        <div className={styles.flexWrapper}>
          <BudgetsData />
          <CommitmentData />
        </div>
      </div>
    </div>
  );
};

export default MonitoringDataWrapper;
