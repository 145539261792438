import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { isNil } from 'lodash';
import styles from './percentTag.module.scss';

const PercentTag = ({ percent, iconText }) => {
  if (percent === Infinity) {
    return null;
  }
  const isArrowUp = percent > 0 || isNil(percent);

  return (
    <div
      className={classNames(
        styles.percentTag,
        (isArrowUp || iconText) && styles.redTag,
        percent === 0 && styles.grayTag,
        iconText && styles.bigWidth,
      )}
    >
      {!isNil(percent) ? `${(percent ?? 0)?.toFixed(2)}%` : '+ 100%'}
      {percent !== 0 && !iconText && (
        <GenerateIcon
          automation-id="arrow-icon"
          iconName={isArrowUp ? ICONS.arrowTrendUp.name : ICONS.arrowTrendDown.name}
        />
      )}
      {iconText && <span className={classNames(styles.percentTxt)}>{iconText}</span>}
    </div>
  );
};

PercentTag.propTypes = {
  percent: PropTypes.number.isRequired,
  iconText: PropTypes.string,
};
export default PercentTag;
