import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { toast } from 'react-toastify';

const root = '/api/v1/msp/billing-rules/v2';

const getBillingRules = () => API.get('billings', root);
const createBillingRule = (billingRule) => API.post('billings', root, { body: billingRule });
const updateBillingRule = (billingRule) => API.put('billings', root, { body: billingRule });
const deleteBillingRule = (ruleId) => API.del('billings', `${root}/${ruleId}`);

export default function useBillingRules() {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.BILLING_RULES];

  return {
    getBillingRules: (options = {}) =>
      useQuery({
        queryKey,
        queryFn: getBillingRules,
        onError: () => toast.error('Error fetching Billing Rules'),
        staleTime: 10 * 60 * 1000,
        ...options,
      }),
    createBillingRule: ({ onSuccess = () => {}, ...options } = {}) =>
      useMutation({
        mutationFn: createBillingRule,
        onSuccess: () => {
          toast.success('Billing Rule created');
          queryClient.invalidateQueries({ queryKey });
          onSuccess();
        },
        onError: () => toast.error('Error creating Billing Rule'),
        ...options,
      }),
    updateBillingRule: ({ onSuccess = () => {}, ...options } = {}) =>
      useMutation({
        mutationFn: updateBillingRule,
        onSuccess: () => {
          toast.success('Billing Rule updated');
          queryClient.invalidateQueries({ queryKey });
          onSuccess();
        },
        onError: () => toast.error('Error updating Billing Rule'),
        ...options,
      }),
    deleteBillingRule: (options = {}) =>
      useMutation({
        mutationFn: deleteBillingRule,
        onSuccess: () => {
          toast.success('Billing Rule deleted');
          queryClient.invalidateQueries({ queryKey });
        },
        onError: () => toast.error('Error deleting Billing Rule'),
        ...options,
      }),
  };
}
