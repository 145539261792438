import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';

const { QUERY_KEYS } = apiConstants;

const getS3BucketCostsApi = (startDate, endDate) =>
  API.get('billings', `/api/v1/usage/s3/bucket-costs?startDate=${startDate}&endDate=${endDate}`);

export function useS3Usage() {
  return {
    getS3BucketCosts: (startDate, endDate) =>
      useQuery({
        queryKey: [QUERY_KEYS.S3_BUCKET_COSTS, startDate, endDate],
        queryFn: () => getS3BucketCostsApi(startDate, endDate),
      }),
  };
}
