import React from 'react';
import { Col, Row } from 'reactstrap';
import CustomModal from 'shared/components/andtComponents/Modal';
import toast from 'shared/components/andtComponents/Toast';
import MultiTypeFieldFilterSelector from 'shared/components/MultiTypeFieldFilterSelector';
import NumberInput from './NumberInput';
import TextInput from './TextInput';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const CreateOrEditRuleModal = ({ criteria, onClose, handleSubmit, existingCriteriasNames }) => {
  const isEditMode = !!criteria;
  const [criteriaName, setCriteriaName] = React.useState(criteria?.criteriaName || '');
  const [maxCpu, setMaxCpu] = React.useState(criteria?.maxCpu || '');
  const [maxNetwork, setMaxNetwork] = React.useState(criteria?.maxNetwork || '');
  const [linkedAccSelectedOptionsMap, setLinkedAccSelectedOptionsMap] = React.useState(
    criteria?.linkedAccSelectedOptionsMap || new Map([['linkedaccid', []]]),
  );
  const getIsNameValid = () => {
    const isNameExists = !!existingCriteriasNames.find(
      (crtName) => crtName.label.toLowerCase() === criteriaName.toLowerCase() && crtName.value !== criteria?.criteriaId,
    );
    return !isNameExists;
  };
  const getIsFormValid = () => {
    const isMaxNetValid = !!maxNetwork;
    const isMaxCpuValid = !!maxCpu;
    const iscriteriaNameValid = !!criteriaName;
    const linkedAccValid = !!linkedAccSelectedOptionsMap.get('linkedaccid')?.length;
    return isMaxNetValid && isMaxCpuValid && iscriteriaNameValid && linkedAccValid;
  };
  const handleSave = () => {
    const isValid = getIsFormValid();
    const isNameValid = getIsNameValid();
    if (!isValid) {
      toast.error('Please fill all fields.');
      return;
    }
    if (!isNameValid) {
      toast.error('Criteria name already exists');
      return;
    }
    handleSubmit({ maxCpu, maxNetwork, criteriaName, linkedAccSelectedOptionsMap, criteriaId: criteria?.criteriaId });
  };

  return (
    <CustomModal
      overrideStyles={{ width: 800 }}
      open
      onClose={onClose}
      title={!isEditMode ? 'Create Idle Criteria Rule' : 'Edit Idle Criteria Rule'}
      closeOnSave={false}
      onSave={handleSave}
      headerMode={isEditMode ? 'edit' : 'add'}
      saveTitle={!isEditMode ? 'Create' : 'Save'}
    >
      <Row style={{ marginBottom: '10px' }}>
        <Col>
          <h5 style={{ width: '100%' }} className="filter-bar-inner-col-title">
            Criteria Name
          </h5>
          <TextInput
            className="insert-budget-input"
            name="criteriaName"
            placeHolder=""
            onChange={(e) => setCriteriaName(e.target.value)}
            value={criteriaName}
            label=""
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '10px' }}>
        <Col>
          <h5 style={{ width: '100%' }} className="filter-bar-inner-col-title">
            Max CPU Utilization Limit Percent
          </h5>
          <NumberInput
            className="insert-budget-input"
            name="maxCpu"
            placeHolder=""
            onChange={(e) => setMaxCpu(e.target.value)}
            value={maxCpu}
            label=""
            isPercent
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '10px' }}>
        <Col>
          <h5 style={{ width: '100%' }} className="filter-bar-inner-col-title">
            {' '}
            Max Network In & Out Limit (MB)
          </h5>
          <NumberInput
            className="insert-budget-input"
            name="maxNetwork"
            placeHolder=""
            isPercent={false}
            label=""
            onChange={(e) => setMaxNetwork(e.target.value)}
            value={maxNetwork}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '10px' }}>
        <Col>
          <h5 style={{ width: '100%' }} className="filter-bar-inner-col-title">
            Linked Account
          </h5>
          <MultiTypeFieldFilterSelector
            selectedValues={linkedAccSelectedOptionsMap.get('linkedaccid')}
            handler={(filterType, selectedOptions) => {
              const selectedOptionsMap = new Map(linkedAccSelectedOptionsMap);
              selectedOptionsMap.set(filterType, selectedOptions);
              setLinkedAccSelectedOptionsMap(selectedOptionsMap);
            }}
            type="linkedaccid"
            name="linkedaccid"
            placeHolder="Select linked accounts"
            labelFunc={(value) => value.displayLabel}
            valueFunc={(value) => value.linkedAccountId}
          />
          <span>
            *Only{' '}
            <a
              href={ExternalLinks.ConnectYourLinkedAccounts}
              target="_blank"
              rel="noopener noreferrer"
            >
              connected linked accounts
            </a>{' '}
            will be inspected
          </span>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default CreateOrEditRuleModal;
