import { trimZeroDecimal } from 'shared/utils/strUtil';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { FilterTypes } from 'usage/constants/usageConstants';
import { getBudgetFiltersArrByCloudType } from 'invoices/constants/FiltersOptionsData';

export const PROPER_COST_COLOR = '#00934E';
export const LIMIT_COST_COLOR = '#FFBA69';
export const HIGH_COST_COLOR = '#F06292';
export const FORECAST_COST_COLOR = '#80DEEA';
export const FORECAST_OVERUSE_COST_COLOR = '#FBA4A4';
export const BACKGROUND_GRAY_COLOR = '#D9D9D9';
export const LIGHT_RED_COLOR = '#DE5773';

export const methodOptions = [
  {
    value: 'recurring',
    label: 'Fixed Monthly Budget',
    description: 'Track spending against a fixed amount per month.',
  },
  {
    value: 'expiring',
    label: 'Planned Monthly Budget',
    description: 'Set your budget amount for each month.',
  },
  {
    value: 'expiringFixed',
    label: 'Fixed Period Budget',
    description: 'Track spending against a fixed amount of months (e.g. 6 months).',
  },
];

export const granularityMethodOptions = [
  {
    value: 'daily',
    label: 'Daily',
    description:
      'Create an daily alert that compares the daily actual spend against the daily budget \n' +
      '  (the daily budget is calculated by dividing the monthly budget by the number \n' +
      ' of days in the calendar month).',
  },
  {
    value: 'monthly',
    label: 'Monthly',
    description: 'Create a monthly alert that compares the actual MTD spend against the \n monthly budget. ',
  },
  {
    value: 'total',
    label: 'Total Budget Period',
    description:
      'Create an alert that compares the actual MTD spend against the overall \n' +
      'budget for the total period (e.g. 6-month period). ',
  },
];

export const getBudgetColor = (value) => {
  if (value < 80) {
    return PROPER_COST_COLOR;
  }
  if (value <= 100) {
    return LIMIT_COST_COLOR;
  }
  if (value > 100) {
    return HIGH_COST_COLOR;
  }
  return FORECAST_COST_COLOR;
};

export const getNumberAbbreviationFormat = (numValue, getCurrencyNumber) => {
  if (Number.isNaN(numValue)) {
    return numValue;
  }
  const T = 1e12;
  const B = 1e9;
  const M = 1e6;
  const K = 1e3;
  let abbreviatedStringNum = '';
  switch (true) {
    case numValue >= T:
      abbreviatedStringNum = `${trimZeroDecimal(numValue && (numValue / T).toFixed(2))}T`;
      break;
    case numValue >= B:
      abbreviatedStringNum = `${trimZeroDecimal(numValue && (numValue / B).toFixed(2))}B`;
      break;
    case numValue >= M:
      abbreviatedStringNum = `${trimZeroDecimal(numValue && (numValue / M).toFixed(2))}M`;
      break;
    case numValue >= K:
      abbreviatedStringNum = `${trimZeroDecimal(numValue && (numValue / K).toFixed(2))}K`;
      break;
    default:
      abbreviatedStringNum = trimZeroDecimal(numValue);
      break;
  }
  return getCurrencyNumber(abbreviatedStringNum);
};

export const getFilterStatusTypeMapByCloudType = (cloudType, budget, defaultExcludeFields) => {
  let filterStatusTypeMapByCloudType = null;
  const getExcludeObject = (excludeValues, defaultExcludeValue) => {
    if (!excludeValues && defaultExcludeValue) {
      return FilterTypes.EXCLUDE;
    }
    const valueObject = {};
    let ExcludeValue = null;
     
    excludeValues?.forEach((obj) => {
      const parentObj = obj.split(':');
      if (parentObj.length === 1) {
        ExcludeValue = FilterTypes.EXCLUDE;
      }
      valueObject[parentObj[0]] = FilterTypes.EXCLUDE;
    });
    return ExcludeValue || valueObject;
  };

  const getFilterField = (fieldName) => [
    fieldName,
    (!budget?.excludeFilters || !budget.excludeFilters[fieldName]) && !defaultExcludeFields?.includes(fieldName)
      ? FilterTypes.INCLUDE
      : getExcludeObject(budget?.excludeFilters[fieldName], defaultExcludeFields?.includes(fieldName)),
  ];

  switch (cloudType) {
    case CLOUD_TYPE_IDS.AWS:
      filterStatusTypeMapByCloudType = new Map(
        getBudgetFiltersArrByCloudType(CLOUD_TYPE_IDS.AWS).map((item) => getFilterField(item)),
      );
      break;
    case CLOUD_TYPE_IDS.GCP:
      filterStatusTypeMapByCloudType = new Map(
        getBudgetFiltersArrByCloudType(CLOUD_TYPE_IDS.GCP).map((item) => getFilterField(item)),
      );
      break;
    case CLOUD_TYPE_IDS.AZURE:
      filterStatusTypeMapByCloudType = new Map(
        getBudgetFiltersArrByCloudType(CLOUD_TYPE_IDS.AZURE).map((item) => getFilterField(item)),
      );
      break;
    case CLOUD_TYPE_IDS.MULTI:
      filterStatusTypeMapByCloudType = new Map(
        getBudgetFiltersArrByCloudType(CLOUD_TYPE_IDS.MULTI).map((item) => getFilterField(item)),
      );
      break;
    default:
      filterStatusTypeMapByCloudType = new Map([
        [AwsCommonFields.LINKED_ACCOUNT_NAME, 0],
        [AwsCommonFields.SERVICE, 0],
        [AwsCommonFields.PROJECT, 0],
      ]);
  }
  return filterStatusTypeMapByCloudType;
};

export const getBudgetAmount = (budget, currentDate = new Date()) => {
  let currentMonthBudgetAmount;
  if (budget.budgetAmounts?.length) {
    const currentMonth = budget.budgetAmounts.find((elem) => {
      const date = new Date(elem.date);
      return date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear();
    });
    currentMonthBudgetAmount = currentMonth?.amount;
  }
  return Math.round(currentMonthBudgetAmount || budget.budgetAmount);
};

export const getCloudProviderForBudget = (budget) => {
  const providerType = ['AWS', 'AZURE', 'GCP'];
  const cpFilter = budget.includeFilters.cloudprovider;
  if (cpFilter?.length > 0) {
    return cpFilter;
  }
  const cpExcludeFilters = budget.excludeFilters.cloudprovider || [];
  return providerType.filter((p) => !cpExcludeFilters.includes(p));
};

const sortObject = (obj) => {
  if (!obj) {
    return {};
  }
  const sortedKeys = Object.keys(obj).sort();
  const sortedObj = {};
  sortedKeys.forEach((key) => {
    sortedObj[key] = obj[key];
  });
  return sortedObj;
};

const accumulateCostData = (dataArr, isPeriodType, previousMonthCost) => {
  let accumilateNum = isPeriodType ? Number(previousMonthCost) : 0;
  const firstDate = dataArr.length > 0 ? new Date(dataArr[0].date) : new Date();

  const newDataArr = dataArr.map((row) => {
    const newRow = { ...row };
    const currentDate = new Date(newRow.date);
    if (newRow.cost) {
      accumilateNum += Number(newRow.cost);
      newRow.cost = accumilateNum;
    }
    if (newRow.overuseCost) {
      if (newRow.forecastedCost) {
        delete newRow.overuseCost;
      } else {
        accumilateNum += Number(newRow.overuseCost);
        newRow.overuseCost = accumilateNum;
      }
    }
    if (newRow.forecastedCost) {
      if (!isPeriodType && currentDate.getDate() === 1 && currentDate.getMonth() !== firstDate.getMonth()) {
        accumilateNum = Number(newRow.forecastedCost);
      } else {
        accumilateNum += Number(newRow.forecastedCost);
      }
      newRow.forecastedCost = accumilateNum;
    }
    newRow.accumilateNum = accumilateNum;
    return newRow;
  });
  return newDataArr;
};

const manageSharedDataInTransitionDays = (dataArr) =>
  dataArr.map((data, index) => {
    const newData = { ...data };
    if (index + 1 > dataArr.length - 1) {
      return newData;
    }
    if (newData.cost && !dataArr[index + 1].cost) {
      if (dataArr[index + 1].overuseCost) {
        newData.overuseCost = newData.cost;
      } else if (dataArr[index + 1].forecastedCost) {
        newData.forecastedCost = newData.cost;
      }
    } else if (newData.overuseCost && !dataArr[index + 1].overuseCost) {
      if (dataArr[index + 1].forecastedCost) {
        newData.forecastedCost = newData.overuseCost;
      }
    }
    return newData;
  });

const prepareCostData = (
  costsObj,
  overusedObj,
  forecastedObj,
  startForecastedDate,
  warnThresholdDate,
  previousMonthCost,
  isPeriodType,
  limitStartDate,
) => {
  let dataArr = [];

  Object.entries(sortObject(costsObj)).forEach(([key, value]) => {
    dataArr.push({
      date: key,
      cost: value,
      isWarnValue: warnThresholdDate && key >= warnThresholdDate,
      actualData: value,
    });
  });

  Object.entries(sortObject(overusedObj)).forEach(([key, value]) => {
    const existData = dataArr.find((d) => d.date === key);
    if (existData) {
      existData.overuseCost = value;
    } else if ((!limitStartDate || key < limitStartDate) && (!startForecastedDate || key <= startForecastedDate)) {
      dataArr.push({ date: key, overuseCost: value, actualData: value });
    }
  });

  let monthNum = 0;
  let overUseDate = 0;
  Object.keys(forecastedObj).forEach((year) => {
    Object.keys(forecastedObj[year]).forEach((month) => {
      overUseDate = forecastedObj[year][month].startOveruseDate;
      Object.entries(sortObject(forecastedObj[year][month].forcastedPrice)).forEach(([key, value]) => {
        if (+new Date(key) > +new Date(startForecastedDate)) {
          const existData = dataArr.find((d) => d.date === key);
          if (existData) {
            existData.forecastedCost = value;
            existData.monthNum = monthNum;
            existData.actualData = value;
            existData.isOverUse = overUseDate !== 0 && +new Date(key) >= +new Date(overUseDate);
          } else {
            dataArr.push({
              date: key,
              forecastedCost: value,
              monthNum,
              actualData: value,
              isOverUse: overUseDate !== 0 && +new Date(key) >= +new Date(overUseDate),
            });
          }
        }
      });
      monthNum += 1;
    });
  });

  dataArr = dataArr.sort((a, b) => new Date(a.date) - new Date(b.date));

  return dataArr;
};

const preparePeriodCosts = (
  costsObj,
  forecastedObj,
  overusedObj,
  startForecastedDate,
  warnThresholdDate,
  previousMonthCost,
  isPeriodType,
  limitStartDate,
) => {
  let dataArr = prepareCostData(
    costsObj,
    overusedObj,
    forecastedObj,
    startForecastedDate,
    warnThresholdDate,
    previousMonthCost,
    isPeriodType,
    limitStartDate,
  );
  dataArr = accumulateCostData(dataArr, isPeriodType, previousMonthCost);
  dataArr = manageSharedDataInTransitionDays(dataArr);
  return dataArr;
};

const prepareFixedPeriodCosts = (budget) => {
  let accumilateCostData = [];
  let accCost = 0;
  if (budget.previousBudgets && budget.previousBudgets.length) {
    budget.previousBudgets.forEach((prevBudget) => {
      const currentAccCostData = preparePeriodCosts(
        prevBudget.costData,
        {},
        prevBudget.overusePrice,
        prevBudget.lastCostDate,
        prevBudget.warnThresholdDate,
        prevBudget.previousMonthCost || accCost,
        true,
        budget.monthlyStartDate, // don't display values for current month that appear in previous month
      );
      accCost =
        currentAccCostData.length > 0 ? currentAccCostData[currentAccCostData.length - 1].accumilateNum : accCost;
      accumilateCostData = accumilateCostData.concat(currentAccCostData);
    });
  }
  const current = preparePeriodCosts(
    budget.costData,
    budget.forecasting || {},
    budget.overusePrice,
    budget.lastCostDate,
    budget.warnThresholdDate,
    budget.previousMonthCost || accCost,
    true,
  );
  return accumilateCostData.concat(current);
};

export const getPreparedAccumCostsData = (budget) => {
  const preparedAccumCostsData =
    budget.budgetType === 'expiringFixed'
      ? prepareFixedPeriodCosts(budget)
      : preparePeriodCosts(
          budget.costData,
          budget.forecasting || {},
          budget.overusePrice,
          budget.lastCostDate,
          budget.warnThresholdDate,
          budget.previousMonthCost,
          budget.budgetType === 'expiring' && budget.budgetAmountType === 'fixed',
        );

  return preparedAccumCostsData;
};
