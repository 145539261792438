import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { dateToStr } from 'shared/utils/dateUtil';
import { v1 as uuidv1 } from 'uuid';
import { prepareNewRecipients } from 'shared/utils/sharedUtils';

const root = '/api/v1/divisions/customers/report';

const getCustomersReport = () => API.get('billings', root);

const createCustomersReport = async (params) => {
  const reportParams = { ...params };
  const { email, recipients } = reportParams;
  reportParams.uuid = uuidv1();
  reportParams.creationDate = dateToStr(new Date(), 'yyyy-mm-dd hh:mm:ss');
  const body = { ...reportParams, recipients: prepareNewRecipients({ email, recipients }) };
  await API.post('billings', root, { body });
};

const sendCustomersReport = async (params) => {
  const reportParams = { ...params };
  const { recipients } = reportParams;
  reportParams.uuid = uuidv1();
  const body = { ...reportParams, recipients };
  await API.post('billings', `${root}/send/${reportParams.uuid}`, { body });
};

const deleteCustomersReport = async ({ reportId }) => {
  await API.del('billings', `${root}?reportId=${reportId}`);
};

const updateCustomersReport = async (params) => {
  const reportParams = { ...params };
  const { email, recipients } = reportParams;
  reportParams.creationDate = dateToStr(new Date(), 'yyyy-mm-dd hh:mm:ss');
  const body = { ...reportParams, recipients: prepareNewRecipients({ email, recipients }) };
  await API.put('billings', root, { body });
};

export default function useCustomerReports() {
  const queryClient = useQueryClient();

  const queryKey = [apiConstants.QUERY_KEYS.CUSTOMER_REPORTS];

  const addCustomerReportMutation = useMutation({
    mutationFn: createCustomersReport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const updateCustomerReportMutation = useMutation({
    mutationFn: updateCustomersReport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const deleteCustomerReportMutation = useMutation({
    mutationFn: deleteCustomersReport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const sendCustomerReportMutation = useMutation({
    mutationFn: sendCustomersReport,
  });

  return {
    invalidate: () => queryClient.invalidateQueries({ queryKey }),
    reset: (filters, options) => queryClient.resetQueries({ queryKey, ...filters }, options),
    fetchCustomerReports: () =>
      useQuery({
        queryKey,
        queryFn: getCustomersReport,
        retry: false,
      }),
    addCustomerReportMutation,
    deleteCustomerReportMutation,
    updateCustomerReportMutation,
    sendCustomerReportMutation,
  };
}
