import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import LinkIconButton from 'shared/components/buttons/LinkIconButton.jsx';
import styles from './monitoringData.module.scss';

const MonitoringPanelAction = ({url}) => {

  return (
    <div className={styles.actionWrapper}>
      <LinkIconButton automationId="exploreDashboardPanel">
        <a href={url} target="_blank" rel="noreferrer"
        />
        <GenerateIcon iconName={ICONS.magnifyingGlassArrowRight.name} className={styles.icon}/>
      </LinkIconButton>
        {/*<GenerateIcon iconName={ICONS.dashboard.name} className={styles.icon}/>*/}
    </div>);
};

MonitoringPanelAction.propTypes = {
  url: PropTypes.string.isRequired,
};

export default MonitoringPanelAction;
