import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MainDashboardProvider,
  useMainDashboardContext,
} from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';

const InnerComponent = ({ chartComponent, filters }) => {
  const { setFilters } = useMainDashboardContext();

  useEffect(() => {
    if (!filters) {
      return;
    }
    setFilters(filters);
  }, [filters]);

  return chartComponent;
};

const SystemPanelsWrapper = (props) => {
  const { chartComponent, filters } = props;
  return (
    <MainDashboardProvider>
      <InnerComponent chartComponent={chartComponent} filters={filters}></InnerComponent>
    </MainDashboardProvider>
  );
};

SystemPanelsWrapper.propTypes = {
  chartComponent: PropTypes.element.isRequired,
  filters: PropTypes.object.isRequired,
};
export default SystemPanelsWrapper;
