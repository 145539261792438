
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { useNavigate } from 'react-router-dom';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import Button from 'shared/components/andtComponents/Button';
import styles from './ProviderPopover.module.scss';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const providerConfigs = {
  [CLOUD_TYPE_IDS.AWS]: {
    title: 'Before we begin, please validate:',
    requirements: [
      'You have access to the CUR file.',
      'You have ability to create assigning IAM policies.'
    ],
    externalLink: ExternalLinks.IntroductionToAWSCostMonitoring
  },
  [CLOUD_TYPE_IDS.AZURE]: {
    title: 'In order to proceed, you must:',
    requirements: [
      'Have the role of Owner, Contributor, or Global Admin on Azure.',
      'Have the ability to your billing scope data.'
    ],
    externalLink: ExternalLinks.PrerequisitesForOnboardingWithAzure
  },
  [CLOUD_TYPE_IDS.GCP]: {
    title: 'Before we begin, please validate:',
    requirements: [
      'Billing dataset name.',
      'Billing table name.',
      'Service account key json file.'
    ],
    externalLink: ExternalLinks.GcpOnboarding
  },
  [CLOUD_TYPE_IDS.BYOD]: {
    title: 'Before we begin, please validate:',
    requirements: [
      'Cost files are in FOCUS format (CSV).',
      'You can store securely the granted S3 access key and secret.',
      'You have a MultiCloud account.'
    ],
    externalLink: ExternalLinks.ByodOnboarding
  },
};

const BaseProviderPopover = ({ title, requirements, externalLink, onClose, link }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      {requirements.map((requirement, index) => (
        <p key={`${index}-${requirement}`} className={styles.desc}>
          <GenerateIcon iconName={ICONS.fileCheck.name} />
          {requirement}
        </p>
      ))}
      <p className={classNames(styles.desc, styles.link)}>
        See{' '}
        <a href={externalLink} target="_blank" rel="noopener noreferrer">
          more details
        </a>
        .
      </p>
      <div className={styles.footer}>
        <Button text="Cancel" onClick={onClose} isSecondary />
        <Button text="Continue" onClick={() => navigate(link)} />
      </div>
    </div>
  );
};

BaseProviderPopover.displayName = 'BaseProviderPopover';

BaseProviderPopover.propTypes = {
  title: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.string).isRequired,
  externalLink: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
};

const CreateProviderPopover = (cloudType) => {
  const config = providerConfigs[cloudType];
  const ProviderPopover = ({ onClose, link }) => (
    <BaseProviderPopover
      title={config.title}
      requirements={config.requirements}
      externalLink={config.externalLink}
      onClose={onClose}
      link={link}
    />
  );
  ProviderPopover.displayName = `${cloudType}ProviderPopover`;

  ProviderPopover.propTypes = {
    onClose: PropTypes.func.isRequired,
    link: PropTypes.string.isRequired,
  };
  return ProviderPopover;
};

const AwsProviderPopover = CreateProviderPopover(CLOUD_TYPE_IDS.AWS);
const AzureProviderPopover = CreateProviderPopover(CLOUD_TYPE_IDS.AZURE);
const GcpProviderPopover = CreateProviderPopover(CLOUD_TYPE_IDS.GCP);
const ByodProviderPopover = CreateProviderPopover(CLOUD_TYPE_IDS.BYOD);

export default {
  [CLOUD_TYPE_IDS.AWS]: AwsProviderPopover,
  [CLOUD_TYPE_IDS.AZURE]: AzureProviderPopover,
  [CLOUD_TYPE_IDS.GCP]: GcpProviderPopover,
  [CLOUD_TYPE_IDS.BYOD]: ByodProviderPopover,
};
