/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import toast from 'shared/components/andtComponents/Toast';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useInvalidateFiltersDistinctValues } from 'invoices/hooks/react-query/useInvoiceFilters';

const getCloudLinkedAccountsFromRole = (cloudId) => {
  const cloudIdToUrl = {
    0: 'aws',
    1: 'azure',
    2: 'gcp',
  };
  return API.get('billings', `/api/v1/users/linked-accounts/${cloudIdToUrl[cloudId]}`);
};

const updateLinkedAccountNameApi = ({ id, name }) =>
  API.put('billings', `/api/v1/users/linked-accounts/${encodeURIComponent(id)}`, { body: { name } });

const getLinkedAccountsByAccountId = (accountId) =>
  API.get('billings', `/api/v1/users/linked-accounts/onboarding/${accountId}`);

export default function linkedAccountsProvider() {
  const queryClient = useQueryClient();
  const { usersStore } = useRootStore();
  const { invalidatePlainFilters } = useInvalidateFiltersDistinctValues();
  const queryKey = [apiConstants.QUERY_KEYS.LINKED_ACCOUNTS];

  return {
    invalidate: () => queryClient.invalidateQueries({ queryKey }),
    reset: () => queryClient.resetQueries({ queryKey }),
    updateLinkedAccountName: () =>
      useMutation({
        mutationFn: updateLinkedAccountNameApi,
        onSuccess: () => {
          usersStore.fetchUserLinkedAccounts();
          invalidatePlainFilters();
          toast.success('Linked account name updated successfully.');
        },
      }),
    fetchLinkedAccountsByCloud: (cloudId) =>
      useQuery({
        queryKey,
        queryFn: () => getCloudLinkedAccountsFromRole(cloudId),
        retry: false,
      }),
    fetchLinkedAccountsByAccountId: (accountId) =>
      useQuery({
        queryKey: [...queryKey, accountId],
        queryFn: () => getLinkedAccountsByAccountId(accountId),
      }),
  };
}
