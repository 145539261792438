import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

export const useFilterGroups = () =>
  useQuery({
    queryKey: [apiConstants.QUERY_KEYS.FILTER_GROUPS],
    queryFn: () => API.get('billings', '/api/v1/usage/categories'),
  });

export function useAddFilterGroup() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ data }) => API.post('billings', '/api/v1/usage/categories', { body: data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [apiConstants.QUERY_KEYS.FILTER_GROUPS] });
    },
  });
}

export function useUpdateFilterGroup() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }) => API.put('billings', `/api/v1/usage/categories/${id}`, { body: data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [apiConstants.QUERY_KEYS.FILTER_GROUPS] });
    },
  });
}

export function useDeleteFilterGroup() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }) => API.del('billings', `/api/v1/usage/categories/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [apiConstants.QUERY_KEYS.FILTER_GROUPS] });
    },
  });
}
