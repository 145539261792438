import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { AWS_RECOMMENDATIONS_ROOT, BILLINGS_API_NAME } from 'recommendations/hooks/react-query/api';
import { prepareNewRecipients } from 'shared/utils/sharedUtils';

const fetchRecommendationReports = async () => API.get(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/report`);

const sendRecommendationsReport = async (reportParams) =>
  API.post(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/report/send`, {
    body: reportParams,
  });

const createRecommendationsReport = async (reportParams) =>
  API.post(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/report`, { body: { ...reportParams } });

const updateRecommendationsReport = async (reportParams) =>
  API.put(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/report`, { body: { ...reportParams } });

const deleteRecommendationsReport = async (reportId) =>
  API.del(BILLINGS_API_NAME, `${AWS_RECOMMENDATIONS_ROOT}/report?reportId=${reportId}`);

export default function useRecommendationReports() {
  const queryClient = useQueryClient();

  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATION_REPORTS];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: (filters, options) => queryClient.resetQueries({ queryKey, ...filters }, options),
    fetchRecommendationReports: () =>
      useQuery({
        queryKey,
        queryFn: () => fetchRecommendationReports(),
        retry: false,
      }),
    createRecommendationReport: async (reportParams) => {
      try {
        const { email, recipients } = reportParams;
        await createRecommendationsReport({ ...reportParams, recipients: prepareNewRecipients({ email, recipients }) });
      } catch (e) {
        throw e;
      } finally {
        await queryClient.invalidateQueries(queryKey);
      }
    },
    updateRecommendationReport: async (reportParams) => {
      try {
        const { email, recipients } = reportParams;
        await updateRecommendationsReport({ ...reportParams, recipients: prepareNewRecipients({ email, recipients }) });
      } catch (e) {
        throw e;
      } finally {
        await queryClient.invalidateQueries(queryKey);
      }
    },
    deleteRecommendationReport: async (reportId) => {
      try {
        await deleteRecommendationsReport(reportId);
      } catch (e) {
        throw e;
      } finally {
        await queryClient.invalidateQueries(queryKey);
      }
    },
    sendRecommendationReport: async (reportParams) => {
      try {
        const { email, recipients } = reportParams;
        await sendRecommendationsReport({ ...reportParams, recipients: prepareNewRecipients({ email, recipients }) });
      } catch (e) {
        throw e;
      } finally {
        await queryClient.invalidateQueries(queryKey);
      }
    },
  };
}
