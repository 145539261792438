import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as HasNoPermission } from 'app/containers/MainDashboard/img/no-permission.svg';
import styles from './noPermission.module.scss';

export const NoPermission = ({ isSmallPanel = false }) => {
  return (
    <div className={classNames(styles.noPermissionWrapper, isSmallPanel && styles.flexRow)}>
      <HasNoPermission className={classNames(isSmallPanel && styles.smallIcon)} />
      <span className={styles.permissionText}>You do not have permissions to see this data</span>
    </div>
  );
};

NoPermission.propTypes = {
  isSmallPanel: PropTypes.bool,
};
