import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthenticatedRoute from 'app/containers/App/components/AuthenticatedRoute';
import { Routes as ROUTES } from 'shared/constants/routes';
import { HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { checkTrialUser } from 'shared/components/CheckTrialUser';
import Recommendation360 from 'recommendationsNew/menuItems/recommendation360';
import RecommendationExplorer from 'recommendationsNew/menuItems/recommendationExplorer';
import RecommendationTrial from 'recommendations/containers/TrialRecPage';
import { RecommendationsNewProvider } from 'recommendationsNew/contexts/recommendationsNewContext';
import RecommendationPreferences from 'recommendations/containers/RecommendationsPreferences';
import RecommendationReports from 'recommendations/containers/Reports';

const getSubRoute = (route) => {
  return route.split(`${ROUTES.RECOMMENDATIONS}`)[1];
};

const RecommendationsRouter = (childProps) => (
  <RecommendationsNewProvider>
    <Routes>
      <Route
        exact
        path={getSubRoute(ROUTES.RECOMMENDATION_360)}
        element={
          <AuthenticatedRoute
            component={checkTrialUser(Recommendation360, RecommendationTrial, childProps)}
            props={childProps}
            authorizationCategories={[HierarchicalEntityCategory.BillingData]}
          />
        }
      />
      <Route
        exact
        path={getSubRoute(ROUTES.RECOMMENDATION_EXPLORER)}
        element={
          <AuthenticatedRoute
            component={checkTrialUser(RecommendationExplorer, RecommendationTrial, childProps)}
            props={childProps}
            authorizationCategories={[HierarchicalEntityCategory.BillingData]}
          />
        }
      />
      <Route
        exact
        path={getSubRoute(ROUTES.RECOMMENDATION_PREFERENCES)}
        element={
          <AuthenticatedRoute
            component={checkTrialUser(RecommendationPreferences, RecommendationTrial, childProps)}
            props={childProps}
          />
        }
      />
      <Route
        exact
        path={getSubRoute(ROUTES.RECOMMENDATION_REPORTS)}
        element={
          <AuthenticatedRoute
            component={checkTrialUser(RecommendationReports, RecommendationTrial, childProps)}
            props={childProps}
          />
        }
      />
    </Routes>
  </RecommendationsNewProvider>
);

export default RecommendationsRouter;
