import React from 'react';
import { Container } from 'reactstrap';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import ConnectK8s from './components/ConnectK8s';
import ConnectK8sLegacy from './components/OldConnectK8s';
import K8sOnboardingWizard from './components/K8sOnboarding';
import checkFeatureFlag from 'shared/utils/featureFlagUtil.js';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants.js';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';

const KubernetesPreview = () => {
  const { usersStore } = useRootStore();
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  const k8sOnboardingEnabled = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.K8S_ONBOARDING);

  if (k8sOnboardingEnabled && showOnboarding) return <K8sOnboardingWizard onClose={() => setShowOnboarding(false)} />;
  return (
    <Container>
      <PageHeader title={PageNames.K8S_PREVIEW} />
      {k8sOnboardingEnabled ? <ConnectK8s onOpenWizard={() => setShowOnboarding(true)} /> : <ConnectK8sLegacy />}
    </Container>
  );
};

export default KubernetesPreview;
