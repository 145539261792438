import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';

const root = '/api/v1/usage/goals';

const fetchGoalsApi = () => API.get('billings', root);
const addGoalApi = ({ data }) => API.post('billings', root, { body: data });
const updateGoalApi = ({ id, data }) => API.put('billings', `${root}/${id}`, { body: data });
const deleteGoalApi = ({ id }) => API.del('billings', `${root}/${id}`);

export const useCUEGoals = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.CUE_GOALS];
  return {
    fetchCUEGoals: () =>
      useQuery({
        queryKey,
        queryFn: fetchGoalsApi,
      }),
    addCUEGoal: () =>
      useMutation({
        mutationFn: addGoalApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
    updateCUEGoal: () =>
      useMutation({
        mutationFn: updateGoalApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
    deleteCUEGoal: () =>
      useMutation({
        mutationFn: deleteGoalApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
  };
};
