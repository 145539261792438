import React from 'react';
import PropTypes from 'prop-types';

export default function MenuItem({
  item,
  handler,
  id = '',
  currentDisplayedCloudTypeId = null,
  classes,
  activeName = '',
  children = null,
  isChild = false,
}) {
  function handleItemClick(e) {
    e.preventDefault();
    handler(item?.key, item?.cloudId, undefined, item?.divisionName, item?.divisionId);
  }

  return (
    <div
      role="button"
      className={`${classes.item} ${
        activeName === item.value &&
        (currentDisplayedCloudTypeId === null || currentDisplayedCloudTypeId === item.cloudId)
          ? classes.active
          : ''
      }`}
      onClick={(e) => handleItemClick(e)}
      id={id}
    >
      <span className={`${classes.noWrap} ${isChild ? classes.childItem : ''}`}>{children || item.value}</span>
    </div>
  );
}

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  activeName: PropTypes.string,
  children: PropTypes.element,
  isChild: PropTypes.bool,
  currentDisplayedCloudTypeId: PropTypes.number,
};
