import { API } from 'shared/utils/apiMiddleware';
import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/users/db-flags';

const fetchDbFlagsApi = () => API.get('billings', root);

export const useDbFlags = () => {
  const queryKey = [apiConstants.QUERY_KEYS.DB_FLAGS];
  return {
    fetchDbFlags: (options = {}) =>
      useQuery({
        queryKey,
        queryFn: fetchDbFlagsApi,
        ...options,
      }),
  };
};
