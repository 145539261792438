import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import {
  BILLINGS_API_NAME,
  GROUP_BY_OPTIONS,
  RECOMMENDATIONS_HEATMAP_ROOT,
  STALE_TIME,
} from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';

function fetchGroupByOptions() {
  return API.get(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/groupByOptions`);
}

export default function useGroupByOptions() {
  const { usersStore } = useRootStore();
  const { currDispUserCloudAccountType } = usersStore;
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [apiConstants.QUERY_KEYS.RECOMMENDATIONS_GROUP_BY_OPTIONS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries({ queryKey }),
    reset: () => queryClient.resetQueries({ queryKey }),
    fetchGroupByOptions: () =>
      useQuery({
        queryKey,
        queryFn: async () => {
          const data = await fetchGroupByOptions();
          return (
            data?.map((item) =>
              item.id === GROUP_BY_OPTIONS.items.LINKED_ACCOUNT_ID.id
                ? {
                    ...item,
                    name: getLinkedAccountName(currDispUserCloudAccountType),
                  }
                : item,
            ) || []
          );
        },
        retry: false,
        staleTime: STALE_TIME,
      }),
  };
}
