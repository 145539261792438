import React from 'react';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import useGetHelpMap from './useGetHelpMap.tsx';
import useGetFieldsList from './useGetFieldsList.tsx';

const AddClusterForm: React.FC = () => {
  const helpsMap = useGetHelpMap();
  const { fieldsList } = useGetFieldsList();
  return (
    <ListWithHelp
      styles={{
        listGap: 16,
        helpWidth: 460,
        controlStyles: {
          marginLeft: '-4px',
        },
      }}
      list={fieldsList}
      help={helpsMap}
    />
  );
};

export default AddClusterForm;
