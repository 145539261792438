// add_aws_support.        -> type, disable_minimum_fee, plan
// add_cost_with_factor    -> type, factor, ustom_service
// add_fixed_cost.         -> type, amount, linked_account_id, custom_service
// remove_aws_support      -> type, disable_minimum_fee, plan
// set_fixed_rate          -> type, amount
// update_cost_with_factor -> type, factor
import { eNotationToNumberStr, percentStr } from 'shared/utils/strUtil';
import { FilterTypes } from 'usage/constants/usageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { getBillingRuleFiltersByCloudType } from 'invoices/constants/FiltersOptionsData';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { AzureCommonFields } from 'shared/constants/azureConstants';
import moment from 'moment';

export const columnExtensions = [
  {
    columnName: 'ruleName',
    width: 'auto',
  },
  {
    columnName: 'customerNames',
    width: 'auto',
  },
  {
    columnName: 'linkedAccountIds',
    width: 'auto',
  },
  {
    columnName: 'margin',
    width: 125,
    compare: (a, b) => {
      // Sort by margin types
      const parsedA = parseInt(a.replace('$', '', '%', ''));
      const parsedB = parseInt(b.replace('$', '', '%', ''));
      if (Number.isNaN(parsedA) && Number.isNaN(parsedB)) {
        return a.localeCompare(b);
      }
      if (Number.isNaN(parsedA)) {
        return -1;
      }
      if (Number.isNaN(parsedB)) {
        return 1;
      }
      if ((a.includes('%') && b.includes('%')) || (a.includes('$') && b.includes('$'))) {
        return parsedA - parsedB;
      }
      if (a.includes('%')) {
        return -1;
      }
      return 1;
    },
  },
  {
    columnName: 'marginType',
    width: 155,
  },
  {
    columnName: 'frequency',
    width: 100,
  },
  {
    columnName: 'startMonth',
    width: 105,
    compare: (a, b) => (moment(a).isAfter(b) ? 1 : -1),
  },
  {
    columnName: 'endMonth',
    width: 100,
    compare: (a, b) => (moment(a).isAfter(b) ? 1 : -1),
  },
  {
    columnName: 'ruleId',
    width: 90,
    compare: (a, b) => a.props.text.localeCompare(b.props.text),
  },
  {
    columnName: 'status',
    width: 120,
    compare: (a, b) => a.props.children[0].localeCompare(b.props.children[0]),
  },
  {
    columnName: 'edit',
    width: 35,
    minWidth: 35,
    maxWidth: 35,
    sortingEnabled: false,
  },
  {
    columnName: 'clone',
    width: 35,
    minWidth: 35,
    maxWidth: 35,
    sortingEnabled: false,
  },
  {
    columnName: 'delete',
    width: 35,
    minWidth: 35,
    maxWidth: 35,
    sortingEnabled: false,
  },
];

export const getMarginToDisplay = (margin) => {
  switch (margin.type) {
    case 'update_cost_with_factor':
    case 'add_cost_with_factor':
      // rounding after 10 decimal places to fix floating point precision issues
      return percentStr(margin.factor * 100, 10);
    case 'add_fixed_cost':
    case 'set_fixed_rate':
      return `$ ${eNotationToNumberStr(margin.amount)}`;
    case 'add_aws_support':
      return margin.plan;
    case 'remove_aws_support':
      return margin.plans?.join(', ');
    default:
      return '';
  }
};

export const marginTypeToDisplayName = {
  update_cost_with_factor: 'Percentage',
  add_fixed_cost: 'Fixed Cost',
  set_fixed_rate: 'Fixed Rate',
  add_cost_with_factor: 'Custom Service Aggregated %',
  add_aws_support: 'AWS Support',
  remove_aws_support: 'Remove AWS Support',
  remove_cost: 'Data Exclusion',
};

export const MARGIN_TYPES_IDS = {
  UPDATE_COST_WITH_FACTOR: 'update_cost_with_factor',
  ADD_FIXED_COST: 'add_fixed_cost',
  SET_FIXED_RATE: 'set_fixed_rate',
  ADD_COST_WITH_FACTOR: 'add_cost_with_factor',
  ADD_AWS_SUPPORT: 'add_aws_support',
  REMOVE_AWS_SUPPORT: 'remove_aws_support',
  REMOVE_COST: 'remove_cost',
};

export const COST_TYPES = [
  { value: 'relative', label: 'Relative' },
  { value: 'unblended', label: 'Unblended' },
  { value: 'distributed', label: 'Distributed' },
  { value: 'amortized', label: 'Amortized' },
];

export const marginTypes = [
  {
    value: MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR,
    label: 'Percentage',
    description:
      'Percentage discount or charge on line items. This rule will affect the total of each line item and will not be displayed as a separate service.',
  },
  {
    value: MARGIN_TYPES_IDS.ADD_FIXED_COST,
    label: 'Fixed Cost',
    description: 'Set charge/discount amount. This rule will add a new service line to Anodot.',
  },
  {
    value: MARGIN_TYPES_IDS.SET_FIXED_RATE,
    label: 'Fixed Rate',
    description:
      // eslint-disable-next-line max-len
      'This rule will change the rate of a line item (ex: if Inter-AZ data transfer out is $0.01/gb, it can be changed to $0.005/gb). This rule will change the rate of every relevant resource.',
  },
  {
    value: MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR,
    label: 'Custom Service Aggregated %',
    description:
      'Define a charge/discount percentage based on the filters that you choose. This rule will add a new service line to Anodot.',
  },
  {
    value: MARGIN_TYPES_IDS.ADD_AWS_SUPPORT,
    label: 'AWS Support',
    description: 'Add a support charge according to the selected plan.',
  },
  {
    value: MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT,
    label: 'Remove AWS Support',
    description: 'Remove a support charge according to the selected plan.',
  },
  {
    value: MARGIN_TYPES_IDS.REMOVE_COST,
    label: 'Data Exclusion',
    description:
      'Removes all records and data specified by the filters, ensuring that customers do not see any of the excluded information.',
  },
];

export const marginTypesByCloudType = {
  [CLOUD_TYPE_IDS.AWS]: marginTypes,
  [CLOUD_TYPE_IDS.AZURE]: marginTypes.filter(
    (marginType) => ![MARGIN_TYPES_IDS.ADD_AWS_SUPPORT, MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT].includes(marginType.value),
  ),
  [CLOUD_TYPE_IDS.GCP]: marginTypes.filter(
    (marginType) => ![MARGIN_TYPES_IDS.ADD_AWS_SUPPORT, MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT].includes(marginType.value),
  ),
};

export const getFilterStatusTypeMapByCloudType = (cloudType, filters, defaultExcludeFields) => {
  const getExcludeObject = (excludeValues, defaultExcludeValue) => {
    if (!excludeValues && defaultExcludeValue) {
      return FilterTypes.EXCLUDE;
    }
    const valueObject = {};
    let ExcludeValue = null;
    excludeValues?.forEach((obj) => {
      const parentObj = obj.split(':');
      if (parentObj.length === 1) {
        ExcludeValue = FilterTypes.EXCLUDE;
      }
      valueObject[parentObj[0]] = FilterTypes.EXCLUDE;
    });
    return ExcludeValue || valueObject;
  };

  const getFilterField = (fieldName) => [
    fieldName,
    (!filters?.excludeFilters || !filters.excludeFilters[fieldName]) && !defaultExcludeFields?.includes(fieldName)
      ? FilterTypes.INCLUDE
      : getExcludeObject(filters?.excludeFilters[fieldName], defaultExcludeFields?.includes(fieldName)),
  ];

  const filterStatusTypeMapByCloudType = new Map(
    getBillingRuleFiltersByCloudType(cloudType).map((item) => getFilterField(item)),
  );
  return filterStatusTypeMapByCloudType;
};

export const getLikeFiltersStatus = (filters = {}) =>
  Object.keys(filters.likeFilters || {}).reduce(
    (acc, filterName) => ({
      ...acc,
      [filterName]: filters.caseSensitive?.[filterName] ? { caseSensitive: 1 } : 1,
    }),
    {},
  );

export const validateBillingRule = (billingRule) => {
  const validationErrors = {};
  if (!billingRule.description?.name) {
    validationErrors.name = 'Rule name is required';
  }
  if (!billingRule.margin?.type) {
    validationErrors.marginType = 'Margin type is required';
    return validationErrors;
  }

  if (
    [
      MARGIN_TYPES_IDS.ADD_FIXED_COST,
      MARGIN_TYPES_IDS.SET_FIXED_RATE,
      MARGIN_TYPES_IDS.UPDATE_COST_WITH_FACTOR,
      MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR,
    ].includes(billingRule.margin?.type) &&
    !billingRule.margin.amount &&
    !billingRule.margin.factor
  ) {
    validationErrors.amount = 'Amount is required';
  }
  if (
    [MARGIN_TYPES_IDS.ADD_AWS_SUPPORT, MARGIN_TYPES_IDS.REMOVE_AWS_SUPPORT].includes(billingRule.margin.type) &&
    !billingRule.margin.plan &&
    !billingRule.margin.plans?.length
  ) {
    validationErrors.plan = 'Plan is required';
  }
  if ([MARGIN_TYPES_IDS.ADD_FIXED_COST, MARGIN_TYPES_IDS.ADD_COST_WITH_FACTOR].includes(billingRule.margin.type)) {
    if (!billingRule.margin.customService) {
      validationErrors.customService = 'Custom Service name is required';
    } else if (/^\d/.test(billingRule.margin.customService)) {
      validationErrors.customService = 'Custom Service name must start with a letter';
    } else if (/[^a-zA-Z0-9 \-_]/.test(billingRule.margin.customService)) {
      validationErrors.customService =
        'Custom Service name may contain letters, numbers, spaces, dashes and underscores';
    } else if (/\s$/.test(billingRule.margin.customService)) {
      validationErrors.customService = 'Custom Service name cannot end with a whitespace';
    }
  }

  return Object.keys(validationErrors).length ? validationErrors : null;
};

export const getFiltersConfig = ({ conjunctionConfig = {}, marginType }) => {
  return {
    [AwsCommonFields.SERVICE]: {
      disableLike: true,
      tooltip:
        marginType === MARGIN_TYPES_IDS.ADD_AWS_SUPPORT
          ? 'All AWS Support services (Business, Developer, Enterprise, Premium) are excluded by default.'
          : null,
      deletedOptions:
        marginType === MARGIN_TYPES_IDS.ADD_AWS_SUPPORT
          ? ['AWS Support [Developer]', 'AWS Support [Business]', 'AWS Support [Enterprise]', 'AWS Premium Support']
          : null,
    },
    [AwsCommonFields.REGION]: {
      disableLike: true,
    },
    [AwsCommonFields.PURCHASE_OPTION]: {
      disableLike: true,
    },
    [AwsCommonFields.CUSTOM_TAGS]: {
      showNotAllocated: false,
      conjunctionSelect: true,
      conjunctionConfig,
    },
    [AwsCommonFields.QUANTITY_TYPE]: {
      disableLike: true,
    },
    [AwsCommonFields.CHARGE_TYPE]: {
      disableLike: true,
    },
    [AwsCommonFields.FAMILY_TYPE]: {
      disableLike: true,
    },
    [AwsCommonFields.COST_TYPE]: {
      disableLike: true,
      deletedOptions: marginType === MARGIN_TYPES_IDS.ADD_AWS_SUPPORT ? ['Credit'] : null,
    },
    [AwsCommonFields.OPERATION]: {
      disableLike: true,
    },
    [AwsCommonFields.INSTANCE_TYPE]: {
      disableLike: true,
    },
    [AwsCommonFields.LINKED_ACCOUNT_ID]: {
      disableLike: true,
    },
    [AwsCommonFields.CLOUD_FRONT_REGION]: {
      disableLike: true,
    },
    [AzureCommonFields.SUB_METER_CATEGORY]: {
      disableLike: true,
    },
    [AwsCommonFields.ITEM_DESCRIPTION]: {
      disableExclude: true,
      disableIs: true,
    },
  };
};

export const getDefaultFilters = (marginType) => {
  switch (marginType) {
    case MARGIN_TYPES_IDS.ADD_AWS_SUPPORT:
      return {
        includeFilters: {
          costtype: ['Cost'],
        },
        excludeAwsMarketplace: true,
      };
    default:
      return null;
  }
};
