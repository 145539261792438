import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/users/share-settings';

const createShareSettingApi = ({ body }) => API.post('billings', root, { body });
const getShareSettingApi = async (id) => {
  try {
    return await API.get('billings', `${root}/${id}`);
  } catch {
    return null;
  }
};
const updateShareSettingApi = ({ id, body }) => API.put('billings', `${root}/${id}`, { body });
const deleteShareSettingApi = ({ id }) => API.del('billings', `${root}/${id}`);

export default function useShareSettings() {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.SHARE_SETTINGS];

  const addShareSetting = useMutation({
    mutationFn: createShareSettingApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
  const updateShareSetting = useMutation({
    mutationFn: updateShareSettingApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
  const deleteShareSetting = useMutation({
    mutationFn: deleteShareSettingApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    fetchShareSettingById: (id, options = {}) =>
      useQuery({
        queryKey: [id, ...queryKey],
        queryFn: () => getShareSettingApi(id),
        retry: false,
        ...options,
      }),
    addShareSetting,
    updateShareSetting,
    deleteShareSetting,
  };
}
