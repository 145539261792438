import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Row } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Panel from 'shared/components/Panel';
import Spinner from 'shared/components/andtComponents/Spinner';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { PageNames } from 'shared/constants/appConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import { RI_CSV_HEADERS, SP_CSV_HEADERS } from '../constants/commitmentConstants';
import FilterBar from './FilterBar';
import CommitmentTable from './CommitmentTable';
import SavingsPlansUtilizationTable from './SavingsPlansUtilizationTable';

class CommitmentTablePanel extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    commitmentStore: PropTypes.object.isRequired,
    getPageFilters: PropTypes.func.isRequired,
    commitmentServiceOptions: PropTypes.array.isRequired,
    selectedCommitmentService: PropTypes.string.isRequired,
    selectedLinkedAccount: PropTypes.string.isRequired,
    selectedPayerAccount: PropTypes.string.isRequired,
    commitmentType: PropTypes.string.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    getCommitmentTableData: PropTypes.func.isRequired,
    loadingTableData: PropTypes.bool.isRequired,
    isGetTableDataButtonActive: PropTypes.bool.isRequired,
    getRiFlexabilityData: PropTypes.func.isRequired,
    getChargeBackData: PropTypes.func.isRequired,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
    tableColumns: PropTypes.object.isRequired,
  };

  componentWillUnmount() {
    const { commitmentStore } = this.props;
    commitmentStore.commitmentModel.invalidateTableData();
  }

  prepareRiCommitmentTable = (data, useCostAbriviation = true) => {
    const { numStrAbriviaionByDisplayMetric } = this.props;
    const preparedData =
      data && data.length
        ? data.map(
            ({
              UtilizationPercentage,
              flexibilityUtilizationPercentage,
              NetRISavings,
              startDateTime,
              endDateTime,
              account_id: accountId,
              linked_account_id: linkedAccountId,
              TotalPotentialRISavings,
              AmortizedUpfrontFee,
              AmortizedRecurringFee,
              TotalAmortizedFee,
              UpfrontFee,
              edp,
              totalUpfrontFeeBeforeRiDiscount,
              totalNetUpfrontFee,
              ...rest
            }) => {
              const amortizedUpfrontFee = +AmortizedUpfrontFee;
              const amortizedRecurringFee = +AmortizedRecurringFee;
              const upfrontFee = UpfrontFee ? +UpfrontFee : '';
              const totalAmortizedFee = +TotalAmortizedFee;
              const  status = new Date(endDateTime) > new Date() ? 'Active' : 'Expired';
              return {
                accountId,
                linkedAccountId,
                AmortizedUpfrontFee: `${
                  useCostAbriviation
                    ? numStrAbriviaionByDisplayMetric(amortizedUpfrontFee, amortizedUpfrontFee, DisplayMetricTypes.COST)
                    : amortizedUpfrontFee
                }`,
                UpfrontFee: upfrontFee
                  ? `${
                      useCostAbriviation
                        ? numStrAbriviaionByDisplayMetric(upfrontFee, upfrontFee, DisplayMetricTypes.COST)
                        : upfrontFee
                    }`
                  : '',
                AmortizedRecurringFee: `${
                  useCostAbriviation
                    ? numStrAbriviaionByDisplayMetric(
                        amortizedRecurringFee,
                        amortizedRecurringFee,
                        DisplayMetricTypes.COST,
                      )
                    : amortizedRecurringFee
                }`,
                TotalAmortizedFee: `${
                  useCostAbriviation
                    ? numStrAbriviaionByDisplayMetric(totalAmortizedFee, totalAmortizedFee, DisplayMetricTypes.COST)
                    : totalAmortizedFee
                }`,
                UtilizationPercentage: +UtilizationPercentage,
                flexibilityUtilizationPercentage: +flexibilityUtilizationPercentage,
                startDateTime: startDateTime?.slice(0, 10),
                endDateTime: endDateTime?.slice(0, 10),
                TotalPotentialRISavings: +TotalPotentialRISavings,
                NetRISavings: +NetRISavings,
                ...rest,
                edp: edp
                  ? `${useCostAbriviation ? numStrAbriviaionByDisplayMetric(edp, edp, DisplayMetricTypes.COST) : edp}`
                  : '',
                subscriptionStatus: status,
                totalUpfrontFeeBeforeRiDiscount: totalUpfrontFeeBeforeRiDiscount
                  ? `${
                      useCostAbriviation
                        ? numStrAbriviaionByDisplayMetric(
                            totalUpfrontFeeBeforeRiDiscount,
                            totalUpfrontFeeBeforeRiDiscount,
                            DisplayMetricTypes.COST,
                          )
                        : totalUpfrontFeeBeforeRiDiscount
                    }`
                  : '',
                totalNetUpfrontFee: totalNetUpfrontFee
                  ? `${
                      useCostAbriviation
                        ? numStrAbriviaionByDisplayMetric(
                            totalNetUpfrontFee,
                            totalNetUpfrontFee,
                            DisplayMetricTypes.COST,
                          )
                        : totalNetUpfrontFee
                    }`
                  : '',
              };
            },
          )
        : data;
    return preparedData;
  };
  prepareSpUtilizationTable = (data, useCostAbriviation = true) => {
    const { numStrAbriviaionByDisplayMetric } = this.props;
    const preparedData = data.map(
      ({
        UtilizationPercentage,
        NetSavings,
        StartDateTime,
        EndDateTime,
        account_id: accountId,
        linked_account_id: linkedAccountId,
        AmortizedRecurringCommitment,
        AmortizedUpfrontCommitment,
        HourlyCommitment,
        OnDemandCostEquivalent,
        RecurringHourlyFee,
        TotalAmortizedCommitment,
        TotalCommitment,
        UsedCommitment,
        Status,
        ...rest
      }) => {
        const amortizedRecurringCommitment = +AmortizedRecurringCommitment;
        const amortizedUpfrontCommitment = +AmortizedUpfrontCommitment;
        const onDemandCostEquivalent = +OnDemandCostEquivalent;
        const recurringHourlyFee = +RecurringHourlyFee;
        const totalAmortizedCommitment = +TotalAmortizedCommitment;
        const totalCommitment = +TotalCommitment;
        const usedCommitment = +UsedCommitment;
        return {
          accountId,
          linkedAccountId,
          AmortizedRecurringCommitment: `${
            useCostAbriviation
              ? numStrAbriviaionByDisplayMetric(
                  amortizedRecurringCommitment,
                  amortizedRecurringCommitment,
                  DisplayMetricTypes.COST,
                )
              : amortizedRecurringCommitment
          }`,
          AmortizedUpfrontCommitment: `${
            useCostAbriviation
              ? numStrAbriviaionByDisplayMetric(
                  amortizedUpfrontCommitment,
                  amortizedUpfrontCommitment,
                  DisplayMetricTypes.COST,
                )
              : amortizedUpfrontCommitment
          }`,
          HourlyCommitment: +HourlyCommitment,
          OnDemandCostEquivalent: `${
            useCostAbriviation
              ? numStrAbriviaionByDisplayMetric(onDemandCostEquivalent, onDemandCostEquivalent, DisplayMetricTypes.COST)
              : onDemandCostEquivalent
          }`,
          RecurringHourlyFee: `${
            useCostAbriviation
              ? numStrAbriviaionByDisplayMetric(recurringHourlyFee, recurringHourlyFee, DisplayMetricTypes.COST)
              : recurringHourlyFee
          }`,
          TotalAmortizedCommitment: `${
            useCostAbriviation
              ? numStrAbriviaionByDisplayMetric(
                  totalAmortizedCommitment,
                  totalAmortizedCommitment,
                  DisplayMetricTypes.COST,
                )
              : totalAmortizedCommitment
          }`,
          TotalCommitment: `${
            useCostAbriviation
              ? numStrAbriviaionByDisplayMetric(totalCommitment, totalCommitment, DisplayMetricTypes.COST)
              : totalCommitment
          }`,
          UsedCommitment: `${
            useCostAbriviation
              ? numStrAbriviaionByDisplayMetric(usedCommitment, usedCommitment, DisplayMetricTypes.COST)
              : usedCommitment
          }`,
          UtilizationPercentage: +UtilizationPercentage,
          StartDateTime: StartDateTime.slice(0, 10),
          EndDateTime: EndDateTime.slice(0, 10),
          NetSavings: +NetSavings,
          Status: new Date(EndDateTime) < new Date() ? 'Expired' : 'Active',
          ...rest,
        };
      },
    );
    return preparedData;
  };
  prepareTableData = (commitmentTableData, commitmentType, useCostAbriviation = true) => {
    const preparedData =
      commitmentType === 'ri'
        ? this.prepareRiCommitmentTable(commitmentTableData, useCostAbriviation)
        : this.prepareSpUtilizationTable(commitmentTableData, useCostAbriviation);
    return preparedData;
  };

  prepareAzureTableData = (commitmentTableData) =>
    commitmentTableData.map(
      ({
        account_id: accountId,
        linked_account_id: subscriptionId,
        linked_account_name: subscriptionName,
        avgUtilizationPercentage,
        reservationId,
        skuName,
        reservationOrderId,
        usedHours,
        reservedHours,
      }) => ({
        accountId,
        subscriptionName,
        subscriptionId,
        utilizationPercentage: avgUtilizationPercentage,
        reservationId,
        skuName,
        reservationOrderId,
        usedHours,
        reservedHours,
      }),
    );

  renderTable = (commitmentType, commitmentTableData, loadingTableData, getRiFlexabilityData, getChargeBackData) => {
    const { commitmentStore, tableColumns, isGetTableDataButtonActive } = this.props;
    const { currDispUserCloudAccountType } = commitmentStore.rootStore.usersStore;
    const isAws = currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS;
    const data = isAws
      ? this.prepareTableData(commitmentTableData, commitmentType, true)
      : this.prepareAzureTableData(commitmentTableData, true);
    const csvData = isAws
      ? this.prepareTableData(commitmentTableData, commitmentType, false)
      : this.prepareAzureTableData(commitmentTableData, false);
    const csvModifiedRows = cloneDeep(csvData);
    if (loadingTableData) {
      return (
        <div className="spLoadingAnimation" style={{ paddingBottom: '40px' }}>
          <Spinner />
        </div>
      );
    }
    if (!isGetTableDataButtonActive && commitmentTableData?.length === 0) {
      return <div>No data available</div>;
    }
    if (commitmentTableData?.length) {
      return commitmentType === 'ri' ? (
        <>
          <CustomCSVDownload
            data={[{ data: csvModifiedRows, filename: 'ri_commitment_data.csv' }]}
            isLoading={false}
            headers={RI_CSV_HEADERS}
            filesNumber={1}
          />
          <CommitmentTable
            data={data}
            getRiFlexabilityData={getRiFlexabilityData}
            getChargeBackData={getChargeBackData}
            tableColumns={tableColumns}
            isAws={isAws}
          />
        </>
      ) : (
        <>
          <CustomCSVDownload
            data={[{ data: csvModifiedRows, filename: 'sp_utilization_data.csv' }]}
            isLoading={false}
            headers={SP_CSV_HEADERS}
            filesNumber={1}
          />
          <SavingsPlansUtilizationTable data={data} getChargeBackData={getChargeBackData} tableColumns={tableColumns} />
        </>
      );
    }
    return null;
  };

  render() {
    const {
      getRiFlexabilityData,
      getChargeBackData,
      isGetTableDataButtonActive,
      selectedCommitmentService,
      selectedLinkedAccount,
      selectedPayerAccount,
      handleFilterChange,
      getCommitmentTableData,
      loadingTableData,
      commitmentServiceOptions,
      commitmentType,
      commitmentStore,
      getPageFilters,
      usageStore,
    } = this.props;
    const commitmentTableData = commitmentStore.commitmentModel.getTableDataByType(commitmentType);
    const { currDispUserCloudAccountType } = commitmentStore.rootStore.usersStore;
    const linkedAccountOptions =
      getPageFilters(PageNames.RI_UTILIZATION, usageStore.rootStore.usersStore.currDispUserCloudAccountType).get(
        AwsCommonFields.LINKED_ACCOUNT_ID,
      ) || [];
    const payerAccountOptions =
      getPageFilters(PageNames.RI_UTILIZATION, usageStore.rootStore.usersStore.currDispUserCloudAccountType).get(
        AwsCommonFields.PAYER_ACCOUNT,
      ) || [];
    return (
      <Panel divider title="Commitment description">
        <Row>
          <FilterBar
            selectedCommitmentService={selectedCommitmentService}
            selectedLinkedAccount={selectedLinkedAccount}
            selectedPayerAccount={selectedPayerAccount}
            handleFilterChange={handleFilterChange}
            commitmentServiceOptions={commitmentServiceOptions}
            linkedAccountOptions={linkedAccountOptions}
            payerAccountOptions={payerAccountOptions}
            getCommitmentTableData={getCommitmentTableData}
            isGetTableDataButtonActive={isGetTableDataButtonActive}
            isAllAccounts={payerAccountOptions.length > 0}
            isAws={currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS}
          />
        </Row>
        <Row>
          {this.renderTable(
            commitmentType,
            commitmentTableData,
            loadingTableData,
            getRiFlexabilityData,
            getChargeBackData,
          )}
        </Row>
      </Panel>
    );
  }
}

const CommitmentCommitmentTablePanel = withInvoiceFiltersContextConsumer(
  withUserSettingsConsumer(observer(CommitmentTablePanel)),
);
export default CommitmentCommitmentTablePanel;
