import React from 'react';
import { useFormContext } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Input from 'shared/components/andtComponents/Input';
import commonStyles from '../Pages.module.scss';
import UploadPageHelp from './UploadPageHelp';
import { BYOD_ONBOARDING_FIELDS } from '../../ByodOnboardingConstants';
import styles from './UploadPage.module.scss';

const UploadPage = () => {
  const {
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();
  const renderInput = ({ fieldName, name, label, icon, placeholder }) => (
    <div className={commonStyles.inputField}>
      <label htmlFor={name}>
        <span>
          <GenerateIcon iconName={ICONS[icon].name} />
          {label}
        </span>
      </label>
      <div className={commonStyles.inputBox}>
        <div>
          <Input
            value={getValues(fieldName)}
            isInvalid={errors?.[fieldName] && errors?.[fieldName] !== 'GeneralError'}
            invalidComponent={errors[fieldName]?.message || errors[fieldName]}
            onChange={(e) => {
              setValue(fieldName, e.target.value);
              clearErrors(fieldName);
            }}
            placeholder={placeholder}
            name={name}
            type="text"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <ListWithHelp
        styles={{
          listGap: 32,
          helpWidth: 460,
          controlStyles: {
            marginLeft: '-4px',
            borderLeft: 'none',
          },
        }}
        list={[
          {
            render: () => (
              <div className={styles.accountInfo}>
                <GenerateIcon iconName={ICONS.cloud.name} />
                <span>{getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME)}</span> (
                {getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_ID)})
              </div>
            ),
          },
          {
            render: () =>
              renderInput({
                fieldName: BYOD_ONBOARDING_FIELDS.S3_PATH,
                name: 'onboarding-s3-path',
                label: 'S3 Path',
                icon: 'bucket',
                placeholder: 's3://<alias>/<company_uuid>/<account_id>',
              }),
            key: 'onboarding-s3-path',
          },
          {
            topSpace: 0,
            render: () => (
              <div className={styles.accessKey}>
                <h4>
                  Get the access key via this link:{' '}
                  <a href={getValues(BYOD_ONBOARDING_FIELDS.ACCESS_KEY_URL)} target="_blank" rel="noreferrer">
                    {getValues(BYOD_ONBOARDING_FIELDS.ACCESS_KEY_URL)}
                  </a>
                </h4>
                <p className={styles.note}>
                  <span>Note:</span>This link is one-time use only. Save the keys securely for future clusters.
                </p>
                <p>These keys are essential for uploading your cost data to the platform.</p>
              </div>
            ),
            key: 'onboarding-access-key',
          },
        ]}
        help={{
          'onboarding-s3-path': UploadPageHelp.s3Path,
          'onboarding-access-key': UploadPageHelp.accessKey,
        }}
      />
    </div>
  );
};

export default UploadPage;
