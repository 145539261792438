import React from 'react';
import PropTypes from 'prop-types';
import {Area, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import { palette } from 'shared/constants/colorsConstants';
import {
  CustomizedLegend
} from '../CustomizedLegend/CustomizedLegend.jsx';
import { CustomTooltip } from '../CustomTooltip.jsx';

const AreasChart = ({data, dataKeys, dataProperties, specialGradient, axisSettings, specialLegends, tooltipComponent, height}) => {

  return (<div>
    <ResponsiveContainer width="100%" height={height || 300}>
      <ComposedChart
        data={data}
        margin={{top: 15, right: 24 }}
      >
        <XAxis dataKey="day"
               tick={axisSettings.xCustomTick}
               interval={0}
               domain={['dataMin', 'dataMax + 0.5']}
               axisLine={false}
               tickLine={false}/>
        <XAxis dataKey="day" interval={0} domain={['dataMin', 'dataMax + 0.5']}/>
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{fill: palette.gray[450], fontSize: 11}}
          tickFormatter={(value) =>  axisSettings.yTickFormatter ?  axisSettings.yTickFormatter(value) : value}
        />
        {specialGradient}
        <Tooltip content={tooltipComponent || <CustomTooltip />}/>
        <Legend content={<CustomizedLegend specialLegends={specialLegends}/>}/>
        <CartesianGrid stroke={palette.gray[100]} vertical={false}/>
        {dataKeys?.map((dataKey) => (
          <Area type="monotone"
                key={dataKey}
                dataKey={dataKey}
                name={dataProperties?.[dataKey]?.name}
                dot={false}
                stroke={dataProperties?.[dataKey]?.stroke} fill={dataProperties?.[dataKey]?.fill}
                strokeWidth={1.5}
                />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  </div>)
}
AreasChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataProperties: PropTypes.object,
  specialGradient: PropTypes.node,
  axisSettings: PropTypes.object,
  tooltipComponent: PropTypes.node,
  dataKeys: PropTypes.array.isRequired,
  specialLegends: PropTypes.array,
  height: PropTypes.number,

};

export default AreasChart;
