import { AddClusterFields, addClusterLabels } from "../config.ts";

export const validateAddClusterForm = (formState) => {
  const errors = {};
  if (!formState[AddClusterFields.linkedAccountId]) {
    errors[AddClusterFields.linkedAccountId] = addClusterLabels[AddClusterFields.linkedAccountId]?.error;
  }
  if (!formState[AddClusterFields.clusterName]) {
    errors[AddClusterFields.clusterName] = addClusterLabels[AddClusterFields.clusterName]?.error;
  }
  if (!formState[AddClusterFields.agentNamespace]) {
    errors[AddClusterFields.agentNamespace] = addClusterLabels[AddClusterFields.agentNamespace]?.error;
  }
  if (!formState[AddClusterFields.prometheusUrl]) {
    errors[AddClusterFields.prometheusUrl] = addClusterLabels[AddClusterFields.prometheusUrl]?.error;
  }
  return errors
}
