import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { FilterSelectHierarchy } from './FilterSelectHierarchy';

import classes from './FieldSidebarFilter.module.scss';

const FieldWithHierarchyFilter = ({
  options = [],
  isLoading = false,
  field,
  isOpen = false,
  setIsOpen,
  selectedOptions = [],
  changeSelectedOptions,
}) => {
  const [filterSearchString, setFilterSearchString] = useState('');

  const optionsFilteredBySearch = useMemo(() => {
    return options.filter((option) => option.label.toLowerCase().includes(filterSearchString?.toLowerCase()));
  }, [options, filterSearchString]);

  const hierarchyOptions = useMemo(() => {
    const buildHierarchyOptions = (options, parentId) => {
      const currentLevelOptions = options.filter((o) => o.parentId === parentId);
      if (currentLevelOptions.length === 0) {
        return [];
      }
      return currentLevelOptions.map((option) => ({
        label: option.label,
        value: option.value,
        children: buildHierarchyOptions(options, option.value),
      }));
    };

    return buildHierarchyOptions(optionsFilteredBySearch, null);
  }, [optionsFilteredBySearch]);

  const [drillStack, setDrillStack] = useState([]);

  function onFilterSelectionChange(data) {
    changeSelectedOptions(field, data);
  }

  function onExpandOption(data) {
    const expandedItem = data;
    if (expandedItem) {
      setDrillStack((prev) => [...prev, expandedItem]);
    }
  }

  const currentStackOptions = drillStack.length === 0 ? hierarchyOptions : drillStack[drillStack.length - 1].children;

  const filterContainerRef = useRef();

  return (
    <div ref={filterContainerRef}>
      {drillStack?.length > 0 ? (
        <button
          className={classes.backContainer}
          style={{ width: '100%' }}
          onClick={() => {
            setDrillStack((prev) => prev.slice(0, prev.length - 1));
          }}
          type="button"
          id="backButton"
        >
          <span className={classes.leftSide}>
            <GenerateIcon iconName={ICONS.chevronLeft.name} />
          </span>
          <span className={classes.key}> {drillStack[drillStack.length - 1].label}</span>
        </button>
      ) : null}
      <FilterSelectHierarchy
        field={field}
        hasSubItems
        isLoading={isLoading}
        isMulti
        isOpen={isOpen}
        limitOnBlurInId={`toggle-${field}`}
        onChange={onFilterSelectionChange}
        onExpandOption={onExpandOption}
        onMenuBlur={(event) => {
          const target = event.relatedTarget;
          if (!filterContainerRef.current?.contains(target)) {
            return setIsOpen(false);
          }
        }}
        options={currentStackOptions}
        selectedItems={selectedOptions}
        searchHandler={setFilterSearchString}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

FieldWithHierarchyFilter.propTypes = {
  changeSelectedOptions: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  setIsOpen: PropTypes.func.isRequired,
};

export default FieldWithHierarchyFilter;
