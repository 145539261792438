import React from 'react';
import { palette } from 'shared/constants/colorsConstants';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const InfoIconSVG = () => {
  const id = React.useId();
  const gradientId = `paint0_linear_3899_3014_${id}`;
  return (
    <svg xmlns={ExternalLinks.SvgXMLPAth} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M1 8C1 13.4444 2.55556 15 8 15C13.4444 15 15 13.4444 15 8C15 2.55556 13.4444 1 8 1C2.55556 1 1 2.55556 1 8ZM9.32106 4.0782C9.32106 3.42654 8.87713 3 8.19956 3C7.37012 3 6.82106 3.56872 6.82106 4.4218C6.82106 5.07346 7.26498 5.5 7.91919 5.5C8.77199 5.5 9.32106 4.93128 9.32106 4.0782ZM8.58583 11.233L9.32106 6.65346L9.19658 6.5H6.18672L6 7.70405L7.0306 7.86931L6.42064 11.8474C6.39575 12.0126 6.3833 12.1543 6.3833 12.2841C6.3833 13.0987 6.88122 13.5 7.85218 13.5C8.17583 13.5 9.22589 13.4528 9.71136 13.3465L9.89809 12.2015H9.19658C8.55737 12.2015 8.45698 12.1494 8.58583 11.233Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient id={gradientId} x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC530" />
          <stop offset="1" stopColor={palette.orange['400']} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InfoIconSVG;
