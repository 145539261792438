/* eslint-disable no-unused-vars */
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import { isArray } from 'lodash';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class CostCenterDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    const newDataState = CostUsageStates.SERVICE;
    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.COST_CENTER;
  }
}
