import { useMutation, useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, RECOMMENDATIONS_VIEWS_ROOT, STALE_TIME } from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';

const fetchRecViews = async () => API.get(BILLINGS_API_NAME, `${RECOMMENDATIONS_VIEWS_ROOT}`);
const createViewPanel = async (viewParams) =>
  API.post('billings', `${RECOMMENDATIONS_VIEWS_ROOT}`, { body: viewParams });
const updateViewPanel = async (viewParams) =>
  API.put('billings', `${RECOMMENDATIONS_VIEWS_ROOT}/${viewParams.id}`, { body: viewParams });
const deleteViewPanel = async (id) => API.del('billings', `${RECOMMENDATIONS_VIEWS_ROOT}/${id}`);

export default function useRecViews(isEnabled = true) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS,
    apiConstants.QUERY_KEYS.RECOMMENDATIONS_VIEW,
    userAccountKey,
  ];

  return {
    invalidate: (queryKeyToInvalidate) =>
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidate
          ? [...queryKey, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.sortBy]
          : queryKey,
      }),
    reset: (queryKeyToInvalidate, options) =>
      queryClient.resetQueries(
        {
          queryKey: queryKeyToInvalidate
            ? [...queryKey, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.sortBy]
            : queryKey,
        },
        options,
      ),
    fetchRecViews: () =>
      useQuery({
        queryKey: [...queryKey],
        queryFn: () => fetchRecViews(),
        retry: false,
        staleTime: STALE_TIME,
        enabled: isEnabled,
        keepPreviousData: true,
      }),
    useCreateViewPanel: () =>
      useMutation({
        mutationFn: createViewPanel,
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }),
    useUpdateViewPanel: () =>
      useMutation({
        mutationFn: updateViewPanel,
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }),
    useDeleteViewPanel: () =>
      useMutation({
        mutationFn: deleteViewPanel,
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }),
  };
}
