import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'shared/constants/routes';
import PropTypes from 'prop-types';
import Button from 'shared/components/andtComponents/Button';
import { waitForToast } from 'users/containers/LogIn/utils/loginUtils';
import * as usersApi from 'users/api/usersApi';
import Spinner from 'shared/components/andtComponents/Spinner';
import integrationsUtils from 'shared/utils/integrationsUtils';
import anodotLogo from 'shared/img/images/cloud_cost_logo.png';
import { isPasswordValid } from 'users/utils/userUtil';
import LogInForm from 'users/containers/LogIn/components/LogInForm';
import RequestCodeForm from 'users/containers/LogIn/components/RequestCodeForm';
import ConfirmationForm from 'users/containers/LogIn/components/ConfirmationForm';
import SuccessMessage from 'users/containers/LogIn/components/SuccessMessage';
import Modal from 'shared/components/andtComponents/Modal';
import LoginPhases from 'users/containers/LogIn/loginPhases';
import { parseJwt, setLocalStorage } from 'shared/utils/tokenUtil';
import { useUsers } from 'shared/hooks/react-query/useUsers';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import {
  createKeycloakInstance,
  initKeycloak,
  isKeycloakAuthenticated,
  keycloakLogin,
  persistRealm,
} from 'shared/keycloak/keycloak.service';
import LeftSideIllustration from './LeftSideIllustration';
import styles from './Login.module.scss';
import config from '../../../config';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const validateEmail = (val) => {
  const re = /\S+@\S+/;
  return re.test(val);
};

const isAuthTokenValid = (authToken) => {
  try {
    return authToken;
  } catch {
    return false;
  }
};

const signInWithSsoDomain = (clientId) => {
  const cognitoPoolUrl = config.COGNITO_POOL_URL || ExternalLinks.ProdCognitoPool;
  const redirectUri = config.COGNITO_REDIRECT_URI || ExternalLinks.ProdRedirectURL;
  window.location.replace(
    `${cognitoPoolUrl}/login?\
response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}`,
    { replace: true },
  );
};
const LogIn = ({ handleLogout, usersStore, appStore, userHasAuthenticated, setUsername = {} }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [logPhase, setLogPhase] = useState(LoginPhases.LOG_PHASE_EMAIL);
  const [isValidating, setIsValidating] = useState(false);
  const [email, setEmail] = useState(location?.source?.username || '');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [codeConfirmed, setCodeConfirmed] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState([]);
  const [existingTokenAuthentication, setExistingTokenAuthentication] = useState(false);
  const [displayRoleSelectionModal, setDisplayRoleSelectionModal] = useState(false);
  const [selectedBusinessGroup, setSelectedBusinessGroup] = useState(null);
  const [isSelectRoleButtonLoginLoading, setIsSelectRoleButtonLoginLoading] = useState(false);
  const { fetchRealm } = useUsers();

  const handleEmail = async (username) => {
    // TODO - Pay attention this method called twice in case of submit the login form using enter key - should be fix.
    let realm;
    try {
      const res = await fetchRealm(username);
      realm = res?.realm; // In case of keycloak realm won't be null
      // eslint-disable-next-line no-empty
    } catch { }
    if (realm) {
      persistRealm(realm);
      // keycloak mode
      createKeycloakInstance(realm);
      await initKeycloak();
      const keycloakAuthenticated = isKeycloakAuthenticated();
      if (!keycloakAuthenticated) {
        await keycloakLogin(username);
      }
    } else {
      const clientId = await usersApi.getSsoClientId(username);
      if (clientId) {
        signInWithSsoDomain(clientId);
      } else {
        setLogPhase(LoginPhases.LOG_PHASE_LOGIN);
      }
    }
  };

  const signInFailValidToken = async () => {
    localStorage.removeItem('authToken');
    await handleLogout('/log_in');
    return false;
  };

  const connectExternalUserNameToUserKey = async (userName, externalUserName) => {
    const userKey = await usersStore.getUserKeyFromUserName(userName);
    if (userKey) {
      await usersStore.setExternalUserNameToUserKey(externalUserName, userKey);
    }
    return userKey;
  };

  const handleSignIn = async ({ authToken, selectedRole = null }) => {
    let userData = {};
    let connectedUserKey = '';
    try {
      userData = await usersStore.signinWithToken(selectedRole);
      const { userKey, userName, externalUserName, hasExternalMapping } = userData;
      connectedUserKey = userKey;
      if (!hasExternalMapping && !appStore.isKeyCloakManagement) {
        connectedUserKey = await connectExternalUserNameToUserKey(userName, externalUserName);
      }
      if (!connectedUserKey) {
        // non-existing user
        await signInFailValidToken();
        return;
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // bad token
        throw error;
      } else {
        // non-existing user
        await signInFailValidToken();
        return;
      }
    }

    // setting the initial displayed Account user to logged in user
    usersStore.updateCurrentAuthUser(connectedUserKey);
    usersStore.updateCurrentDisplayedUserKey(connectedUserKey);
    usersStore.updateCurrentDisplayedUserName(userData.userName);
    await usersStore.initMainUser();
    userHasAuthenticated();
    let anodotToken = '';
    if (!appStore.isKeyCloakManagement) {
      anodotToken = await usersApi.getAnodotUserToken();
    }
    usersStore.rootStore.fetchData(connectedUserKey);
    const decoded = parseJwt(authToken);
    setLocalStorage('anodotToken', anodotToken, decoded['custom:useSessionStorage'] === '1');
    await integrationsUtils.completeAuthForIntegrations();
  };

  // for login only
  const handleLogin = async (username, password) => {
    // Catch the user if he tries to bypass SSO login.
    const clientId = await usersApi.getSsoClientId(username);

    if (clientId) {
      setLogPhase(LoginPhases.LOG_PHASE_EMAIL);
      return false;
    }
    let loginResult = false;
    try {
      const loweredCaseUsername = username.toLowerCase();
      const user = await usersApi.signIn(loweredCaseUsername, password);
      const { jwtToken: authToken, refreshToken } = user || {};
      const decoded = parseJwt(authToken);
      setLocalStorage('authToken', authToken, decoded['custom:useSessionStorage'] === '1');
      setLocalStorage('refreshToken', refreshToken, decoded['custom:useSessionStorage'] === '1');
      setLocalStorage('username', loweredCaseUsername, decoded['custom:useSessionStorage'] === '1');
      await handleSignIn({ authToken });
      loginResult = true;
    } catch {
      await waitForToast('There seems to be an issue with your username or password');
      handleLogout();
    }
    return loginResult;
  };

  useEffect(() => {
    (async () => {
      const displayAsyncToast = async () => {
        await waitForToast('There seems to be an issue with authentication token');
      };
      const handleAsyncSignIn = async (token) => {
        try {
          await handleSignIn({ authToken: token, selectedRole: selectedBusinessGroup?.value });
        } catch {
          // we need to duplicate the catch code since exception is thrown from handleSignIn
          // will not be caught by outer catch
          localStorage.removeItem('authToken');
          sessionStorage.removeItem('authToken');
          setExistingTokenAuthentication(false);
          await displayAsyncToast();
          navigate('/');
        }
      };
      try {
        const { hash } = location;
        const params = new URLSearchParams(hash.replace('#', '?'));
        const idToken = params.get('id_token');
        if (isAuthTokenValid(idToken)) {
          setExistingTokenAuthentication(true);
          const authToken = idToken;
          const decoded = parseJwt(authToken);
          setLocalStorage('authToken', authToken, decoded['custom:useSessionStorage'] === '1');
          const businessGroups = decoded['custom:businessGroups'];
          if (businessGroups) {
            const businessGroupsList =
              businessGroups
                ?.replace(/^\[|\]$/g, '')
                ?.split(',')
                ?.map((businessGroup) => businessGroup.trim()) || [];
            if (businessGroupsList.length > 1) {
              setDisplayRoleSelectionModal(true);
              return;
            }
            setSelectedBusinessGroup({ value: businessGroupsList[0] });
          }
          await handleAsyncSignIn(authToken);
        }
      } catch {
        localStorage.removeItem('authToken');
        setExistingTokenAuthentication(false);
        displayAsyncToast();
        handleLogout();
        navigate('/');
      }
    })();
  }, []);
  // user clicked send confirmation code
  const handleSendCodeClick = async (username) => {
    try {
      const loweredCaseUsername = username.toLowerCase();
      await usersApi.forgotPassword(loweredCaseUsername);
      setUsername(loweredCaseUsername);
      setCodeSent(true);
      setLogPhase(LoginPhases.LOG_PHASE_NEW_PASSWORD);
    } catch {
      await waitForToast('Failed to send confirmation code');
    }
  };
  // user updated password and clicked update password
  const handleConfirmNewPasswordClick = async (username, code, password) => {
    try {
      const loweredCaseUsername = username.toLowerCase();
      await usersApi.forgotPasswordSubmit(loweredCaseUsername, code, password);
      setUsername(loweredCaseUsername);
      setCodeConfirmed(true);
      setLogPhase(LoginPhases.LOG_PHASE_BACK_LOGIN);
    } catch {
      await waitForToast('Failed to update password. Please make sure the confirmation code is correct.');
    }
  };
  const emailMapObj = {
    text: LoginPhases.TEXT_EMAIL,
    loadingText: LoginPhases.LOADING_TEXT_EMAIL,
    formValidation: () => validateEmail(email),
    submitHandler: () => handleEmail(email),
  };
  const logInMapObj = {
    text: LoginPhases.TEXT_LOGIN,
    loadingText: LoginPhases.LOADING_TEXT_LOGIN,
    formValidation: () => validateEmail(email) && password.length > 0,
    submitHandler: () => handleLogin(email, password),
  };
  const requestCodeMapObj = {
    text: LoginPhases.TEXT_CODE,
    loadingText: LoginPhases.LOADING_TEXT_CODE,
    formValidation: () => validateEmail(email),
    submitHandler: () => handleSendCodeClick(email),
  };
  const passwordEntryMapObj = {
    text: LoginPhases.TEXT_NEW_PASS,
    loadingText: LoginPhases.LOADING_TEXT_NEW_PASS,
    formValidation: () => code.length > 0 && password.length > 0 && password === confirmPassword,
    submitHandler: () => handleConfirmNewPasswordClick(email, code, password),
  };
  const backToLogInMapObj = {
    text: LoginPhases.TEXT_BACK_LOGIN,
    formValidation: () => true,
    submitHandler: () => setLogPhase('login'),
  };
  const logStatusMap = new Map([
    [LoginPhases.LOG_PHASE_EMAIL, emailMapObj],
    [LoginPhases.LOG_PHASE_LOGIN, logInMapObj],
    [LoginPhases.LOG_PHASE_REQUEST_CODE, requestCodeMapObj],
    [LoginPhases.LOG_PHASE_NEW_PASSWORD, passwordEntryMapObj],
    [LoginPhases.LOG_PHASE_BACK_LOGIN, backToLogInMapObj],
  ]);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setIsValidating(true);
    try {
      const result = false;
      await logStatusMap.get(logPhase).submitHandler();
      setIsValidating(result);
    } catch {
      setIsValidating(false);
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const { resultStrings: currentValidation } = isPasswordValid(e.target.value);
    if (e.target.value !== confirmPassword) {
      currentValidation.push('Passwords do not match');
    }
    setPasswordValidation(currentValidation);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    const { resultStrings: currentValidation } = isPasswordValid(password);
    if (e.target.value !== password) {
      currentValidation.push('Passwords do not match');
    }
    setPasswordValidation(currentValidation);
  };

  // user clicked forgot passwrod button on Login from
  const handleForgotPassword = async () => {
    // Catch the user if he tries to bypass SSO login.
    const clientId = await usersApi.getSsoClientId(email);
    if (clientId) {
      setLogPhase(LoginPhases.LOG_PHASE_EMAIL);
      return;
    }

    setLogPhase(LoginPhases.LOG_PHASE_REQUEST_CODE);
    setPassword('');
    setCodeConfirmed(false);
    setCodeSent(false);
    setCode('');
    setConfirmPassword('');
  };

  const handleRoleSelection = async (selectedRole) => {
    setSelectedBusinessGroup(selectedRole);
  };

  const handleLoginWithSelectedRole = async () => {
    const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
    setIsSelectRoleButtonLoginLoading(true);
    await handleSignIn({ authToken, selectedRole: selectedBusinessGroup?.value });
    setIsSelectRoleButtonLoginLoading(false);
  };

  if (displayRoleSelectionModal) {
    const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
    const decodedToken = parseJwt(authToken);
    const businessGroups = decodedToken['custom:businessGroups'];
    const roles =
      businessGroups
        ?.replace(/^\[|\]$/g, '')
        ?.split(',')
        ?.map((businessGroup) => businessGroup.trim()) || [];
    return (
      <div className={styles.loginContainer}>
        <Modal
          open={displayRoleSelectionModal}
          onClose={() => { }}
          onCloseClick={() => { }}
          onSave={handleLoginWithSelectedRole}
          saveDisabled={!selectedBusinessGroup || isSelectRoleButtonLoginLoading}
          cancelHidden
          closeOnSave={false}
        >
          <OneChoiceFieldFilter
            placeholder="Select Group"
            type="businessGroup"
            value={selectedBusinessGroup}
            options={roles.map((role) => ({ value: role, label: role }))}
            handleChange={(_, value) => handleRoleSelection(value)}
          />
        </Modal>
      </div>
    );
  }

  if (existingTokenAuthentication) {
    return <Spinner />;
  }
  const loginForm = (
    <LogInForm
      handleForgotPassword={handleForgotPassword}
      handleSubmit={handleSubmit}
      email={email}
      handleEmailChange={(e) => setEmail(e.target.value)}
      password={password}
      handlePasswordChange={(e) => setPassword(e.target.value)}
      logPhase={logPhase}
      isEmailValid={validateEmail(email)}
    />
  );

  const requestCodeForm = (
    <RequestCodeForm
      handleEmailChange={(e) => {
        e.preventDefault();
        setEmail(e.target.value);
      }}
      email={email}
    />
  );

  const confirmationFrom = (
    <ConfirmationForm
      email={email}
      code={code}
      handleCodeChange={(e) => setCode(e.target.value)}
      password={password}
      handlePasswordChange={handlePasswordChange}
      confirmPassword={confirmPassword}
      handleConfirmPasswordChange={handleConfirmPasswordChange}
      passwordValidation={passwordValidation}
    />
  );
  const successMessage = <SuccessMessage />;

  const selectForm = () => {
    let form = loginForm;
    if (logPhase === LoginPhases.LOG_PHASE_REQUEST_CODE && !codeSent) {
      form = requestCodeForm;
    } else if (logPhase === LoginPhases.LOG_PHASE_NEW_PASSWORD && !codeConfirmed) {
      form = confirmationFrom;
    } else if (logPhase === LoginPhases.LOG_PHASE_BACK_LOGIN) {
      form = successMessage;
    }

    return form;
  };

  const noAccountStr = "Don't have an account? ";
  const gotAccountStr = 'Already have an account? ';

  return (
    <div className={styles.loginContainer}>
      <LeftSideIllustration />
      <div className={styles.loginForm}>
        <img src={anodotLogo} alt="Anodot for Cloud Cost" className={styles.loginLogo} />
        {selectForm()}
        <div className={styles.loaderButton}>
          <Button
            onClick={handleSubmit}
            disabled={!logStatusMap.get(logPhase).formValidation() || isValidating}
            isLoading={isValidating}
            text={logStatusMap.get(logPhase).text}
            overrideStyles={{ height: '50px' }}
            automationId="mainLoginButton"
          />
        </div>
        <div className={styles.loginFormFooter}>
          <div>
            {noAccountStr}
            <Link to={Routes.REGISTER}>Register</Link>
          </div>
          {logPhase === LoginPhases.LOG_PHASE_REQUEST_CODE ? (
            <div>
              {gotAccountStr}
              <a href="#" onClick={() => setLogPhase(LoginPhases.LOG_PHASE_EMAIL)}>
                Login
              </a>
            </div>
          ) : null}
          <div className={styles.loginFormFooterLinks}>
            <a rel="noopener noreferrer" target="_blank" href={Routes.TERMS_AND_CONDS}>
              Terms of use
            </a>
            <a rel="noopener noreferrer" target="_blank" href={Routes.PRIVACY_POLICY}>
              Privacy policy
            </a>
            <a rel="noopener noreferrer" target="_blank" href={Routes.CONTACT_US}>
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;

LogIn.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  usersStore: PropTypes.object.isRequired,
  userHasAuthenticated: PropTypes.func.isRequired,
  setUsername: PropTypes.func.isRequired,
};
