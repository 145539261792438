import React from 'react';
import styles from './ByodDetailsPage.module.scss';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const ByodDetailsPageHelp = {
  accountName: {
    component: () => (
      <div>
        <p className={styles.helpText}>
          Give your account a meaningful name.
          <br /> This name will be visible in Anodot Cost in the list of accounts, the top menu bar and every other
          place where you would choose an account.
        </p>
        <p className={styles.helpText}>
          FYI: This account will be a sibling to AWS Payer accounts, as well as Azure and GCP Billing accounts
        </p>
        <a href={ExternalLinks.ByodOnboarding} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      </div>
    ),
  },
  importType: {
    component: () => (
      <div>
        <p className={styles.helpText}>
          The supported format is FOCUS. Make sure the files you wish to upload to our S3 bucket are in FOCUS
          format.
        </p>
        <p className={styles.helpText}>
          Custom Schemas are coming soon and will allow you to process cost files with a more flexible structure.
        </p>
        <a href={ExternalLinks.ByodOnboarding} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      </div>
    ),
  },
  vendor: {
    component: () => (
      <div>
        <p className={styles.helpText}>
          Choose the vendor from the drop down list. If you cannot find it on the list, you can add it by pressing the
          "Add vendor to list" button. Give the vendor a name and upload a logo to represent it in Anodot Cost.
        </p>
        <p className={styles.helpText}>
          The added vendor will be available as part of the list in future cost data imports, within your organization.
        </p>
        <a href={ExternalLinks.ByodOnboarding} target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      </div>
    ),
  },
};

export default ByodDetailsPageHelp;
