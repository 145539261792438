import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import toast from 'shared/components/andtComponents/Toast';

const root = '/api/v1/divisions/customers/rebilling';

const triggerRebillingApi = ({ customers, months }) => API.post('billings', root, { body: { customers, months } });

const getRebillingPerCustomers = () => {
  return API.get('billings', root);
};

export default function useRebilling() {
  const queryClient = useQueryClient();

  const queryKey = [apiConstants.QUERY_KEYS.REBILLING_HISTORY];

  const triggerRebillingMutation = useMutation({
    mutationFn: triggerRebillingApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Rebilling scheduled successfully');
    },
  });

  return {
    useFetchRebillingHistory: () =>
      useQuery({
        queryKey: queryKey,
        queryFn: getRebillingPerCustomers,
        staleTime: 60 * 1000,
      }),
    triggerRebillingMutation,
  };
}
