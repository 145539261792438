import React, { useEffect } from 'react';
import { PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import classNames from 'classnames';
import moment from 'moment';
import DateFilter from 'shared/modules/dateFilter.js';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import { getFirstDayOfMonth } from 'app/containers/MainDashboard/utils.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import styles from './costPanels.module.scss';

const LinkedAccountTotal = () => {
  const { updatePanelLoadingState, getDynamicFilters } = useMainDashboardContext();
  const cueHook = useCueData();
  const { usersStore, appStore } = useRootStore();
  const isMspAccount = usersStore.isCurrentUserReseller;
  const isMultiAccount = usersStore.isCurrentAccountMulticloud || usersStore.isCurrentAccountAllAccounts;

  const customerCueHook = useCueData(isMspAccount);
  const linkedAccountParams = {
    ...PANELS[PANELS_TYPES.LINKED_ACCOUNT_TOTAL].params,
    start: getFirstDayOfMonth(moment(DateFilter.getDate())),
    end: moment(DateFilter.getDate()).format('YYYY-MM-DD'),
    isPpApplied: appStore.isPpApplied,
    ...getDynamicFilters(),
  };
  if (isMultiAccount) {
    linkedAccountParams.groupBy = 'payeraccount';
  }
  const { data: linkedAccount, isLoading: isLinkedAccountLoading } = cueHook.fetchCueData(linkedAccountParams);
  const { data: customers, isLoading: isCustomerDataLoading } = customerCueHook.fetchCueData({
    ...linkedAccountParams,
    groupBy: 'division',
  });

  const isLoading = isLinkedAccountLoading || (isMspAccount && isCustomerDataLoading);

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.LINKED_ACCOUNT_TOTAL, isLoading);
  }, [isLoading]);

  return (
    <div className={classNames(styles.costPanel, styles.marginLeft, styles.limitWidth)}>
      {isLoading ? (
        <Spinner size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <div className={styles.laWrapper}>
          <GenerateIcon iconName={ICONS.buildings.name} className={styles.icon} />
          <span className={styles.content}>
            <span className={styles.value}>{linkedAccount?.length || 0}</span>
            {!isMultiAccount ? `${getLinkedAccountName(usersStore.currDispUserCloudAccountType)}s` : 'Accounts'}
          </span>
          {isMspAccount && (
            <span className={classNames(styles.content, styles.secondContent)}>
              <span className={styles.value}>{customers?.length || 0}</span> Customers
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default LinkedAccountTotal;
