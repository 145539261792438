import styles from './addAlertRuleModal.module.scss';
import { AwsCommonFields } from 'shared/constants/awsConstants.js';
import React from 'react';
import PropTypes from 'prop-types';
import { ALL_FILTERS_BY_ID as filters } from 'shared/components/FilterSidebar/constants.ts';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import FieldSidebarFilter from 'shared/components/FilterSidebar/FieldSidebarFilter.jsx';
import { useInvoiceFilters } from "../../../../invoices/contexts/InvoiceFiltersContext.jsx";
import { formatFilterOptionValueLabel } from "../../CostAndUsageExplorer/helpers/customDashboardHelperMethods.js";
import {
  getDefaultFiltersConfig,
  getLabel,
} from 'shared/components/FilterSidebar/filterUtil.js';
import { useRootStore } from "app/contexts/RootStoreContext.jsx";
import { CLOUD_TYPE_IDS } from "users/constants/usersConstants.js";

const getIsExclude = (filter) => filter?.relation?.value === 'exclude';
const getExcludeHandler = (setFilters) => (field, _, isExclude) =>
  setFilters(field, 'relation', { value: isExclude ? 'exclude' : 'include' });

const propTypes = {
  selectedAlertRule: PropTypes.object,
  filtersOptions: PropTypes.object,
  alertRule: PropTypes.object,
  className: PropTypes.string,
  setFilters: PropTypes.func,
};

const getNestedFilterProps = (defaultOptions, generalProps) => {
  if (!generalProps) {
    return
  }

  const {
    filter,
    isAutoComplete,
    values,
    subMenu,
    isAsyncLoad,
    isAsyncLoadClick,
    keys,
    disableLike,
    fetchData,
    ...other
  } = getDefaultFiltersConfig(AwsCommonFields.ACCOUNT_TAGS, {}, generalProps)
  const createOptions = (fieldValues, filterType) =>
    fieldValues.map((fieldValue) => formatFilterOptionValueLabel(fieldValue, filterType));
  const field = AwsCommonFields.ACCOUNT_TAGS;
  const options = createOptions(
    isAutoComplete || isAsyncLoad || isAsyncLoadClick ? values || [] : defaultOptions,
    field,
  );
  const label = getLabel(field, generalProps.currDispUserCloudAccountType);
  return {
    ...other,
    keys,
    isAutoComplete,
    subMenu,
    isAsyncLoad,
    isAsyncLoadClick,
    field,
    disableLike,
    options,
    filter,
    label,
    isOneChoiceFieldFilter: false,
    isDisabled: false,
    fetchData,
  };
}
const linkedIdMap = {
  [CLOUD_TYPE_IDS.AZURE]: "Subscription",
  [CLOUD_TYPE_IDS.GCP]: "Project"
};

const AlertFilters = ({
  filtersOptions,
  alertRule = {},
  setFilters,
  className,
}) => {
  const { usersStore } = useRootStore() || {};
  const generalProps = useInvoiceFilters();
  const {divisions = [],
    linkedAccounts = [],
    services = [], accountTags: accountTagsOptions = []} = filtersOptions;
  const { service = {}, linkedaccid = {}, division = {}, accounttags = {} } = alertRule?.filters || {};

  const accountTagsProps = getNestedFilterProps(accountTagsOptions, generalProps)

  return (
    <div className={className}>
      <div className={styles.filtersBlock}>
        <FieldSidebarFilter
          className={{ sideBarField: styles.filter, filterTitle: styles.filterTitle }}
          field={AwsCommonFields.DIVISION}
          options={divisions}
          handleChange={(field, value) => setFilters(field, 'values', value)}
          selectedOptions={division?.values}
          excludeMode={getIsExclude(division)}
          handleChangeFilterType={getExcludeHandler(setFilters)}
          filterIcon={filters[AwsCommonFields.DIVISION]?.icon || ICONS.filterList?.name}
        />
        <div className={styles.logicalBox}>OR</div>
        <FieldSidebarFilter
          className={{ sideBarField: styles.filter, filterTitle: styles.filterTitle }}
          field={AwsCommonFields.LINKED_ACCOUNT_ID}
          options={linkedAccounts}
          handleChange={(field, value) => setFilters(field, 'values', value)}
          selectedOptions={linkedaccid?.values}
          excludeMode={getIsExclude(linkedaccid)}
          handleChangeFilterType={getExcludeHandler(setFilters)}
          filterIcon={filters[AwsCommonFields.LINKED_ACCOUNT_ID]?.icon || ICONS.filterList?.name}
          label={linkedIdMap[usersStore?.currDispUserCloudAccountType || CLOUD_TYPE_IDS.AWS]}
        />
      </div>
      <div className={styles.filtersBlock}>
        <div className={styles.logicalBox}>AND</div>
        <FieldSidebarFilter
          className={{ sideBarField: styles.filter, filterTitle: styles.filterTitle }}
          field={AwsCommonFields.SERVICE}
          options={services}
          handleChange={(field, value) => setFilters(field, 'values', value)}
          selectedOptions={service.values}
          excludeMode={getIsExclude(service)}
          handleChangeFilterType={getExcludeHandler(setFilters)}
          filterIcon={filters[AwsCommonFields.SERVICE]?.icon || ICONS.filterList?.name}
        />
      </div>
      <div className={styles.filtersBlock}>
        <div className={styles.logicalBox}>AND</div>
        {accountTagsProps && (
          <FieldSidebarFilter
          {...accountTagsProps}
          className={{ sideBarField: styles.filter, filterTitle: styles.filterTitle }}
          field={AwsCommonFields.ACCOUNT_TAGS}
          handleChange={(field, value) => setFilters(field, 'values', value)}
          selectedOptions={accounttags.values}
          excludeMode={getIsExclude(accounttags)}
          handleChangeFilterType={getExcludeHandler(setFilters)}
          filterIcon={filters[AwsCommonFields.ACCOUNT_TAGS]?.icon || ICONS.filterList?.name}
        />
        )}
      </div>
    </div>
  );
};

AlertFilters.propTypes = propTypes;
export default AlertFilters;
