import React, { useEffect, useState } from 'react';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import PropTypes from 'prop-types';
import { mapGranularityToDisplayName } from '../../../usage/constants/costAndUsageConstants';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const GoalIcon = ({ x, y, className, avg = null, granularity, target, name, goalReached = false }) => {
  const [tooltipOpen, setTooltipOpen] = useState(true);
  useEffect(() => {
    setTimeout(() => setTooltipOpen(false), 5000);
  }, []);
  const tooltipText = (
    <>
      {goalReached ? (
        <>
          You have reached your goal!
          <br />
          Time to set a new one...
          <br />
        </>
      ) : (
        <>
          {name}
          <br />
        </>
      )}
      {mapGranularityToDisplayName.get(granularity) || ''} Goal: {target}
      <br />
      {avg ? `Avg. current amount: ${avg}` : null}
    </>
  );
  return (
    <Tooltip
      title={tooltipText}
      arrow
      open={goalReached ? tooltipOpen : undefined}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns={ExternalLinks.SvgXMLPAth}
        x={x}
        y={y}
        className={className}
      >
        <path
          /* eslint-disable-next-line max-len */
          d="M2.66666 10H11.91C12.0367 9.99995 12.1607 9.96381 12.2676 9.89582C12.3745 9.82782 12.4598 9.73078 12.5135 9.61606C12.5672 9.50134 12.5871 9.37369 12.5709 9.24806C12.5547 9.12242 12.5031 9.004 12.422 8.90667L10 6L12.422 3.09333C12.5031 2.996 12.5547 2.87758 12.5709 2.75194C12.5871 2.62631 12.5672 2.49866 12.5135 2.38394C12.4598 2.26922 12.3745 2.17218 12.2676 2.10418C12.1607 2.03619 12.0367 2.00005 11.91 2H2.66666V14"
          stroke="#3D4C59"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Tooltip>
  );
};

GoalIcon.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  avg: PropTypes.string,
  granularity: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  goalReached: PropTypes.bool,
};

export default GoalIcon;
