import PropTypes from 'prop-types';
import React from 'react';
import { palette } from 'shared/constants/colorsConstants';

export const CustomizedAxisTick = ({ x, y, payload, index, data }) => {
    const value = Number(payload.value);
    if (data.length === 0) return;
    const lastDate = new Date(data[data.length - 1]?.fullDate);
    const numOfDayInLastMonth = new Date(lastDate.getFullYear(), lastDate.getMonth() + 1, 0).getDate();

    const getMonthLabel = () => (
      <text x={x} y={y - 18} dy={32} textAnchor="middle" style={{ marginBottom: '6px', fontSize: '12px', fill: palette.gray[400], fontWeight: '300' }}>
          {new Date(data[index].fullDate).toLocaleString('default', { month: 'short' })}
      </text>
    );

    // Display the last month label when the month is not full
    if (
      value !== 15 &&
      lastDate.getDate() < numOfDayInLastMonth &&
      value === Math.floor(lastDate.getDate() / 2) &&
      new Date(data[index].fullDate).getMonth() === lastDate.getMonth()
    ) {
        if (value === 1) {
            return (
              <g x={x} y={y}>
                  {getMonthLabel()}
              </g>
            );
        }
        return getMonthLabel();
    }
    if (value !== 1 && value !== 15) {
        return null;
    }
    // Display only 1 and 15 day each month in xAxis
    return (
      <g x={x} y={y}>
          {value === 15 &&
            (new Date(data[index].fullDate).getMonth() !== lastDate.getMonth() ||
              lastDate.getDate() === numOfDayInLastMonth ||
              Math.floor(lastDate.getDate() / 2) === 15) &&
            getMonthLabel()}
      </g>
    );
};

CustomizedAxisTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.object,
    index: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
};
