import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import { FlatObject } from 'shared/types/commonTypes.ts';
import FeaturesTable from './components/FeaturesTable.tsx';
import Filters, { SelectOption } from './components/Filters.tsx';
import { useFetchFeatures } from './hooks/useFetchFeatures.ts';
import { AccountFeaturesT } from './types.ts';
import { ALL_ACCOUNTS_DEFAULT } from './constants.ts';

const FeatureFlags = () => {
  const [accountsFeaturesState, setAccountsFeaturesState] = useState<FlatObject<AccountFeaturesT> | null>(null);
  const [addedFeatures, setAddedFeatures] = useState<{ featureName: string }[]>([]);
  const [filters, setFilters] = useState({
    companies: [] as SelectOption[],
    accounts: [] as SelectOption[],
    features: [] as SelectOption[],
  });
  const selectedAccountsIds = filters.accounts.map((a) => a.value);
  const selectedCompaniesIds = filters.companies.map((c) => c.value);
  const { getFeaturesList, getAccountsFeatures, getDefaultFeatures, saveAccountsFeatures } = useFetchFeatures();
  const { data: allFeaturesList } = getFeaturesList();
  const { data: defaultFeatures } = getDefaultFeatures();
  const { data: accountsFeatures, isLoading, refetch } = getAccountsFeatures(selectedAccountsIds, selectedCompaniesIds);
  const { mutate: handleSaveFeatures, isLoading: isSavingInProgress } = saveAccountsFeatures();

  const mergedFeatures = useMemo(() => {
    if (!filters.features.length) {
      return addedFeatures.concat(allFeaturesList || []);
    }
    return filters.features.map(({ value }) => ({ featureName: value }));
  }, [filters.features, allFeaturesList, addedFeatures]);

  const onLoadData = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const addFeature = useCallback(
    (featureName: string) => {
      if (featureName) {
        setAddedFeatures((state) => [{ featureName }, ...state]);
        if (filters.features.length) {
          setFilters((state) => ({
            ...state,
            features: [{ label: featureName, value: featureName }, ...state.features],
          }));
        }
      }
    },
    [filters.features],
  );

  const onSave = useCallback(() => {
    const changedAccounts = Object.values(accountsFeaturesState || {}).reduce(
      (res, { accountId, features, changedFeatures = {} }) => {
        const newValuesFeatures = {} as { [key: string]: '1' | '0' };
        Object.keys(changedFeatures).forEach((featureName) => {
          if (changedFeatures[featureName] !== features[featureName]) {
            newValuesFeatures[featureName] = changedFeatures[featureName];
          }
        });
        if (Object.keys(newValuesFeatures).length) {
          res.push({ accountId, features: newValuesFeatures });
        }
        return res;
      },
      [] as Pick<AccountFeaturesT, 'accountId' | 'features'>[],
    );
    if (changedAccounts.length) {
      handleSaveFeatures(changedAccounts);
    }
  }, [accountsFeaturesState]);

  useEffect(() => {
    if (defaultFeatures) {
      setAccountsFeaturesState((s) => ({ [defaultFeatures.id]: defaultFeatures, ...s }));
    }
  }, [defaultFeatures]);

  useEffect(() => {
    if (accountsFeatures?.length) {
      setAccountsFeaturesState((s) => ({
        [ALL_ACCOUNTS_DEFAULT]: (s || {})[ALL_ACCOUNTS_DEFAULT],
        ...Object.fromEntries(accountsFeatures?.map((row) => [row.id, row])),
      }));
    }
  }, [accountsFeatures]);

  if (!allFeaturesList) {
    return null;
  }

  return (
    <div className="container">
      <PageHeader title={PageNames.FEATURE_FLAGS} />
      <Filters
        features={addedFeatures.concat(allFeaturesList || [])}
        filters={filters}
        setFilters={setFilters}
        onSave={onSave}
        onAddFeature={addFeature}
        onRefresh={onLoadData}
        isSavingLoading={isSavingInProgress}
        isListLoading={isLoading}
      />
      {!isLoading && accountsFeaturesState && (
        <FeaturesTable
          allFeatures={mergedFeatures}
          accountsFeatures={accountsFeaturesState}
          setAccountsFeaturesState={setAccountsFeaturesState}
          showSpinner={isLoading}
        />
      )}
    </div>
  );
};
export default FeatureFlags;
