import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import fileSaver from 'file-saver';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import {
  AZURE_BILLING_HISTORY_TABLE_COLUMNS,
  AZURE_BILLING_HISTORY_TABLE_COLUMNS_WIDTHS,
} from 'divisions/constants/customersConstants';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import DateFilter from 'shared/modules/dateFilter';
import {
  buildStartAndEndDate,
  buildTimeDiffDateFromBaseDate,
  createTimeZoneAgnosticDateFromStr,
} from 'shared/utils/dateUtil';
import PileusLogo from 'shared/img/login_logo_plain.png';
import AwsLogo from 'shared/img/cloud-providers/aws_logo.jpeg';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { b64toBlob } from 'shared/utils/strUtil';
import CustomersHistoryTable from './components/CustomersHistoryTable';
import DownloadDropdown from './components/DownloadDropdown';
import ExplainFlexibilityModal from '../Customers/awsCustomers/components/ExplainFlexibilityModal';
import {
  FLEXIBILITY_TABLE_COLUMNS,
  FLEXIBILITY_TABLE_COLUMNS_WIDTHS,
  FLEXIBILITY_TABLE_COST_COLUMNS,
} from '../../constants/customersConstants';
import useBillingInfo from '../../hooks/react-query/useBillingInfo';

export const BILLING_HISTORY_TABLE_COLUMNS = [
  {
    name: 'customerName',
    title: 'Customer Name',
    getCellValue: (row) => LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', row.customerName),
  },
  {
    name: 'month',
    title: 'Month',
    getCellValue: (row) => {
      const date = new Date(row.year, row.month - 1, 1);
      return date.toLocaleDateString(undefined, { month: 'long' });
    },
  },
  {
    name: 'year',
    title: 'Year',
  },
  { name: 'mtdResellerCost', title: 'Reseller Cost' },
  { name: 'mtdCustomerCost', title: 'Customer Cost' },
  { name: 'totalMarginCost', title: 'Total Margin' },
  { name: 'riFlexibilityMargin', title: 'RI Margin' },
  { name: 'spFlexibilityMargin', title: 'SP Margin' },
  { name: 'publicPriceMargin', title: 'Tiers Margin' },
  { name: 'billingRuleMargin', title: 'Billing Rule Margin' },
  { name: 'creditMargin', title: 'AWS Credits' },
  { name: 'resellerCredit', title: 'Reseller Credit' },
];

const getInitialDate = () => {
  let { startDate, endDate } = buildStartAndEndDate(null, null);
  const month = new Date(startDate).getUTCMonth();
  const year = new Date(startDate).getUTCFullYear();
  const rawStartDate = new Date(year, month - 1, 1);
  const rawEndDate = new Date(year, month, 0);
  startDate = createTimeZoneAgnosticDateFromStr(buildStartAndEndDate(rawStartDate, null).startDate);
  endDate = createTimeZoneAgnosticDateFromStr(buildStartAndEndDate(null, rawEndDate).endDate);
  return { startDate, endDate };
};
const CustomersHistoryPage = () => {
  const { usersStore, appStore } = useRootStore();
  const [downloadRows, setDownloadRows] = useState({});
  const [startDate, setStartDate] = useState(getInitialDate().startDate);
  const [endDate, setEndDate] = useState(getInitialDate().endDate);
  const [explainMeModalConfig, setExplainMeModalConfig] = useState(null);
  const [explainMeModalIsOpen, setExplainMeModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { fetchBillingHistory, fetchResellerCustomerInvoiceData } = useBillingInfo();
  const { data, isLoading } = fetchBillingHistory({
    startDate,
    endDate,
    cloudTypeId: usersStore.currDispUserCloudAccountType,
  });
  const { refetch: downloadCustomerInvoiceData, isFetching: downloadLoading } = fetchResellerCustomerInvoiceData({
    rows: downloadRows.rows,
    type: downloadRows.type,
    enabled: false,
  });
  useEffect(() => {
    const fetchData = async () => {
      if (downloadRows.type) {
        const { data } = await downloadCustomerInvoiceData();
        fileSaver.saveAs(b64toBlob(data), 'reseller-customers-invoices.zip');
        appStore.updatePricingMode(false);
        setDownloadRows({});
      }
    };
    fetchData();
  }, [downloadRows.type]);
  const getTableColumnsAndWidths = () => {
    if (usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE) {
      const dataMissingRuleMargin = data?.every((row) => !row.ruleMargin);
      return {
        tableColumns: AZURE_BILLING_HISTORY_TABLE_COLUMNS.filter((a) => {
          return a.name !== 'ruleMargin' || !dataMissingRuleMargin;
        }),
        columnWidths: AZURE_BILLING_HISTORY_TABLE_COLUMNS_WIDTHS,
      };
    }
    return {
      tableColumns: BILLING_HISTORY_TABLE_COLUMNS,
      columnWidths: FLEXIBILITY_TABLE_COLUMNS_WIDTHS(explainMeModalConfig?.type),
    };
  };

  const datePickerSyntexDates = (start, end) => {
    let { startDate, endDate } = buildStartAndEndDate(start || '', end || '');
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  const handleDateChange = async ({ startDate, endDate }) => {
    const modStartDate = buildStartAndEndDate(startDate, endDate).startDate;
    const modEndDate = buildStartAndEndDate(startDate, endDate).endDate;

    setStartDate(createTimeZoneAgnosticDateFromStr(modStartDate));
    setEndDate(createTimeZoneAgnosticDateFromStr(modEndDate));
  };
  const toggleExplainMeModal = () => {
    setExplainMeModalIsOpen((prevExplainMeModalIsOpen) => !prevExplainMeModalIsOpen);
  };
  const handleExplainCustomerFlexibilityMargin = async ({
    divisionId,
    divisionName,
    accountKey,
    flexibilityType,
    month,
    year,
    e,
  }) => {
    e.preventDefault();
    const rowStartDate = new Date(year, month - 1, 1); // date of first day for row's month
    const rowEndtDate = new Date(year, month, 0); // date of last day for row's month
    const { startDate, endDate } = buildStartAndEndDate(rowStartDate, rowEndtDate); // format dates for API call
    setExplainMeModalConfig({
      divisionId,
      divisionName,
      accountKey,
      type: flexibilityType,
      startDate,
      endDate,
    });
    setExplainMeModalIsOpen(true);
  };
  const prepareCsvModifiedRows = (data) => {
    let csvModifiedRows = JSON.parse(JSON.stringify(data));
    csvModifiedRows = csvModifiedRows.map((row) => {
      const currRow = row;

      for (const [key, value] of Object.entries(currRow)) {
        if (['accountKey', 'divisionId', 'divisionTypeId'].includes(key)) {
          delete currRow[key];
        }
        if (key === 'customerName') {
          currRow[key] = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', value) || value;
        }
      }
      return currRow;
    });
    return csvModifiedRows;
  };

  const handleDownloadFile = (type) => async () => {
    const rows = selectedRows.map((r) => {
      // check if works
      const { divisionId, customerName, accountKey, month, year } = data[r];
      const customerDisplayName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', customerName);
      return {
        divisionId,
        customerName,
        customerDisplayName,
        accountKey,
        month,
        year,
      };
    });
    setDownloadRows({ rows, type });
  };

  if (isLoading) {
    return <Spinner />;
  }
  const lastProcessTime = DateFilter.getDate();
  const maxDate = datePickerSyntexDates(
    null,
    buildTimeDiffDateFromBaseDate(lastProcessTime, -30, 'd').startDate,
  ).endDate;

  const { tableColumns } = getTableColumnsAndWidths();

  return (
    <Container>
      <PageHeader title={PageNames.CUSTOMERS_HISTORY} />
      <Card>
        <CardBody>
          <DatePickerFilter
            isDateRangeError={false}
            onDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            currPeriodGranLevel="month"
            maxDate={maxDate}
          />
          {data && data.length ? (
            <div style={{ marginTop: '10px' }}>
              <Row style={{ width: '100%' }}>
                {selectedRows.length ? (
                  <Col style={{ display: 'flex', justifyContent: 'flex-start', minWidth: '300px' }}>
                    <DownloadDropdown
                      downloadCsv={handleDownloadFile('csv')}
                      isLoadingCsv={downloadLoading}
                      isLoadingPdf={downloadLoading}
                      downloadPdf={handleDownloadFile('pdf')}
                      filesNumber={(selectedRows || []).length}
                    />
                  </Col>
                ) : (
                  <div style={{ height: '45px' }} />
                )}
                <Col style={{ display: 'flex', justifyContent: 'flex-end', minWidth: '300px' }}>
                  <CSVLink
                    data={prepareCsvModifiedRows(data)}
                    headers={BILLING_HISTORY_TABLE_COLUMNS.name}
                    filename="billing-history.csv"
                    className="biling-history-csv-btn"
                  >
                    Export as CSV
                    <span
                      className="lnr lnr-download"
                      style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }}
                    />
                  </CSVLink>
                </Col>
              </Row>
              <CustomersHistoryTable
                tableData={data}
                handleExplainCustomerFlexibilityMargin={handleExplainCustomerFlexibilityMargin}
                pdfRightLogo={AwsLogo}
                pdfLeftLogo={PileusLogo}
                selectedRowsUpdate={setSelectedRows}
                tableColumns={tableColumns}
              />
              {explainMeModalIsOpen && (
                <ExplainFlexibilityModal
                  toggle={toggleExplainMeModal}
                  tableColumns={FLEXIBILITY_TABLE_COLUMNS(explainMeModalConfig.type)}
                  columnWidths={FLEXIBILITY_TABLE_COLUMNS_WIDTHS(explainMeModalConfig.type)}
                  costColumnsArray={FLEXIBILITY_TABLE_COST_COLUMNS}
                  {...explainMeModalConfig}
                />
              )}
            </div>
          ) : null}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CustomersHistoryPage;
