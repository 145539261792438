import React, { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Routes } from 'shared/constants/routes';
import PropTypes from 'prop-types';
import * as usersApi from 'users/api/usersApi';
import LoginPileusLogo from 'shared/components/LoginPileusLogo';
import RequestCodeForm from 'users/containers/LogIn/components/RequestCodeForm';
import ConfirmationForm from 'users/containers/LogIn/components/ConfirmationForm';
import SuccessMessage from 'users/containers/LogIn/components/SuccessMessage';
import LoginPhases from 'users/containers/LogIn/loginPhases';
import { isPasswordValid } from 'users/utils/userUtil';
import { waitForToast } from 'users/containers/LogIn/utils/loginUtils';
import { ReactComponent as Ship } from 'users/containers/LogIn/assets/reset-bg.svg';
import Button from 'shared/components/andtComponents/Button';
import styles from './Login.module.scss';

const ResetPassword = ({ setUsername }) => {
  const [logPhase, setLogPhase] = useState(LoginPhases.LOG_PHASE_REQUEST_CODE);
  const [isValidating, setIsValidating] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [codeConfirmed, setCodeConfirmed] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState([]);
  const navigate = useNavigate();

  const { force } = useParams();

  // user clicked send confirmation code
  const handleSendCodeClick = async (username) => {
    try {
      const ssoClientId = await usersApi.getSsoClientId(username);
      if (ssoClientId && force !== 'force') {
        navigate({
          pathname: `${Routes.LOG_IN}`,
          state: {
            username,
          },
        });
        return;
      }
      const loweredCaseUsername = username.toLowerCase();
      await usersApi.forgotPassword(loweredCaseUsername);
      setUsername(loweredCaseUsername);
      setCodeSent(true);
      setLogPhase(LoginPhases.LOG_PHASE_NEW_PASSWORD);
    } catch {
      await waitForToast('Failed to send confirmation code');
    }
  };
  // user upddated password and clicked update password
  const handleConfirmNewPasswordClick = async (username, code, password) => {
    try {
      const loweredCaseUsername = username.toLowerCase();
      await usersApi.forgotPasswordSubmit(loweredCaseUsername, code, password);
      setUsername(loweredCaseUsername);
      setCodeConfirmed(true);
      setLogPhase(LoginPhases.LOG_PHASE_BACK_LOGIN);
    } catch {
      await waitForToast('Failed to update password. Please make sure the confirmation code is correct.');
    }
  };
  const requestCodeMapObj = {
    text: LoginPhases.TEXT_CODE,
    loadingText: LoginPhases.LOADING_TEXT_CODE,
    formValidation: () => email.length > 0,
    submitHandler: () => handleSendCodeClick(email),
  };
  const passwordEntryMapObj = {
    text: LoginPhases.TEXT_NEW_PASS,
    loadingText: LoginPhases.LOADING_TEXT_NEW_PASS,
    formValidation: () => code.length > 0 && password.length > 0 && password === confirmPassword,
    submitHandler: () => handleConfirmNewPasswordClick(email, code, password),
  };

  const backToLogInMapObj = {
    text: LoginPhases.TEXT_BACK_LOGIN,
    formValidation: () => true,
    submitHandler: () => navigate(`${Routes.LOG_IN}`),
  };

  const logStatusMap = new Map([
    [LoginPhases.LOG_PHASE_REQUEST_CODE, requestCodeMapObj],
    [LoginPhases.LOG_PHASE_NEW_PASSWORD, passwordEntryMapObj],
    [LoginPhases.LOG_PHASE_BACK_LOGIN, backToLogInMapObj],
  ]);

  // submit is state dependent
  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setIsValidating(true);
    try {
      const result = false;
      await logStatusMap.get(logPhase).submitHandler();
      setIsValidating(result);
    } catch {
      setIsValidating(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const { resultStrings: currentValidation } = isPasswordValid(e.target.value);
    if (e.target.value !== confirmPassword) {
      currentValidation.push('Passwords do not match');
    }
    setPasswordValidation(currentValidation);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    const { resultStrings: currentValidation } = isPasswordValid(password);
    if (e.target.value !== password) {
      currentValidation.push('Passwords do not match');
    }
    setPasswordValidation(currentValidation);
  };

  const requestCodeForm = (
    <RequestCodeForm handleEmailChange={(e) => setEmail(e.target.value)} email={email} readOnly={false} />
  );

  const confirmationFrom = (
    <ConfirmationForm
      email={email}
      code={code}
      handleCodeChange={(e) => setCode(e.target.value)}
      password={password}
      handlePasswordChange={handlePasswordChange}
      confirmPassword={confirmPassword}
      handleConfirmPasswordChange={handleConfirmPasswordChange}
      passwordValidation={passwordValidation}
    />
  );
  const successMessage = <SuccessMessage />;

  const renderTitle = () => {
    let title = 'Verify your email';
    if (logPhase === LoginPhases.LOG_PHASE_NEW_PASSWORD) {
      title = 'Enter code & create password';
    } else if (logPhase === LoginPhases.LOG_PHASE_BACK_LOGIN) {
      title = 'Registration completed';
    }
    return title;
  };
  const selectForm = () => {
    let form = requestCodeForm;
    if (logPhase === LoginPhases.LOG_PHASE_REQUEST_CODE && !codeSent) {
      form = requestCodeForm;
    } else if (logPhase === LoginPhases.LOG_PHASE_NEW_PASSWORD && !codeConfirmed) {
      form = confirmationFrom;
    } else if (logPhase === LoginPhases.LOG_PHASE_BACK_LOGIN) {
      form = successMessage;
    }

    return form;
  };

  return (
    <div className={`${styles.loginContainer} ${styles.reset}`}>
      <div className={styles.header}>
        <LoginPileusLogo align="left" />
        <div className={`${styles.loginForm} ${styles.card}`}>
          <div className={styles.title}>{renderTitle()}</div>
          {selectForm()}
          <div className={styles.loaderButton}>
            <Button
              onClick={handleSubmit}
              disabled={!logStatusMap.get(logPhase).formValidation()}
              isLoading={isValidating}
              text={logStatusMap.get(logPhase).text}
              loadingText={logStatusMap.get(logPhase).loadingText}
              overrideStyles={{ height: '50px' }}
            />
          </div>
          {logPhase === LoginPhases.LOG_PHASE_REQUEST_CODE ? (
            <div className={styles.loginFormFooter}>
              <p>
                Already have an account? <Link to={Routes.LOG_IN}>Login</Link>
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.bgFooter}>
        <Ship className={styles.bgShip} />
      </div>
    </div>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  setUsername: PropTypes.func.isRequired,
};
