import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardBody, Container } from 'reactstrap';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { PageNames } from 'shared/constants/appConstants';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import PageHeader from 'shared/components/PageHeader';
import Spinner from 'shared/components/andtComponents/Spinner';
import Button from 'shared/components/andtComponents/Button';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { propComparator } from 'shared/utils/sortUtil';
import { useDivisions } from 'shared/hooks/react-query/useDivisions';
import { ReactComponent as EmptyComponent } from 'shared/img/icons/empty-component.svg';
import { AwsCommonFields, serviceNameToDisplayNameWithShortName } from 'shared/constants/awsConstants';
import Input from 'shared/components/andtComponents/Input';
import ICONS from 'shared/constants/assetsConstants';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getCreditAlertByAlertId } from './creditHelpers';
import useCredits from './hooks/UseCredits';
import useCreditsAlerts from './hooks/UseCreditsAlerts';
import CreditModal from './components/CreditModal';
import AlertModal from './components/AlertModal';
import CreditsTable from './components/CreditsTable';
import { creditsColumns } from './creditConstants';
import styles from './credits.module.scss';

const getDivisions = (divisionsData) => {
  const divisions = (divisionsData.divisionGroups || [])
    .map((div) => div.divisionName)
    .map((item) => ({
      value: item,
      label: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item),
    }));
  if (divisions.length) {
    divisions.sort(propComparator('label'));
  }
  return divisions;
};

const getServicesOptions = (services, cloudTypeId) => {
  const values = [...services].filter(Boolean);
  if (cloudTypeId === CLOUD_TYPE_IDS.AWS) {
    values.push(...['AWS Support [Business]', 'AWS Support [Developer]', 'AWS Premium Support']);
  }
  const options = values.map((value) => ({
    label: serviceNameToDisplayNameWithShortName.has(value) ? serviceNameToDisplayNameWithShortName.get(value) : value,
    value,
  }));
  if (options.length) {
    options.sort(propComparator('label'));
  }
  options.unshift({ value: 'all', label: 'All Services' });
  return options;
};

const getFilteredData = (data, input, fieldsForFilter = null) => {
  if (!input) {
    return cloneDeep(data);
  }
  const filteredData = cloneDeep(data).filter((datum) =>
    Object.entries(datum).some(([key, value]) => {
      if ((!fieldsForFilter || fieldsForFilter.includes(key)) && value) {
        const valueAsString = ((val) => {
          if (Array.isArray(val)) {
            return val.join(',');
          }
          if (typeof val === 'object') {
            return JSON.stringify(val);
          }
          return `${val}`;
        })(value);
        return valueAsString.toLowerCase().includes(input.toLowerCase());
      }
      return false;
    }),
  );
  return filteredData;
};

const Credits = ({ filtersValuesMap }) => {
  const { usersStore } = useRootStore();
  const navigate = useNavigate();
  const location = useLocation();
  const onCloseModal = () => {
    params.delete('creditModalOpen');
    params.delete('modalType');
    params.delete('creditId');

    navigate({ search: params.toString() });
  };
  const [searchInput, setSerachInput] = useState('');
  const [isCreditModalOpen, setIsCreditModalOpen] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [creditToDelete, setDeleteCredit] = useState(null);
  const [alertToDelete, setDeleteAlert] = useState(null);
  const {
    data: credits,
    isLoading: isCreditsLoading,
    refetch: refetchCredits,
    isRefetching: isRefetchingCredits,
  } = useCredits('get');
  const {
    data: alertsData,
    isLoading: AlertsLoading,
    refetch: refetchAlerts,
    isRefetching: alertsRefetching,
  } = useCreditsAlerts('get');
  const { getDivisions: getDivisionsData } = useDivisions();
  const { data: divisionsData, isLoading: isDivisionsLoading } = getDivisionsData();
  const { mutate: addCredit, isLoading: isAddingCredit } = useCredits('post', {}, () => {
    onCloseModal();
    refetchCredits();
  });
  const { mutate: deleteCredit, isLoading: isDeleteCredit } = useCredits('delete', {}, refetchCredits);
  const { mutate: addAlert, isLoading: isAddingAlert } = useCreditsAlerts('post', () => {
    onCloseModal();
    refetchAlerts();
  });
  const { mutate: deleteAlert, isLoading: isDeletingAlert } = useCreditsAlerts('delete', refetchAlerts);
  const services = getServicesOptions(
    filtersValuesMap.get(AwsCommonFields.SERVICE) || [],
    usersStore.currDispUserCloudAccountType,
  );
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    const modalType = params.get('modalType');
    if (params.get('creditModalOpen')) {
      if (!modalType || modalType.toLowerCase() === 'add') {
        setIsCreditModalOpen(true);
      } else if (['clone', 'update'].includes(modalType.toLowerCase())) {
        // add checking if credit id found
        setIsCreditModalOpen(true);
      } else if (modalType.toLowerCase() === 'alert') {
        setIsAlertModalOpen(true);
      } else {
        onCloseModal();
      }
    }
  }, [location.search]);

  const getModalType = () => {
    const type = params.get('modalType');
    switch ((type || '').toLowerCase()) {
      case 'edit':
        return 'Edit';
      case 'clone':
        return 'Clone';
      default:
        return 'New';
    }
  };

  const onAddCredit = () => {
    params.set('creditModalOpen', true);
    params.set('modalType', 'add');
    navigate({ search: params.toString() });
  };
  const onCloneCredit = (creditId) => {
    params.set('creditModalOpen', true);
    params.set('modalType', 'clone');
    params.set('creditId', creditId);
    navigate({ search: params.toString() });
  };

  const onDeleteCredit = async (action) => {
    if (action === 'cancel') {
      setDeleteCredit(null);
    } else if (action === 'delete') {
      try {
        const creditAlert = alertsData.find(({ creditId }) => creditId === creditToDelete);
        await deleteCredit(creditToDelete);
        if (creditAlert) {
          await deleteAlert(creditAlert.uuid);
        }
        setDeleteCredit(null);
      } catch {
        setDeleteCredit(null);
      }
    }
  };
  const onDeleteAlert = async (action) => {
    if (action === 'cancel') {
      setDeleteAlert(null);
    } else if (action === 'delete') {
      try {
        setDeleteAlert(null);
        await deleteAlert(alertToDelete);
      } catch {
        setDeleteAlert(null);
      }
    }
  };

  const onAlertClick = (creditId) => {
    params.set('creditModalOpen', true);
    params.set('modalType', 'alert');
    params.set('creditId', creditId);
    navigate({ search: params.toString() });
  };

  const filteredData = getFilteredData(credits, searchInput, [
    'divisionName',
    'includeServices',
    'excludeServices',
    'name',
    'amount',
    'amountUsed',
    'startDate',
    'expirationDate',
  ]);

  const prepareCreditParams = (credit) => {
    const {
      name,
      customers,
      service,
      isExcludeAwsMarketplace,
      amount,
      monthlyAmountLimit,
      startDate,
      expirationDate,
      description,
    } = credit;
    return {
      name,
      description,
      divNames: customers.map(({ value }) => value),
      service: service.values.map(({ value }) => value),
      isExcludeAwsMarketplace,
      amount: +amount,
      monthlyAmountLimit: +monthlyAmountLimit,
      startDate: moment(startDate).startOf('month').format('YYYY-MM-DD'),
      expirationDate: expirationDate ? moment(expirationDate).endOf('month').format('YYYY-MM-DD') : '',
      isExcludeService: service.relation === 'exclude',
    };
  };

  const handleSaveCredit = async (credit) => {
    const creditParams = prepareCreditParams(credit);
    await addCredit(creditParams);
  };

  const handleSaveAlert = (alert) => {
    const creditId = params.get('creditId');
    const currAlert = alertsData.find((alertData) => alertData.creditId === creditId);

    const preparedAlert = {
      uuid: currAlert && currAlert.uuid,
      ...alert,
      recipientsEmails: alert.recipientsEmails
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email)
        .join(','),
      creditId,
      alertType: 'credit',
    };
    addAlert(preparedAlert);
  };

   
  const getCreditAmount = (credits, creditId) => {
    const selectedCredit = credits.find((credit) => credit.id === creditId);
    if (!selectedCredit) {
      onCloseModal();
      return null;
    }
    return selectedCredit.amount;
  };

   
  const getCreditExpirationDate = (credits, creditId) => {
    const selectedCredit = credits.find((credit) => credit.id === creditId);
    if (!selectedCredit) {
      onCloseModal();
      return null;
    }
    return selectedCredit.expirationDate;
  };

  const getSelectedAlert = (alerts, creditId) => {
    const selectedAlert = alerts.find((alert) => alert.creditId === creditId);
    return selectedAlert;
  };

  const prepareCsvData = async () => {
    const columnsForCsv = creditsColumns.filter(({ name }) => !['alert', 'userActions'].includes(name));
    const preparedData = credits.map((credit) => {
      const formattedValuesRow = {};
      columnsForCsv.forEach(({ name, getCellValue }) => {
        const formattedValue = getCellValue ? getCellValue(credit) : credit[name];
        formattedValuesRow[name] = formattedValue;
      });
      return formattedValuesRow;
    });
    return [
      {
        data: preparedData,
        filename: 'credits.csv',
        headers: columnsForCsv.map(({ name, title }) => ({ label: title, key: name })),
      },
    ];
  };

  if (isCreditsLoading || isDivisionsLoading || isDeleteCredit || isRefetchingCredits || isAddingAlert) {
    return <Spinner />;
  }
  const divisions = getDivisions(divisionsData);

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center">
        <div className={`${styles.pageTitle}`}>
          <PageHeader showDate title={`${PageNames.AWS_CREDITS} (${filteredData.length} / ${credits.length})`} />
        </div>
        <div className="d-flex">
          <div className={styles.searchInputContainer}>
            <Input
              name="search"
              placeholder="Search"
              onInput={(e) => setSerachInput(e.target.value)}
              value={searchInput}
              onChange={(e) => setSerachInput(e.target.value)}
              overrideStyles={{
                marginInlineEnd: '40px',
                width: 'auto',
                height: '36px',
                fontFamily: 'Roboto',
                fontSize: '14px',
              }}
              automationId="search"
            />
          </div>
          <Button
            type="button"
            text="+ Add Credit"
            onClick={onAddCredit}
            overrideStyles={{
              marginInlineEnd: '10px',
              height: '36px',
              width: '107px',
              fontFamily: 'Roboto',
              fontSize: '14px',
            }}
            automationId="addCreditButton"
          />
          <CustomCSVDownload
            fetchData={prepareCsvData}
            filesNumber={1}
            isLoading={false}
            showDownloadIcon={false}
            hideText
            classNames={`btn-no-style ${styles.downloadCsvOption}`}
          >
            <Button
              type="button"
              // text=""
              icon={ICONS.exportIcon}
              iconSize={14}
              onClick={() => {}}
              overrideStyles={{
                marginInlineEnd: '10px',
                height: '36px',
                background: '#E9E9F0',
              }}
            />
          </CustomCSVDownload>
          <Button
            type="button"
            icon={ICONS.collapse}
            iconSize={14}
            onClick={() => setExpandedRows([])}
            overrideStyles={{
              marginInlineEnd: '10px',
              height: '36px',
              background: '#E9E9F0',
            }}
          />
          <Button
            type="button"
            icon={ICONS.userAccount}
            iconSize={14}
            onClick={() => {}}
            overrideStyles={{
              height: '36px',
              background: '#E9E9F0',
              visibility: 'hidden',
            }}
            disabled
          />
        </div>
      </div>{' '}
      <Card>
        <CardBody>
          <div className={styles.container}>
            {filteredData.length ? (
              <CreditsTable
                rows={filteredData}
                onAlertClick={onAlertClick}
                onCloneCredit={onCloneCredit}
                onDeleteCredit={setDeleteCredit}
                onDeleteAlert={setDeleteAlert}
                alerts={alertsData}
                isAddingAlert={isAddingAlert}
                creditId={params.get('creditId') || ''}
                expandedRows={expandedRows}
                setExpandedRows={setExpandedRows}
                isDeletingAlert={isDeletingAlert}
                isAlertsLoading={AlertsLoading || alertsRefetching}
              />
            ) : (
              <div className={styles.emptyBlock}>
                <EmptyComponent />
                <p className={`${styles.noCreditText}`}>No Credits</p>
                {!credits.length ? (
                  <div>
                    <p>To get started create a new Credit.</p>
                    <Button
                      type="button"
                      text="+ Add Credit"
                      onClick={onAddCredit}
                      overrideStyles={{
                        marginInlineEnd: '10px',
                        height: '36px',
                        width: '107px',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                      }}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </CardBody>
      </Card>
      {isCreditModalOpen ? (
        <CreditModal
          open={isCreditModalOpen}
          onClose={onCloseModal}
          modalType={getModalType()}
          services={services}
          divisions={divisions}
          handleSaveCredit={handleSaveCredit}
          selectedCredit={credits.find((credit) => params.get('creditId') && params.get('creditId') === credit.id)}
          creditNames={credits.map(({ name }) => name)}
          isLoading={isAddingCredit}
        />
      ) : null}
      {isAlertModalOpen ? (
        <AlertModal
          open={isAlertModalOpen}
          onClose={onCloseModal}
          modalType={getModalType()}
          handleSaveAlert={handleSaveAlert}
          selectedAlert={getSelectedAlert(alertsData, params.get('creditId'))}
          creditAmount={getCreditAmount(credits, params.get('creditId'))}
          creditExpirationDate={getCreditExpirationDate(credits, params.get('creditId'))}
          getMyEmail={() => usersStore.currUserName}
        />
      ) : null}
      <DeleteWarningModal
        deletedItemName={(credits.find((credit) => credit.id === creditToDelete) || {}).name || ''}
        isOpen={creditToDelete}
        handleDelete={onDeleteCredit}
        warningMessage="Be advise you are about to delete Credit for: "
        modalTitle="Delete Credit"
      />
      <DeleteWarningModal
        isOpen={alertToDelete}
        handleDelete={onDeleteAlert}
        warningMessage={`Be advise you are about to delete Alert of ${
          (getCreditAlertByAlertId(alertToDelete, credits, alertsData) || {}).name || ''
        } Credit`}
        modalTitle="Delete Alert"
      />
    </Container>
  );
};

Credits.propTypes = {
  filtersValuesMap: PropTypes.object.isRequired,
};
export default withInvoiceFiltersContextConsumer(Credits);
