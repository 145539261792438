import { BYOD_ONBOARDING_FIELDS, BYOD_ONBOARDING_STEPS } from '../ByodOnboardingConstants';

async function byodDetailsValidation(currentScreen) {
  const errors = {};
  const accountName = currentScreen[BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME];
  if (!accountName) {
    errors[BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME] = 'Account name is required';
  }
  if (Object.values(errors).length) {
    return errors;
  }
  return errors;
}

export default async function useFormValidation(currentScreen) {
  switch (currentScreen.screen) {
    case BYOD_ONBOARDING_STEPS.BYOD_DETAILS:
      return {
        errors: await byodDetailsValidation(currentScreen),
        currentScreen,
      };
    default:
      return {
        errors: {},
        currentScreen,
      };
  }
}
