import { ExternalLinks } from "shared/enums/external-links.enum";

const apiReqHeaders = {
  createHeaders: () => { },
  setCreateHeadersFunc: (func) => {
    apiReqHeaders.createHeaders = func;
  },
};

const local = {
  env: 'localDev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: ExternalLinks.LocalHost,
    LEGACY_URL: ExternalLinks.LocalLegacy
  },
  COGNITO_POOL_URL: ExternalLinks.ProdCognitoPool,
  COGNITO_REDIRECT_URI: ExternalLinks.LocalServerURI,
};

const dev = {
  env: 'dev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: ExternalLinks.DevAPI,
  },
  KEYCLOAK_URL: ExternalLinks.DevKeyclock,
  COGNITO_POOL_URL: ExternalLinks.DevCognitoPool,
  COGNITO_REDIRECT_URI: ExternalLinks.DevRedirectURL,
};

const feature = {
  env: 'feature',
  apiGateway: {
    REGION: 'us-east-1',
    URL: ExternalLinks.FeatureFrontendAppServer,
  },
  KEYCLOAK_URL: ExternalLinks.FeatureKeyclock,
  COGNITO_POOL_URL: ExternalLinks.DevCognitoPool,
  COGNITO_REDIRECT_URI: ExternalLinks.DevAPI,
};

const staging = {
  env: 'staging',
  apiGateway: {
    REGION: 'us-east-1',
    URL: ExternalLinks.StagingAPI,
  },
  COGNITO_POOL_URL: ExternalLinks.DevCognitoPool,
  COGNITO_REDIRECT_URI: ExternalLinks.StagingRedirectURL,
  KEYCLOAK_URL: ExternalLinks.DevKeyclock,
};

const prod = {
  env: 'prod',
  apiGateway: {
    REGION: 'us-east-1',
    URL: ExternalLinks.ProdAPI,
    LEGACY_URL: ExternalLinks.ProdLagacyAPI,
  },
  COGNITO_POOL_URL: ExternalLinks.ProdCognitoPool,
  COGNITO_REDIRECT_URI: ExternalLinks.ProdRedirectURL,
  KEYCLOAK_URL: ExternalLinks.DevKeyclock,
};

let config;
switch (import.meta.env.VITE_APP_STAGE) {
  case 'local': {
    config = { ...local, KEYCLOAK_URL: ExternalLinks.DevKeyclock };
    break;
  }
  case 'dev': {
    config = dev;
    break;
  }
  case 'feature': {
    config = feature;
    break;
  }
  case 'staging': {
    config = staging;
    break;
  }
  case 'prod': {
    config = prod;
    break;
  }
  default:
    config = dev;
}

export default {
  apiReqHeaders,
  mock: local,
  ...config,
};
