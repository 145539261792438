import React, { Component, createRef } from 'react';
import { Container, Row } from 'reactstrap';
import If from 'shared/components/SimpleIf';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import CustomTooltip from 'shared/components/CustomTooltip';
import {
  Area,
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Line,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getGoalById } from 'users/utils/cueGoalsUtil';
import { withCUEGoalsContextConsumer } from 'users/contexts/CUEGoalsContext';
import { StringToColor } from 'shared/utils/graphicUtil';
import {
  calcChartYAxisTopPadding,
  calcTotalChartCost,
  makeChartDataCumulative,
  prepareDataKeys,
  prepareSecondaryGroupDataKeys,
  updateDataIfOthersIsCheckedV2,
} from 'shared/utils/dataPrepareUtil';
import { CostTrackingConstants, ItemsOptionsValues } from 'usage/constants/costAndUsageConstants';
import { AWS_QUANTITY_TYPE_SELECT } from 'shared/constants/awsConstants';
import { getDisplayMetricTypeByUsageType } from '../chartUtil';
import GoalIcon from 'shared/img/icons/Goal';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { withCUEEventsChartBlockProvider } from 'users/contexts/CUEEventsChartBlockProvider';
import CustomizedDateXAxisTick from '../../CustomizedDateXAxisTick';
import LegendsPanel from '../../chartComponents/LegendsPanel';
import renderCustomizedPieLabel from '~/shared/components/chartComponents/CustomizedPieLabel/index.jsx';

class UncontrolledCostChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currKey: '',
      dataKeys: this.prepareDatakeys(),
      uncheckedKeys: null,
      isShowOthers: props.isShowOthers || false,
    };
    this.chartRef = createRef();
  }

  getDisplayMetricTypeByUsageType = (selectedUsageType) => {
    const { displayedMetric } = this.props;
    return getDisplayMetricTypeByUsageType({ displayedMetric, selectedUsageType });
  };

  prepareDataForPie = (data) =>
    Object.values(
      data.reduce((acc, currData) => {
        Object.entries(currData).forEach(([key, value]) => {
          if (!['usageDate', 'totalSum', 'groupBySecondary'].includes(key)) {
            if (!acc[key]) {
              acc[key] = {
                value: 0,
                name: key,
              };
            }
            acc[key].value += value;
          }
        });
        return acc;
      }, {}),
    );

  prepareTotalSumWithOthersAndZoomForTrendLine = (data, key = 'usageDate') => {
    const { uncheckedKeys, isShowOthers } = this.state;
    let maxValue = 0;
    let minValue = 0;
    const dataWithOthers = updateDataIfOthersIsCheckedV2(data, uncheckedKeys, isShowOthers);
    const dataWithTotalCost = dataWithOthers.map((dataObj) => {
      const newDataObj = {};
      Object.keys(dataObj).forEach((e) => {
        if (!uncheckedKeys.includes(e) || e === CostTrackingConstants.OTHERS) {
          newDataObj[e] = dataObj[e];
        }
      });
      const dateTotalSum = Object.values(newDataObj).reduce((accumulator, currValue) => {
        if (typeof currValue !== 'number') {
          return accumulator;
        }
        return accumulator + parseFloat(currValue);
      }, 0);
      newDataObj.totalSum = dateTotalSum;
      newDataObj[key] = dataObj[key];
      maxValue = Math.max(maxValue, dateTotalSum);
      minValue = Math.min(minValue, dateTotalSum);
      return newDataObj;
    });
    if (this.props.isCumulative) {
      makeChartDataCumulative(dataWithTotalCost, '');
    }
    return { dataWithTotalCost, maxValue, minValue };
  };

  prepareDatakeys() {
    const { data, dataKey = 'usageDate' } = this.props;
    return dataKey === 'usageDate'
      ? prepareDataKeys(data, 'name', 'cost')
      : prepareSecondaryGroupDataKeys(data, 'name', 'cost');
  }

  renderChartByType(ChartComponent) {
    const { displayedMetric } = this.props;
    const { dataKeys, uncheckedKeys } = this.state;
    return [...dataKeys, { name: CostTrackingConstants.OTHERS }]
      .filter((key) => !uncheckedKeys.includes(key.name))
      .map((dataKey) => {
        const customMouseOver = () => {
          const { currKey } = this.state;
          if (dataKey.name !== currKey) {
            this.setState({ currKey: dataKey.name });
          }
        };
        const chartColor =
          dataKey.name === CostTrackingConstants.OTHERS
            ? CostTrackingConstants.OTHERS_NAME_COLOR
            : StringToColor.next(dataKey.name);
        return (
          <ChartComponent
            key={dataKey.name}
            dataKey={dataKey.name}
            stackId="a"
            stroke={chartColor}
            fill={chartColor}
            onMouseOver={customMouseOver}
            type="monotone"
            maxBarSize={150}
            strokeWidth={displayedMetric === 'Cost' ? 1.5 : 3}
          />
        );
      });
  }

  renderChart = () => {
    const { isAreaChart, isLineChart } = this.props;
    switch (true) {
      case isAreaChart:
        return this.renderChartByType(Area);
      case isLineChart:
        return this.renderChartByType(Line);
      default:
        return this.renderChartByType(Bar);
    }
  };

  renderGoalsLine = (goalLine) => {
    const { granLevel, displayedMetric, numStrAbriviaionByDisplayMetric, overrideCurrency } = this.props;
    if (!goalLine) {
      return null;
    }
    return (
      <ReferenceLine
        y={goalLine.target}
        isFront
        strokeWidth={1}
        stroke="#f00"
        strokeDasharray="6 6"
        label={({ viewBox }) => (
          <GoalIcon
            x={viewBox.x - 16}
            y={viewBox.y - 8}
            className="goal-icon"
            granularity={granLevel}
            target={numStrAbriviaionByDisplayMetric(
              goalLine.target,
              goalLine.target,
              displayedMetric,
              overrideCurrency,
            )}
            name={goalLine.title}
          />
        )}
      />
    );
  };

  renderBody() {
    const {
      data,
      displayedMetric,
      secondaryGroupBy,
      dataKey = 'usageDate',
      chartId,
      xAxisLabelTitle = '',
      isTrendLine,
      granLevel,
      isPieChart,
      SelectedUsageType = AWS_QUANTITY_TYPE_SELECT,
      CUEGoals,
      selectedGoal,
      numStrAbriviaionByDisplayMetric,
      overrideCurrency,
      renderEventsComponents,
      isCumulative,
    } = this.props;
    const finalChartId = chartId || `cost-chart-${random5DigitNumber()}`;
    const { dataWithTotalCost, maxValue, minValue } = this.prepareTotalSumWithOthersAndZoomForTrendLine(data, dataKey);
    let dataForPieChart = [];
    let totalCostForPieChart = 0;
    if (isPieChart) {
      dataForPieChart = this.prepareDataForPie(dataWithTotalCost);
      totalCostForPieChart = dataForPieChart.reduce((acc, currData) => {
        acc += currData.value;
        return acc;
      }, 0);
    }
    const displayMetricTypeByUsageType = this.getDisplayMetricTypeByUsageType(SelectedUsageType);
    const pieChartLabel = numStrAbriviaionByDisplayMetric(
      totalCostForPieChart,
      totalCostForPieChart,
      displayMetricTypeByUsageType,
      overrideCurrency,
    );
    const totalCost = calcTotalChartCost(dataWithTotalCost, isCumulative);

    const goalLine = selectedGoal ? getGoalById(CUEGoals, selectedGoal) : null;
    const { currKey } = this.state;
    return (
      <Row id={finalChartId} className="cost-chart-container">
        <If cond={isPieChart}>
          <p
            style={{
              position: 'absolute',
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '400px',
              width: '100%',
              alignItems: 'center',
              margin: 0,
            }}
          >
            <span style={{ fontSize: '17px' }}>
              {SelectedUsageType !== AWS_QUANTITY_TYPE_SELECT ? `${SelectedUsageType}:` : 'Total Cost:'}
            </span>
            <b style={{ fontSize: '17px' }}>{pieChartLabel}</b>
          </p>
        </If>
        <ResponsiveContainer height={400}>
          {isPieChart ? (
            <PieChart>
              <Pie
                data={dataForPieChart}
                dataKey="value"
                nameKey="name"
                innerRadius="50%"
                outerRadius="85%"
                labelLine={false}
                isAnimationActive={false}
                label={(item) =>
                  renderCustomizedPieLabel(
                    item,
                    (value) => numStrAbriviaionByDisplayMetric(totalCost, value, displayMetricTypeByUsageType, null),
                    (value) =>
                      numStrAbriviaionByDisplayMetric(totalCost, value, displayedMetric, false, {
                        showPercent: true,
                        decimal: 0,
                      }),
                  )
                }
              >
                {dataForPieChart.map((currData) => (
                  <Cell
                    key={currData.name}
                    fill={currData.name === 'Others' ? '#afafaf' : StringToColor.next(currData.name)}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value) => {
                  const valueStr = numStrAbriviaionByDisplayMetric(
                    totalCostForPieChart,
                    value,
                    displayMetricTypeByUsageType,
                    null,
                  );
                  const percentStr = numStrAbriviaionByDisplayMetric(
                    totalCostForPieChart,
                    (value / (totalCostForPieChart || 1)) * 100,
                    displayedMetric,
                    false,
                    { showPercent: true, decimal: 2 },
                  );
                  return `${valueStr} (${percentStr})`;
                }}
              />
            </PieChart>
          ) : (
            <ComposedChart
              stackOffset="sign"
              width={1000}
              height={400}
              data={dataWithTotalCost}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                label={{ value: xAxisLabelTitle || '' }}
                dataKey={dataKey}
                interval={0}
                height={50}
                tick={
                  <CustomizedDateXAxisTick
                    isSecondaryGroupBy={secondaryGroupBy}
                    granLevel={granLevel}
                    detailedInfo
                    ticksNumber={(data || []).length}
                    chartWidth={this.chartRef.current ? this.chartRef.current.offsetWidth : null}
                  />
                }
              />
              <YAxis
                tickLine
                type="number"
                domain={[
                  'dataMin',
                  (dataMax) => {
                    const goalTarget = goalLine ? goalLine.target : -Infinity;
                    return Math.max(dataMax, goalTarget) + calcChartYAxisTopPadding(maxValue);
                  },
                ]}
                padding={{ top: 20 }}
                tickFormatter={(value) =>
                  numStrAbriviaionByDisplayMetric(
                    // calc max shown value for formatting (-10 000 means we need to format like 10K as well as 10 000)
                    Math.max(maxValue, Math.abs(minValue)),
                    value,
                    ['Memory', 'Bytes'].includes(SelectedUsageType) ? '' : displayedMetric,
                    overrideCurrency,
                  )
                }
              />
              <Tooltip
                cursor={false}
                content={
                  <CustomTooltip
                    data={data}
                    currKey={currKey}
                    overrideCurrency={overrideCurrency}
                    displayedMetric={
                      SelectedUsageType && ['memory', 'bytes'].includes(SelectedUsageType.toLowerCase())
                        ? ''
                        : displayedMetric
                    }
                  />
                }
              />
              <ReferenceLine y={0} stroke="#000" />
              {this.renderChart()}
              {this.renderGoalsLine(goalLine)}
              {isTrendLine ? (
                <Line type="linear" dataKey="totalSum" stroke="#ff7300" isAnimationActive={false}>
                  <LabelList
                    dataKey="totalSum"
                    position="top"
                    angle="35"
                    offset={20}
                    formatter={(value) =>
                      numStrAbriviaionByDisplayMetric(
                        // calc max shown value for formatting
                        // (-10 000 means we need to format like 10K as well as 10 000)
                        Math.max(maxValue, Math.abs(minValue)),
                        value,
                        ['Memory', 'Bytes'].includes(SelectedUsageType) ? '' : displayedMetric,
                        overrideCurrency,
                      )
                    }
                  />
                </Line>
              ) : null}
              {renderEventsComponents(maxValue)}
            </ComposedChart>
          )}
        </ResponsiveContainer>
      </Row>
    );
  }

  render() {
    const { numberOfItems = ItemsOptionsValues.ALL, isLineChart, groupBy } = this.props;
    const { dataKeys, isShowOthers, uncheckedKeys } = this.state;
    return (
      <div ref={this.chartRef}>
        <Container>
          {isArray(uncheckedKeys) ? this.renderBody() : null}
          <div>
            <Row>
              <LegendsPanel
                dataKeys={dataKeys}
                uncheckedKeys={uncheckedKeys}
                handleInitialize={() => {}}
                handleShowOthersChange={(val) => this.setState({ isShowOthers: val })}
                isShowOthers={isShowOthers}
                numberOfItems={numberOfItems}
                handleKeysUpdate={(values) => this.setState({ uncheckedKeys: values })}
                isLineChart={isLineChart}
                groupBy={groupBy}
              />
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

UncontrolledCostChart.propTypes = {
  chartId: PropTypes.string,
  data: PropTypes.object.isRequired,
  secondaryGroupBy: PropTypes.string.isRequired,
  dataKey: PropTypes.string,
  isAreaChart: PropTypes.bool,
  isLineChart: PropTypes.bool,
  displayedMetric: PropTypes.string.isRequired,
  isTrendLine: PropTypes.bool,
  isCumulative: PropTypes.bool,
  granLevel: PropTypes.string,
  groupBy: PropTypes.string,
  renderEventsComponents: PropTypes.func.isRequired,
  selectedGoal: PropTypes.string,
  isPieChart: PropTypes.bool,
  isShowOthers: PropTypes.bool,
  xAxisLabelTitle: PropTypes.string,
  numberOfItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  SelectedUsageType: PropTypes.string,
  numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  overrideCurrency: PropTypes.string,
  CUEGoals: PropTypes.array.isRequired,
};

export default withCUEEventsChartBlockProvider(
  withCUEGoalsContextConsumer(withUserSettingsConsumer(UncontrolledCostChart)),
);
