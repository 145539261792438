import { useEffect } from 'react';
import PropTypes from 'prop-types';
import externalLinksFunctions from 'shared/utils/external-links.util';

const LINK_ID = 'whitelabeling-stylesheet';
export const DEFAULT_THEME = 'default-theme';

const CustomThemeApplier = ({ customThemeName = '' }) => {
  useEffect(() => {
    const link = document.getElementById(LINK_ID);
    link.href = externalLinksFunctions.AwsWhitelabelingThemesDynamic({
      customThemeName: customThemeName || DEFAULT_THEME
    });
  }, [customThemeName]);
  return null;
};

CustomThemeApplier.propTypes = {
  customThemeName: PropTypes.string,
};

export default CustomThemeApplier;
