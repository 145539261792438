import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { useRootStore } from '~/app/contexts/RootStoreContext.jsx';

const root = '/api/v1/users/roles';

const fetchRolesApi = () => API.get('billings', root);
const fetchDefaultRoleApi = async () => {
  const response = await API.get('billings', `${root}/default`);
  return response.defaultRoleId;
};
const fetchAllCloudAccountsLinkedAccountsApi = (accountsIdsAndClouds, usersStore) => {
  const accountsWithoutTenant = accountsIdsAndClouds?.filter((acc) => !usersStore.isAccountAllAccounts(acc));
  const accIdsAndClouds = accountsWithoutTenant?.map((acc) => ({
    accountId: acc.accountId,
    cloudTypeId: acc.cloudTypeId,
  }));
  return API.post('billings', `${root}/linked-accounts`, { body: accIdsAndClouds });
};
const createNewRoleApi = (data) => API.post('billings', root, { body: data });
const updateRoleApi = (data) => API.put('billings', root, { body: data });
const updateRolesBatchApi = (data) => API.put('billings', `${root}/batch`, { body: data });
const deleteRoleApi = (uuid) => API.del('billings', `${root}?uuid=${uuid}`);

export default function useRoles() {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.ROLES];
  const { appStore, usersStore } = useRootStore();
  return {
    fetchRoles: (options = {}) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery({
        queryKey: ['fetchRolesIs', apiConstants.QUERY_KEYS.ALL_ROLES],
        queryFn: fetchRolesApi,
        enabled: !appStore.isKeyCloakManagement,
        ...options,
      }),
    fetchDefaultRole: (options = {}) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery({
        queryKey: ['fetchRolesIs', apiConstants.QUERY_KEYS.ALL_ROLES, 'default'],
        queryFn: fetchDefaultRoleApi,
        ...options,
      }),
    fetchAllCloudAccountsLinkedAccounts: (data, options) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery({
        queryKey: [...queryKey, JSON.stringify(data)],
        queryFn: () => fetchAllCloudAccountsLinkedAccountsApi(data, usersStore),
        staleTime: 10 * 1000,
        ...options,
      }),
    createNewRole: () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMutation({
        mutationFn: createNewRoleApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
    updateRole: () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMutation({
        mutationFn: updateRoleApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
    updateRolesBatch: () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMutation({
        mutationFn: updateRolesBatchApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
    deleteRole: () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMutation({
        mutationFn: deleteRoleApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
  };
}
