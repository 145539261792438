import React, { useEffect } from 'react';
import classNames from 'classnames';
import { PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { usePermissions } from 'app/contexts/PermissionsContext.jsx';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import { useAnomalyDetection } from 'usage/containers/AnomalyDetection/hooks/react-query/useAnomalyDetection';
import mainStyles from 'app/containers/MainDashboard/mainDashboardCommon.module.scss';
import { ReactComponent as EmptyAnomalies } from 'app/containers/MainDashboard/img/empty-anomalies.svg';
import { Routes as ROUTES } from 'shared/constants/routes.js';
import MonitoringPanelAction from 'app/containers/MainDashboard/panels/MonitoringData/MonitoringPanelAction.jsx';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import { NoPermission } from 'app/containers/MainDashboard/components/NoPermission.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import styles from './monitoringData.module.scss';

const AnomaliesData = () => {
  const { appStore } = useRootStore();
  const { updatePanelLoadingState } = useMainDashboardContext();
  const { getCurrencyNumber } = useUserSettingsContext();
  const { isHasPermission } = usePermissions();
  const hasPermission = isHasPermission(HierarchicalEntityCategory.BillingData, Action.View);
  const anomaliesHook = useAnomalyDetection(hasPermission);
  const { data: anomaliesData, isLoading } = anomaliesHook.fetchAnomaliesData({ isPpApplied: appStore.isPpApplied });

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.ANOMALIES_STATS, isLoading);
  }, [isLoading]);

  const renderContent = () => {
    if (!hasPermission) {
      return <NoPermission />;
    }
    if (!anomaliesData?.openAnomalies) {
      return (
        <div className={styles.emptyStateWrapper}>
          <EmptyAnomalies />
          <span className={styles.simpleText}>You have no open Anomalies</span>
        </div>
      );
    }
    return (
      <>
        <div className={styles.field}>
          {anomaliesData.openAnomalies} <span className={styles.simpleText}>Open Anomalies</span>
        </div>
        <div className={styles.redField}>
          {getCurrencyNumber(anomaliesData.impact)}
          <span className={styles.simpleText}>Open Anomalies cost impact</span>
        </div>
      </>
    );
  };

  return (
    <div className={classNames(mainStyles.panelWrapper, styles.anomalyWrapper)}>
      {isLoading ? (
        <Spinner size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <div>
          <div className={styles.headerFlex}>
            <div className={styles.header}>Anomalies</div>
            <MonitoringPanelAction url={ROUTES.ANOMALY_DETECTION} />
          </div>
          {renderContent()}
        </div>
      )}
    </div>
  );
};

export default AnomaliesData;
