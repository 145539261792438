import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common';
import { CategoryAction, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { Routes } from 'shared/constants/routes';
import { cloudTypeToSimpleIcon } from 'shared/constants/appConstants';
import CustomModal from 'shared/components/andtComponents/Modal';
import ProviderPopover from './ProviderPopover';
import { useNavigate } from 'react-router-dom';
import styles from './CloudProviderButton.module.scss';
import { useRootStore } from '~/app/contexts/RootStoreContext.jsx';

// Mapping provider types to their titles and links
const PROVIDER_TITLE = {
  [CLOUD_TYPE_IDS.AWS]: 'AWS',
  [CLOUD_TYPE_IDS.AZURE]: 'Azure',
  [CLOUD_TYPE_IDS.GCP]: 'GCP',
  [CLOUD_TYPE_IDS.BYOD]: 'Bring Your Own Data',
};

const PROVIDER_LINK = {
  [CLOUD_TYPE_IDS.AWS]: Routes.ONBOARDING_AWS,
  [CLOUD_TYPE_IDS.AZURE]: Routes.ONBOARDING_AZURE,
  [CLOUD_TYPE_IDS.GCP]: Routes.ONBOARDING_GCP,
  [CLOUD_TYPE_IDS.BYOD]: Routes.ONBOARDING_BYOD,
};

const CloudProviderButton = ({ type }) => {
  const { usersStore } = useRootStore();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const Logo = cloudTypeToSimpleIcon[type];
  const title = PROVIDER_TITLE[type];
  const PopoverBody = ProviderPopover[type];
  const link = PROVIDER_LINK[type];

  const handleOpenModal = () => {
    if (!usersStore.isHasPermission(HierarchicalEntityCategory.Accounts, CategoryAction.Create)) return;
    setOpen(true);
  };

  const handleNavigate = () => {
    if (!usersStore.isHasPermission(HierarchicalEntityCategory.Accounts, CategoryAction.Create)) return;
    navigate(link);
  };

  return (
    <div className={styles.popoverWrapper}>
      {type === CLOUD_TYPE_IDS.AZURE ? (
        <>
          <div className={styles.wrapper}>
            <div className={styles.container} onClick={handleOpenModal}>
              <Logo />
            </div>
            <p className={styles.title} onClick={handleOpenModal}>
              {title} <InfoIcon className={styles.infoIcon} />
            </p>
          </div>
          {open && (
            <CustomModal footerDisabled title="" onClose={() => setOpen(false)} open>
              <PopoverBody onClose={() => setOpen(false)} link={link} />
            </CustomModal>
          )}
        </>
      ) : (
        <Popover open={open} onOpenChange={() => setOpen(!open)}>
          <div className={styles.wrapper}>
            <div className={styles.container} onClick={handleNavigate}>
              <Logo />
            </div>
            <PopoverTrigger>
              <p className={styles.title}>
                {title} <InfoIcon className={styles.infoIcon} />
              </p>
            </PopoverTrigger>
          </div>
          <PopoverContent className={styles.popoverContent}>
            <PopoverBody onClose={() => setOpen(false)} link={link} />
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};

CloudProviderButton.propTypes = {
  type: PropTypes.oneOf(Object.values(CLOUD_TYPE_IDS)).isRequired,
};

export default CloudProviderButton;
