/* eslint-disable no-nested-ternary */



import {
  AwsCommonFields,
  awsCommonFieldToDisplayField,
  mapPurchaseOptionDbNamesToDisplay,
  mapRegionNamesToDescription,
} from 'shared/constants/awsConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LabelCoordinator from '../modules/labelCoordinator';
import { getCustomTagFilterLabel } from './sharedUtils';
import externalLinksFunctions from './external-links.util';

export const getDisplayAwsLabel = (rawAwsName) => {
  let displayLabel = '';
  displayLabel = LabelCoordinator.getServiceNameDisplayValue(rawAwsName);
  return displayLabel;
};

export const buildResourceIdAwsConsoleLink = (region, resourceId) => {
  const resourceLink = externalLinksFunctions.AwsRosourceUrlByIdAndRegion({ region, resourceId });
  return resourceLink;
};

export const buildResourceNameAwsConsoleLink = (region, resourceName) => {
  const resourceLink = externalLinksFunctions.AwsRosourceUrlByNameAndRegion({ region, resourceName });
  return resourceLink;
};

export const getFullAwsServiceName = (serviceName) => LabelCoordinator.getServiceNameDataKeyByValue(serviceName);

export const getAccountTagFilterLabel = (awsField) => `Enrichment Tag - ${awsField.split('accounttags:')[1]}`;

export const getBusinessMappingFilterLabel = (awsField) =>
  `Viewpoint - ${LabelCoordinator.getDataKeyDisplayName(
    'cueDisplayCoordinator',
    awsField.split(`${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: `)[1] || 'Default',
  )}`;

export const getFieldDisplayLabel = (awsField, secondLevelItemsValuesMap) => {
  let label = '';
  if (awsField) {
    label = awsField.includes('customtags')
      ? getCustomTagFilterLabel(awsField, CLOUD_TYPE_IDS.AWS)
      : awsCommonFieldToDisplayField.has(awsField.toLowerCase())
        ? awsCommonFieldToDisplayField.get(awsField)
        : awsField;
    if (awsField.includes('accounttags')) {
      label = getAccountTagFilterLabel(awsField);
    }
    if (awsField.includes(AwsCommonFields.BUSINESS_MAPPING)) {
      let field = awsField;
      if (secondLevelItemsValuesMap) {
        field = ([...secondLevelItemsValuesMap.entries()].find(([, v]) => v === awsField) || [])[0] || awsField;
      }
      label = getBusinessMappingFilterLabel(field);
    }
  }
  return label;
};

export const getZeroPadAccountId = (accountId) => {
  let fixedAccountId = accountId;
  try {
    fixedAccountId = `000000${accountId}`.slice(-12);
  } catch {
    fixedAccountId = accountId;
  }

  return fixedAccountId;
};

const servicesWithTypes = ['Elasticsearch', 'Data Transfer', 'Amazon Elasticsearch Service'];

const familyTypesWithTypes = ['Load Balancer', 'Compute', 'Instance'];

const servicesWithFamilyTypes = [
  'EC2',
  'RDS',
  'Savings Plans for AWS Compute usage',
  'EDP',
  'S3',
  'Amazon Elastic Compute Cloud',
  'Amazon Simple Storage Service',
  'Amazon Relational Database Service',
  'Amazon Elastic Compute Cloud (EC2)',
  'Amazon Simple Storage Service (S3)',
  'Amazon Relational Database Service (RDS)',
];

export const isServiceHasTypes = (serviceName) => servicesWithTypes.includes(serviceName);

export const isServiceHasFamilyType = (serviceName) => servicesWithFamilyTypes.includes(serviceName);

export const isFamilyTypeHasTypes = (familyType) => familyTypesWithTypes.includes(familyType);

export const modifyRegionNameToDisplay = (groupBy, regionName) => {
  if (groupBy === AwsCommonFields.REGION && mapRegionNamesToDescription.get(regionName)) {
    return `${mapRegionNamesToDescription.get(regionName)} ${regionName}`;
  }
  return regionName;
};

export const modifiedPurchaseOptionToDisplay = (value) => mapPurchaseOptionDbNamesToDisplay.get(value) || value;
