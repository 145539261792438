import React, { useCallback, useMemo, useState } from 'react';
import Stepper from 'shared/components/andtComponents/Stepper/Stepper';
import onboardingStyles from 'shared/components/andtComponents/Onboarding.module.scss';
import k8sStyles from './K8sOnboarding.module.scss';
import { ReactComponent as K8sIcon } from 'shared/img/images/k8s.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { K8S_ONBOARDING_STEPS_CONFIG } from './config.ts';
import classNames from 'classnames';

const K8sOnboardingWizard: React.FC<{ onClose?(): void }> = ({ onClose }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const onNextClick = useCallback(
    () => setCurrentStepIndex((s) => Math.min(K8S_ONBOARDING_STEPS_CONFIG.length - 1, s + 1)),
    [],
  );

  const onBackClick = useCallback(() => {
    setCurrentStepIndex((s) => Math.max(0, s - 1));
    if (!currentStepIndex && onClose) {
      onClose();
    }
  }, [currentStepIndex]);

  const isNextLoading = false;
  const { component: StepPageComponent, title } = K8S_ONBOARDING_STEPS_CONFIG[currentStepIndex];

  const stepperSteps = useMemo(
    () =>
      K8S_ONBOARDING_STEPS_CONFIG.map(({ titleShort }, index) => ({
        id: index,
        title: titleShort,
      })),
    [],
  );

  return (
    <div className={classNames(onboardingStyles.container, k8sStyles.k8sOnboardingContainer)}>
      <Stepper activeStepIndex={currentStepIndex} steps={stepperSteps} />
      <div className={onboardingStyles.pageScreen}>
        <div className={onboardingStyles.header}>
          <div className={onboardingStyles.headerContent}>
            {currentStepIndex + 1}. {title}
          </div>
          <div className={onboardingStyles.headerLogo}>
            <K8sIcon />
          </div>
        </div>
        <div className={onboardingStyles.body}>{StepPageComponent && <StepPageComponent />}</div>
        <div className={onboardingStyles.footer}>
          <div className={onboardingStyles.footerLeft} />
          <div className={onboardingStyles.footerMenu}>
            <Button
              onClick={onBackClick}
              text="Back"
              isTextButton
              disabled={false}
              icon={() => <GenerateIcon iconName={ICONS.chevronLeft.name} />}
            />
            <Button
              onClick={onNextClick}
              disabled={isNextLoading}
              text="Next"
              isLoading={isNextLoading}
              iconPlacement="right"
              icon={() => <GenerateIcon iconName={ICONS.chevronRight.name} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default K8sOnboardingWizard;
