import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import { numberWithCommas, removeDecPoint } from 'shared/utils/strUtil';
import { getFakeT } from 'shared/utils/translationUtil';
import ExplanationList from './ExplanationList';
import { ReactComponent as Illustration } from './illustration-trial.svg';
import useHeatMapSummary from 'recommendationsNew/hooks/react-query/useHeatMapSummary.js';
import { getInitialFiltersStateCopy } from 'recommendationsNew/components/heatMap/heatMapFilters/filterStates.js';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import styles from './trial.module.scss';

const textMap = {
  HEADLINE1: (savingAmount) => <>Save up to {savingAmount} of your annual cloud costs</>,
  HEADLINE2:
    'Upgrade your account and get full access to our recommendations - Saving plans ' +
    'and reserved instances, compute and storage resources, databases and load balancers, Kubernetes and more.',
  HEADING_NO_SAVINGS: 'Save on your annual cloud costs',
};

const t = getFakeT(textMap);

const propTypes = {
  appStore: PropTypes.object.isRequired,
  mainDbData: PropTypes.object.isRequired,
};

const TrialRecPage = ({ appStore }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const heatmapSummaryHook = useHeatMapSummary();
  const { data, isLoading } = heatmapSummaryHook.fetchHeatMapSummary(getInitialFiltersStateCopy());

  if (appStore.isLoading || isLoading) {
    return <Spinner />;
  }
  const savingAmount = numberWithCommas(removeDecPoint(data?.potentialAnnualSavings || 0), 10);

  return (
    <div className={styles.trialRecPageContainer}>
      <div>
        <div className={styles.promotionCard}>
          {data?.potentialAnnualSavings ? (
            <h2>{t('HEADLINE1', <span>{getCurrencyNumber(savingAmount)}</span>)}</h2>
          ) : (
            <h2>{t('HEADING_NO_SAVINGS')}</h2>
          )}
          <div>{t('HEADLINE2')}</div>

          <Illustration />
        </div>
        <ExplanationList />
      </div>
    </div>
  );
};

TrialRecPage.propTypes = propTypes;

export default TrialRecPage;
