import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { toast } from 'react-toastify';

const root = '/api/v1/user-management/share-entity';

const updateEntityPermissions = async (data) =>
  API.post('billings', root + `/${data.category}/${data.id}`, { body: data });
const getEntityPermissionsRoles = async (data) => API.get('billings', root + `/${data.category}/${data.id}/roles`);

export const useSharingEntities = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.SHARING_ENTITIES];

  return {
    reset: () => queryClient.resetQueries({ queryKey }),
    useUpdateEntityPermissions: (options) =>
      useMutation({
        mutationFn: updateEntityPermissions,
        ...options,
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey });
          if (options.onSuccess) {
            options.onSuccess();
          }
        },
        onError: () =>
          toast.error('Error updating permissions', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
    useGetEntityPermissionsRoles: (data, options) =>
      useQuery({
        queryKey,
        queryFn: () => getEntityPermissionsRoles(data),
        ...options,
        onError: () =>
          toast.error('Error getting permissions roles', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
  };
};
