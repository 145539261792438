import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import { K8S_QUANTITY_TYPE_SELECT } from 'shared/constants/k8sConstants';
import { K8S_USAGE_TYPES } from '~/kubernetes/constants/k8sConstants.js';

export const getDisplayMetricTypeByUsageType = ({ k8sMode = '', displayedMetric, selectedUsageType = '' }) => {
  if (!k8sMode) {
    return displayedMetric;
  }
  switch (selectedUsageType) {
    case K8S_QUANTITY_TYPE_SELECT:
      return DisplayMetricTypes.COST;
    case K8S_USAGE_TYPES.BYTES:
    case K8S_USAGE_TYPES.MEMORY:
      return DisplayMetricTypes.BYTES;
    default:
      return selectedUsageType;
  }
};
