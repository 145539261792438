import React from 'react';
import OnboardingLinks from 'app/containers/Onboarding/utils/OnboardingLinks';

const getBucketNotFoundError = () => (
  <>
    <p>We were not able to download files from your bucket. Please verify the following:</p>
    <ul>
      <li>The .txt file in your bucket contains the correct Role ARN.</li>
      <li>
        Your IAM Role contains the correct trust entity policy, and the IAM Role is associated with the Policy you
        created for Anodot.
      </li>
    </ul>
  </>
);

const getBucketWrongRegionError = () => `Bucket region is wrong.`;

const getBucketNoAccessError = () => (
  <>
    <p>We were not able to assume your role. Please validate the following:</p>
    <ul>
      <li>
        In the AWS console, you edited the trust policy on the Role you created for Anodot based on PileusRole.json,
        which you downloaded in step #3 (Grant Access).
      </li>
      <li>The ARN value was entered correctly in step #3 (Grant Access) of the onboarding process.</li>
    </ul>
  </>
);

export const getOnboardingError = ({ code, accountId, bucketName, roleARN }) =>
  ({
    // app-server errors
    BUCKET_FILE_WRONG_REGION: getBucketWrongRegionError({ accountId, bucketName }),
    BUCKET_FILE_NOT_FOUND: getBucketNotFoundError({ accountId, bucketName }),
    BUCKET_NO_ACCESS: getBucketNoAccessError({ roleARN, bucketName }),
    // airflow errors (key should match airflow error)
    ERR_HANDSHAKE_ASSUME_ROLE: getBucketNoAccessError({ roleARN, bucketName }),
    ERR_HANDSHAKE_DOWNLOAD_FILE: getBucketNotFoundError({ accountId, bucketName }),
    ERR_HANDSHAKE_VARIABLE_NOT_FOUND: 'Internal server error, please try again or contact support',
    ERR_HANDSHAKE_UPLOAD_FILE:
      'We are unable to download files from your bucket due to missing permissions.' +
      ' Please verify that you provided the needed permission for the Policy' +
      ' you created for Anodot on the AWS console.' +
      ' Policy permissions should be created using PileusPolicy.json, which you downloaded in step #3 (Grant Access).',
    ERR_HANDSHAKE_LAMBDA_NOT_STARTED: (
      <>
        <p>We did not receive SNS. Please verify the following on the AWS side:</p>
        <ul>
          <li>In the event notification destination, the region should match the bucket region.</li>
          <li>
            Verify that you created the event notification as described{' '}
            <a href={OnboardingLinks.AWS.onboardingTypeEventNotification}>here</a>.
          </li>
        </ul>
      </>
    ),
    ERR_HANDSHAKE_FLAG_NOT_RECEIVED:
      'It seems like you did not click on “Next” in step #4 (Validate Access),' +
      ' please upload the file to your bucket and click on Next.',
    ERR_HANDSHAKE_ACCOUNT_ONBOARDED: 'This account was already onboarded',
    ERR_HANDSHAKE_HANDLE_LINKED_ACCOUNTS: 'Internal server error, please try again or contact support',
    ERR_HANDSHAKE_DB_WRITE: 'Internal server error, please try again or contact support',
    ERR_HANDSHAKE_AWS_LINKED_ACCOUNTS_NOT_AVAILABLE:
      'Due to missing permission, we were unable to get your linked accounts list. ' +
      'Please verify that you created the IAM Policy using the file PileusPolicy.json permissions, which you' +
      ' downloaded in step #3 (Grant Access) of the onboarding.',
    ERR_INVOICE_RESOURCE_IDS_MISSING: (
      <>
        <p>
          Processing is paused because the CUR created in the AWS console is missing the option to “Include Resource
          IDs.”
        </p>
        <br />
        <p>To fix it, go to:</p>
        <p>
          <span>AWS console &gt; Account &gt; Cost & Usage Report and select “Include Resource IDs.”</span>
          Processing will automatically resume once you make this fix.
        </p>
      </>
    ),
    ERR_INVOICE_COST_TAGS_MISSING: (
      <>
        <p>
          Cost allocation tags are not activated on your AWS Console. This results in missing information when
          visualizing costs.
        </p>
        <br />
        <p>To fix it, go to:</p>
        <p>
          <span>AWS console &gt; Account &gt; Cost allocation tags. </span>
          Your fix will be reflected on the next processing cycle.
        </p>
      </>
    ),
    ERR_INVOICE_HOUR_GRANULARITY_MISSING: (
      <>
        <p>
          The CUR created in the AWS console is not set to “hour granularity.” This will prevent proper calculation of
          some metrics.
        </p>
        <br />
        <p>To fix it, go to:</p>
        <p>
          <span>AWS console &gt; Account &gt; Cost & usage reports.</span>
          Your fix will be reflected on the next processing cycle.
        </p>
      </>
    ),
    ERR_INVOICE_ACCOUNT_IS_NOT_PAYER: (
      <>
        <p>
          The payer account ID entered at the first step of onboarding does not match the payer account ID from the
          export file (it might have happened if you had entered the linked account ID instead of the payer account ID).
          You should delete this onboarding and recreate it using the correct payer account ID.
        </p>
      </>
    ),
  }[code]);
