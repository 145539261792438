import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {GenerateIcon, ICONS} from '@pileus-cloud/anodot-frontend-common';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import styles from './forecastChart.module.scss';


const getDateFormat = (date) => {
  return moment(date).format('MMM DD YYYY');
};
export const DailyChartCustomTooltip = ({ payload }) => {
  const {getCurrencyNumber} = useUserSettingsContext();
  if (!payload?.length) {
    return null;
  }
  const dataPoint = payload[0]?.payload;

  return (
    <div className={styles.dailyCustomizedTooltip}>
      <div className={styles.tooltipTitleContainer}>
        <GenerateIcon iconName={ICONS.calendarDay.name}/>
        <div className={styles.tooltipTitle}>{getDateFormat(dataPoint.fullDate)}</div>
      </div>
      <div className={styles.tooltipDataContainer}>
        <div className={styles.dailyValueWrapper}>
          <span className={styles.dailyKey}>{dataPoint.currExpected ? 'Daily Forecast:' : 'Daily Cost:'} </span>
          <span className={styles.value}>{getCurrencyNumber(+payload[0]?.value, 2)}</span>
        </div>
        <div className={styles.dailyValueWrapper}>
          <span className={styles.dailyKey}>Monthly Accumulated: </span>
          <span className={styles.value}>{getCurrencyNumber(dataPoint?.accumulated, 2)}</span>
        </div>
      </div>
    </div>
  )
};

DailyChartCustomTooltip.propTypes = {
  payload: PropTypes.object,
}
