import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common';
import { observer } from 'mobx-react';
import { Routes } from 'shared/constants/routes';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import TopBarSearch from 'shared/components/TopBarSearch';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import MenuItem from 'app/containers/Layout/topNavbar/components/MenuItem';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import { useNavigate } from 'react-router-dom';
import MenuCategory from 'app/containers/Layout/topNavbar/components/MenuCategory';

import styles from 'app/containers/Layout/topNavbar/TopNavBar.module.scss';

const customStyles = {
  inputStyle: {
    backgroundColor: '#E9F0FF',
    height: '45px',
    border: 'none',
    color: '#8995A0',
    fontWeight: '400',
    borderRadius: '0',
    borderBottom: '1px solid #d0dfff',
    margin: '0px',
    padding: '5px 0px 0px 30px',
  },
  iconStyle: { top: '13px', left: '5px' },
  formStyle: { width: '100%' },
};

const ScopePicker = ({
  classes,
  currentDisplayedCustomer,
  currentDisplayedCloudTypeId,
  handleBackToCurrDisplayedAccount,
  scopesList,
  handleChangeCustomer,
  currAccountDivisionId,
  currDispUserDivisionId,
  isAccountLoading = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [filteredScopes, setFilteredScopes] = useState([]);
  const navigate = useNavigate();

  const toggle = () => {
    const currentIsOpen = isOpen;
    setIsOpen((prev) => !prev);
    if (!currentIsOpen) {
      setSearchValue('');
    }
  };

  const backToMainScope = () => {
    toggle();
    handleBackToCurrDisplayedAccount();
    navigate(Routes.DASHBOARD);
  };

  const sortSearchValues = (values) => {
    if (values) {
      return values.sort((a, b) => {
        const getDivisionName = (d) => d.nameDisplay || d.name;
        return getDivisionName(a).toLowerCase().localeCompare(getDivisionName(b).toLowerCase());
      });
    }
    return [];
  };

  function getAllParentScopes(scope) {
    const result = [];
    let currentScope = scope;

    while (currentScope) {
      result.push(currentScope);
      currentScope =
        currentScope.parentId !== undefined ? scopesList.find((s) => s.id === currentScope.parentId) : undefined;
    }

    return result;
  }

  function getMatchingScopeWithAllParents(scope) {
    const fullString = (scope.name || '').toLowerCase();
    const updatedName = (scope.nameDisplay || '').toLowerCase();
    const displayName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', scope.name || '');
    const subString = searchValue?.toLowerCase();
    const isMatching =
      subString.length === 0 ||
      fullString.includes(subString) ||
      updatedName.includes(subString) ||
      displayName.toLowerCase().includes(subString);

    let matchingScopes = [];
    if (isMatching) {
      matchingScopes = getAllParentScopes(scope);
    }

    return matchingScopes;
  }

  useEffect(() => {
    const matchingScopes = new Map();
    for (const scope of scopesList) {
      const resultScopes = getMatchingScopeWithAllParents(scope);
      resultScopes.forEach((resultScope) => matchingScopes.set(resultScope.id, resultScope));
    }
    const filteredList = Array.from(matchingScopes.values());
    const sortedList = sortSearchValues(filteredList);
    setFilteredScopes(sortedList);
  }, [searchValue, scopesList]);

  const handleChangeDivisionClicked = (scope) => {
    toggle();
    handleChangeCustomer(scope.id, scope.nameDisplay || scope.name, scope.accountKey);
  };

  const isHierarchy = useMemo(() => {
    return scopesList.some((scope) => scope.parentId !== null);
  }, [scopesList]);

  const renderMenuCategories = (scopes, filteredScopeList, level = 0) => {
    const displayName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', currentDisplayedCustomer);

    return scopes.map((scope, index) => {
      const selfScope = filteredScopeList.find((s) => s.id === scope.id);
      const childScopes = filteredScopeList.filter((s) => s.parentId === scope.id);

      return (
        <div
          key={`${scope.nameDisplay}scopePicker-${index}`}
          style={{
            ...{ paddingLeft: 6 + (childScopes?.length > 0 || !isHierarchy ? 0 : 21) },
            ...(isAccountLoading ? { pointerEvents: 'none', opacity: '0.4' } : {}),
          }}
        >
          {childScopes?.length > 0 ? (
            <MenuCategory
              title={scope.name}
              id={scope.id}
              classes={classes}
              onClick={() => handleChangeDivisionClicked(scope)}
              isHierarchy={isHierarchy}
            >
              {renderMenuCategories(childScopes, filteredScopeList, level + 1)}
            </MenuCategory>
          ) : (
            <>
              {selfScope ? (
                <MenuItem
                  key={`${scope.nameDisplay}scopePicker-${index}`}
                  activeName={displayName}
                  classes={classes}
                  handler={() => handleChangeDivisionClicked(scope)}
                  item={{
                    value: LabelCoordinator.getDataKeyDisplayName(
                      'cueDisplayCoordinator',
                      scope.nameDisplay || scope.name,
                    ),
                  }}
                  id={`${scope.accountKey}_scopePicker`}
                />
              ) : null}
            </>
          )}
        </div>
      );
    });
  };

  const renderScopePicker = () => {
    const displayName = scopesList?.find((s) => s.id === currDispUserDivisionId)?.nameDisplay;

    const overrideClass = { ...classes };
    overrideClass.item = classes.customItem;

    const topLevelScopes = scopesList.filter((scope) => scope.parentId === null);
    return (
      <Popover open={isOpen} onOpenChange={toggle}>
        <PopoverTrigger>
          <Tooltip title={`Explore a specific ${LabelCoordinator.getFieldLabel('Cost Center').toLowerCase()}`}>
            <div className={classes.topPickerEnv}>
              <span>Scope:</span>
              <span>{currAccountDivisionId === currDispUserDivisionId ? 'Main Account' : displayName}</span>
              <ChevronDown size={16} />
            </div>
          </Tooltip>
        </PopoverTrigger>
        <div className={classes.popoverWrapper}>
          <PopoverContent className={classes.noPadding}>
            {currAccountDivisionId !== currDispUserDivisionId && !isAccountLoading ? (
              <div className={classes.itemWrap}>
                <MenuItem
                  classes={overrideClass}
                  handler={backToMainScope}
                  item={{
                    key: 'main-account-div',
                    value: 'Back to Main Account',
                    cloudId: currentDisplayedCloudTypeId,
                  }}
                  id="back_to_main_account"
                >
                  <span>
                    <ChevronLeftIcon className="pagination__link-icon" />
                    Back to Main Account
                  </span>
                </MenuItem>
              </div>
            ) : null}
            <div>
              {scopesList?.length ? (
                <div className={`${classes.itemWrap} ${classes.marginBottom}`}>
                  <TopBarSearch
                    handler={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    inputStyle={customStyles.inputStyle}
                    formStyle={customStyles.formStyle}
                    iconStyle={customStyles.iconStyle}
                    id="topbarScopeSearch"
                    placeholder="Search scope"
                  />
                </div>
              ) : null}
              {filteredScopes?.length ? (
                <div className={`${styles.listWrapper} show-scroll`}>
                  {renderMenuCategories(topLevelScopes, filteredScopes)}
                </div>
              ) : null}
            </div>
          </PopoverContent>
        </div>
      </Popover>
    );
  };

  return renderScopePicker();
};

ScopePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  currentDisplayedCustomer: PropTypes.string.isRequired,
  currentDisplayedCloudTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleBackToCurrDisplayedAccount: PropTypes.func.isRequired,
  accountsList: PropTypes.array.isRequired,
  scopesList: PropTypes.array.isRequired,
  handleChangeCustomer: PropTypes.func.isRequired,
  currAccountDivisionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currDispUserDivisionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAccountLoading: PropTypes.bool,
};

const ObserverScopePicker = observer(ScopePicker);
export default ObserverScopePicker;
