import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverTrigger, PopoverContent } from '@pileus-cloud/anodot-frontend-common';
import { ChevronDown } from 'react-feather';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useRootStore } from 'app/contexts/RootStoreContext';
import MenuItem from 'app/containers/Layout/topNavbar/components/MenuItem';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const MarginPicker = ({ classes }) => {
  const { appStore } = useRootStore();
  const [isOpen, setIsOpen] = useState(false);

  const getPricingModeText = () => {
    const { isPpApplied } = appStore;
    return isPpApplied ? 'Customer' : 'Partner';
  };

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const marginItemClickedHandler = (newValue) => {
    // at the moment only go back tom main account
    toggle();
    if (newValue === appStore.isCustomerPricingMode) {
      return;
    }
    appStore.updatePricingMode(newValue);
  };

  const renderMarginPicker = () => {
    const pricingMode = getPricingModeText();
    return (
      <Popover open={isOpen} onOpenChange={toggle} style={{ width: '260px' }} className="topbar__menu-wrap">
        <PopoverTrigger id="topbar_pricing_mode">
          <Tooltip
            placement="left"
            title={
              <div className={classes.tooltip}>
                Select which prices you would like to see:
                <br />
                1) Partner prices before the re-billing.
                <br />
                2) Billing Customer prices after the re-billing process.
                <br />
                <a
                  href={ExternalLinks.DocumentationForResellers}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here for more details on the impacted capabilities.
                </a>
              </div>
            }
          >
            <div className={classes.topPickerEnv}>
              <span>Pricing mode: </span>
              <span>{pricingMode}</span>
              <ChevronDown size={16} />
            </div>
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent>
          <div className={classes.itemWrap}>
            <MenuItem
              activeName={pricingMode}
              classes={classes}
              id="pricing_mode_partner"
              handler={() => marginItemClickedHandler(false)} // if partner selected done show margin - false
              item={{ key: 'partner', value: 'Partner', cloudId: '' }}
            />
          </div>
          <div className={classes.itemWrap}>
            <MenuItem
              classes={classes}
              id="pricing_mode_customer"
              handler={() => marginItemClickedHandler(true)} // if customer selected show margin - true
              item={{ key: 'customer', value: 'Customer', cloudId: '' }}
            />
          </div>
        </PopoverContent>
      </Popover>
    );
  };

  return renderMarginPicker();
};

MarginPicker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default MarginPicker;
