import React from 'react';
import PropType from 'prop-types';
import styles from './EmptyState.module.scss';
import classNames from 'classnames';

const EmptyState = ({ mainText, subTexts, action, backgroudIcon, classes }) => {
  return (
    <div className={classNames(styles.emptyStateContainer, classes)}>
      {backgroudIcon}
      <div className={styles.emptyStateText}>
        <span className={styles.mainText}>{mainText}</span>
        {subTexts ? (
          <div className={styles.subTexts}>
            {subTexts.map((subText) => (
              <div key={subText}>{subText}</div>
            ))}
          </div>
        ) : null}
        {action ? action : null}
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  mainText: PropType.string.isRequired,
  classes: PropType.string,
  subTexts: PropType.array,
  action: PropType.elementType,
  backgroudIcon: PropType.elementType,
};

export default EmptyState;
