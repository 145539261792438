import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual } from 'lodash';
import Panel from 'shared/components/Panel';
import { GCPCommonFieldLabels, GcpCommonFields } from 'shared/constants/gcpConstants';
import {
  buildStartAndEndDate,
  createDateDisplayStr,
  createTimeZoneAgnosticDateFromStr,
  parseDateFormatFromDate,
} from 'shared/utils/dateUtil';
import SaveGoalModal from 'usage/containers/CostAndUsageExplorer/components/SaveGoalModal';
import { flattenArrayDeep, isEmptyArray } from 'shared/utils/arrayUtils';
import {
  countFilters,
  getCostReadOnlyOptions,
  groupDataBySecondaryGroupBy,
  groupDataByUsageDate,
  modifiedDailyBalancesToAverage,
  prepareDataForDisplayChart,
  prepareDataForDisplayTable,
  prepareDataKeys,
  prepareSecondaryGroupDataKeys,
  splitLinkedAccountNameAndId,
  splitWorkloadValueAndType,
} from 'shared/utils/dataPrepareUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import EventsSideBar from 'shared/components/events/EventsSideBar';
import {
  AmortizeCostTypesK8SItems,
  K8S_USAGE_QUANTITY_TYPES,
  K8S_USAGE_TYPES,
  k8sFiltersNodesFields,
  k8sFiltersPodsFields,
  k8sGranularityPodsFields,
  k8sUsageNodesFields,
  k8sUsagePodsFields,
} from 'kubernetes/constants/k8sConstants';
import { AppCommonFields, OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import {
  K8S_QUANTITY_TYPE_SELECT,
  K8sCommonFields,
  K8sCostTypes,
  k8sGranLevel,
  mapCloudTypeIdToK8sCostTypes,
} from 'shared/constants/k8sConstants';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import FiltersSidebar from 'shared/components/FilterSidebar/FiltersSidebar';
import FiltersSidebarContainer from 'shared/components/FilterSidebar/FiltersSidebarContainer';
import CostTable from 'usage/containers/CostAndUsageExplorer/components/CostTable/CostTable';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import {
  AMORTIZE_COST_DEFAULT_STATE,
  AMORTIZE_COST_TYPES,
  convertChartTypesFlag,
  convertFlagToChartTypes,
  convertModeToUsageRateFlags,
  convertUsageRateFlagsToMode,
  CostTrackingConstants,
  CostTypeModes,
  costTypeToFlags,
  CostUsageStates,
  DisplayMetricTypes,
  getValueFuncByMetricType,
  K8S_GROUP_BY_LOV,
  K8sGroupByLovToAttributes,
  mapCloudBaseGroupByToCostUsageStateMap,
  mapCostUsageStateToDisplayByCloudType,
} from 'usage/constants/costAndUsageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import NewCustomDashboardPanelModal from 'shared/components/NewCustomDashboardPanelModal';
import DevExpSearchAndVisibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import LegendKeysFilterHandler from 'shared/components/ChartKeysFilter/legendKeysFilterHandler';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import { formatExcludeFilterMap } from 'shared/utils/filtersUtils';
import CustomModal from 'shared/components/andtComponents/Modal';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { convertObjToMap, convertStringifyMapToObj } from 'shared/utils/apiUtil';

import { formatFiltersMapToSelectOptionsMap } from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import CostChart from 'usage/containers/CostAndUsageExplorer/components/CostChart';
import ChartButtonsMenu from 'shared/components/chartComponents/ChartButtonsMenu';
import { FilterTypes } from 'usage/constants/usageConstants';
import Button from 'shared/components/andtComponents/Button';
import toast from 'shared/components/andtComponents/Toast';
import { isDecimalNeeded } from 'shared/utils/strUtil';
import { withCUEGoalsContextProvider } from 'users/contexts/CUEGoalsContext';
import { getGoalById, getK8SGoals } from 'users/utils/cueGoalsUtil';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';

import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import {
  Action,
  CategoryAction,
  HierarchicalEntityCategory,
  OrganizationEntityCategory,
} from '@anodot-cost/rbac-client';
import ClusterDataState from './helpers/stateMachine/clusterDataState';
import NamespaceDataState from './helpers/stateMachine/namespaceDataState';
import NodeDataState from './helpers/stateMachine/nodeDataState';
import NodeGroupDataState from './helpers/stateMachine/nodeGroupDataState';
import PodDataState from './helpers/stateMachine/podDataState';
import CostTypeDataState from './helpers/stateMachine/costTypeDataState';
import PodDetailsDataState from './helpers/stateMachine/podDetailsDataState';
import InstanceTypeDataState from './helpers/stateMachine/instanceTypeDataState';
import RegionDataState from './helpers/stateMachine/regionDataState';
import LinkedAccountDataState from './helpers/stateMachine/linkedAccDataState';
import SubsciptionDataState from './helpers/stateMachine/subsciptionDataState';
import ProjectDataState from './helpers/stateMachine/projectDataState';
import EnvironmentDataState from './helpers/stateMachine/environmentDataState';
import BusinessMappingDataState from './helpers/stateMachine/businessMappingDataState';
import CustomTagsDataState from './helpers/stateMachine/customTagsDataState';
import AccountTagsDataState from './helpers/stateMachine/accountTagsDataState';
import ViewsCustomTagsDataState from './helpers/stateMachine/viewsCustomTagsDataState';
import LabelDataState from './helpers/stateMachine/labelDataState';
import DoubleGroupDataState from './helpers/stateMachine/doubleGroupDataState';
import NoneTypeDataState from './helpers/stateMachine/noneDataState';
import PayerAccountDataState from './helpers/stateMachine/payerAccountDataState';
import DeploymentDataState from './helpers/stateMachine/deploymentDataState';
import * as custDabrdHelpers from './helpers/k8sCustomDashboardHelperMethods';
import FilterBarK8s from '../FilterBarK8s';
import WorkloadDataState from './helpers/stateMachine/workloadDataState';
import WorkloadTypeDataState from './helpers/stateMachine/workloadTypeDataState';
import withUrlParamsComponentWrapper from '~/shared/components/UrlStateHOC/UrlStateHOC.jsx';

export const getCellDisplayValue = (row, param, numStrAbriviaionByDisplayMetric) => {
  if (!row[param]) {
    row[param] = 0;
  }
  const value = row[param] / 1000;
  if (row.parameter === 'cpu') {
    return `${value.toFixed(isDecimalNeeded(value, 3) ? 3 : 0)} Cores`;
  }

  return numStrAbriviaionByDisplayMetric(row[param], row[param], DisplayMetricTypes.BYTES, false, { decimal: 3 });
};

export const POD_DETAILS_TABLE_COLUMNS = [
  { name: 'parameter', title: 'Parameter' },
  {
    name: 'requests',
    title: 'Requests',
    formatNumValue: true,
  },
  {
    name: 'usage',
    title: 'Usage',
    formatNumValue: true,
  },
  {
    name: 'usageWaste',
    title: 'Usage Waste',
    formatNumValue: true,
  },
  {
    name: 'cost',
    title: 'Usage Cost',
  },
  { name: 'costWaste', title: 'Usage Waste Cost' },
  {
    name: 'totalCost',
    title: 'Total Cost',
    getCellValue: (row) => parseFloat(row.cost) + parseFloat(row.costWaste),
  },
];

const formatDefaultDates = () => {
  const { startDate, endDate } = buildStartAndEndDate(null, null, false);
  return { startDate, endDate };
};

export const POD_DETAILS_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'parameter', width: 200 },
  { columnName: 'requests', width: 150 },
  { columnName: 'usage', width: 150 },
  { columnName: 'usageWaste', width: 150 },
  { columnName: 'cost', width: 150 },
  { columnName: 'costWaste', width: 150 },
  { columnName: 'totalCost', width: 150 },
];

export const POD_DETAILS_TABLE_COLUMNS_EXTENSIONS = [
  { columnName: 'cost', align: 'center' },
  { columnName: 'costWaste', align: 'center' },
  { columnName: 'usage', align: 'center' },
  { columnName: 'usageWaste', align: 'center' },
  { columnName: 'totalCost', align: 'center' },
];

const mapUnclickableStatesByCloudTypeId = new Map([
  [
    CLOUD_TYPE_IDS.AWS,
    [
      CostUsageStates.TYPE,
      CostUsageStates.REGION,
      CostUsageStates.VIEWS_CUSTOM_TAGS,
      CostUsageStates.CUSTOM_TAGS,
      CostUsageStates.ACCOUNT_TAGS,
      CostUsageStates.BUSINESS_MAPPING,
      CostUsageStates.LABELS,
    ],
  ],
  [
    CLOUD_TYPE_IDS.GCP,
    [
      CostUsageStates.NODE,
      CostUsageStates.VIEWS_CUSTOM_TAGS,
      CostUsageStates.CUSTOM_TAGS,
      CostUsageStates.ACCOUNT_TAGS,
      CostUsageStates.LABELS,
      CostUsageStates.BUSINESS_MAPPING,
    ],
  ],
  [
    CLOUD_TYPE_IDS.AZURE,
    [
      CostUsageStates.LINKED_ACC_ID,
      CostUsageStates.REGION,
      CostUsageStates.TYPE,
      CostUsageStates.LABELS,
      CostUsageStates.ACCOUNT_TAGS,
      CostUsageStates.BUSINESS_MAPPING,
    ],
  ],
]);

class ChartsPanel extends Component {
  baseInternalState = {
    filteredKeys: [],
    favourites: [],
    forceRefresh: false,
    isNewCustomDashboardModalOpen: false,
    isOverwriteExistingCustomDbModalOpen: false,
    changedTableColumnWidth: [],
    isInitialDataKeyFilterLoad: true,
    changeGoalModalOpen: false,
    dataStartDate: null,
    dataEndDate: null,
  };
  baseState = {
    isEventsOpen: false,
    isAreaChart: false,
    isDisplayTable: false,
    isPieChart: false,
    isLineChart: false,
    redirectParams: {},
    currDataState: CostUsageStates.CLUSTER,
    filterBarGroupBy: K8sGroupByLovToAttributes.get(K8S_GROUP_BY_LOV.BY_CLUSTER),
    currentGroupBy: K8sGroupByLovToAttributes.get(K8S_GROUP_BY_LOV.BY_CLUSTER),
    dataKeyToWhereParamsMap: {},
    fieldToFilterdValuesMap: {},
    excludedFiltersStatusMap: {},
    likeFiltersStatus: {},
    filtersConfig: {},
    filterBarGroupBySecondary: K8sGroupByLovToAttributes.get('Date'),
    displayMetricTypes: DisplayMetricTypes.COST,
    SelectedUsageType: K8S_QUANTITY_TYPE_SELECT,
    isUsageChecked: false,
    currPeriodGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY,
    selectedGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY,
    currCostType: [K8sCostTypes.COMPUTE, K8sCostTypes.NETWORK, K8sCostTypes.DATA_TRANSFER, K8sCostTypes.STORAGE],
    startDate: formatDefaultDates().startDate,
    endDate: formatDefaultDates().endDate,
    isShowAmortizeCost: false,
    isNetAmortize: false,
    isNetUnblended: false,
    isListUnitPrice: false,
    isNegotiatedSavings: false,
    isShowOthers: true,
    wasteCostAllocationChecked: false,
    isFiltersOpen: false,
    isApplyFiltersButtonDisabled: true,
    accountKey: undefined,
    cloudAccountTypeId: undefined,
    divisionId: undefined,
    divisionName: undefined,
    isPpApplied: undefined,
    selectedGoal: null,
    isCumulative: false,
    isTrendLine: false,
    k8SGranularity: null,
  };

  constructor(props) {
    const { urlParams, urlState } = props;
    super(props);
    this.state = { ...this.baseInternalState, ...this.baseState, ...urlParams };
    const { panelId, startDate, endDate } = this.state;
    const sourceOrPanelIdPresent = panelId || urlState;
    if (sourceOrPanelIdPresent) {
      (async () => {
        const sourceState = await this.getStateBySource(urlState, panelId, startDate, endDate);
        this.setDefaultState(Object.assign({}, this.baseState, sourceState));
      })();
    } else {
      this.initState();
    }
    this.mainLegendKeysFilterHandler = new LegendKeysFilterHandler(this);
    this.setKeysFilterHandler = this.mainLegendKeysFilterHandler.setKeysFilterHandler.bind(this);
    this.mainLegendKeysFilterHandler.addKeysFilterHandler.bind(this);
    this.mainLegendKeysFilterHandler.removeKeysFilterHandler.bind(this);
    this.dataStates = {
      [CostUsageStates.NONE]: new NoneTypeDataState(this),
      [CostUsageStates.CLUSTER]: new ClusterDataState(this),
      [CostUsageStates.NAMESPACE]: new NamespaceDataState(this),
      [CostUsageStates.NODE]: new NodeDataState(this),
      [CostUsageStates.NODE_GROUP]: new NodeGroupDataState(this),
      [CostUsageStates.POD]: new PodDataState(this),
      [CostUsageStates.REGION]: new RegionDataState(this),
      [CostUsageStates.COST_TYPE]: new CostTypeDataState(this),
      [CostUsageStates.LINKED_ACC_ID]: new LinkedAccountDataState(this),
      [CostUsageStates.SUBSCRIPTION_ID]: new SubsciptionDataState(this),
      [CostUsageStates.PROJECT]: new ProjectDataState(this),
      [CostUsageStates.ENVIRONMENT]: new EnvironmentDataState(this),
      [CostUsageStates.CUSTOM_TAGS]: new CustomTagsDataState(this),
      [CostUsageStates.LABELS]: new LabelDataState(this),
      [CostUsageStates.POD_DETAILS]: new PodDetailsDataState(this),
      [CostUsageStates.TYPE]: new InstanceTypeDataState(this),
      // NEEDED FOR OLD PANELS WITH THIS DATASTATE
      [CostUsageStates.INSTANCE_TYPE]: new InstanceTypeDataState(this),
      [CostUsageStates.SECOND_GROUP_BY]: new DoubleGroupDataState(this),
      [CostUsageStates.PAYER_ACCOUNT]: new PayerAccountDataState(this),
      [CostUsageStates.VIEWS_CUSTOM_TAGS]: new ViewsCustomTagsDataState(this),
      [CostUsageStates.DEPLOYMENT]: new DeploymentDataState(this),
      [CostUsageStates.WORKLOAD]: new WorkloadDataState(this),
      [CostUsageStates.WORKLOAD_TYPE]: new WorkloadTypeDataState(this),
      [CostUsageStates.BUSINESS_MAPPING]: new BusinessMappingDataState(this),
      [CostUsageStates.ACCOUNT_TAGS]: new AccountTagsDataState(this),
    };
    this.eventsSidebarRef = React.createRef();
    this.chartDisplayMap = new Map([
      [CostUsageStates.POD_DETAILS, this.renderPodDetailsChart],
      [CostUsageStates.SECOND_GROUP_BY, this.renderSecondGroupByChartOrTable],
    ]);
  }

  initState = () => {
    const { setUrlParams } = this.props;
    setUrlParams({}, this.state);
  };

  setState = (stateOrUpdater) => {
    const { setUrlParams } = this.props;

    // Compute the next state properly
    const nextState = typeof stateOrUpdater === 'function' ? stateOrUpdater(this.state) : stateOrUpdater;

    const onlyUrlParams = {};
    Object.keys(this.baseState).forEach((key) => {
      if (nextState[key] !== undefined && !isEqual(nextState[key], this.state[key])) {
        onlyUrlParams[key] = nextState[key];
      }
    });

    // Should not update state if Ctrl was clicked to open a new tab
    let shouldUpdateState = true;
    if (Object.keys(onlyUrlParams).length > 0) {
      shouldUpdateState = setUrlParams(onlyUrlParams, this.baseState);
    }

    if (shouldUpdateState) {
      super.setState(stateOrUpdater);
    }
  };

  async componentDidMount() {
    const { cloudAccountTypeId, panelId, urlState } = this.state;
    if (cloudAccountTypeId === CLOUD_TYPE_IDS.GCP) {
      this.setGCPK8sGranularityByUrlState();
    }
    if (!panelId && !urlState) {
      this.handleDataStatesFilterChange();
    }
  }

  setGCPK8sGranularityByUrlState = () => {
    const { kubernetesStore } = this.props;
    const fields = [];
    const {
      filterBarGroupBy,
      filterBarGroupBySecondary,
      SelectedUsageType,
      fieldToFilterdValuesMap,
      excludedFiltersStatusMap,
      k8SGranularity,
    } = this.state;
    if (k8SGranularity === k8sGranLevel.NODES || k8SGranularity === k8sGranLevel.PODS) {
      kubernetesStore.setK8sGranularity(k8SGranularity);
      this.setState({ k8SGranularityState: k8SGranularity });
      return;
    }
    // backwords compatibility, if dashboard has no k8SGranularity get it from other params
    fields.push(
      filterBarGroupBy,
      filterBarGroupBySecondary,
      SelectedUsageType,
      ...Object.keys(fieldToFilterdValuesMap),
      ...Object.keys(excludedFiltersStatusMap),
    );
    let granularity = k8sGranLevel.NODES;
    fields.some((field) => {
      if (k8sGranularityPodsFields.includes(field)) {
        granularity = k8sGranLevel.PODS;
        return true;
      }
      return false;
    });
    this.setState({ k8SGranularity: granularity });
    kubernetesStore.setK8sGranularity(granularity);
  };

  async componentDidUpdate(prevProps, prevState) {
    const { currDispUserAccountKey, kubernetesStore, usersStore, urlParams } = this.props;
    const { k8SGranularityState } = this.state;
    const { currDispUserCloudAccountType } = usersStore;
    const { forceRefresh, dataKeyToWhereParamsMap, selectedGoal } = this.state;
    if (currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP && !prevProps.location.search.includes('k8SGranularity')) {
      this.setGCPK8sGranularityByUrlState();
    }
    const isForceFlagChanged = prevState.forceRefresh !== forceRefresh && forceRefresh;
    if (isForceFlagChanged) {
      this.setState({ forceRefresh: false });
    }
    const isDiveLevelChanged =
      prevState.dataKeyToWhereParamsMap &&
      dataKeyToWhereParamsMap &&
      JSON.stringify(dataKeyToWhereParamsMap) !== JSON.stringify(prevState.dataKeyToWhereParamsMap);
    const isGoalChanged = !!selectedGoal && selectedGoal !== prevState.selectedGoal;
    if (isDiveLevelChanged) {
      this.setState({ selectedGoal: null });
    }
    if (currDispUserAccountKey !== prevProps.currDispUserAccountKey) {
      this.handleResetToInitialState();
    } else if (isDiveLevelChanged || isForceFlagChanged) {
      this.handleDataStatesFilterChange();
    } else if (isGoalChanged) {
      this.updateStateWhenGoalSelected();
    }
    if (
      usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP &&
      k8SGranularityState !== kubernetesStore.k8SGranularity
    ) {
      this.setState({ k8SGranularityState: kubernetesStore.k8SGranularity });
      this.resetSelectedOptionsGCP();
    }

    if (urlParams.accountKey !== prevProps.urlParams.accountKey) {
      this.setState({
        ...urlParams,
      });
    }
  }

  // is used in baseCostUsageDataState.js
  updateDataDates = () => {
    const { startDate, endDate } = this.state;
    this.setState({
      dataStartDate: startDate,
      dataEndDate: endDate,
    });
  };

  resetSelectedOptionsGCP = () => {
    const { kubernetesStore } = this.props;
    const {
      fieldToFilterdValuesMap,
      excludedFiltersStatusMap,
      newFilterBarGroupBy,
      newFilterBarGroupBySecondary,
      filterBarGroupBy,
      filterBarGroupBySecondary,
    } = this.state;
    const viewpoints = this.getCurrentGroupByViewpoint(
      newFilterBarGroupBy || filterBarGroupBy,
      newFilterBarGroupBySecondary || filterBarGroupBySecondary,
    );
    if (!fieldToFilterdValuesMap || !excludedFiltersStatusMap) {
      return;
    }
    let fieldsToRemove = [];
    if (kubernetesStore.k8SGranularity === k8sGranLevel.NODES) {
      fieldsToRemove = k8sFiltersPodsFields;
      // todo - remove this when group by tags/labels will be supported when granularity is by nodes
      fieldsToRemove.push(GcpCommonFields.CUSTOM_TAGS);
    } else {
      fieldsToRemove = k8sFiltersNodesFields;
    }
    const fieldToFilterdValuesMapCopy = {};
    const excludedFiltersStatusMapCopy = {};
    Object.keys(fieldToFilterdValuesMap).forEach((field) => {
      if (!fieldsToRemove.includes(field)) {
        fieldToFilterdValuesMapCopy[field] = fieldToFilterdValuesMap[field];
      }
    });
    Object.keys(excludedFiltersStatusMap).forEach((field) => {
      if (!fieldsToRemove.includes(field)) {
        excludedFiltersStatusMapCopy[field] = excludedFiltersStatusMap[field];
      }
    });
    delete fieldToFilterdValuesMapCopy[AwsCommonFields.BUSINESS_MAPPING];
    const dataState = {
      fieldToFilterdValuesMap: fieldToFilterdValuesMapCopy,
      excludedFiltersStatusMap: excludedFiltersStatusMapCopy,
      isApplyFiltersButtonDisabled: false,
      k8SGranularity: kubernetesStore.k8SGranularity,
    };
    if (viewpoints.length) {
      dataState.newFilterBarGroupBy = this.baseState.filterBarGroupBy;
      dataState.newFilterBarGroupBySecondary = this.baseState.filterBarGroupBySecondary;
    }
    this.setState(dataState);
  };

  getAmortizeCostValue = () => {
    const { isNetUnblended, isNetAmortize, isShowAmortizeCost, isListUnitPrice, isNegotiatedSavings } = this.state;
    if (isShowAmortizeCost) {
      return AMORTIZE_COST_TYPES.AMORTIZED;
    }
    if (isNetAmortize) {
      return AMORTIZE_COST_TYPES.NET_AMORTIZED;
    }
    if (isNetUnblended) {
      return AMORTIZE_COST_TYPES.NET_UNBLENDED;
    }
    if (isListUnitPrice) {
      return AMORTIZE_COST_TYPES.LIST_UNIT_PRICE;
    }
    if (isNegotiatedSavings) {
      return AMORTIZE_COST_TYPES.NEGOTIATED_SAVINGS;
    }
    return AMORTIZE_COST_TYPES.UNBLENDED;
  };

  getCostType = () => {
    const { isUsageChecked } = this.state;
    return convertUsageRateFlagsToMode({ isUsageChecked, isRateChecked: false });
  };
  getStateBySource = async (source, panelId, startDate, endDate) => {
    const { usageStore } = this.props;
    if (!source && !!panelId) {
      const panel = await usageStore.customDbSubStore.customDashboardModel.getPanelById(panelId);
      if (!panel) {
        return null;
      }
      source = {
        ...panel,
        startDate,
        endDate,
        isFromCustomDashboard: true,
      };
    }
    if (source.isFromCustomDashboard) {
      delete source.state.isNewCustomDashboardModalOpen;
      delete source.state.isOverwriteExistingCustomDbModalOpen;
      let state = source.state || {};
      state.redirectParams = {
        itemName: source.name,
        id: source.id || source.uuid,
        isFromCustomDashboard: !source.fromTemplate,
      };
      if (!source.state) {
        state = this.baseState;
        state.currentGroupBy = source.routeParams.groupByLevel;
        state.isDisplayTable = source.isDisplayTable;
        state.isPieChart = source.isPieChart;
        state.filterBarGroupBy =
          source.routeParams.whereParamsMap &&
          source.routeParams.whereParamsMap[0] &&
          source.routeParams.whereParamsMap[0][0]
            ? source.routeParams.whereParamsMap[0][0]
            : source.routeParams.groupByLevel;
      }
      const formattedGroupBy =
        state.currentGroupBy === AwsCommonFields.DEPLOYMENT
          ? `${AwsCommonFields.WORKLOAD}:Deployment`
          : state.currentGroupBy;
      state.filterBarGroupBy = formattedGroupBy;
      state.currentGroupBy = formattedGroupBy;
      state.isInitialDataKeyFilterLoad = false;
      state.startDate = source.startDate;
      if (typeof state.currDataState !== 'number') {
        // support old panels where currDataState might be empty for a groupby:label
        state.currDataState = CostUsageStates.LABELS;
      }
      state.endDate = source.endDate;
      state.excludedFiltersStatusMap = convertStringifyMapToObj(state.excludedFiltersStatusMap);
      state.fieldToFilterdValuesMap = convertStringifyMapToObj(source.routeParams.filtersMap);
      // support legacy filter and group by - Deployment
      if (state.fieldToFilterdValuesMap?.deployment) {
        state.fieldToFilterdValuesMap[AwsCommonFields.WORKLOAD] = state.fieldToFilterdValuesMap.deployment.map(
          (a) => `Deployment: ${a}`,
        );
        delete state.fieldToFilterdValuesMap.deployment;
      }
      if (state.excludedFiltersStatusMap?.deployment) {
        state.excludedFiltersStatusMap[AwsCommonFields.WORKLOAD] = {
          Deployment: state.excludedFiltersStatusMap.deployment,
        };
        delete state.excludedFiltersStatusMap.deployment;
      }
      state.dataKeyToWhereParamsMap = convertStringifyMapToObj(source.routeParams.whereParamsMap);
      return state;
    }
    return {};
  };

  getDataKeyByField = (fieldType) => {
    const { dataKeyToWhereParamsMap } = this.state;
    let dataKey = '';
    if (dataKeyToWhereParamsMap[fieldType]) {
      dataKey = dataKeyToWhereParamsMap[fieldType];
    }
    return dataKey;
  };

  getCsvTitle = () => {
    const { usersStore } = this.props;
    const { currentGroupBy, currPeriodGranLevel } = this.state;
    const clusterName = this.getDataKeyByField(K8sCommonFields.CLUSTER);
    const podName = this.getDataKeyByField(K8sCommonFields.POD);
    const groupByLabel =
      usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP && GCPCommonFieldLabels.has(currentGroupBy)
        ? `${GCPCommonFieldLabels.get(currentGroupBy)}-`
        : null;
    const currGroupBy = currentGroupBy ? `${currentGroupBy}s-` : 'clusters-';
    const clusterTName = clusterName ? `${clusterName}-` : '';
    const podTName = podName ? `${podName}-` : '';
    const granLevel = `${currPeriodGranLevel}-`;
    return `${groupByLabel || currGroupBy}${clusterTName}${podTName}${granLevel}cost`;
  };

  getCostTypesByCloudTypeId = () => {
    const { currDispUserCloudAccountType } = this.props.usersStore;
    return mapCloudTypeIdToK8sCostTypes.get(currDispUserCloudAccountType);
  };

  getCurrK8sParamsForCustomDashboard = () => {
    const { dataKeyToWhereParamsMap, fieldToFilterdValuesMap, excludedFiltersStatusMap, ...other } = this.state;
    return custDabrdHelpers.prepareK8sParamsForNewCustomDashboardPanel({
      ...other,
      fieldToFilterdValuesMap: convertObjToMap(fieldToFilterdValuesMap),
      excludedFiltersStatusMap: convertObjToMap(excludedFiltersStatusMap),
      dataKeyToWhereParamsMap: convertObjToMap(dataKeyToWhereParamsMap),
    });
  };

  getYAxisLabel = (SelectedUsageType) => {
    switch (SelectedUsageType) {
      case K8S_USAGE_TYPES.MEMORY:
        return K8S_USAGE_QUANTITY_TYPES.BYTES;
      case K8S_USAGE_TYPES.BYTES:
        return K8S_USAGE_QUANTITY_TYPES.BYTES;
      case K8S_USAGE_TYPES.QUANTITY_NODES:
      case K8S_USAGE_TYPES.QUANTITY_PODS:
      case K8S_USAGE_TYPES.QUANTITY_CLUSTERS:
      case K8S_USAGE_TYPES.QUANTITY_NAMESPACES:
        return SelectedUsageType;
      case K8S_USAGE_TYPES.HOURS:
        return K8S_USAGE_QUANTITY_TYPES.HOURS;
      case K8S_USAGE_TYPES.COST:
        return K8S_USAGE_QUANTITY_TYPES.COST;
      case K8S_QUANTITY_TYPE_SELECT:
        return K8S_USAGE_QUANTITY_TYPES.COST;
      default:
        return K8S_USAGE_QUANTITY_TYPES.CORES;
    }
  };

  getIsGoalsVisible = () => {
    const { isDisplayTable, isPieChart, isLineChart, filterBarGroupBySecondary } = this.state;
    return !isDisplayTable && !isPieChart && !isLineChart && filterBarGroupBySecondary === 'usagedate';
  };

  getFilterLabel = (field) => LabelCoordinator.getFieldLabel(field);

  getCustomDashboardPanelType = (isLineChart) => {
    const { currDataState, isDisplayTable, isPieChart, isUsageChecked, SelectedUsageType } = this.state;
    if (currDataState === CostUsageStates.POD_DETAILS) {
      return 'k8s-pod-details-table';
    }
    if (isPieChart) {
      return 'k8s-pie-chart';
    }
    if (isDisplayTable) {
      return 'k8s-table';
    }
    if (isLineChart && isUsageChecked && SelectedUsageType !== K8S_QUANTITY_TYPE_SELECT) {
      return 'k8s-usage';
    }
    return 'k8s';
  };

  getStateToSaveInGoal = () => {
    const {
      isAreaChart,
      isDisplayTable,
      isCumulative,
      isTrendLine,
      isPieChart,
      isLineChart,
      filterBarGroupBy,
      currentGroupBy,
      filterBarGroupBySecondary,
      dataKeyToWhereParamsMap,
      fieldToFilterdValuesMap,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      filtersConfig,
      displayMetricTypes,
      SelectedUsageType,
      currDataState,
      currCostType,
    } = this.state;
    return {
      chartType: convertChartTypesFlag({ isPieChart, isAreaChart, isLineChart, isDisplayTable }),
      selectGroupBy: filterBarGroupBy,
      groupBy: currentGroupBy,
      groupBySecondary: filterBarGroupBySecondary,
      whereParams: convertObjToMap(dataKeyToWhereParamsMap),
      filters: fieldToFilterdValuesMap,
      excludeFilters: excludedFiltersStatusMap,
      likeFilters: likeFiltersStatus,
      filtersConfig,
      metricType: displayMetricTypes,
      usageType: SelectedUsageType,
      costTypeMode: this.getCostType(),
      costType: this.getAmortizeCostValue(),
      isCumulative,
      isTrendLine,
      currDataState,
      currCostType,
      type: 'K8S',
    };
  };

  getStateToRestoreFromGoal = (goal) => {
    const {
      explorerState: {
        chartType,
        selectGroupBy,
        groupBy,
        groupBySecondary,
        whereParams,
        filters,
        excludeFilters,
        likeFilters,
        filtersConfig,
        metricType,
        usageType,
        costTypeMode,
        costType,
        currDataState,
        currCostType,
        isCumulative,
        isTrendLine,
      },
      granularity,
      startDate,
      endDate,
    } = goal;
    return {
      ...convertFlagToChartTypes(chartType),
      filterBarGroupBy: selectGroupBy,
      currentGroupBy: groupBy,
      filterBarGroupBySecondary: groupBySecondary,
      dataKeyToWhereParamsMap: convertStringifyMapToObj(whereParams),
      fieldToFilterdValuesMap: filters,
      excludedFiltersStatusMap: excludeFilters,
      likeFiltersStatus: likeFilters,
      filtersConfig,
      displayMetricTypes: metricType,
      SelectedUsageType: usageType,
      selectedGranLevel: granularity,
      currPeriodGranLevel: granularity,
      startDate,
      endDate,
      currDataState,
      isCumulative,
      isTrendLine,
      currCostType,
      ...convertModeToUsageRateFlags(costTypeMode),
      ...costTypeToFlags(costType),
    };
  };

  isChangesAppliedBeforeSave = () => {
    const { isApplyFiltersButtonDisabled } = this.state;
    if (!isApplyFiltersButtonDisabled) {
      toast.error('Apply changes before saving dashboard');
      return false;
    }
    return true;
  };

  isDiveAllowed = (dataKey) => {
    const { isUsageChecked, filterBarGroupBy } = this.state;
    let isAllowed = true;
    if (dataKey.toLowerCase().includes('waste')) {
      isAllowed = false;
    }
    if ((filterBarGroupBy || '').startsWith('labels:')) {
      isAllowed = false;
    }
    if (isUsageChecked) {
      isAllowed = false;
    }
    return isAllowed;
  };
  setDefaultState = (state = {}) => {
    const { usersStore, appStore } = this.props;
    const stateVal = {
      ...this.baseInternalState,
      ...this.baseState,
      ...state,
      accountKey: usersStore.currDispUserAccountKey,
      cloudAccountTypeId: usersStore.currDispUserCloudAccountType,
      divisionId: usersStore.currDispUserDivisionId,
      isPpApplied: appStore.isPpApplied,
      divisionName: usersStore.currDispUserDivisionName,
      currCostType: state.currCostType || this.getCostTypesByCloudTypeId(),
      forceRefresh: true,
    };
    this.setState(stateVal);
  };
  formatColumnTitleDate = (column) => {
    const format = parseDateFormatFromDate(column);
    if (format) {
      return {
        name: column,
        title: createDateDisplayStr(column, format),
      };
    }
    return { name: column, title: column };
  };

  defaultDates = () => {
    const { startDate: start, endDate: end } = this.state;
    let { startDate, endDate } = buildStartAndEndDate(start, end);
    startDate = createTimeZoneAgnosticDateFromStr(startDate);
    endDate = createTimeZoneAgnosticDateFromStr(endDate);
    return { startDate, endDate };
  };

  buildStateValue = (value, newValue, setNewVal = null) => {
    const stateNewValue = this.state[newValue];
    const obj = {};
    if (stateNewValue) {
      obj[value] = stateNewValue;
      obj[newValue] = setNewVal;
    }
    return obj;
  };

  buildStateToUpdate = () => {
    const { isUsageChecked, newFilterBarGroupBySecondary, selectedGranLevel } = this.state;
    let state = {
      isApplyFiltersButtonDisabled: true,
      selectedGoal: null,
    };
    const groupByFilters = this.buildStateValue('filterBarGroupBy', 'newFilterBarGroupBy');
    const secondGroupByFilters = this.buildStateValue('filterBarGroupBySecondary', 'newFilterBarGroupBySecondary');

    state.currPeriodGranLevel = selectedGranLevel;
    if (newFilterBarGroupBySecondary && newFilterBarGroupBySecondary !== K8sGroupByLovToAttributes.get('Date')) {
      state.currPeriodGranLevel = CostTrackingConstants.GRAN_LEVEL_DAILY;
      state.selectedGranLevel = CostTrackingConstants.GRAN_LEVEL_DAILY;
    }
    state = { ...state, ...groupByFilters, ...secondGroupByFilters, isUsageChecked };
    return state;
  };

  updateStateWhenGoalSelected = () => {
    const { CUEGoals } = this.props;
    const { selectedGoal } = this.state;
    const goal = getGoalById(CUEGoals, selectedGoal);
    if (!goal) {
      return;
    }
    const stateByGoal = this.getStateToRestoreFromGoal(goal);
    this.setState({ ...stateByGoal, forceRefresh: true });
  };

  prepareMonthlyData(baseData) {
    if (!baseData || !Array.isArray(baseData) || baseData.length === 0) {
      return { modifiedDailyBalances: [], tableModifiedDailyBalance: [] };
    }
    const data = groupDataByUsageDate(baseData);

    const { modifiedDailyBalances, tableModifiedDailyBalance } = this.prepareData(data);
    return { modifiedDailyBalances, tableModifiedDailyBalance };
  }

  prepareData(data) {
    const { displayMetricTypes, isCumulative, currentGroupBy } = this.state;
    const getValue = getValueFuncByMetricType(displayMetricTypes);

    const modifiedDailyBalances = prepareDataForDisplayChart(
      data,
      AppCommonFields.USAGE_DATE,
      AppCommonFields.GROUP_BY,
      getValue,
      isCumulative,
    );
    const tableModifiedDailyBalance = prepareDataForDisplayTable({
      data,
      entryAnchorFieldName: AppCommonFields.USAGE_DATE,
      keyFieldName: AppCommonFields.GROUP_BY,
      valueFieldName: getValue,
      isShowTrendRow: false,
      currentGroupBy,
      isCumulative,
    });
    return { modifiedDailyBalances, tableModifiedDailyBalance };
  }

  prepareCSVData = (rows) => {
    // eslint-disable-next-line no-unused-vars
    const csvData = rows.map(({ groupBy, ...rest }) => rest);
    return csvData;
  };

  prepareExportTableToCsv = async () => {
    const { currDataState, isTableTrendRow, currentGroupBy } = this.state;
    const { usersStore } = this.props;
    const csvTitle = this.getCsvTitle();
    let baseData;
    let tableData;
    if (currDataState !== CostUsageStates.POD_DETAILS) {
      baseData = this.dataStates[currDataState].getData();
      const { tableModifiedDailyBalance } =
        currDataState === CostUsageStates.SECOND_GROUP_BY
          ? this.prepareSecondLevelGroupByData(baseData)
          : this.prepareMonthlyData(baseData, undefined, isTableTrendRow);
      tableData = [...tableModifiedDailyBalance];
      tableData = splitLinkedAccountNameAndId(tableData, currentGroupBy, usersStore.currDispUserCloudAccountType);
      tableData = splitWorkloadValueAndType(tableData, currentGroupBy);
    } else {
      const resourceData = this.dataStates[currDataState].getData();
      const clusterName = this.getDataKeyByField(K8sCommonFields.CLUSTER);
      baseData =
        resourceData && Array.isArray(resourceData)
          ? resourceData.map(({ ...params }) => ({
              clusterName,
              ...params,
            }))
          : [];
      tableData = (baseData && this.prepareCSVData(baseData)) || [];
    }
    return [{ data: tableData, filename: csvTitle }];
  };

  prepareParamsForOverwriteExistingCustomDbPanel = () => {
    const { redirectParams } = this.state;
    const { id, itemName } = redirectParams;
    const {
      // eslint-disable-next-line no-unused-vars
      existingCustomDashboardPanelModalIsOpen,
      // eslint-disable-next-line no-unused-vars
      newCustomDashboardPanelModalIsOpen,
      // eslint-disable-next-line no-unused-vars
      saveModalName,
      // eslint-disable-next-line no-unused-vars
      saveModalRelativeDates,
      ...rest
    } = this.state;
    const type = this.getCustomDashboardPanelType();
    const baseParams = custDabrdHelpers.prepareBaseParams({
      customDashboardPanelName: itemName,
      type,
      state: { ...rest },
    });
    const periodParams = {};
    const routeParams = this.getCurrK8sParamsForCustomDashboard();
    const preparedParams = custDabrdHelpers.createNewCustomDashboardPanelObj(baseParams, periodParams, routeParams);
    preparedParams.uuid = id;
    return preparedParams;
  };

  prepareSecondLevelGroupByData(baseData) {
    if (!baseData || baseData.length === 0) {
      return { modifiedDailyBalances: [], tableModifiedDailyBalance: [] };
    }
    const data = groupDataBySecondaryGroupBy(baseData);
    const { modifiedDailyBalances, tableModifiedDailyBalance } = this.prepareDataBySecondGroupBy(data);
    return { modifiedDailyBalances, tableModifiedDailyBalance };
  }

  prepareDataBySecondGroupBy(baseData) {
    const { displayMetricTypes, isCumulative } = this.state;
    const getValue = getValueFuncByMetricType(displayMetricTypes);

    const modifiedDailyBalances = prepareDataForDisplayChart(
      baseData,
      'groupBySecondary',
      AppCommonFields.GROUP_BY,
      getValue,
      isCumulative,
    );
    const tableModifiedDailyBalance = prepareDataForDisplayTable({
      data: baseData,
      entryAnchorFieldName: 'groupBySecondary',
      keyFieldName: AppCommonFields.GROUP_BY,
      valueFieldName: getValue,
      isShowTrendRow: false,
      isCumulative,
    });
    return { modifiedDailyBalances, tableModifiedDailyBalance };
  }

  prepareDataWithSizeLimit = (data, limit) => {
    if (data && Array.isArray(data)) {
      const groupByKeys = [...new Set(data.map(({ groupBy }) => groupBy))];
      if (groupByKeys.length < limit) {
        return data;
      }
      const keysMapToTotalCost = new Map();
      data.forEach((datum) => {
        if (keysMapToTotalCost.has(datum.groupBy)) {
          const currCost = keysMapToTotalCost.get(datum.groupBy);
          const newCost = currCost + datum.totalCost;
          keysMapToTotalCost.set(datum.groupBy, newCost);
        } else {
          keysMapToTotalCost.set(datum.groupBy, datum.totalCost);
        }
      });
      const keysTotalCost = [...keysMapToTotalCost.entries()].map(([k, v]) => ({ groupBy: k, totalSum: v }));
      const topByLimit = keysTotalCost
        .sort((a, b) => b.totalSum - a.totalSum)
        .map(({ groupBy }) => groupBy)
        .slice(0, limit - 1);
      return data.map((item) => ({
        ...item,
        groupBy: topByLimit.includes(item.groupBy) ? item.groupBy : 'Rest',
      }));
    }
    return [];
  };

  prepareTableHeaders = (data, newWidths) => {
    const dataKeys = flattenArrayDeep(data.map((row) => Object.keys(row)));
    const result = [...new Set(dataKeys)];
    const columns = result.map(this.formatColumnTitleDate);

    const totalSummaryItems = result.map((elem) => {
      if (elem === 'groupBy') {
        return { columnName: elem, type: 'count' };
      }
      return { columnName: elem, type: 'sum' };
    });

    const columnWidths =
      newWidths.length > 0
        ? newWidths
        : result.map((elem) => ({
            columnName: elem,
            width: result.length > 5 ? 150 : 300,
          }));

    const tableColumnExtensions = result.map((elem) => ({
      columnName: elem,
      align: 'left',
    }));

    const indexOfGroupBy = columns.findIndex((col) => col.name === 'groupBy');
    if (indexOfGroupBy > -1) {
      columns[indexOfGroupBy] = { name: 'groupBy', title: 'Group By' };
    }
    return { columns, columnWidths, tableColumnExtensions, totalSummaryItems };
  };

  handleTableColumnWidthChange = (newWidths) => {
    this.setState({ changedTableColumnWidth: newWidths });
  };

  addKeysFilterHandler = (dataKeys, isInitialLoad, updateVisible) => {
    this.mainLegendKeysFilterHandler.addKeysFilterHandler(dataKeys, isInitialLoad, updateVisible);
    this.setState({ isShowOthers: false });
  };
  removeKeysFilterHandler = (ataKeys, isClearAll) => {
    this.mainLegendKeysFilterHandler.removeKeysFilterHandler(ataKeys, isClearAll);
    this.setState({ isShowOthers: false });
  };

  getFiltersConfig = () => {
    const { kubernetesStore } = this.props;
    return {
      [AwsCommonFields.BUSINESS_MAPPING]: {
        isK8S: true,
        k8SGranularity: kubernetesStore.k8SGranularity,
      },
      [AwsCommonFields.CATEGORIES]: {
        disableLike: true,
      },
      [AwsCommonFields.PURCHASE_OPTION]: {
        disableLike: true,
      },
      [AwsCommonFields.REGION]: {
        disableLike: true,
      },
      [AwsCommonFields.QUANTITY_TYPE]: {
        disableLike: true,
      },
      [AwsCommonFields.DIVISION]: {
        disableLike: true,
      },
      [AwsCommonFields.CLOUD_PROVIDER]: {
        disableLike: true,
      },
      [AwsCommonFields.VIRTUAL_CUSTOM_TAGS]: {
        disableLike: true,
      },
      [AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS]: {
        disableLike: true,
      },
      [AwsCommonFields.PURCHASE_OPTION]: {
        disableLike: true,
      },
      [AwsCommonFields.QUANTITY_TYPE]: {
        disableLike: true,
      },
      [AwsCommonFields.DIVISION]: {
        disableLike: true,
      },
      [AwsCommonFields.CLOUD_PROVIDER]: {
        disableLike: true,
      },
      [AwsCommonFields.K8S_LABEL_TAGS]: {
        showNotAllocated: true,
      },
    };
  };

  backClickHandler = (e, dataStateType) => {
    const { length } = this.state.changedTableColumnWidth;
    e.preventDefault();
    if (length > 0) {
      this.setState({ changedTableColumnWidth: [] });
    }
    this.dataStates[dataStateType].handleBackTo();
  };

  handleChangeFilterType = (field, subField, type) => {
    const { excludedFiltersStatusMap = {}, likeFiltersStatus = {}, fieldToFilterdValuesMap = {} } = this.state;
    const likeState =
      type === OPERATORS_KEYS.LIKE
        ? {
            likeFiltersStatus: {
              ...likeFiltersStatus,
              [field]: +!likeFiltersStatus[field],
            },
            fieldToFilterdValuesMap: {
              ...fieldToFilterdValuesMap,
              [field]: [],
            },
            excludedFiltersStatusMap: {
              ...excludedFiltersStatusMap,
              [field]: 0,
            },
          }
        : {};
    this.setState({
      excludedFiltersStatusMap: convertStringifyMapToObj(
        formatExcludeFilterMap(convertObjToMap(excludedFiltersStatusMap), field, subField),
      ),
      isApplyFiltersButtonDisabled: false,
      ...likeState,
    });
  };

  handleSaveDashboardPanelButtonClick(isSaveOnExistingDb = false) {
    if (!this.isChangesAppliedBeforeSave()) {
      return;
    }
    if (isSaveOnExistingDb) {
      this.setState({ isOverwriteExistingCustomDbModalOpen: true });
    } else {
      this.setState({ isNewCustomDashboardModalOpen: true });
    }
  }

  barClickHandler = (date, dataKey) => {
    const { currDataState } = this.state;
    if (!this.isDiveAllowed(dataKey)) {
      return;
    }
    this.dataStates[currDataState].handleDive(date, dataKey);
  };

  getCurrentGroupByViewpoint = (groupBy, secondaryGroupBy) => {
    const viewpoints = [
      groupBy && groupBy.startsWith(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS) ? groupBy : '',
      secondaryGroupBy && secondaryGroupBy.startsWith(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS)
        ? secondaryGroupBy
        : '',
    ].filter(Boolean);
    return viewpoints.map((vp) => vp.replace(`${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: `, ''));
  };

  moveWheresToFilters = () => {
    const { dataKeyToWhereParamsMap, fieldToFilterdValuesMap, excludedFiltersStatusMap } = this.state;
    Object.keys(dataKeyToWhereParamsMap).forEach((field) => {
      let fieldFormatted = field;
      let where = dataKeyToWhereParamsMap[field];
      if (field === AwsCommonFields.LINKED_ACCOUNT_ID && where.includes('(')) {
        // get linked account id from where
        where = (where.match(/.*\((.+)\)/) || [])[1] || where;
      }
      if (where === 'no tag') {
        where = 'no_tag';
      }
      if (field.startsWith(AwsCommonFields.CUSTOM_TAGS) || field.startsWith(AwsCommonFields.ACCOUNT_TAGS)) {
        let tagKey;
        [fieldFormatted, tagKey] = field.split(':');
        where = `${tagKey}: ${where}`;
      }

      fieldToFilterdValuesMap[fieldFormatted] = [where];
      excludedFiltersStatusMap[fieldFormatted] = FilterTypes.INCLUDE;
    });
    return { fieldToFilterdValuesMap, excludedFiltersStatusMap };
  };

  handleDateChange = ({ startDate, endDate }) => {
    const { startDate: start, endDate: end } = this.state;
    this.setState({
      startDate: startDate || start,
      endDate: endDate || end,
      isApplyFiltersButtonDisabled: false,
    });
  };

  handleApplyFiltersButtonClick = () => {
    const { usersStore } = this.props;
    const {
      newFilterBarGroupBy,
      newFilterBarGroupBySecondary,
      filterBarGroupBy,
      filterBarGroupBySecondary,
      currDataState,
    } = this.state;
    segmentEvent({ type: 'click-event', target: 'kubernetes_filter_apply' }, usersStore);
    let stateToUpdate = this.buildStateToUpdate();

    if (newFilterBarGroupBy || newFilterBarGroupBySecondary) {
      const { currDispUserCloudAccountType } = usersStore;
      const mapByCloudType = mapCloudBaseGroupByToCostUsageStateMap.get(currDispUserCloudAccountType);
      const preparedNewGroupBy = this.dataStates[currDataState].getPreparedNewGroupBy(
        newFilterBarGroupBy || filterBarGroupBy,
      );
      const newDataState =
        (newFilterBarGroupBySecondary || filterBarGroupBySecondary) !== AwsCommonFields.USAGE_DATE
          ? CostUsageStates.SECOND_GROUP_BY
          : mapByCloudType.get(preparedNewGroupBy);
      stateToUpdate = {
        ...stateToUpdate,
        currDataState: newDataState,
        selectedGroupByItem: null,
        currentGroupBy: newFilterBarGroupBy || filterBarGroupBy,
        ...this.moveWheresToFilters(),
        dataKeyToWhereParamsMap: {},
      };
      this.dataStates[currDataState].fetchDataByState(
        newDataState,
        newFilterBarGroupBy || filterBarGroupBy,
        newFilterBarGroupBySecondary || filterBarGroupBySecondary,
      );
    } else {
      this.handleDataStatesFilterChange();
    }
    this.setState({ ...stateToUpdate });
  };

  handleDataStatesFilterChange = () => {
    const {
      currentGroupBy,
      dataKeyToWhereParamsMap,
      startDate,
      endDate,
      selectedGranLevel,
      fieldToFilterdValuesMap,
      currCostType,
      SelectedUsageType,
      wasteCostAllocationChecked,
      diveDate,
      currDataState,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      filtersConfig,
      isNetUnblended,
      isShowAmortizeCost,
      isNetAmortize,
      isListUnitPrice,
      isNegotiatedSavings,
      k8SGranularity,
    } = this.state;
    this.dataStates[currDataState].handleFilterChange(
      currentGroupBy,
      dataKeyToWhereParamsMap,
      startDate,
      endDate,
      selectedGranLevel,
      {
        filtersMap: fieldToFilterdValuesMap,
        likedFiltersStatus: likeFiltersStatus,
        excludedFiltersStatusMap,
        filtersConfig,
      },
      currCostType,
      SelectedUsageType,
      ['actual', 'requirements'],
      isNetUnblended,
      isShowAmortizeCost,
      isNetAmortize,
      isListUnitPrice,
      isNegotiatedSavings,
      wasteCostAllocationChecked,
      diveDate,
      k8SGranularity || k8sGranLevel.NODES, // default is set to NODES
    );
  };

  handleResetToInitialState = () => {
    const { kubernetesStore } = this.props;
    kubernetesStore.setK8sGranularity(k8sGranLevel.NODES);
    this.setState({ ...this.baseInternalState, ...this.baseState, forceRefresh: true });
  };

  handleCostTypeChange = (selected) => {
    const { currCostType } = this.state;
    this.setState({
      currCostType: currCostType.includes(selected)
        ? currCostType.filter((c) => c !== selected)
        : [...currCostType, selected],
      isApplyFiltersButtonDisabled: false,
    });
  };

  handleWasteCostAllocationToggle = () => {
    this.setState((prevState) => ({
      wasteCostAllocationChecked: !prevState.wasteCostAllocationChecked,
      forceRefresh: true,
    }));
  };

  handleFilterChange = (filterType, selectedOptions) => {
    const { fieldToFilterdValuesMap } = this.state;
    let filteredValues = [];
    let selectedUsageOption = K8S_QUANTITY_TYPE_SELECT;
    if (Array.isArray(selectedOptions)) {
      filteredValues = selectedOptions.map(({ value }) => value);
    } else {
      selectedUsageOption = selectedOptions.label;
      filteredValues.push(selectedUsageOption);
    }
    this.setState({
      isApplyFiltersButtonDisabled: false,
      fieldToFilterdValuesMap: { ...fieldToFilterdValuesMap, [filterType]: filteredValues },
    });
  };

  handleGroupByChange = (groupByVal, isSecondaryGroupBy = false) => {
    const { usersStore } = this.props;
    const { newFilterBarGroupBySecondary, newFilterBarGroupBy } = this.state;
    segmentEvent({ type: 'click-event', target: `kubernetes_group_by_${groupByVal}` }, usersStore);
    const anotherGroupBy = isSecondaryGroupBy ? newFilterBarGroupBy : newFilterBarGroupBySecondary;
    this.setState({
      isApplyFiltersButtonDisabled: false,
      [isSecondaryGroupBy ? 'newFilterBarGroupBySecondary' : 'newFilterBarGroupBy']: groupByVal,
      [!isSecondaryGroupBy ? 'newFilterBarGroupBySecondary' : 'newFilterBarGroupBy']: anotherGroupBy,
    });
  };

  handleRenderChart = (chartId, baseData, filteredKeys, favourites, isLineChart) => {
    const { kubernetesStore, usageStore } = this.props;
    const { currDataState } = this.state;
    const chartRenderFunc = this.chartDisplayMap.get(currDataState) || this.renderChartOrTable;
    const { isCostAndUsageLoading, podGeneralData = {} } = kubernetesStore.kubernetesModel;
    const { modelIsLoading } = usageStore.customDbSubStore.customDashboardModel;
    if (isCostAndUsageLoading || modelIsLoading || (currDataState === CostUsageStates.POD_DETAILS && !podGeneralData)) {
      return (
        <div className="cue-loading-page">
          <Spinner position="relative" />
        </div>
      );
    }
    if (chartRenderFunc) {
      return chartRenderFunc(chartId, baseData, filteredKeys, favourites, isLineChart);
    }
    return null;
  };

  handleChangeGranLevel = (granLevel) => {
    this.setState({
      selectedGranLevel: granLevel,
      isApplyFiltersButtonDisabled: false,
    });
  };

  handleUpdateCostUsage = (costValue, usageValue, costTypeMode) => {
    const { forceRefresh } = this.state;
    const displayMetricByCostType = {
      [CostTypeModes.COST]: DisplayMetricTypes.COST,
      [CostTypeModes.USAGE]: DisplayMetricTypes.USAGE,
    };
    this.setState({
      ...(costTypeMode !== CostTypeModes.USAGE ? costTypeToFlags(costValue) : AMORTIZE_COST_DEFAULT_STATE),
      ...convertModeToUsageRateFlags(costTypeMode),
      displayMetricTypes: displayMetricByCostType[costTypeMode],
      forceRefresh: !forceRefresh,
      SelectedUsageType: costTypeMode !== CostTypeModes.COST ? usageValue : K8S_QUANTITY_TYPE_SELECT,
      selectedGoal: null,
    });
  };

  toggleChartOrTable = (value) => {
    this.setState({
      ...convertFlagToChartTypes(value),
      isCumulative: false,
    });
  };

  handleOverwriteExistingCustomDbPanel = async (action) => {
    const { usageStore } = this.props;
    this.setState({ isOverwriteExistingCustomDbModalOpen: false });
    if (action === 'delete') {
      const params = this.prepareParamsForOverwriteExistingCustomDbPanel();
      await usageStore.customDbSubStore.overwriteExistingCustomDashboardPanel(params);
    }
  };

  toggleFilters = (e) => {
    e.preventDefault();
    const { isFiltersOpen } = this.state;
    this.setState({ isFiltersOpen: !isFiltersOpen });
  };

  renderPodDetailsChart = (chartId, dataForCharts) => {
    const { kubernetesStore } = this.props;
    const { podGeneralData = {} } = kubernetesStore.kubernetesModel;
    const generalDataTotalCost = podGeneralData ? parseFloat(podGeneralData.totalCost) : 0;
    if (!podGeneralData) {
      return null;
    }
    return (
      <Col xs={12}>
        <div style={{ padding: '0px 15px' }}>
          <div>
            <span style={{ marginInlineEnd: '5px' }}>
              <b>Pod Name:</b>
            </span>
            <span>
              <b>{podGeneralData.podName || ''}</b>
            </span>
          </div>
          <div>
            <span style={{ marginInlineEnd: '5px' }}>Date:</span>
            <span>{moment(podGeneralData.usageDate).format('YYYY-MM-DD')}</span>
          </div>
          <div>
            <span style={{ marginInlineEnd: '5px' }}>Instance Type:</span>
            <span>{podGeneralData.instanceType || ''}</span>
          </div>
          {podGeneralData.namespaceName && (
            <div>
              <span style={{ marginInlineEnd: '5px' }}>Namespace Name:</span>
              <span>{podGeneralData.namespaceName || ''}</span>
            </div>
          )}
          <div>
            <span style={{ marginInlineEnd: '5px' }}>Resource Id:</span>
            <span>{podGeneralData.resourceId || ''}</span>
          </div>
          <div>
            <span style={{ marginInlineEnd: '5px' }}>Total Pod Cost:</span>
            <span>${(generalDataTotalCost || 0).toFixed(isDecimalNeeded(generalDataTotalCost || 0, 3) ? 3 : 0)}</span>
          </div>
          <div>
            <span style={{ marginInlineEnd: '5px' }}>Total Running Hours:</span>
            <span>
              {podGeneralData.runningHours > 0 && podGeneralData.runningHours < 1
                ? 'Less then 1 hour'
                : (podGeneralData.runningHours || 0).toFixed(
                    isDecimalNeeded((podGeneralData || {}).runningHours || 0, 3) ? 3 : 0,
                  )}
            </span>
          </div>
        </div>
        {this.renderPodDetailsTable(dataForCharts)}
      </Col>
    );
  };

  renderPodDetailsTable = (baseData) => {
    const { numStrAbriviaionByDisplayMetric } = this.props;
    if (!baseData || !baseData.length) {
      return this.renderNoDataMessage();
    }
    const columns = POD_DETAILS_TABLE_COLUMNS.map((column) => {
      if (column.formatNumValue) {
        column.getCellValue = (row) => getCellDisplayValue(row, column.name, numStrAbriviaionByDisplayMetric);
      }
      return column;
    });

    return (
      <DevExpSearchAndVisibilityTable
        data={baseData}
        isDownloadAsCsv={false}
        columns={columns}
        costColumnsArray={['cost', 'costWaste', 'totalCost']}
        currenciesColumns={['cost', 'costWaste']}
        tableColumnExtensions={POD_DETAILS_TABLE_COLUMNS_EXTENSIONS}
        columnWidths={POD_DETAILS_TABLE_COLUMNS_WIDTHS}
        defaultSorting={[]}
        isResize
        isVisibility={false}
        numCostDecimal={3}
      />
    );
  };

  renderChartOrTable = (chartId, baseData = []) => {
    const { usersStore, kubernetesStore } = this.props;
    const {
      currDataState,
      isInitialDataKeyFilterLoad,
      isDisplayTable,
      filteredKeys,
      favourites,
      isLineChart,
      changedTableColumnWidth,
    } = this.state;
    const { modifiedDailyBalances, tableModifiedDailyBalance } = this.prepareMonthlyData(baseData);
    if (kubernetesStore.kubernetesModel.dataTooBigErrorMessage) {
      const textArr = kubernetesStore.kubernetesModel.dataTooBigErrorMessage.split('.');
      return this.renderDataTooBigMessage(textArr);
    }
    if (!modifiedDailyBalances || !modifiedDailyBalances.length) {
      return this.renderNoDataMessage();
    }
    const dataKeys = prepareDataKeys(modifiedDailyBalances, 'name', 'cost');
    const { columns, columnWidths, tableColumnExtensions, totalSummaryItems } = this.prepareTableHeaders(
      tableModifiedDailyBalance,
      changedTableColumnWidth,
    );
    const unclickableStates = mapUnclickableStatesByCloudTypeId.get(usersStore.currDispUserCloudAccountType);
    const isBarClickable = !unclickableStates.includes(currDataState);
    return !isDisplayTable
      ? this.renderChart(
          chartId,
          modifiedDailyBalances,
          favourites,
          dataKeys,
          isBarClickable,
          filteredKeys,
          isInitialDataKeyFilterLoad,
          isLineChart,
        )
      : this.renderTable(tableModifiedDailyBalance, columns, columnWidths, tableColumnExtensions, totalSummaryItems);
  };

  renderBreadCrumbs() {
    const { usersStore } = this.props;
    const { dataKeyToWhereParamsMap, diveDate } = this.state;
    if (isEmptyArray(Object.keys(dataKeyToWhereParamsMap))) {
      return null;
    }
    const entries = [...Object.entries(dataKeyToWhereParamsMap)];
    const mapByCloudType = mapCloudBaseGroupByToCostUsageStateMap.get(usersStore.currDispUserCloudAccountType);
    const getDataState = (key) => (key ? mapByCloudType.get(key) || mapByCloudType.get(key.split(':')[0]) : null);
    return entries.map(([key, value], index) => {
      const dataState = getDataState(key);
      const nextDataState = getDataState((entries[index + 1] || [])[0]);
      const bLabel = this.dataStates[dataState].getBreadCrumbLabel(value, key, diveDate);
      return (
        <>
          {index === 0 ? (
            <BreadcrumbItem
              className="btn-no-style text-nowrap"
              tag="button"
              onClick={(e) => this.backClickHandler(e, dataState)}
            >
              {key.includes('customtags')
                ? custDabrdHelpers.getBreadcrumbLabelDisplayName(
                    value,
                    dataState,
                    key,
                    usersStore.currDispUserCloudAccountType,
                  )
                : mapCostUsageStateToDisplayByCloudType(usersStore.currDispUserCloudAccountType, dataState)}
            </BreadcrumbItem>
          ) : null}
          {index + 1 === entries.length ? (
            <BreadcrumbItem className="text-nowrap" tag="u">
              <span>{bLabel}</span>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem
              className="btn-no-style text-nowrap"
              tag="button"
              onClick={(e) => this.backClickHandler(e, nextDataState)}
            >
              <span>{bLabel}</span>
            </BreadcrumbItem>
          )}
        </>
      );
    });
  }

  renderTable(rows, columns, columnWidths, tableColumnExtensions, totalSummaryItems) {
    const { displayMetricTypes } = this.state;
    if (rows.length > 0) {
      return (
        <CostTable
          rows={rows}
          columns={columns}
          renderedColumnWidths={columnWidths}
          tableColumnExtensions={tableColumnExtensions}
          displayedMetric={displayMetricTypes}
          handleTableColumnWidthChange={this.handleTableColumnWidthChange}
          totalSummaryItems={totalSummaryItems}
        />
      );
    }
    return null;
  }

  renderChart(chartId, data, favourites, dataKeys, isBarClickable, filteredKeys, isInitialDataKeyFilterLoad) {
    const { usersStore, kubernetesStore, CUEGoals } = this.props;
    const {
      displayMetricTypes,
      isEventsOpen,
      SelectedUsageType,
      isTrendLine,
      isCumulative,
      isAreaChart,
      currPeriodGranLevel,
      isPieChart,
      isLineChart,
      selectedGoal,
      isShowOthers,
      dataStartDate,
      dataEndDate,
      filterBarGroupBySecondary,
      isUsageChecked,
    } = this.state;
    const currentCloudType = usersStore.currDispUserCloudAccountType;
    return (
      <CostChart
        handleGoalEdit={() => this.setState({ saveGoalModalOpen: selectedGoal })}
        goalLine={this.getIsGoalsVisible() && selectedGoal ? getGoalById(CUEGoals, selectedGoal) : null}
        setKeysFilterHandler={this.setKeysFilterHandler}
        addKeysFilterHandler={this.addKeysFilterHandler}
        removeKeysFilterHandler={this.removeKeysFilterHandler}
        barClickHandler={this.barClickHandler}
        data={data}
        currentCloudType={currentCloudType}
        k8sMode={kubernetesStore.k8SGranularity}
        isSecondaryGroupBy={filterBarGroupBySecondary !== AwsCommonFields.USAGE_DATE}
        secondaryGroupBy={filterBarGroupBySecondary}
        onEventCollapse={(id) => {
          this.eventsSidebarRef.current.setExpanded(id);
          this.setState(() => ({
            isEventsOpen: true,
          }));
        }}
        isEventsOpen={isEventsOpen}
        startDate={dataStartDate}
        endDate={dataEndDate}
        isInitialDataKeyFilterLoad={isInitialDataKeyFilterLoad}
        mainLegendKeysFilterHandler={this.mainLegendKeysFilterHandler}
        filteredKeys={filteredKeys}
        dataKeys={dataKeys}
        favourites={favourites}
        barClickable={isBarClickable}
        chartId={chartId}
        displayedMetric={displayMetricTypes}
        selectedUsageType={SelectedUsageType}
        isTrendLine={isTrendLine}
        isCumulative={isCumulative}
        isAreaChart={isAreaChart}
        isOthersVisible
        isShowOthers={isShowOthers}
        isShowOthersChangeHandler={(value) => this.setState({ isShowOthers: value })}
        isLineChart={isLineChart}
        yAxisLabelTitle={this.getYAxisLabel(SelectedUsageType)}
        granLevel={currPeriodGranLevel}
        isPieChart={isPieChart}
        costValue={this.getAmortizeCostValue()}
        costTypeMode={this.getCostType()}
        isUsageChecked={isUsageChecked}
      />
    );
  }

  renderDataTooBigMessage = (textArr) => (
    <NoDataFoundComponent title={textArr[0]} bodyText={textArr[1]} height="455px" isBorderNeeded={false} />
  );

  renderNoDataMessage = () => <NoDataFoundComponent height="455px" isBorderNeeded={false} />;

  renderSecondGroupByChartOrTable = (chartId, baseData, filteredKeys, favourites) => {
    const { kubernetesStore } = this.props;
    const { isInitialDataKeyFilterLoad, changedTableColumnWidth, filterBarGroupBy, isDisplayTable } = this.state;
    const { modifiedDailyBalances, tableModifiedDailyBalance } = this.prepareSecondLevelGroupByData(baseData);
    const dataKeys = prepareSecondaryGroupDataKeys(modifiedDailyBalances, 'name', 'cost');
    const { columns, columnWidths, tableColumnExtensions, totalSummaryItems } = this.prepareTableHeaders(
      tableModifiedDailyBalance,
      changedTableColumnWidth,
      this.getFilterLabel(filterBarGroupBy),
    );
    const fullChartComponentsId = 'second-level-group-full-cost-and-usage-chart';
    if (kubernetesStore.kubernetesModel.dataTooBigErrorMessage) {
      const textArr = kubernetesStore.kubernetesModel.dataTooBigErrorMessage.split('.');
      return this.renderDataTooBigMessage(textArr);
    }
    if (!modifiedDailyBalances || !modifiedDailyBalances.length) {
      return this.renderNoDataMessage();
    }
    return !isDisplayTable
      ? this.renderChart(
          fullChartComponentsId,
          modifiedDailyBalances,
          favourites,
          dataKeys,
          false,
          filteredKeys,
          isInitialDataKeyFilterLoad,
        )
      : this.renderTable(tableModifiedDailyBalance, columns, columnWidths, tableColumnExtensions, totalSummaryItems);
  };

  mapFieldToFilterValuesByType = () => {
    const { getPageFilters, usersStore, kubernetesStore } = this.props;
    const filters = getPageFilters(PageNames.K8S_USAGE, usersStore.currDispUserCloudAccountType);
    if (usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.GCP) {
      return filters;
    }
    let fieldsToRemove = [];
    if (kubernetesStore.k8SGranularity === k8sGranLevel.NODES) {
      fieldsToRemove = k8sFiltersPodsFields;
      // todo - remove this when group by tags/labels will be supported when granularity is by nodes
      fieldsToRemove.push(GcpCommonFields.CUSTOM_TAGS);
    } else {
      fieldsToRemove = k8sFiltersNodesFields;
    }
    fieldsToRemove.forEach((field) => {
      if (filters.has(field)) {
        filters.delete(field);
      }
    });
    return filters;
  };

  formatUsageOptions = () => {
    const { k8SGranularityState } = this.state;
    const { SelectedUsageType, currDataState } = this.state;
    const { usersStore, kubernetesStore } = this.props;
    const getCostOptions = () => {
      // TODO: enable when backend tests K8S Azure focus
      // if (usersStore.getIsCurrentAccountAzureFocus) {
      //     return [AZURE_FOCUS_COST_TYPES_ITEMS.AMORTIZED, AZURE_FOCUS_COST_TYPES_ITEMS.UNBLENDED];
      // }
      return usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && !usersStore.isCurrentUserSharedReCustomer
        ? AmortizeCostTypesK8SItems
        : undefined;
    };
    const usageOptions =
      currDataState === CostUsageStates.POD_DETAILS
        ? null
        : [
            K8S_USAGE_TYPES.CPU,
            K8S_USAGE_TYPES.MEMORY,
            K8S_USAGE_TYPES.QUANTITY_CLUSTERS,
            K8S_USAGE_TYPES.QUANTITY_NAMESPACES,
            K8S_USAGE_TYPES.QUANTITY_NODES,
            K8S_USAGE_TYPES.QUANTITY_PODS,
          ];
    if (usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP) {
      let fieldsToRemove = [];
      if (kubernetesStore.k8SGranularity === k8sGranLevel.NODES) {
        fieldsToRemove = k8sUsagePodsFields;
        // todo - remove this when group by tags/labels will be supported when granularity is by nodes
        fieldsToRemove.push(GcpCommonFields.CUSTOM_TAGS);
      } else {
        fieldsToRemove = k8sUsageNodesFields;
      }
      if (k8SGranularityState) {
        if (fieldsToRemove.some((field) => field === SelectedUsageType)) {
          this.setState({ SelectedUsageType: DisplayMetricTypes.USAGE, isUsageChecked: false });
        }
      }
      let removeIndex = -1;
      fieldsToRemove.forEach((field) => {
        removeIndex = usageOptions?.indexOf(field);
        if (removeIndex > -1) {
          usageOptions.splice(removeIndex, 1);
        }
      });
    }

    return {
      usageOptions: usageOptions ? usageOptions.map((value) => ({ name: value, value })) : null,
      costOptions: getCostOptions(),
      costReadOnlyOptions: getCostReadOnlyOptions({
        currDispUserCloudAccountType: usersStore.currDispUserCloudAccountType,
        isFocus: usersStore.getIsCurrentAccountAzureFocus,
        isAzureUnblended: usersStore.getIsCurrentAccountAzureUnblended,
        isCurrentUserSharedReCustomer: usersStore.isCurrentUserSharedReCustomer,
      }),
      costValue: this.getAmortizeCostValue(),
      usageValue: SelectedUsageType,
      isRateDisabled: true,
      costTypeMode: this.getCostType(),
      onChange: this.handleUpdateCostUsage,
    };
  };

  render() {
    const {
      usersStore,
      invoiceStore,
      usageStore,
      kubernetesStore,
      filtersValuesMap,
      getK8sCustomTagsKeys,
      getCustomTagsKeys,
      getAccountTagsKeys,
      getK8SBusinessMappingViewpoints,
      CUEGoals,
    } = this.props;
    const {
      isPieChart,
      isEventsOpen,
      startDate,
      endDate,
      filterBarGroupBy,
      currDataState,
      isUsageChecked,
      SelectedUsageType,
      isNewCustomDashboardModalOpen,
      filteredKeys,
      favourites,
      isFiltersOpen,
      isDisplayTable,
      newFilterBarGroupBy,
      newFilterBarGroupBySecondary,
      filterBarGroupBySecondary,
      currPeriodGranLevel,
      isCumulative,
      isTrendLine,
      selectedGoal,
      isAreaChart,
      isOverwriteExistingCustomDbModalOpen,
      fieldToFilterdValuesMap,
      currCostType,
      redirectParams,
      selectedGranLevel,
      isApplyFiltersButtonDisabled,
      excludedFiltersStatusMap,
      saveGoalModalOpen,
      isShowOthers,
      wasteCostAllocationChecked,
      changeGoalModalOpen,
      likeFiltersStatus,
      dataStartDate,
      dataEndDate,
    } = this.state;
    if (!usersStore.currUserInitDone || typeof usersStore.currDispUserCloudAccountType !== 'number') {
      return (
        <Panel>
          <div style={{ height: 400 }}>
            <Spinner />
          </div>
        </Panel>
      );
    }
    if (typeof currDataState !== 'number') {
      return <Spinner />;
    }
    const fieldToFieldDistincValuesMap = this.mapFieldToFilterValuesByType();
    const arrCustomTagsKeys = getK8sCustomTagsKeys();
    const arrViewsNames = filtersValuesMap.get(AwsCommonFields.VIEWS_CUSTOM_TAGS);
    const arrWorkloadTypes = filtersValuesMap.get(AwsCommonFields.WORKLOAD_TYPE);
    const arrCloudTagsKeys = getCustomTagsKeys();
    const arrAccountTagsKeys = getAccountTagsKeys();
    const arrBusinessMappingViewpoints = getK8SBusinessMappingViewpoints(
      kubernetesStore.k8SGranularity === k8sGranLevel.PODS,
    );
    const { currDispUserCloudAccountType, isHasPermission } = usersStore;
    const fullChartComponentsId = 'full-cost-and-usage-chart';
    const usageLovItems = this.dataStates[currDataState].getSupportedQuantityTypes();
    usageLovItems.splice(0, 1);
    const baseData = this.dataStates[currDataState].getData();
    const dataForCharts = baseData && baseData.length ? this.prepareDataWithSizeLimit(baseData, 1000) : [];
    const selectedCostItem = Array.isArray(currCostType) ? [...currCostType] : [currCostType];
    const { modifiedDailyBalances } = this.prepareMonthlyData(baseData);
    const { existingDashboardsNamesAndIds } = usageStore.customDbSubStore.customDashboardModel;
    const { isLineChart } = this.state;
    const showEventsBar = isEventsOpen && !isDisplayTable && !isPieChart;
    const infoColWidth = 12 - +isFiltersOpen * 3 - +showEventsBar * 3;
    const filtersCount = countFilters(fieldToFilterdValuesMap);
    const currUserMultiCloud = currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI;
    const isPodDetailsView = currDataState === CostUsageStates.POD_DETAILS;
    const showViewOptions = !isPieChart && filterBarGroupBySecondary === 'usagedate' && !isPodDetailsView;
    const { isFromCustomDashboard, id, itemName } = redirectParams || {};
    return (
      <Container style={{ padding: '0px' }} id="k8s-full-cost-and-usage-chart">
        <Card>
          <CardBody>
            <div className="d-flex">
              <div className="d-flex" style={{ fontSize: '0.8 rem', marginLeft: '0.7vw' }}>
                <FilterBarK8s
                  topLevelGroupBy={newFilterBarGroupBy || filterBarGroupBy}
                  topLevelSecondaryGroupBy={newFilterBarGroupBySecondary || filterBarGroupBySecondary}
                  handleGroupByChange={this.handleGroupByChange}
                  currentCloudType={currDispUserCloudAccountType}
                  arrCustomTagsKeys={arrCustomTagsKeys}
                  arrViewsNames={arrViewsNames}
                  arrWorkloadTypes={arrWorkloadTypes}
                  arrCloudTagsKeys={arrCloudTagsKeys}
                  arrBusinessMappingViewpoints={arrBusinessMappingViewpoints}
                  arrAccountTagsKeys={arrAccountTagsKeys}
                  usersStore={usersStore}
                  kubernetesStore={kubernetesStore}
                  isAllAccounts={!isEmptyArray(fieldToFieldDistincValuesMap.get(AwsCommonFields.PAYER_ACCOUNT))}
                />
                <div className="main-filter-bar-separator" />
                <div className="d-flex">
                  <FiltersSidebar
                    isDateRangeError={false}
                    startDate={this.defaultDates().startDate}
                    endDate={this.defaultDates().endDate}
                    handleDateChange={this.handleDateChange}
                    renderDatePickerFilter
                    currPeriodGranLevel={selectedGranLevel}
                    handleChangeGranLevel={this.handleChangeGranLevel}
                    renderGranPeriodButton={(newFilterBarGroupBySecondary || filterBarGroupBySecondary) === 'usagedate'}
                    handleFilterChange={this.handleFilterChange}
                    renderFilters
                    handleToggleFilters={this.toggleFilters}
                    isFiltersOpen={isFiltersOpen}
                    isFilters={filtersCount > 0}
                    filtersCount={filtersCount}
                    isShowQuarterly
                    isShowYearly
                  />
                  <Col>
                    <Button
                      text="Apply"
                      overrideStyles={{ width: 140, height: 35 }}
                      onClick={this.handleApplyFiltersButtonClick}
                      disabled={isApplyFiltersButtonDisabled}
                    />
                  </Col>
                </div>
              </div>
            </div>
            <div className="cue-separation-line" />
            <Row style={{ marginTop: '3vh' }}>
              <Col style={{ display: isFiltersOpen ? '' : 'none', paddingInline: 0 }} xs={3}>
                <FiltersSidebarContainer
                  isOpen={isFiltersOpen}
                  selectedOptionsMap={formatFiltersMapToSelectOptionsMap(
                    convertObjToMap(fieldToFilterdValuesMap),
                    fieldToFieldDistincValuesMap,
                  )}
                  likeOperator
                  likeFiltersStatus={likeFiltersStatus}
                  likeCaseConfig={{
                    get: (field) => (this.state.filtersConfig || {})[field]?.caseSensitive,
                    set: (field, value) => {
                      this.setState({
                        isApplyFiltersButtonDisabled: false,
                        filtersConfig: {
                          ...this.state.filtersConfig,
                          [field]: { ...this.state.filtersConfig[field], caseSensitive: +value },
                        },
                      });
                    },
                  }}
                  fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
                  fieldToFieldDistincValuesProps={this.getFiltersConfig()}
                  currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
                  excludedFiltersStatusMap={convertObjToMap(excludedFiltersStatusMap)}
                  handleChangeFilterType={this.handleChangeFilterType}
                  handleFilterChange={this.handleFilterChange}
                  isForK8S
                  selectedK8SCostType={selectedCostItem}
                  k8sCostTypeItems={this.getCostTypesByCloudTypeId()}
                  handleK8SCostTypeChecked={this.handleCostTypeChange}
                  invoiceStore={invoiceStore}
                  className="k8s"
                />
              </Col>
              <Col xs={infoColWidth}>
                <div className="d-flex justify-content-between align-items-start">
                  <Breadcrumb className="mb-0">{this.renderBreadCrumbs()}</Breadcrumb>
                  <div className="align-items-end d-flex">
                    <ChartButtonsMenu
                      costUsageHandler={this.formatUsageOptions()}
                      resetHandler={this.handleResetToInitialState}
                      chartTypeHandler={{
                        onChange: this.toggleChartOrTable,
                        disabled: isPodDetailsView,
                        value: convertChartTypesFlag({
                          isDisplayTable,
                          isAreaChart,
                          isPieChart,
                          isLineChart,
                        }),
                      }}
                      viewMenuDisabled={!showViewOptions}
                      cumulativeHandler={{
                        value: isCumulative,
                        onChange: (value) => {
                          this.setState({ isCumulative: value, selectedGoal: null });
                        },
                      }}
                      trendLineHandler={
                        !isDisplayTable && {
                          value: isTrendLine,
                          onChange: (value) => {
                            this.setState({ isTrendLine: value });
                          },
                        }
                      }
                      eventsHandler={
                        !isDisplayTable && {
                          value: isEventsOpen,
                          onChange: (value) => {
                            this.setState({ isEventsOpen: value });
                          },
                        }
                      }
                      goalsHandler={
                        currUserMultiCloud || !this.getIsGoalsVisible()
                          ? null
                          : {
                              value: selectedGoal,
                              onChange: (value) => {
                                if (selectedGoal && value) {
                                  this.setState({ changeGoalModalOpen: value });
                                  return;
                                }
                                this.setState({ selectedGoal: value || null });
                              },
                              options: getK8SGoals(CUEGoals),
                            }
                      }
                      saveGoalHandler={
                        currUserMultiCloud || !this.getIsGoalsVisible()
                          ? null
                          : {
                              onClick: (goalId) => {
                                this.setState({ saveGoalModalOpen: goalId || true });
                              },
                              options: getK8SGoals(CUEGoals),
                              disabled:
                                !isHasPermission(OrganizationEntityCategory.CostAllocation, CategoryAction.Create) ||
                                isPodDetailsView,
                            }
                      }
                      saveDashboardHandler={{
                        onClick: () => this.handleSaveDashboardPanelButtonClick(false),
                        disabled:
                          !isHasPermission(HierarchicalEntityCategory.Dashboards, CategoryAction.Create) ||
                          isPodDetailsView,
                      }}
                      overwriteDashboardHandler={
                        isFromCustomDashboard && !!id && !currUserMultiCloud
                          ? {
                              onClick: () => this.handleSaveDashboardPanelButtonClick(true),
                              disabled: !isHasPermission(HierarchicalEntityCategory.Dashboards, Action.Update),
                            }
                          : null
                      }
                      exportToCSVHandler={{ onClick: this.prepareExportTableToCsv }}
                      wasteCostAllocationHandler={
                        !isUsageChecked
                          ? {
                              onChange: this.handleWasteCostAllocationToggle,
                              value: wasteCostAllocationChecked,
                            }
                          : null
                      }
                    />
                  </div>
                </div>
                <Row>
                  {this.handleRenderChart(fullChartComponentsId, dataForCharts, filteredKeys, favourites, isLineChart)}
                </Row>
              </Col>
              <Col style={{ display: showEventsBar ? '' : 'none' }} xs={3} md={3} lg={3} xl={3}>
                <EventsSideBar
                  ref={this.eventsSidebarRef}
                  granLevel={currPeriodGranLevel}
                  startDate={dataStartDate}
                  endDate={dataEndDate}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <NewCustomDashboardPanelModal
          modalIsOpen={isNewCustomDashboardModalOpen}
          getCurrentCauParams={this.getCurrK8sParamsForCustomDashboard}
          onClose={() => this.setState({ isNewCustomDashboardModalOpen: false })}
          customDashboardStore={usageStore.customDbSubStore}
          existingDashboardsNamesAndIds={existingDashboardsNamesAndIds}
          helpers={custDabrdHelpers}
          type={this.getCustomDashboardPanelType(isLineChart)}
          state={this.state}
          arrBreadCrumbs={{}}
          usageStore={usageStore}
        />
        <DeleteWarningModal
          modalTitle="Overwrite Warning"
          warningMessage="Be advised that you are about to overwrite this chart data on the existing panel"
          deletedItemName={itemName}
          executeButtonTitle="Overwrite & Save"
          isOpen={isOverwriteExistingCustomDbModalOpen}
          handleDelete={this.handleOverwriteExistingCustomDbPanel}
        />
        {changeGoalModalOpen && (
          <CustomModal
            title="Change Goal"
            open
            onClose={(value) => this.setState({ changeGoalModalOpen: !!value })}
            onSave={() => this.setState({ selectedGoal: changeGoalModalOpen })}
          >
            By choosing a different goal, the view will change accordingly.
            <br />
            Do you want continue this action?
          </CustomModal>
        )}
        {!!saveGoalModalOpen && (
          <SaveGoalModal
            cloudType={usersStore.currDispUserCloudAccountType}
            granularity={currPeriodGranLevel}
            cueStartDate={startDate}
            cueEndDate={endDate}
            avgTarget={modifiedDailyBalancesToAverage(modifiedDailyBalances, filteredKeys, isShowOthers)}
            usageStore={usageStore}
            item={typeof saveGoalModalOpen === 'string' ? getGoalById(CUEGoals, saveGoalModalOpen) : null}
            costTypeMode={this.getCostType()}
            usageSelected={SelectedUsageType}
            costSelected={this.getAmortizeCostValue()}
            onGoalSaved={(goalId) => {
              if (goalId === selectedGoal) {
                this.updateStateWhenGoalSelected();
              } else {
                this.setState({ selectedGoal: goalId });
              }
            }}
            onClose={() => this.setState({ saveGoalModalOpen: false })}
            explorerState={this.getStateToSaveInGoal()}
          />
        )}
      </Container>
    );
  }
}

ChartsPanel.propTypes = {
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  kubernetesStore: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  getPageFilters: PropTypes.func.isRequired,
  filtersValuesMap: PropTypes.object.isRequired,
  isPpApplied: PropTypes.bool.isRequired,
  CUEGoals: PropTypes.array.isRequired,
  setUrlParams: PropTypes.func.isRequired,
  urlState: PropTypes.object.isRequired,
  urlParams: PropTypes.object.isRequired,
};

const ObserverChartsPanel = withUrlParamsComponentWrapper(
  withCUEGoalsContextProvider(withInvoiceFiltersContextConsumer(withUserSettingsConsumer(observer(ChartsPanel)))),
);
export default ObserverChartsPanel;
