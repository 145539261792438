import React from 'react';
import PropType from 'prop-types';
import { ReactComponent as NoCostCenters } from 'users/containers/Organization/CostCenters/img/no-cost-centers.svg';

import styles from './CostCentersNoData.module.scss';
import EmptyState from './EmptyState';

const SUB_TEXTS = [`Use Cost Centers to allocate your cost according to the business unit hierarchy that fits your organization.`,
`A Cost Center may contain an entire Payer account, or specific linked accounts.`,
`Furthermore, a Cost Center may contain accounts from different cloud providers.`];
const NO_COST_CENTER_SUB_TEXTS=['Refine your search to find the required Cost Center']
const NOT_DEFINED = 'Cost Centers were not defined yet';
const NO_COST_CENTER = 'Could not find the Cost Center';

const CostCentersNoData = ({ colSpan, noCostCentersInOrg }) => {
  return (
    <td colSpan={colSpan} className="border-bottom-0 border-start-0 border-end-0 border-top-0">
      <EmptyState
        mainText={noCostCentersInOrg ? NOT_DEFINED : NO_COST_CENTER}
        subTexts={noCostCentersInOrg ? SUB_TEXTS : NO_COST_CENTER_SUB_TEXTS}
        backgroudIcon={<NoCostCenters className={styles.svgImage} />}
      ></EmptyState>
    </td>
  );
};

CostCentersNoData.propTypes = {
  colSpan: PropType.number,
  noCostCentersInOrg: PropType.bool,
};

export default CostCentersNoData;
