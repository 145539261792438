import React from 'react';
import moment from 'moment/moment.js';
import PropTypes from 'prop-types';
import { palette } from 'shared/constants/colorsConstants.js';

const CustomTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={7} dy={12} textAnchor="middle" fill={palette.gray[400]}>
        {moment(payload.value).format('MMM')}
      </text>
    </g>
  );
};
CustomTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
};
export default CustomTick;
