import React from 'react';
import classNames from 'classnames';
import MtdCost from 'app/containers/MainDashboard/panels/MtdCost.jsx';
import PreviousMonthCost from 'app/containers/MainDashboard/panels/PreviousMonthCost.jsx';
import AnnualSavings from 'app/containers/MainDashboard/panels/AnnualSavings.jsx';
import OpenRecommendations from 'app/containers/MainDashboard/panels/OpenRecommendations.jsx';
import ForecastedCost from 'app/containers/MainDashboard/panels/ForecastedCost.jsx';
import LinkedAccountTotal from 'app/containers/MainDashboard/panels/LinkedAccountTotal.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import styles from './costPanelWrapper.module.scss';
import mainStyles from '../mainDashboardCommon.module.scss';

const CostPanelsWrapper = () => {
  const { usersStore } = useRootStore();

  return (
    <div className={classNames(styles.costPanelsWrapper, mainStyles.fullWidth)}>
      <MtdCost />
      <PreviousMonthCost />
      {usersStore.isCurrentAccountSingle && <ForecastedCost />}
      {usersStore.isCurrentAccountSingle && <AnnualSavings />}
      <div className={styles.dataPanelsWrapper}>
        {usersStore.isCurrentAccountSingle && <OpenRecommendations />}
        <LinkedAccountTotal />
      </div>
    </div>
  );
};

export default CostPanelsWrapper;
