import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import Spinner from 'shared/components/andtComponents/Spinner';
import { RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { Container } from 'reactstrap';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import Button from 'shared/components/andtComponents/Button';
import { useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import ChannelDetails from './ChannelDetails';
import AddSlackChannelModal from './AddSlackChannelModal';
import AddJiraChannelModal from './AddJiraChannelModal';
import AddJiraDataCenterChannelModal from './AddJiraDataCenterChannelModal';
import AddMSTeamsChannelModal from './AddMSTeamsChannelModal';
import AddServiceNowChannelModal from './AddServiceNowChannelModal';
import { shouldIncludeRecipient, titleByChannelType, iconByChannelType, groupedChannelTypes } from './channelsHelpers';
import { ReactComponent as ChannelsNotFoundImg } from './assets/channels-not-found.svg';
import { ReactComponent as ChannelsEmptyState } from './assets/channels-empty-state.svg';
import styles from './channels.module.scss';
import DeleteChannelModal from './DeleteChannelModal';

const NoChannelsFound = () => (
  <div className={styles.emptyChannels}>
    <ChannelsNotFoundImg />
    <h3>There are no channels found</h3>
    <p>Change your search filter to present data</p>
  </div>
);

const CreateChannelButton = ({ onClick, isGhost }) => {
  const [isCreateChannelsSelectOpen, setIsChannelsSelectOpen] = useState(false);
  return (
    <div className={styles.createChannelButtonContainer}>
      <Button
        text="Create Channel"
        onClick={() => setIsChannelsSelectOpen(true)}
        isGhost={isGhost}
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        overrideStyles={{ width: '168px' }}
      />
      {isCreateChannelsSelectOpen && (
        <>
          <div className={styles.createChannelSelect}>
            {Object.entries(titleByChannelType).map(([channelType, title]) => (
              <Button
                key={channelType}
                text={title}
                onClick={() => {
                  onClick({ channelType });
                  setIsChannelsSelectOpen(false);
                }}
                isSecondary
                icon={() => iconByChannelType[channelType]}
                className={{ buttonContent: styles.createChannelButtonContent }}
              />
            ))}
          </div>
          <div className={styles.closeWrapper} onClick={() => setIsChannelsSelectOpen(false)} />
        </>
      )}
    </div>
  );
};

export default function Channels() {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [channelToAdd, setChannelToAdd] = useState(null);
  const { searchText } = useTableHeaderContext();

  const { NewTableWrapper, NewTableRow } = useTable();
  
  const { getChannels, deleteChannel, deleteBulkChannels } = useChannels();

  const { mutateAsync: handleDeleteChannel, isLoading: isDeletingChannel } = deleteChannel();
  const { mutateAsync: handleDeleteBulkChannels, isLoading: isDeletingBulkChannels } = deleteBulkChannels();
  const { data: channels = [], isLoading: isLoadingChannels } = getChannels();

  const [channelTypeToDelete, setChannelTypeToDelete] = useState(null);
  const [channelsToDelete, setChannelsToDelete] = useState([]);

  useEffect(() => {
    if (searchText) {
      setExpandedRowIds(channels.map((_, i) => i)); // Expand all channels when searching
    } else if (channels?.length === 1) {
      setExpandedRowIds([0]); // Expand channel if there is only one channel
    } else {
      setExpandedRowIds([]);
    }
  }, [channels?.length, searchText]);

  if (isLoadingChannels) {
    return <Spinner />;
  }

  const channelComponent = (channel) => (
    <div className={styles.channelRow}>
      {iconByChannelType[channel.channelType]}
      <h4 className={styles.title}>
        {titleByChannelType[channel.channelType]}
        {channel.name && `: ${channel.name}`}
        {channel.recipients?.length >= 0 && (
          <span>
            &nbsp;(
            {searchText &&
              `${channel.recipients.filter((r) => shouldIncludeRecipient(searchText, r, channel)).length}/`}
            {channel.recipients.length})
          </span>
        )}
      </h4>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setChannelToAdd(channel);
        }}
        text="Add Channel"
        isTextButton
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
      />
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setChannelTypeToDelete(channel.channelType);
          setChannelsToDelete([channel.channelId]);
        }}
        isTextButton
        icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
        isLoading={isDeletingChannel || isDeletingBulkChannels}
      />
    </div>
  );
  const groupedChannelsComponent = (channels) => (
    <div className={styles.channelRow}>
      {iconByChannelType[channels[0]?.channelType]}
      <h4 className={styles.title}>
        {titleByChannelType[channels[0]?.channelType]}
        {channels.length >= 0 && (
          <span>
            &nbsp;(
            {searchText && `${channels.filter((c) => shouldIncludeRecipient(searchText, null, c)).length}/`}
            {channels.length})
          </span>
        )}
      </h4>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setChannelToAdd({ channelType: channels[0]?.channelType });
        }}
        text="Add Channel"
        isTextButton
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
      />
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setChannelTypeToDelete(channels[0]?.channelType);
          setChannelsToDelete(channels.map((c) => c.channelId));
        }}
        isTextButton
        icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
        isLoading={isDeletingChannel || isDeletingBulkChannels}
      />
    </div>
  );

  const addChannelModal = (channel) => {
    const addChannelModalByType = {
      SLACK: (
        <AddSlackChannelModal
          selectedChannel={channelToAdd}
          channels={channels.filter((c) => c.channelType === 'SLACK')}
          onChannelSelect={setChannelToAdd}
          onClose={() => {
            setChannelToAdd(null);
          }}
        />
      ),
      JIRA_CLOUD: (
        <AddJiraChannelModal
          selectedChannel={channelToAdd}
          channels={channels.filter((c) => c.channelType === 'JIRA_CLOUD')}
          onChannelSelect={setChannelToAdd}
          onClose={() => {
            setChannelToAdd(null);
          }}
        />
      ),
      JIRA_DATACENTER: (
        <AddJiraDataCenterChannelModal
          selectedChannel={channelToAdd}
          channels={channels.filter((c) => c.channelType === 'JIRA_DATACENTER')}
          onChannelSelect={setChannelToAdd}
          onClose={() => {
            setChannelToAdd(null);
          }}
        />
      ),
      MS_TEAMS: (
        <AddMSTeamsChannelModal
          selectedChannel={channelToAdd}
          channels={channels.filter((c) => c.channelType === 'MS_TEAMS')}
          onChannelSelect={setChannelToAdd}
          onClose={() => {
            setChannelToAdd(null);
          }}
        />
      ),
      SERVICENOW: (
        <AddServiceNowChannelModal
          selectedChannel={channelToAdd}
          channels={channels.filter((c) => c.channelType === 'SERVICENOW')}
          onChannelSelect={setChannelToAdd}
          onClose={() => {
            setChannelToAdd(null);
          }}
        />
      ),
    };
    return addChannelModalByType[channel.channelType] || addChannelModalByType.SLACK;
  };

  const EmptyChannels = () => (
    <div className={styles.emptyChannels}>
      <ChannelsEmptyState />
      <h4>No channels defined yet</h4>
      <h5>Channels are the outgoing integrations to send Alerts, recommendations and reports to 3rd party apps.</h5>
      <h5> Link your ticketing and messaging apps here.</h5>
      <br></br>
      <CreateChannelButton onClick={setChannelToAdd} isGhost />
    </div>
  );

  if (channels == null) {
    return (
      <Container className={styles.channels}>
        <div className={styles.emptyChannels}>
          <ChannelsEmptyState />
          <h4>Unable to retrieve Channels.</h4>
          <h4>Please try again later.</h4>
        </div>
      </Container>
    );
  }

  const groupedChannelsList = groupedChannelTypes.map((channelType) => ({
    groupedChannels: channels.filter((c) => c.channelType === channelType),
  }));

  // Hide channels without recipients only when searching
  const filteredChannels = [
    ...(searchText
      ? channels.filter(
          (c) =>
            !groupedChannelTypes.includes(c.channelType) &&
            c.recipients.some((r) => shouldIncludeRecipient(searchText, r, c)),
        )
      : channels.filter((c) => !groupedChannelTypes.includes(c.channelType))),
    ...groupedChannelsList.filter(({ groupedChannels }) =>
      groupedChannels.some((c) => shouldIncludeRecipient(searchText, null, c)),
    ),
  ];

  return (
    <Container className={styles.channels}>
      {channels.length === 0 ? (
        <EmptyChannels closeModal={() => setChannelToAdd(null)} />
      ) : (
        <>
          <TableHeader
            titleComponent={<p className={styles.headerTitle}>Channels</p>}
            createButtonComponent={<CreateChannelButton onClick={setChannelToAdd} />}
            expanded={expandedRowIds.length > 0}
            onCreate={() => setChannelToAdd({ channelType: 'JIRA_CLOUD' })}
            setExpanded={(shouldExpand) => setExpandedRowIds(shouldExpand ? channels.map((_, i) => i) : [])}
            tableName="Channel"
            includeDetails
            isCreateLast
            isInline
          />
          {filteredChannels.length === 0 ? (
            <NoChannelsFound />
          ) : (
            <NewTableWrapper>
              <Grid
                rows={filteredChannels}
                columns={[
                  {
                    getCellValue: (row) =>
                      row.groupedChannels ? groupedChannelsComponent(row.groupedChannels) : channelComponent(row),
                  },
                ]}
              >
                <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
                <Table
                  rowComponent={(props) => (
                    <NewTableRow setExpandedRowIds={setExpandedRowIds} expandedRowIds={expandedRowIds} {...props} />
                  )}
                />
                <TableRowDetail contentComponent={(props) => <ChannelDetails searchText={searchText} {...props} />} />
              </Grid>
            </NewTableWrapper>
          )}
        </>
      )}
      {channelToAdd && addChannelModal(channelToAdd)}
      <DeleteChannelModal
        isOpen={channelTypeToDelete}
        onClose={() => {
          setChannelTypeToDelete(null);
          setChannelsToDelete([]);
        }}
        onDelete={() => {
          if (channelsToDelete.length > 1) {
            handleDeleteBulkChannels(channels.map((c) => c.channelId));
          } else {
            handleDeleteChannel(channelsToDelete[0]);
          }
          setChannelTypeToDelete(null);
          setChannelsToDelete([]);
        }}
        title={`Delete ${titleByChannelType[channelTypeToDelete]} Channel`}
        content={`
          You are about to delete this ${titleByChannelType[channelTypeToDelete]} integration and all its channels,
          are you sure?`}
      />
    </Container>
  );
}

Channels.propTypes = {
  usersStore: PropTypes.object.isRequired,
};
