import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { AZURE_PROPERTIES, DB_PROPERTIES, ENGINE_PROPERTIES } from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import externalLinksFunctions from 'shared/utils/external-links.util';

const AzureSqlIdle = ({ recommendation }) => {
  const deleteURL =
    externalLinksFunctions.AwsSqlTableAddress({
      linkedAccountId: recommendation?.linkedAccountId,
      resource_group: recommendation?.recData?.resource_group,
      server: recommendation?.recData?.server,
      db_name: recommendation?.recData?.db_name
    });
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          actionCommand: `DELETE ${deleteURL}`,
        },
      ],
    },
  ];
  const description = 'This DB instance was idle in the past 14 days. We recommend that you terminate it.';
  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{
        ...ENGINE_PROPERTIES,
        ...AZURE_PROPERTIES,
        ...DB_PROPERTIES,
      }}
    />
  );
};

AzureSqlIdle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureSqlIdle;
