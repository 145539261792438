import React from "react";

type Props = {
  className?: string;
}

const YamlStep: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      YamlStep
    </div>
  );
};

export default YamlStep;
