import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@mui/material/Paper';
import Spinner from 'shared/components/andtComponents/Spinner';
import AlertCircleOutline from 'mdi-react/AlertCircleOutlineIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { sortMonth } from 'shared/utils/sortUtil';

const CSVButton = ({ fetchCSVData }) => (
  <span onClick={fetchCSVData} className="csv-download" style={{ cursor: 'pointer', color: '#80bbf8' }}>
    CSV
  </span>
);

CSVButton.propTypes = {
  fetchCSVData: PropTypes.func.isRequired,
};

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '80%',
  height: '100%',
};
const textStyle = {
  width: '50%',
};

const colStyle = {
  position: 'relative',
  width: '100%',
  minHeight: '1px',
  paddingRight: '15px',
  flexBasis: 0,
  flexGrow: 1,
  maxWidth: '100%',
};

class CustomersTable extends Component {
  static propTypes = {
    tableData: PropTypes.object.isRequired,
    handleExplainCustomerFlexibilityMargin: PropTypes.func.isRequired,
    selectedRowsUpdate: PropTypes.func.isRequired,
    tableColumns: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { tableData } = this.props;
    this.state = {
      rows: tableData,
      pageSizes: [15, 20, 25, 0],
      selectedRows: [],
      tableIsUpdated: false,
    };
    this.csvLink = React.createRef();
  }

  setSelectedRows = (selectedRows) => {
    this.setState({ selectedRows });
    this.props.selectedRowsUpdate(selectedRows);
  };

  currenciesFormatter = (value) => {
    const { getCurrencyNumber } = this.props;
    if (value && _.isNumber(value.value)) {
      const cost = getCurrencyNumber(value.value || 0, 1);
      return `${cost}`;
    }
    return '';
  };

  publicPriceFlexibilityFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.publicPriceMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'pp',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  billingRuleMarginFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.billingRuleMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'billingRules',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  spFlexibilityFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.spFlexibilityMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'sp',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  riFlexibilityFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.riFlexibilityMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'ri',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  creditMarginFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.creditMargin || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'creditMargin',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );
  resellerCreditFormatter = (data) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{this.props.getCurrencyNumber(data.row.resellerCredit || 0, 1)}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              this.props.handleExplainCustomerFlexibilityMargin({
                e,
                divisionId: data.row.divisionId,
                divisionName: data.row.customerName,
                accountKey: data.row.accountKey,
                flexibilityType: 'resellerCredit',
                month: data.row.month,
                year: data.row.year,
              })
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );

  render() {
    const { tableColumns } = this.props;
    const { selectedRows, pageSizes, tableIsUpdated, rows } = this.state;
    if (tableIsUpdated) {
      return <Spinner />;
    }

    const sortingExtensions = [
      {
        columnName: 'month',
        compare: sortMonth,
      },
    ];

    return (
      <div className="card history-table-page">
        <Paper>
          <Grid rows={rows} columns={tableColumns}>
            <SortingState
              defaultSorting={[
                { columnName: 'year', direction: 'desc' },
                { columnName: 'month', direction: 'desc' },
                { columnName: 'mtdResellerCost', direction: 'desc' },
                { columnName: 'mtdCustomerCost', direction: 'desc' },
                { columnName: 'partnerMargin', direction: 'desc' },
              ]}
            />
            <IntegratedSorting columnExtensions={sortingExtensions} />
            <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[0]} />
            <SelectionState selection={selectedRows} onSelectionChange={this.setSelectedRows} />
            <SearchState />
            <IntegratedFiltering />

            <IntegratedPaging />
            <IntegratedSelection />
            <DataTypeProvider
              for={['mtdResellerCost', 'mtdCustomerCost', 'partnerMargin', 'riMargin', 'usageMargin', 'ruleMargin']}
              formatterComponent={this.currenciesFormatter}
            />
            <DataTypeProvider for={['riFlexibilityMargin']} formatterComponent={this.riFlexibilityFormatter} />
            <DataTypeProvider for={['spFlexibilityMargin']} formatterComponent={this.spFlexibilityFormatter} />
            <DataTypeProvider for={['publicPriceMargin']} formatterComponent={this.publicPriceFlexibilityFormatter} />
            <DataTypeProvider for={['billingRuleMargin']} formatterComponent={this.billingRuleMarginFormatter} />
            <DataTypeProvider for={['creditMargin']} formatterComponent={this.creditMarginFormatter} />
            <DataTypeProvider for={['resellerCredit']} formatterComponent={this.resellerCreditFormatter} />
            <TableWrapper columnExtensions={this.tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel />
            <TableSelection showSelectAll />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        </Paper>
      </div>
    );
  }
}

const ObserverCustomersTable = withUserSettingsConsumer(CustomersTable);
export default ObserverCustomersTable;
