import { useEffect, useState } from 'react';
export function useDynamicSvgImport(cloudTypeString, cloudMapper, label) {
  const [svgIcon, setSvgIcon] = useState(null);
  const [loading, setLoading] = useState(true);
  const defaultCloudIcon = `${cloudTypeString?.toUpperCase()}Generic`;
  useEffect(() => {
    setLoading(true);
    const importSvgIcon = async () => {
      try {
        const module = await import(`../servicesIcons/${cloudTypeString}/${cloudMapper[label]}.svg`);
        setSvgIcon(module.ReactComponent);
      } catch {
        try {
          const module = await import(`../servicesIcons/${cloudTypeString}/${defaultCloudIcon}.svg`);
          setSvgIcon(module.ReactComponent);
        } catch {
          setSvgIcon(null);
        }
      } finally {
        setLoading(false);
      }
    };
    importSvgIcon();
  }, [cloudTypeString, cloudMapper, label]);
  return { loading, SvgIcon: svgIcon };
}
