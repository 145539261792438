import PropTypes from 'prop-types';
import React from 'react';
import BaseRecommendation from '../baseRecommendation';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const EC2_DATA_TRANSFER_PROPERTIES = {
  DATA_TRANSFER_TYPE: {
    label: 'Data Transfer Type',
    getterFunction: (recommendation) => recommendation?.recData?.dt_type,
  },
  AVAILABILITY_ZONE: {
    label: 'Availability Zone',
    getterFunction: (recommendation) => recommendation?.recData?.az,
  },
};

const Ec2DataTransfer = ({ recommendation }) => {
  const description = `AWS charges for inter-Availability Zones data transfer between Amazon EC2 instances within
   the same region. This resource has inter-Availability Zones Data Transfer and if possible you should consider 
   to co-locate it within the same Availability Zone to avoid unnecessary data transfer charges.`;

  const commandsList = [
    {
      type: COMMAND_TYPES.CONSOLE,
      instructions: [
        {
          actionText: 'Move the EC2 instance to the same Availability Zone',
          actionLink: ExternalLinks.AWSMoveEc2Instance,
        },
      ],
    },
  ];

  return (
    <BaseRecommendation
      commandsList={commandsList}
      dynamicProps={EC2_DATA_TRANSFER_PROPERTIES}
      description={description}
      recommendation={recommendation}
    />
  );
};

Ec2DataTransfer.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default Ec2DataTransfer;
