import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Button from 'shared/components/andtComponents/Button';

import { ReactComponent as MigrationImg } from './migration.svg';
import styles from './AzureMCAMigrationModal.module.scss';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const AzureMCAMigrationModal = ({ open, onClose, onContinue }) => (
  <CustomModal
    title="Cloud account migration required"
    open={open}
    saveTitle="Let`s do it"
    cancelTitle="Later"
    onClose={onClose}
    onSave={onContinue}
  >
    <div className={styles.content}>
      <p>
        Azure <span>EA</span> API is going to be replaced by <span>EA</span> Billing Export.
      </p>
      <p>
        <span>To avoid any disruption to your account, you must migrate your cloud account to EA Export.</span>
      </p>
      <Button
        onClick={() => {
          window.open(
            ExternalLinks.AzureMigrateFromEnterpriseAgreementToMicrosoftCustomerAgreementAPIs,
            'blank',
          );
        }}
        text="Learn More"
        isTextButton
      />
      <MigrationImg />
    </div>
  </CustomModal>
);

AzureMCAMigrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default AzureMCAMigrationModal;
