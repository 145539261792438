import {
  BYOD_ONBOARDING_STEPS_SHORT_TITLES,
  BYOD_ONBOARDING_FIELDS,
  BYOD_ONBOARDING_STEPS,
} from './ByodOnboardingConstants';
import ProcessDataPage from './pages/ProcessDataPage/ProcessDataPage';
import DetailsPage from './pages/ByodDetailsPage/ByodDetailsPage';
import UploadPage from './pages/UploadPage/UploadPage';

export const BYOD_ONBOARDING_STEPS_CONFIG = {
  [BYOD_ONBOARDING_STEPS.BYOD_DETAILS]: {
    title: 'Add account details',
    titleShort: BYOD_ONBOARDING_STEPS_SHORT_TITLES.BYOD_DETAILS,
    component: DetailsPage,
    noReturn: true,
    nextEnabled:
      BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME &&
      BYOD_ONBOARDING_FIELDS.VENDOR_TYPE_UUID &&
      BYOD_ONBOARDING_FIELDS.IMPORT_TYPE &&
      BYOD_ONBOARDING_FIELDS.ACCESS_POINT_ALIAS,
  },
  [BYOD_ONBOARDING_STEPS.UPLOAD]: {
    title: 'Upload your cost',
    titleShort: BYOD_ONBOARDING_STEPS_SHORT_TITLES.UPLOAD,
    component: UploadPage,
  },
  [BYOD_ONBOARDING_STEPS.PROCESS_DATA]: {
    title: 'Data Process',
    titleShort: BYOD_ONBOARDING_STEPS_SHORT_TITLES.PROCESS_DATA,
    component: ProcessDataPage,
    nextText: 'Done',
  },
};
