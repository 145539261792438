import moment from 'moment';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import DateFilter from 'shared/modules/dateFilter.js';

export const getFirstDayOfMonth = (date) => {
  return moment(date).startOf('month').format('YYYY-MM-DD');
};
export const getFirstAndLastDayOfPreviousMonth = () => {
  const firstDay = moment(DateFilter.getDate()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  const lastDay = moment(DateFilter.getDate()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

  return {
    start: firstDay,
    end: lastDay,
  };
};

export const sumTotalCost = (data) => {
  return data?.reduce((acc, item) => acc + (item.total_cost || 0), 0);
};

export const compareFormatterNumber = (a, b) => {
  const numA = Number((a.split(' ')[1] || a.split(' ')[0]).replace(/,/g, ''));
  const numB = Number((b.split(' ')[1] || b.split(' ')[0]).replace(/,/g, ''));
  return numA - numB;
};
const getFilterFields = (filters) => {
  const filterFields = {};
  Object.keys(filters).forEach((k) => {
    filterFields[k] = 1;
  });
  return JSON.stringify(filterFields);
};
export const getSearchParamsPanel = (params, isPpApplied) => {
  const filterParams = new URLSearchParams();

  filterParams.set('currCostType', JSON.stringify(params.costType));
  filterParams.set('fieldToFilterdValuesMap', JSON.stringify({}));
  filterParams.set('excludedFiltersStatusMap', JSON.stringify({}));
  filterParams.set('likeFiltersStatus', JSON.stringify({}));
  filterParams.set('startDate', params.start);
  filterParams.set('endDate', params.end);
  filterParams.set('currPeriodGranLevel', params.periodGranLevel);
  filterParams.set('selectedGranLevel', params.periodGranLevel);
  filterParams.set('currentGroupBy', params.groupBy);
  filterParams.set('filterBarGroupBy', params.groupBy);
  filterParams.set('isCumulative', false);
  filterParams.set('isPpApplied', isPpApplied);
  filterParams.set('isAmortize', params.isAmortized);
  filterParams.set('isShowAmortizeCost', params.isAmortized);
  filterParams.set('isDistributed', params.isDistrubuted);
  filterParams.set('isNetAmortize', params.isNetAmortized);
  filterParams.set('isNetUnblended', params.isNetUnblended);
  filterParams.set('isPublicCost', params.isPublicCost);
  filterParams.set('isUnblended', params.isUnblended);

  if (params.exclude) {
    filterParams.set('fieldToFilterdValuesMap', JSON.stringify({ ...params.exclude }));
    const test = getFilterFields(params.exclude);
    filterParams.set('excludedFiltersStatusMap', test);
  }
  return `?${filterParams.toString()}`;
};

export const getExcludeOptions = (usersStore) => {
  const { currDispUserCloudAccountType } = usersStore;
  const SUPPORT_OPTION = { value: 'support', label: 'Support' };
  const TAX_OPTION = { value: 'tax', label: 'Tax' };
  const MARKETPLACE_OPTION = { value: 'marketplace', label: 'Marketplace' };

  switch (currDispUserCloudAccountType) {
    case CLOUD_TYPE_IDS.AWS:
      return [SUPPORT_OPTION, TAX_OPTION, MARKETPLACE_OPTION];
    case CLOUD_TYPE_IDS.AZURE:
      return [MARKETPLACE_OPTION];
    case CLOUD_TYPE_IDS.GCP:
      return [];
    default:
      return null;
  }
};

export const getExcludeFilterParams = (filters) => {
  const params = new URLSearchParams();

  Object.keys(filters).forEach((key) => {
    filters[key].forEach((value) => {
      params.append(`excludeFilters[${key}]`, value);
    });
  });
  const queryString = params.toString();
  return queryString ? `&${queryString}` : '';
};

export const getGroupSettingByAccount = (usersStore) => {
  if (usersStore.isCurrentAccountAllAccounts) {
    return 'payeraccount';
  }
  if (usersStore.isCurrentAccountMulticloud) {
    return 'cloudprovider';
  }
  return 'none';
};

export const aggregateCostsByDate = (data, key) => {
  const dateMap = new Map();

  data.forEach(({ usage_date, total_cost }) => {
    if (dateMap.has(usage_date)) {
      dateMap.get(usage_date)[key] += parseFloat(total_cost);
    } else {
      dateMap.set(usage_date, {
        day: moment(usage_date).format('DD'),
        fullDate: usage_date,
        [key]: parseFloat(total_cost),
      });
    }
  });

  return Array.from(dateMap.values());
};

export const aggregateCostsByGroup = (data, includeLAName) => {
  const dataMap = new Map();
  data.forEach(({ group_by, total_cost, linked_account_name }) => {
    const currentTotal = dataMap.get(group_by)?.totalCost || 0;
    dataMap.set(group_by, {
      totalCost: currentTotal + parseFloat(total_cost),
      linkedAccountName: includeLAName ? linked_account_name : null,
    });
  });
  return dataMap;
};
