import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { STALE_TIME } from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getCostAndUsageData } from 'invoices/hooks/react-query/cost-and-usage';
import { getExcludeFilterParams } from 'app/containers/MainDashboard/utils.js';

export default function useCueData(isEnabled = true) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [apiConstants.QUERY_KEYS.MAIN_DASHBOARD, userAccountKey];
  const useFetchCueData = (params) => {
    const filterParams = params.exclude ? getExcludeFilterParams(params.exclude) : params.filterParams;
    return useQuery({
      queryKey: [...queryKey, params],
      queryFn: async () => {
        return await getCostAndUsageData(
          params.groupBy,
          params.secondaryGroupBy,
          params.whereParams,
          params.start,
          params.end,
          params.periodGranLevel,
          filterParams,
          params.costType,
          false,
          params.isAmortized,
          false,
          params.excludeFilters,
          '',
          params.isNetAmortized,
          params.isNetUnblended,
          params.isPublicCost,
          params.isDistributed,
        );
      },
      retry: false,
      staleTime: STALE_TIME,
      enabled: isEnabled,
      keepPreviousData: true,
    });
  };
  return {
    invalidate: (queryKeyToInvalidate) =>
      queryClient.invalidateQueries({
        queryKey: queryKeyToInvalidate
          ? [...queryKey, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.sortBy]
          : queryKey,
      }),
    reset: (queryKeyToInvalidate) =>
      queryClient.resetQueries({
        queryKey: queryKeyToInvalidate
          ? [...queryKey, queryKeyToInvalidate?.filters, queryKeyToInvalidate?.groupBy, queryKeyToInvalidate?.sortBy]
          : queryKey,
      }),
    fetchCueData: useFetchCueData,
  };
}
