import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import SearchIcon from 'mdi-react/SearchIcon';
import { Collapse } from 'reactstrap';
import { AwsCommonFields, awsCommonFieldToDisplayField } from "../constants/awsConstants";
import { K8S_POD_LABELS } from "../constants/appConstants.js";

const mapItemNameToItemSymbol = new Map([
  ['Virtual Tags', 'virtualTag'],
  ['Tags', 'customTag'],
  ['Enrichment Tags', 'accountTag'],
  ['Labels', 'label'],
  [awsCommonFieldToDisplayField.get(AwsCommonFields.K8S_CUSTOM_TAGS), 'label'],
  ['Views', 'viewsTag'],
  ['Business Mapping Viewpoints', AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS],
]);

const gcpMapItemNameToItemSymbol = new Map([
  ['Virtual Tags', 'virtualTag'],
  ['Tags', 'customTag'],
  ['Enrichment Tags', 'accountTag'],
  ['Labels', 'customTag'],
  [K8S_POD_LABELS, 'label'],
  ['Views', 'viewsTag'],
  ['Business Mapping Viewpoints', AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS],
]);
export default class GroupBySecondLevelDropDownWithSearch extends PureComponent {
  static propTypes = {
    handler: PropTypes.func.isRequired,
    toggleCollapse: PropTypes.func.isRequired,
    secondLevelItems: PropTypes.array.isRequired,
    itemsValuesMap: PropTypes.object.isRequired,
    secondaryCollapse: PropTypes.bool.isRequired,
    isSecondary: PropTypes.bool.isRequired,
    item: PropTypes.string.isRequired,
    isCue: PropTypes.bool,
    simpleList: PropTypes.bool,
    currentCloudType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // secondLevelItemsValuesMap: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isCue: false,
    simpleList: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
    };
  }

  getItemNameToItemSymbolByCloudType = (cloudType, item) => {
    let itemName = item;
    if (cloudType === CLOUD_TYPE_IDS.GCP) {
      itemName = gcpMapItemNameToItemSymbol.get(item);
      return itemName;
    }
    itemName = mapItemNameToItemSymbol.get(item);
    return itemName;
  };

  handleClick = (item, handler) => {
    // handler(item);
    handler(this.props.itemsValuesMap.get(item), this.props.isSecondary);
    if (this.props.toggleCollapse) {
      this.props.toggleCollapse();
    }
  };

  handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    this.setState({ searchValue });
  };

  renderItems = (items, handler) =>
    items.map((item) => (
      <p className="collapse-item" key={item} onClick={() => this.handleClick(item, handler)}>
        {/* {item.replace(`${mapItemNameToItemSymbol.get(this.props.item)}:`, '')} */}
        {item.replace(`${this.getItemNameToItemSymbolByCloudType(this.props.currentCloudType, this.props.item)}:`, '')}
      </p>
    ));

  render() {
    const { secondaryCollapse, handler, secondLevelItems, simpleList } = this.props;
    const secondaryListItemsByItemType = simpleList
      ? secondLevelItems
      : secondLevelItems.filter(
          (item) =>
            item.split(':')[0] ===
            this.getItemNameToItemSymbolByCloudType(this.props.currentCloudType, this.props.item),
        );
    const { searchValue } = this.state;
    if (!secondaryListItemsByItemType || secondaryListItemsByItemType.length === 0) {
      return null;
    }
    const filteredList = !searchValue.length
      ? secondaryListItemsByItemType
      : secondaryListItemsByItemType.filter((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
    return (
      <Collapse
        isOpen={secondaryCollapse}
        className={`menu-wrap-stretch${this.props.isCue ? ' cue' : ''}`}
        style={{
          overflowY: 'auto',
          boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        <div className="drop__menu-stretch">
          {this.props.secondLevelItems.length > 5 && (
            <div
              style={{
                display: 'flex-flow',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '5px 10px',
                width: '100%',
                position: 'sticky',
                top: '0',
                background: '#fff',
              }}
            >
              <SearchIcon style={{ opacity: '0.5' }} />
              <input
                style={{ width: '80%', marginLeft: '10px', border: 'solid #d8d8d8 1px' }}
                className="topbar__search-field--open"
                value={searchValue}
                onChange={(e) => this.handleSearchInputChange(e)}
                autoComplete="off"
              />
            </div>
          )}
          <div style={{ width: '100%' }}>{this.renderItems(filteredList, handler)}</div>
        </div>
      </Collapse>
    );
  }
}
