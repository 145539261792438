import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { alignedStartEndDateByGranLevel } from 'shared/utils/dateUtil';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';

const root = '/api/v1/users/events';

const fetchEventsApi = ({ startDate, endDate, granLevel, enabled }) => {
  // events for hourly granularity are not supported
  if (!enabled || !startDate || !endDate || granLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY) {
    return [];
  }
  const { start, end } = alignedStartEndDateByGranLevel(startDate, endDate, granLevel);
  return API.get('billings', `${root}?startDate=${start}&endDate=${end}`);
};
const addEventApi = (data) => API.post('billings', root, { body: data });
const updateEventApi = (data) => API.put('billings', `${root}/${data.id}`, { body: data });
const deleteEventApi = ({ id }) => API.del('billings', `${root}/${id}`);

export const useCUEEvents = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.CUE_EVENTS];
  return {
    fetchCUEEvents: (startDate, endDate, granLevel, enabled) =>
      useQuery({
        queryKey: [...queryKey, startDate, endDate, enabled],
        queryFn: () =>
          fetchEventsApi({
            startDate,
            endDate,
            granLevel,
            enabled,
          }),
        staleTime: 1000 * 60 * 5,
      }),
    addCUEEvent: () =>
      useMutation({
        mutationFn: addEventApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
    updateCUEEvent: () =>
      useMutation({
        mutationFn: updateEventApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
    deleteCUEEvent: () =>
      useMutation({
        mutationFn: deleteEventApi,
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
      }),
  };
};
