import React from 'react';
import { ReactComponent as ConnectK8sImg } from 'shared/img/images/connect-k8s.svg';
import styles from './ConnectK8s.module.scss';
import Button from 'shared/components/andtComponents/Button';

const ConnectK8s: React.FC<{onOpenWizard?(): void}> = ({ onOpenWizard }) => (
  <div className={styles.container}>
    <ConnectK8sImg />
    <div className={styles.landingFirstLine}>
      Follow the{' '}
      <Button isTextButton onClick={onOpenWizard} onKeyDown={onOpenWizard} text="step-by-step wizard"/>
      {' '}to connect your Kubernetes data to Anodot
    </div>
    <div className={styles.landingSecondLine}>
      Once you’ve completed the agent installation, your Kubernetes metrics will start appearing here{' '}
      <b>up to 48 hours</b>.
    </div>
    <div className={styles.landingThirdLine}>
      For any assistance, reach out to our support team at <a href="mailto:support@anodot.com">support@anodot.com</a>.
    </div>
  </div>
);

export default ConnectK8s;
