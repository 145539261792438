import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Input from 'shared/components/andtComponents/Input';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Select from 'shared/components/andtComponents/SimpleSelect';
import commonStyles from '../Pages.module.scss';
import ByodDetailsPageHelp from './ByodDetailsPageHelp';
import { BYOD_ONBOARDING_FIELDS, vendorTypeUuidToIcon } from '../../ByodOnboardingConstants';
import styles from './ByodDetailsPage.module.scss';
import { getTenantParams } from '../../../utils/OnboardingUtils';
import { ReactComponent as BYODIcon } from 'shared/img/cloud-providers/simple/byod.svg';
import { ReactComponent as FocusIcon } from 'shared/img/cloud-providers/azure-focus.svg';
import RadioButtonsList from 'shared/components/andtComponents/RadioButtonList';
import Button from 'shared/components/andtComponents/Button';
import { AddVendorModal } from './AddVendorModal';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';

const ByodDetailsPage = () => {
  const {
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { usersStore } = useRootStore();
  const [isAddVendorModalOpen, setIsAddVendorModalOpen] = useState(false);
  useWatch(BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME);
  const accountName = getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME);

  const { getByodVendors, addByodVendor } = useOnboarding();
  const { data: vendors = [] } = getByodVendors();
  const { mutateAsync: handleAddByodVendor } = addByodVendor();

  useEffect(() => {
    const { tenantName, tenantSchemaName } = getTenantParams(usersStore.currentDisplaydUser, accountName);
    setValue(BYOD_ONBOARDING_FIELDS.TENANT_NAME, tenantName);
    setValue(BYOD_ONBOARDING_FIELDS.TENANT_SCHEMA_NAME, tenantSchemaName);
  }, [accountName]);

  const renderInput = ({ fieldName, name, label, icon, placeholder }) => (
    <div className={commonStyles.inputField}>
      <label htmlFor={name}>
        <span>
          <GenerateIcon iconName={ICONS[icon].name} />
          {label}
        </span>
      </label>
      <div className={commonStyles.inputBox}>
        <div>
          <Input
            value={getValues(fieldName)}
            isInvalid={errors?.[fieldName] && errors?.[fieldName] !== 'GeneralError'}
            invalidComponent={errors[fieldName]?.message || errors[fieldName]}
            onChange={(e) => {
              setValue(fieldName, e.target.value);
              clearErrors(fieldName);
            }}
            placeholder={placeholder}
            name={name}
            type="text"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <ListWithHelp
        styles={{
          listGap: 32,
          helpWidth: 460,
          controlStyles: {
            marginLeft: '-4px',
            borderLeft: 'none',
          },
        }}
        list={[
          {
            render: () =>
              renderInput({
                fieldName: BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME,
                name: 'onboarding-account-name',
                label: 'Account Name',
                icon: 'fileLinesLight',
                placeholder: 'Enter Name',
              }),
            key: 'onboarding-account-name',
          },
          {
            render: () => (
              <div className={commonStyles.inputField}>
                <label htmlFor="onboarding-import-type">
                  <span>
                    <GenerateIcon iconName={ICONS.fileImport.name} />
                    Import Type
                  </span>
                </label>
                <div className={commonStyles.checkboxField}>
                  <RadioButtonsList
                    onChange={(value) => {
                      setValue(BYOD_ONBOARDING_FIELDS.IMPORT_TYPE, value);
                    }}
                    options={[
                      {
                        value: 'focus',
                        key: 1,
                        label: (
                          <>
                            <FocusIcon height="30" width="30" /> Focus
                          </>
                        ),
                        primary: true,
                      },
                      {
                        value: 'custom',
                        key: 2,
                        label: (
                          <>
                            <BYODIcon height="30" width="30" /> Custom Schema <span>Coming Soon!</span>
                          </>
                        ),
                        primary: true,
                        disabled: true,
                      },
                    ]}
                    value={getValues(BYOD_ONBOARDING_FIELDS.IMPORT_TYPE)}
                    direction="row"
                  />
                </div>
              </div>
            ),
            key: 'onboarding-import-type',
          },
          {
            render: () => (
              <div className={commonStyles.inputField}>
                <label htmlFor="onboarding-vendor">
                  <span>
                    <GenerateIcon iconName={ICONS.comment.name} />
                    Vendor
                  </span>
                  <Button text="Add vendor to list" isTextButton onClick={() => setIsAddVendorModalOpen(true)} />
                </label>
                <div className={commonStyles.inputBox}>
                  <Select
                    placeholder="Select"
                    className={styles.select}
                    value={getValues(BYOD_ONBOARDING_FIELDS.VENDOR_TYPE_UUID)}
                    options={vendors?.map((vendor) => ({
                      label: (
                        <div className={styles.vendorOption}>
                          {vendor.vendorLogoUrl ? (
                            <img src={vendor.vendorLogoUrl} alt={vendor.vendorName} />
                          ) : (
                            vendorTypeUuidToIcon[vendor.vendorTypeUuid] || vendorTypeUuidToIcon['default']
                          )}{' '}
                          {vendor.vendorName}
                        </div>
                      ),
                      value: vendor.vendorTypeUuid,
                    }))}
                    name="onboarding-vendor"
                    onChange={(value) => {
                      setValue(BYOD_ONBOARDING_FIELDS.VENDOR_TYPE_UUID, value);
                    }}
                  />
                </div>
              </div>
            ),
            key: 'onboarding-vendor',
          },
        ]}
        help={{
          'onboarding-account-name': ByodDetailsPageHelp.accountName,
          'onboarding-import-type': ByodDetailsPageHelp.importType,
          'onboarding-vendor': ByodDetailsPageHelp.vendor,
        }}
      />
      <AddVendorModal
        isOpen={isAddVendorModalOpen}
        onClose={() => setIsAddVendorModalOpen(false)}
        onSave={async (vendor) => {
          const { vendorTypeUuid } = await handleAddByodVendor(vendor);
          if (vendorTypeUuid) {
            setValue(BYOD_ONBOARDING_FIELDS.VENDOR_TYPE_UUID, vendorTypeUuid);
          }
        }}
      />
    </div>
  );
};

export default ByodDetailsPage;
