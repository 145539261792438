/*

PLEASE DON'T ADD Route LINKS HERE

*/
export enum ExternalLinks {
  ProdAPI = 'https://api-front.mypileus.io',
  ProdLagacyAPI = 'https://api.mypileus.io',
  ProdRedirectURL = 'https://mypileus.io',
  ProdCognitoPool = 'https://mypileus.auth.us-east-1.amazoncognito.com',

  StagingAPI = 'https://api.staging.mypileus.io',
  StagingRedirectURL = 'https://staging.mypileus.io',

  DevAPI = 'https://api.dev.mypileus.io',
  DevRedirectURL = 'https://dev.mypileus.io',
  DevKeyclock = 'https://keycloak.dev.mypileus.io',
  DevCognitoPool = 'https://dev-mypileus.auth.us-east-1.amazoncognito.com',

  // eslint-disable-next-line typescript:S5332
  LocalServer = 'http://localhost:3000',
  // eslint-disable-next-line typescript:S5332
  LocalHost = 'http://127.0.0.1:8080',
  // eslint-disable-next-line typescript:S5332
  LocalLegacy = 'https://f78f-199-203-250-201.ngrok-free.app',
  // eslint-disable-next-line typescript:S5332
  FeatureFrontendAppServer = 'http://feature-frontend-frontend-app-server:8080',
  // eslint-disable-next-line typescript:S5332
  FeatureKeyclock = 'http://keycloak-http:80',

  CognitoIdpPrefix = 'https://cognito-idp',
  TermsAndConditions = 'https://www.anodot.com/terms-of-use',
  PrivacyPolicy = 'https://www.anodot.com/privacy-policy',
  ContactUs = 'https://www.anodot.com/contact-us',
  AwsSavingsPlansPurchase = 'https://console.aws.amazon.com/costmanagement/home?/savings-plans/purchase#/savings-plans/purchase',
  AwsOnboardingCFTJson = 'https://pileus-cloudformation-public.s3.amazonaws.com/PileuseOnboardingCFT.json',
  AwsActivateTrustedAccessForStackSetsWithOrganizations = 'https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stacksets-orgs-activate-trusted-access.html',
  AwsConfiguringWettingsForCLI = 'https://docs.aws.amazon.com/cli/latest/userguide/cli-configure-quickstart.html',
  AwsAccountHome = 'https://console.aws.amazon.com/billing/home?#/account',
  SvgXMLPAth = 'http://www.w3.org/2000/svg',
  AzureMigrateFromEnterpriseAgreementToMicrosoftCustomerAgreementAPIs = 'https://learn.microsoft.com/en-us/azure/cost-management-billing/costs/migrate-cost-management-api',
  AtlassianAPITokens = 'https://id.atlassian.com/manage-profile/security/api-tokens',
  ConnectYourLinkedAccounts = 'https://cloudcost.zendesk.com/hc/en-us/articles/6041352942492',
  SlackAuthorize = 'https://slack.com/oauth/v2/authorize',
  AzureVMShutdownTipsToSaveMoney = 'https://build5nines.com/properly-shutdown-azure-vm-to-save-money/',
  AzureMoveResourcesToANewResourceGroupOrSubscription = 'https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/move-resource-group-and-subscription',
  AzureSaveCostsWithAppServiceReservedInstances = 'https://docs.microsoft.com/en-us/azure/cost-management-billing/reservations/prepay-app-service',
  AWSDiscoveringAndDeletingIncompleteMultipartUploadsToLowerS3Costs = 'https://aws.amazon.com/blogs/aws-cloud-financial-management/discovering-and-deleting-incomplete-multipart-uploads-to-lower-amazon-s3-costs/',
  AWSDeleteKmsKey = 'https://docs.aws.amazon.com/kms/latest/developerguide/deleting-keys.html',
  AWSIPv6SupportForYourVPC = 'https://docs.aws.amazon.com/vpc/latest/userguide/vpc-migrate-ipv6.html',
  AWSEc2TypeChanges = 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-resize.html',
  TerminateAWSEc2Instances = 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html',
  AWSMoveEc2Instance = 'https://aws.amazon.com/premiumsupport/knowledge-center/move-ec2-instance/',
  AWSDeletingAnAmazonDocumentDBCluster = 'https://docs.aws.amazon.com/documentdb/latest/developerguide/db-cluster-delete.html',
  AWSLogClasses = 'https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/CloudWatch_Logs_Log_Classes.html',
  FlexeraPrivacyNotice = 'https://www.snowsoftware.com/legal/privacy-policy/',
  FlexeraLegal = 'https://www.snowsoftware.com/legal/tos/',
  SettingNonUSDCurrenciesForAccounts = 'https://cloudcost.anodot.com/hc/en-us/articles/9719238564124',
  ConnectAzureApplications = 'https://cloudcost.anodot.com/hc/en-us/articles/17715327015324-Connect-Azure-Applications',
  CueFilters = 'https://cloudcost.anodot.com/hc/en-us/articles/11040212772508-Understand-the-Cost-Usage-filters',
  RecommendationsForAWS = 'https://cloudcost.anodot.com/hc/en-us/articles/7017727629596-Recommendations-for-AWS',
  RecommendationsForAzure = 'https://cloudcost.anodot.com/hc/en-us/articles/7017727826716-Recommendations-for-Azure',
  RecommendationsForGCP = 'https://cloudcost.anodot.com/hc/en-us/articles/7017727991964-Recommendations-for-GCP',
  K8sWorkloadRightsizingRecommendation = 'https://cloudcost.anodot.com/hc/en-us/articles/15324209487644-K8s-Workload-Rightsizing-Recommendation',
  RecommendationsForAwsSectionRds = 'https://cloudcost.anodot.com/hc/en-us/articles/7017727629596-Recommendations-for-AWS#h_01H4ENV0FJ4CAFZ4PWYWZE13DW',
  PrometheusAgentForKubernetesForAWSAzure = 'https://cloudcost.anodot.com/hc/en-us/articles/7145768262812',
  WorkingWithCostGPT = 'https://cloudcost.anodot.com/hc/en-us/articles/9568761938076',
  IntroductionToAWSCostMonitoring = 'https://cloudcost.anodot.com/hc/en-us/articles/6629591463068',
  PrerequisitesForOnboardingWithAzure = 'https://cloudcost.anodot.com/hc/en-us/articles/9425596170780',
  GcpOnboarding = 'https://cloudcost.anodot.com/hc/en-us/articles/15181340450972-GCP-onboarding',
  DocumentationForResellers = 'https://cloudcost.anodot.com/hc/en-us/articles/6143226566812',
  ForecastArchitecture = 'https://cloudcost.anodot.com/hc/en-us/articles/10019455778972-Forecast-Architecture',
  AWSCustomerType = 'https://cloudcost.anodot.com/hc/en-us/articles/10273697984668',
  AWSOnboardingType = 'https://cloudcost.anodot.com/hc/en-us/articles/10273724354076',
  AWSOnboardingLinkedAccountsAutomatic = 'https://cloudcost.anodot.com/hc/en-us/articles/6041352942492-Connect-your-linked-accounts',
  AWSTrustedAccess = 'https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stacksets-orgs-activate-trusted-access.html',
  AWSOnboardingTypeEventNotification = 'https://cloudcost.anodot.com/hc/en-us/articles/10273724354076-AWS-Automatic-and-Manual-Onboarding#01HQEFVPQ5E3C4BACM3Y1YR3TS',

  AzureApplication = 'https://cloudcost.anodot.com/hc/en-us/articles/9425408352540',
  AzureOnboardingType = 'https://cloudcost.anodot.com/hc/en-us/articles/12759538700572-Azure-Onboarding-CSP-MCA-Pay-as-you-go',
  AzureCustomerType = 'https://cloudcost.anodot.com/hc/en-us/articles/12865472672668-Azure-Onboarding-for-MSP',
  AzureEaGuide = 'https://cloudcost.anodot.com/hc/en-us/articles/9425634478748',
  AzureFocusGuide = 'https://cloudcost.anodot.com/hc/en-us/articles/15866356565660-Migrate-Azure-accounts-to-FOCUS',
  GcpCustomerType = 'https://cloudcost.anodot.com/hc/en-us/articles/15181395736860-GCP-onboarding-for-MSP',
  GcpOnboardingType = 'https://cloudcost.anodot.com/hc/en-us/articles/15181340450972-GCP-new-onboarding',
  GcpK8sIntegration = 'https://cloudcost.anodot.com/hc/en-us/articles/6787850245404-How-to-connect-a-GCP-K8s-Cluster',
  ByodOnboarding = 'https://cloudcost.anodot.com/hc/en-us/articles/18317901529628-Bring-Your-Own-Cost-Data',
  SystemNotifications = 'https://cloudcost.anodot.com/hc/en-us/articles/12414162952732-System-Notifications',
  ConnectLinkedAccount = 'https://cloudcost.anodot.com/hc/en-us/articles/6041352942492-Connect-your-linked-accounts#01J9GN2XTYFXGZGM50HW38DBHW'
}
