import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { toast } from 'react-toastify';

const root = '/api/v1/gpt';

const fetchUserData = async () => API.get('billings', `${root}/user-data`);

const fetchAvailableData = async () => API.get('billings', `${root}/available-data`);

const postQuestion = async (question) =>
  API.post('billings', `${root}/question`, {
    body: { question },
  });

const removeQuestion = async (requestId) => API.del('billings', `${root}/question?requestId=${requestId}`);

const postFeedback = async ({ requestId, feedback }) =>
  API.post('billings', `${root}/feedback`, {
    body: { requestId, feedback },
  });

export const useGpt = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.COST_GPT];

  return {
    reset: () => queryClient.resetQueries({ queryKey }),
    fetchUserData: () =>
      useQuery({
        queryKey,
        queryFn: fetchUserData,
        onError: () =>
          toast.error('Error fetching user data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
    fetchAvailableData: () =>
      useQuery({
        queryKey: [...queryKey, 'metadata'],
        queryFn: fetchAvailableData,
        onError: () =>
          toast.error('Error fetching API capabilities', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
    postQuestion: ({ onSuccess = () => {}, ...mutationSettings } = {}) =>
      useMutation({
        mutationFn: postQuestion,
        onSuccess: async (answer) => {
          onSuccess(answer);

          await queryClient.cancelQueries(queryKey);
          const previousQuestions = queryClient.getQueryData(queryKey);
          queryClient.setQueryData(queryKey, (old) => ({
            ...old,
            history: [answer, ...old.history],
          }));
          return { previousQuestions };
        },
        ...mutationSettings,
      }),
    removeQuestion: ({ onError = () => {}, ...mutationSettings } = {}) =>
      useMutation({
        mutationFn: removeQuestion,
        onMutate: async (requestId) => {
          await queryClient.cancelQueries(queryKey);
          const previousQuestions = queryClient.getQueryData(queryKey);
          queryClient.setQueryData(queryKey, (old) => ({
            ...old,
            history: old.history.filter((question) => question.requestId !== requestId),
          }));
          return { previousQuestions };
        },
        onError: (err, payload, context) => {
          toast.error('Error deleting question', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          queryClient.setQueryData(queryKey, context.previousQuestions);
          onError();
        },
        ...mutationSettings,
      }),
    postFeedback: ({ onSuccess = () => {}, onError = () => {}, ...mutationSettings } = {}) =>
      useMutation({
        mutationFn: postFeedback,
        onMutate: async ({ requestId, feedback }) => {
          await queryClient.cancelQueries(queryKey);
          const previousQuestions = queryClient.getQueryData(queryKey);
          queryClient.setQueryData(queryKey, (old) => ({
            ...old,
            history: old.history.map((question) =>
              question.requestId === requestId ? { ...question, feedback } : question,
            ),
          }));
          return { previousQuestions };
        },
        onSuccess: () => {
          toast.success('Thanks for your feedback', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          onSuccess();
        },
        onError: (err, payload, context) => {
          toast.error('Error sending feedback', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          queryClient.setQueryData(queryKey, context.previousQuestions);
          onError();
        },
        ...mutationSettings,
      }),
  };
};
