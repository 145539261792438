import React from 'react';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import commonStyles from 'app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss';
import { AddClusterFields } from "../config.ts";
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import { objAwsRegionNames } from 'shared/constants/awsConstants';
import { ExternalLinks } from "shared/enums/external-links.enum.ts";

type Props = {
  className?: string;
};

export const SelectLinkedAccountField = ({ onChange, value, ...selectProps }) => {
  const { usersStore } = useRootStore();
  const isAzure = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;
  const linkedAccountLabel = isAzure ? 'Subscription' : 'Linked Account';

  return (
    <div className={commonStyles.inputField}>
      <label htmlFor={AddClusterFields.linkedAccountId}>
        <span>{linkedAccountLabel}</span>
      </label>
      <div className={commonStyles.inputBox}>
        <SimpleSelect
          className={commonStyles.select}
          value={value}
          options={Object.values(objAwsRegionNames).map((a) => ({ label: a, value: a }))}
          onChange={onChange}
          invalidComponent={<span>{linkedAccountLabel} is required</span>}
          {...selectProps}
        />
      </div>
    </div>
  );
};

export const SelectLinkedAccountHelp = () => {
  const { usersStore } = useRootStore();
  const isAzure = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;
  const linkedAccountLabel = isAzure ? 'Subscription' : 'Linked Account';
  return (
    <div className={commonStyles.helpText}>
      <p>Choose the {linkedAccountLabel} where your cluster is located.</p>
      <p>
        <span className={commonStyles.notice}>Note</span>: If your {linkedAccountLabel.toLowerCase()} does not appear in
        the list, it may not be connected. To verify the connection status, navigate to Account {'>'}{' '}
        {linkedAccountLabel} page. For instructions on connecting your linked accounts,{' '}
        <a href={ExternalLinks.ConnectLinkedAccount}>
          click here
        </a>
        .
      </p>
    </div>
  );
};
