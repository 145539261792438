import moment from 'moment';
import DateFilter from 'shared/modules/dateFilter.js';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import {
  AWS_GROUP_BY_LOV,
  AZURE_GROUP_BY_LOV,
  GCP_GROUP_BY_LOV,
  GROUP_BY_LOV,
  MULTI_GROUP_BY_LOV,
} from 'usage/constants/costAndUsageConstants.js';

const currentMonth = moment(DateFilter.getDate()).format('MMMM');
const previousMonth = moment(DateFilter.getDate()).subtract(1, 'months').format('MMMM');

const BASE_PARAMS = {
  secondaryGroupBy: 'usagedate',
  whereParams: '',
  filterParams: '',
  costType: ['cost', 'discount'],
  isAmortized: false,
  isNetAmortized: false,
  isNetUnblended: false,
  isPublicCost: false,
  isDistrubuted: false,
  isUnblended: true,
};

const awsGroupByOptions = [
  AWS_GROUP_BY_LOV.BY_SERVICE,
  AWS_GROUP_BY_LOV.BY_REGION,
  AWS_GROUP_BY_LOV.BY_DIVISION,
  AWS_GROUP_BY_LOV.BY_LINKED_ACC_ID,
  AWS_GROUP_BY_LOV.BY_FAMILY_TYPE,
  AWS_GROUP_BY_LOV.BY_OPERATION,
];
const azureGroupByOptions = [
  AZURE_GROUP_BY_LOV.BY_SERVICE,
  AZURE_GROUP_BY_LOV.BY_REGION,
  AZURE_GROUP_BY_LOV.BY_DIVISION,
  AZURE_GROUP_BY_LOV.BY_SUBSCRIPTION_ID,
  AZURE_GROUP_BY_LOV.BY_RESOURCE_GROUP,
  AZURE_GROUP_BY_LOV.BY_SUB_METER_CATEGORY,
  AZURE_GROUP_BY_LOV.BY_USAGE_TYPE,
];
const gcpGroupByOptions = [
  GCP_GROUP_BY_LOV.BY_SERVICE,
  GCP_GROUP_BY_LOV.BY_REGION,
  GCP_GROUP_BY_LOV.BY_DIVISION,
  GCP_GROUP_BY_LOV.BY_LINKED_ACC_ID,
  GCP_GROUP_BY_LOV.BY_OPERATION,
];
const multiGroupByOptions = [
  MULTI_GROUP_BY_LOV.BY_SERVICE,
  MULTI_GROUP_BY_LOV.BY_REGION,
  MULTI_GROUP_BY_LOV.BY_DIVISION,
  MULTI_GROUP_BY_LOV.BY_CLOUD,
  GROUP_BY_LOV.PAYER_ACCOUNT,
];
export const PANELS_TYPES = {
  MTD_COST: 'mtd_cost',
  PREVIOUS_MONTH_COST: 'previous_month_cost',
  ANNUAL_POTENTIAL_SAVINGS: 'annual_potential_savings',
  FORECASTED_MONTHLY_COST: 'forecasted_monthly_cost',
  OPEN_RECOMMENDATIONS: 'open_recommendations',
  MTD_TOP_10: 'mtd_top_10',
  HISTORY_DAILY_WITH_FORECAST: 'history_daily_with_forecast',
  HISTORY_MONTHLY_WITH_FORECAST: 'history_monthly_with_forecast',
  REGION_COST: 'region_cost',
  LINKED_ACCOUNT_TOTAL: 'linked_account_total',
  ANOMALIES_STATS: 'anomalies_stats',
};

export const PANELS = {
  [PANELS_TYPES.MTD_COST]: {
    title: 'MTD cost',
    info: 'Month-to-date',
    params: {
      ...BASE_PARAMS,
      groupBy: 'none',
      periodGranLevel: 'month',
    },
    secondTitle: 'Previous MTD Cost',
  },
  [PANELS_TYPES.PREVIOUS_MONTH_COST]: {
    title: 'Previous Month Total Cost',
    params: {
      ...BASE_PARAMS,
      groupBy: 'none',
      periodGranLevel: 'day',
    },
  },
  [PANELS_TYPES.MTD_TOP_10]: {
    title: 'Top 10',
    params: {
      ...BASE_PARAMS,
      groupBy: 'service',
      periodGranLevel: 'month',
    },
  },
  [PANELS_TYPES.ANNUAL_POTENTIAL_SAVINGS]: {
    title: 'Annual Potential Savings',
    info: 'Annual Potential Savings based on open recommendations from Waste Detector page.',
  },
  [PANELS_TYPES.FORECASTED_MONTHLY_COST]: {
    title: 'Forecasted Monthly Cost',
    info: 'Expected Monthly Costs based on the previous month usage.',
  },
  [PANELS_TYPES.OPEN_RECOMMENDATIONS]: {
    title: 'Open Recommendations',
  },
  [PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST]: {
    title: `Daily Cost: ${currentMonth} over ${previousMonth}`,
    params: {
      ...BASE_PARAMS,
      groupBy: 'none',
      periodGranLevel: 'day',
    },
  },
  [PANELS_TYPES.HISTORY_MONTHLY_WITH_FORECAST]: {
    title: 'Monthly Cost',
    params: {
      ...BASE_PARAMS,
      groupBy: 'none',
      periodGranLevel: 'month',
    },
  },
  [PANELS_TYPES.REGION_COST]: {
    title: 'Cost by Region',
    params: {
      ...BASE_PARAMS,
      groupBy: 'region',
      periodGranLevel: 'day',
    },
  },
  [PANELS_TYPES.LINKED_ACCOUNT_TOTAL]: {
    title: 'Linked Accounts',
    params: {
      ...BASE_PARAMS,
      groupBy: 'linkedaccid',
      periodGranLevel: 'month',
    },
  },
};

export const supportServices = [
  'AWS Support [Developer]',
  'AWS Support [Business]',
  'AWS Support [Enterprise]',
  'AWS Premium Support',
];
export const marketPlaceFamilyTypes = ['AWS Marketplace'];
export const marketPlaceService = ['Marketplace'];

export const filtersFields = {
  costType: { label: 'Cost Type', id: 'costType' },
  cost: { label: 'Cost', id: 'cost' },
  include: { label: 'Include', id: 'include' },
  by: { label: 'By', id: 'by' },
};
export const groupByOptions = {
  [CLOUD_TYPE_IDS.AWS]: awsGroupByOptions,
  [CLOUD_TYPE_IDS.AZURE]: azureGroupByOptions,
  [CLOUD_TYPE_IDS.GCP]: gcpGroupByOptions,
  [CLOUD_TYPE_IDS.MULTI]: multiGroupByOptions,
};
