import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { DB_PROPERTIES, ENGINE_PROPERTIES } from '../../recommendationPropertyUtils';
import { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const DOCUMNETDB_IDLE_PROPERTIES = {
  CLUSTER_RESOURCE_ID: {
    label: 'Cluster Resource Id',
    getterFunction: (recommendation) => recommendation?.recData?.db_cluster_resource_id,
  },
  NUMBER_OD_MEMBER: {
    label: 'Number of Members',
    getterFunction: (recommendation) => recommendation?.recData?.num_members,
  },
};

const DocumentDbIdle = ({ recommendation }) => {
  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      commentUrl: {
        url: ExternalLinks.AWSDeletingAnAmazonDocumentDBCluster,
        text: 'Deleting an Amazon DocumentDB Cluster.',
      },
      comment:
        // eslint-disable-next-line max-len
        'If you delete the last instance in an Amazon DocumentDB cluster, you also delete that cluster and the automatic snapshots and continuous backups associated with that cluster To delete the last instance in your cluster, you can delete the cluster and optionally create a final snapshot. For more information, see ',
      instructions: [
        {
          actionText: '',
          actionCommand: `aws docdb delete-db-instance --db-instance-identifier ${recommendation?.recData?.primary_db_instance_id}`,
        },
      ],
    },
  ];
  const description =
    'The DocumentDB service has been underutilized for the past 7 days. We recommend terminating this service.';

  return (
    <BaseRecommendation
      recommendation={recommendation}
      description={description}
      commandsList={commandsList}
      dynamicProps={{ ...ENGINE_PROPERTIES, ...DB_PROPERTIES, ...DOCUMNETDB_IDLE_PROPERTIES }}
    />
  );
};

DocumentDbIdle.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default DocumentDbIdle;
