import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Progress, Button } from 'reactstrap';
import {
  RowDetailState,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  TableFilterRow,
  Toolbar,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import InfoOutline from 'mdi-react/InfoOutlineIcon';
import { kFormatter } from 'shared/utils/strUtil';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import {
  COMMITMENT_TABLE_COLUMN_EXTENSIONS_AWS,
  COMMITMENT_TABLE_COLUMN_EXTENSIONS_AZURE,
  COMMITMENT_TABLE_COLUMN_WIDTHS_AWS,
  COMMITMENT_TABLE_COLUMN_WIDTHS_AZURE,
  COMMITMENT_DETAIL_NAMES,
  MAP_COMMITMENT_DETAIL_VALUES_TO_DISPLAY_NAMES,
  RI_OFFERING_TYPES,
  RI_REC_ACTION_OPTIONS,
} from '../constants/commitmentConstants';
import externalLinksFunctions from 'shared/utils/external-links.util';

const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;
FilterIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

const RowDetail = ({ row }) => {
  const arrRow = row ? Object.entries(row) : [];
  return arrRow
    .filter(([key, value]) => COMMITMENT_DETAIL_NAMES.indexOf(key) > -1 && !!value)
    .map(([key, value]) => (
      <Col xs={12} md={12} lg={12} xl={12} key={key}>
        <Row>
          <p style={{ padding: '3px', margin: '2px 0px' }}>
            <b>{MAP_COMMITMENT_DETAIL_VALUES_TO_DISPLAY_NAMES.get(key)}</b>
            <span>{`: ${value}`}</span>
          </p>
        </Row>
      </Col>
    ));
};

RowDetail.propTypes = {
  row: PropTypes.object.isRequired,
};

class CommitmentTable extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    getRiFlexabilityData: PropTypes.func.isRequired,
    getChargeBackData: PropTypes.func.isRequired,
    tableColumns: PropTypes.object.isRequired,
    isAws: PropTypes.bool,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAws: true,
  };

  constructor(props) {
    super(props);
    const { data, isAws } = props;
    this.state = {
      rows: data,
      pageSizes: [5, 10, 20, 0],
      columnWidths: isAws ? COMMITMENT_TABLE_COLUMN_WIDTHS_AWS : COMMITMENT_TABLE_COLUMN_WIDTHS_AZURE,
      numericFilterOperations: [
        'equal',
        'notEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'lessThan',
        'lessThanOrEqual',
      ],
      filteringStateColumnExtensions: [
        { columnName: 'chargeBack', filteringEnabled: false },
        { columnName: 'riFlexability', filteringEnabled: false },
      ],
    };
  }
  getChargeBackIcon = ({ row }) => (
    <InfoOutline
      onClick={() => this.handleGetChargeBack(row.arn, row.month, row.year)}
      style={{ margin: 'auto', height: '25px', width: '25px', cursor: 'pointer' }}
    />
  );
  getRiFlexibilityIcon = ({ row }) => (
    <InfoOutline
      onClick={() => this.handleGetRiFlexability(row.arn, row.month, row.year)}
      style={{ margin: 'auto', height: '25px', width: '25px', cursor: 'pointer' }}
    />
  );

  handleGetChargeBack = (arn, month, year) => {
    if (arn && month && year) {
      this.props.getChargeBackData(arn, month, year);
    }
  };
  handleGetRiFlexability = (arn, month, year) => {
    if (arn && month && year) {
      this.props.getRiFlexabilityData(arn, month, year);
    }
  };
  currenciesFormatter = (value) => this.props.getCurrencyNumber(kFormatter(value.value));

  utilizationFormatter = (data) => {
    const { value } = data;
    const getColor = (val) => {
      if (data.column.name !== 'UtilizationPercentage') {
        return '';
      }
      let color = 'success';
      if (val < 75 && val >= 50) {
        color = 'warning';
      } else if (val < 50) {
        color = 'danger';
      }
      return color;
    };
    return (
      <div>
        <div className="text-center">{`${value.toFixed(0)}%`}</div>
        <Progress value={value} color={getColor(value)} />
      </div>
    );
  };

  openLinkNewTab = (link) => {
    window.open(link, '_blank');
  };

  riRecommLinkFormatter = (data) => {
    const { row, value } = data;
    const { region, offeringType } = row;

    const oferringTypeLower = offeringType.toLowerCase();
    const isConvertible = oferringTypeLower === RI_OFFERING_TYPES.CONVERTIBLE;
    const isLowUtilization = value < 40;
    const currDate = moment().format('YYYY-MM-DD');
    const formattedEndDate = moment(row.endDateTime).format('YYYY-MM-DD');
    const isActiveRow = formattedEndDate.localeCompare(currDate) >= 0;

    const url = externalLinksFunctions.AwsReservedInstancesByRegion({ region });
    if (isLowUtilization && isActiveRow) {
      return (
        <div className="ri-recomm-btn-container">
          <Button
            color="success"
            className="form__button-toolbar ri-recomm-btn"
            size="sm"
            onClick={() => this.openLinkNewTab(url)}
          >
            {isConvertible ? RI_REC_ACTION_OPTIONS.CONVERT : RI_REC_ACTION_OPTIONS.SELL}
          </Button>
        </div>
      );
    }
    return null;
  };

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  render() {
    const { numericFilterOperations, filteringStateColumnExtensions, columnWidths, rows, pageSizes } = this.state;
    const { isAws, tableColumns } = this.props;
    const columnExtensions = isAws ? COMMITMENT_TABLE_COLUMN_EXTENSIONS_AWS : COMMITMENT_TABLE_COLUMN_EXTENSIONS_AZURE;
    return (
      <div className="page-wrapper" style={{ overflowX: 'scroll' }}>
        <Grid rows={rows} columns={tableColumns}>
          <RowDetailState />
          <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
          <SortingState defaultSorting={[{ columnName: 'NetRISavings', direction: 'asc' }]} />
          <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <DataTypeProvider
            for={['riRecommLink']}
            formatterComponent={this.riRecommLinkFormatter}
            availableFilterOperations={numericFilterOperations}
          />
          <DataTypeProvider
            for={['UtilizationPercentage']}
            formatterComponent={this.utilizationFormatter}
            availableFilterOperations={numericFilterOperations}
          />
          <DataTypeProvider
            for={['NetRISavings']}
            formatterComponent={this.currenciesFormatter}
            availableFilterOperations={numericFilterOperations}
          />
          <DataTypeProvider
            for={['TotalPotentialRISavings']}
            formatterComponent={this.currenciesFormatter}
            availableFilterOperations={numericFilterOperations}
          />
          <DataTypeProvider for={['chargeBack']} formatterComponent={this.getChargeBackIcon} />
          <DataTypeProvider for={['riFlexibility']} formatterComponent={this.getRiFlexibilityIcon} />
          <DataTypeProvider
            for={['flexibilityUtilizationPercentage']}
            formatterComponent={this.utilizationFormatter}
            availableFilterOperations={numericFilterOperations}
          />
          <TableWrapper columnExtensions={columnExtensions} />
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
          <TableHeaderRow showSortingControls />
          <TableRowDetail contentComponent={RowDetail} />
          <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
          <PagingPanel pageSizes={pageSizes} />
          <Toolbar />
        </Grid>
      </div>
    );
  }
}

const ObserverCommitmentTable = withUserSettingsConsumer(CommitmentTable);
export default ObserverCommitmentTable;
