import { useMutation, useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import {
  createUsers,
  deleteUsers,
  enableDisableUsers,
  fetchPartialUserPermissions,
  fetchUser,
  fetchUsers,
  resetUsersPassword,
  updateRolesAssignedToUsers,
  updateUser,
} from './apiUsers';
import { handleError } from './helperFunctions';
import { isNil } from 'lodash';

export default function useUsers(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const usersQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS, userAccountKey];
  const rolesQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries({ queryKey: usersQueryKey }),
    reset: () => queryClient.resetQueries({ queryKey: usersQueryKey }),
    fetchUsers: (params) => {
      const queryKeyFetchUsers = [...usersQueryKey];
      let paramsQueryKey = {};
      if (params && Object.values(params)?.length) {
        paramsQueryKey = Object.fromEntries(Object.entries(params).filter((p) => !isNil(p[1])));
        if (Object.keys(paramsQueryKey).length) {
          queryKeyFetchUsers.push(paramsQueryKey);
        }
      }
      return useQuery({
        queryKey: queryKeyFetchUsers,
        queryFn: () => fetchUsers(paramsQueryKey),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
        keepPreviousData: true,
      });
    },
    fetchUser: (userId) =>
      useQuery({
        queryKey: [...usersQueryKey, userId],
        queryFn: () => fetchUser(userId),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchPartialUserPermissions: ({ paginationToken, search, actionId, category, userId }) =>
      useQuery({
        queryKey: [
          ...usersQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          userId,
          category,
          actionId,
          paginationToken,
          search,
        ],
        queryFn: () => fetchPartialUserPermissions(userId, category, actionId, paginationToken, search),
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
        enabled: !isDisabled,
      }),
    createUsers: useMutation({
      mutationFn: ({ users }) => createUsers(users),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: usersQueryKey });
        await queryClient.invalidateQueries({ queryKey: rolesQueryKey });
      },
    }),
    updateUser: useMutation({
      mutationFn: ({ user }) => updateUser(user),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: usersQueryKey }),
      onError: handleError,
    }),
    deleteUsers: useMutation({
      mutationFn: ({ usersIds }) => deleteUsers(usersIds),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: usersQueryKey }),
      onError: handleError,
    }),
    updateRolesAssignedToUsers: useMutation({
      mutationFn: (updatePayload) => updateRolesAssignedToUsers(updatePayload),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: usersQueryKey });
        await queryClient.invalidateQueries({ queryKey: rolesQueryKey });
      },
      onError: handleError,
    }),
    enableDisableUsers: useMutation({
      mutationFn: ({ usersIds, enable }) => enableDisableUsers(usersIds, enable),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: usersQueryKey }),
      onError: handleError,
    }),
    resetUsersPassword: useMutation({
      mutationFn: ({ usersIds, enable }) => resetUsersPassword(usersIds, enable),
      onError: handleError,
    }),
  };
}
