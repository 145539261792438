import React, { useEffect } from 'react';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import classNames from 'classnames';
import moment from 'moment';
import DateFilter from 'shared/modules/dateFilter.js';
import { PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { usePermissions } from 'app/contexts/PermissionsContext.jsx';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import { ReactComponent as EmptyCommitment } from 'app/containers/MainDashboard/img/empty-commitments.svg';
import mainStyles from 'app/containers/MainDashboard/mainDashboardCommon.module.scss';
import ValueTag from 'app/containers/MainDashboard/components/ValueTag.jsx';
import { NoPermission } from 'app/containers/MainDashboard/components/NoPermission.jsx';
import useCommitmentData from 'app/containers/MainDashboard/hooks/react-query/useCommitmentData.js';
import { getFirstDayOfMonth } from 'app/containers/MainDashboard/utils.js';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import styles from './monitoringData.module.scss';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

const ComingSoon = () => (
  <div className={styles.comingSoonWrapper}>
    <GenerateIcon iconName={ICONS.sparkles.name} />
    <span>Coming Soon</span>
  </div>
);

const CommitmentData = () => {
  const { usersStore } = useRootStore();
  const { updatePanelLoadingState } = useMainDashboardContext();
  const isGcpAccount = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP;
  const isAzureAccount = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;
  const { fetchCommitmentData } = useCommitmentData(!isGcpAccount);
  const { isHasPermission } = usePermissions();
  const hasPermission = isHasPermission(HierarchicalEntityCategory.Commitments, Action.View);
  const spParams = {
    date: getFirstDayOfMonth(moment(DateFilter.getDate())),
    commitmentServices: ['EC2InstanceSavingsPlans', 'ComputeSavingsPlans'],
    commitmentType: 'sp',
  };
  const riParams = {
    date: getFirstDayOfMonth(moment(DateFilter.getDate())),
    commitmentServices: ['ec2', 'rds', 'elasticache', 'redshift', 'os', 'es'],
    commitmentType: 'ri',
  };
  const { data: spData, isLoading: spIsLoading } = fetchCommitmentData(spParams);
  const { data: riData, isLoading: riIsLoading } = fetchCommitmentData(riParams);

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.ANOMALIES_STATS, spIsLoading || riIsLoading);
  }, [spIsLoading, riIsLoading]);

  const renderContent = () => {
    if (isGcpAccount) {
      return (
        <div className={styles.emptyState}>
          <EmptyCommitment />
          <span className={styles.boldText}>Coming Soon</span>
        </div>
      );
    }
    if (!hasPermission) {
      return <NoPermission isSmallPanel={true} />;
    }
    if (!spData?.total && !riData?.total) {
      return (
        <div className={styles.emptyState}>
          <EmptyCommitment />
          <div>
            <div className={styles.textWrapper}>
              <span className={styles.simpleText}>You have no Commitments</span>
            </div>
            {isAzureAccount && (
              <a
                target="_blank"
                href="https://cloudcost.anodot.com/hc/en-us/articles/11847396384924-RI-Utilization"
                className={styles.link}
              >
                Make sure you provided the needed permissions
              </a>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className={classNames(styles.dataWrapper, isAzureAccount && styles.reverseFlex)}>
        {spData ? (
          <div className={classNames(styles.flexData, styles.padding)}>
            <div className={classNames(styles.field, isAzureAccount && styles.unUseField)}>
              {spData.total} <span className={styles.simpleText}>Saving Plans</span>
            </div>
            {isAzureAccount ? (
              <ComingSoon />
            ) : (
              spData.total && <ValueTag value={spData?.expiredTotal} description="will expired this month" />
            )}
          </div>
        ) : null}
        {riData ? (
          <div className={classNames(styles.flexData, styles.padding)}>
            <div className={styles.field}>
              {riData.total} <span className={styles.simpleText}>Reservation</span>
            </div>
            {riData.total ? <ValueTag value={riData?.expiredTotal} description="will expired this month" /> : null}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className={classNames(mainStyles.panelWrapper, styles.commitmentWrapper)}>
      {spIsLoading ? (
        <Spinner size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <>
          <div className={styles.headerFlex}>
            <div className={styles.flex}>
              <div className={styles.header}>Commitment Inventory</div>
            </div>
          </div>
          {renderContent()}
        </>
      )}
    </div>
  );
};

export default CommitmentData;
