import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { FixedSizeList as List } from 'react-window';
import Spinner from 'shared/components/andtComponents/Spinner';
import { debounce } from 'lodash';
import { ReactComponent as CaseSensitiveIcon } from 'shared/img/icons/case-sensitive.svg';
import { ReactComponent as TriangleDown } from 'shared/img/icons/triangle-down.svg';
import { ReactComponent as EmptyState } from 'shared/img/icons/empty-state.svg';
import { ReactComponent as NoOptions } from 'shared/img/icons/no-options.svg';
import CustomTooltip from 'shared/components/andtComponents/Tooltip';
import { OPERATORS, OPERATORS_KEYS } from 'shared/constants/appConstants';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import Checkbox from '../andtComponents/Checkbox';

import classes from './FieldSidebarFilter.module.scss';

const customStyles = () => ({
  option: (base) => ({
    ...base,
    width: '100%',
    height: '100%',
    borderRadius: 0,
    borderWidth: 0,
    margin: 0,
    padding: 0,
    display: 'flex',
    backgroundColor: 'unset',
    '&:active': {
      backgroundColor: 'unset',
    },
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&:focus': {
      backgroundColor: 'unset',
    },
  }),
  container: (base) => ({
    ...base,
    width: '100%',
    margin: 0,
    backgroundColor: 'white',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: 'rgba(0, 0, 36, 0.25) 0px 4px 12px -2px',
    zIndex: 10,
    marginTop: 0,
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #C9DBFF',
    boxShadow: 'none',
    padding: '0 15px',
    borderRadius: 'unset',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottom: '1px solid #C9DBFF',
    backgroundColor: '#F7FAFF',
    '> div': {
      display: 'flex',
      padding: 'unset',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
    },
    '&:hover': {
      borderBottom: '1px solid #C9DBFF',
    },
  }),
  indicatorSeparator: () => ({}),
  menu: (base) => ({
    ...base,
    width: '100%',
    margin: 0,
    border: '1px solid #C9DBFF',
    borderTop: 'none',
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    position: 'relative',
    boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
    overflowX: 'auto',
    zIndex: 2,
  }),
  input: () => ({
    width: '100%',
  }),
});

function EmptyComponent() {
  return <span />;
}

function SubMenuOptionMulti(props) {
  const { isSelected = false, label, isFocused = false, data, selectOption, onExpandOption } = props;
  const { children } = data;
  const uniqueId = `submenu-option-${data.value}`;

  const handleChevronClick = (e) => {
    e.stopPropagation();
    onExpandOption(data);
  };

  const handleItemClick = (e) => {
    e.stopPropagation();
    selectOption(data);
  };

  return (
    <components.Option {...props}>
      <div
        className={`${classes.option} ${classes.subMenu} ${classes.longText} ${isFocused ? classes.focused : null}`}
        onClick={handleItemClick}
      >
        <input className={classes.Checkbox} type="checkbox" checked={isSelected} onChange={() => null} id={uniqueId} />
        <label className={`${classes.label} ${isSelected ? classes.activeOption : ''}`} htmlFor={uniqueId}>
          {label}
        </label>
        {children?.length ? (
          <GenerateIcon
            className={classes.marginLeft}
            iconName={ICONS.chevronRight.name}
            id={uniqueId}
            onClick={handleChevronClick}
          />
        ) : null}
      </div>
    </components.Option>
  );
}

SubMenuOptionMulti.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  isFocused: PropTypes.bool,
  onExpandOption: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
};

function MenuList(props) {
  const { children = null, selectProps = {} } = props;
  const {
    components,
    isOptionsLoading,
    emptyStateText,
    likeParams,
    searchValue,
    setIsOpen,
    selectedItems,
    afterLoading,
  } = selectProps;
  const { MenuListFooter = null } = components;
  const isEmpty = !Array.isArray(children);
  const rowRenderer = ({ index, style }) => (
    <CustomTooltip title={children[index]?.props?.label} enterDelay={500} enterNextDelay={300}>
      <div style={style}>{children[index]}</div>
    </CustomTooltip>
  );

  if (isOptionsLoading) {
    return (
      <div className={classes.likeModeWrapper}>
        <Spinner />
      </div>
    );
  }
  if (!isOptionsLoading && isEmpty) {
    return (
      <>
        {emptyStateText && !afterLoading ? (
          <div className={classes.emptyStateWrapper}>
            <EmptyState />
            <span>{emptyStateText}</span>
          </div>
        ) : (
          <div className={`${classes.emptyStateWrapper} ${classes.emptyState}`}>
            <NoOptions />
            <span>No Results</span>
          </div>
        )}
      </>
    );
  }
  if (!isOptionsLoading && !isEmpty && !likeParams?.likeMode) {
    return (
      <>
        <List className={classes.menuList} height={230} itemSize={37} itemCount={children?.length}>
          {rowRenderer}
        </List>
        {MenuListFooter}
      </>
    );
  }
  if (!isOptionsLoading && !isEmpty && likeParams?.likeMode) {
    return (
      <>
        {searchValue ? (
          <>
            <div className={classes.selectAllItem}>
              <Checkbox
                isChecked={selectedItems?.find((s) => s.value === searchValue)}
                primary
                onChange={() => {
                  setTimeout(() => {
                    likeParams?.searchHandler(searchValue);
                    if (setIsOpen) {
                      setIsOpen(false);
                    }
                  }, 100);
                }}
              >
                <div
                  onClick={() =>
                    setTimeout(() => {
                      likeParams?.searchHandler(searchValue);
                      if (setIsOpen) {
                        setIsOpen(false);
                      }
                    }, 100)
                  }
                  className={classes.freeSearchWrapper}
                >
                  <span className={classes.boldText}>Select all containing: * {searchValue} *</span>
                  <p> ({children?.length})</p>
                </div>
              </Checkbox>
            </div>
            <List className={classes.menuList} height={220} itemSize={37} itemCount={children?.length} {...props}>
              {rowRenderer}
            </List>
          </>
        ) : (
          <div className={classes.emptyStateWrapper}>
            <EmptyState />
            <span>Start typing to see results</span>
          </div>
        )}
      </>
    );
  }
  return <></>;
}

MenuList.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object,
};

const SearchInput = (props, onSearchClick, inputValue) => {
  const { selectProps } = props;
  const { likeParams } = selectProps;
  const { likeOperator, likeMode, handleChangeFilterType, field, likeCaseConfig, disableIs } = likeParams || {};
  if (!selectProps.menuIsOpen) {
    return <components.Input {...props} />;
  }

  return (
    <div className={classes.InputSearch} onBlur={selectProps.onMenuClose} data-like-mode={!!likeMode}>
      {likeOperator && (
        <div className={classes.operatorDropdown}>
          <ButtonDropdown
            text={likeMode ? OPERATORS.LIKE : OPERATORS.IS}
            overrideMenuStyles={{ position: 'fixed', minWidth: '50px', left: 20 }}
            iconPlacement="right"
            icon={TriangleDown}
            overrideButtonStyles={{ paddingRight: '3px' }}
          >
            {!disableIs && (
              <li onClick={!likeMode ? undefined : () => handleChangeFilterType(field, null, OPERATORS_KEYS.LIKE)}>
                <span>{OPERATORS.IS}</span>
              </li>
            )}
            <li onClick={likeMode ? undefined : () => handleChangeFilterType(field, null, OPERATORS_KEYS.LIKE)}>
              <span>{OPERATORS.LIKE}</span>
            </li>
          </ButtonDropdown>
        </div>
      )}
      <span className={`${classes.Placeholder} ${likeOperator ? classes.left : ''}`}>
        {!selectProps.inputValue ? 'Search' : null}
      </span>
      <div className={`${classes.inputEnv} ${likeOperator ? classes.left : ''}`}>
        <components.Input {...props} autoFocus />
      </div>
      {likeMode && likeCaseConfig ? (
        <CustomTooltip title={likeCaseConfig?.get(field) ? 'Case sensitive is on' : 'Case sensitive is off'}>
          <div
            onClick={() => {
              likeCaseConfig.set(field, !likeCaseConfig.get(field));
            }}
            className={classes.caseButton}
            data-off={!likeCaseConfig.get(field)}
          >
            <CaseSensitiveIcon />
          </div>
        </CustomTooltip>
      ) : (
        <div
          onClick={() => {
            if (onSearchClick) {
              onSearchClick(inputValue);
            }
          }}
        >
          <GenerateIcon iconName={ICONS.magnifyingGlass.name} className={classes.IconGray} />
        </div>
      )}
    </div>
  );
};
SearchInput.propTypes = {
  selectProps: PropTypes.object.isRequired,
};

export const FilterSelectHierarchy = ({
  emptyStateText = null,
  isLoading = false,
  isMulti = false,
  isOpen = false,
  likeParams = {},
  limitOnBlurInId = null,
  onChange = null,
  onExpandOption = null,
  onMenuBlur,
  onSearchClick = null,
  options = null,
  searchHandler,
  selectedItems = [],
  setIsOpen = null,
}) => {
  const [inputValue, setInputValue] = useState('');

  const selectProps = {
    isOptionsLoading: isLoading && isOpen,
    emptyStateText,
    likeParams,
    searchValue: inputValue,
    setIsOpen,
    selectedItems,
  };

  const onBlur = (event) => {
    if (!limitOnBlurInId || event.relatedTarget?.id !== limitOnBlurInId) {
      onMenuBlur(event);
    }
  };

  // Do not create MenuList component on each render
  // Since the component is getting all needed data as props, it should be created only once
  const memoizedMenuList = useMemo(() => {
    return (props) => MenuList({ ...props });
  }, []);

  const customComponents = {
    DropdownIndicator: EmptyComponent,
    MultiValue: EmptyComponent,
    SingleValue: EmptyComponent,
    MultiValueRemove: EmptyComponent,
    Placeholder: EmptyComponent,
    ClearIndicator: EmptyComponent,
    Option: (props) => SubMenuOptionMulti({ ...props, onExpandOption: onExpandOption }),
    MenuList: memoizedMenuList,
    Input: (props) => SearchInput(props, onSearchClick, inputValue),
    MenuListFooter: EmptyComponent,
  };

  const searchInputChanged = (val, reason) => {
    if (reason.action !== 'menu-close') {
      setInputValue(val);
    }
    const searchOptions = () => {
      if (reason.action === 'input-change') {
        searchHandler(val);
      }
    };
    debounce(searchOptions, 200)();
  };

  return (
    isOpen && (
      <Select
        menuIsOpen
        onChange={onChange}
        onInputChange={searchInputChanged}
        options={options}
        filterOption={null}
        isOptionDisabled={() => likeParams?.likeMode}
        components={customComponents}
        value={selectedItems}
        styles={customStyles()}
        isMulti={isMulti}
        autoFocus
        closeMenuOnSelect={false}
        escapeClearsValue
        onBlur={onBlur}
        inputValue={inputValue}
        hideSelectedOptions={false}
        {...selectProps}
      />
    )
  );
};

FilterSelectHierarchy.propTypes = {
  emptyStateText: PropTypes.string,
  hasSubItems: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOpen: PropTypes.bool,
  likeParams: PropTypes.object,
  limitOnBlurInId: PropTypes.string,
  onChange: PropTypes.func,
  onExpandOption: PropTypes.func,
  onMenuBlur: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func,
  options: PropTypes.array,
  searchHandler: PropTypes.func,
  selectedItems: PropTypes.array,
  setIsOpen: PropTypes.func,
};
