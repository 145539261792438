import { API } from 'shared/utils/apiMiddleware';
import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { buildFilterParams, sanitizeQuery } from 'shared/utils/apiUtil';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import { prepareEC2TopPanelData } from 'usage/utils/ec2UsageUtil';
import DateFilter from 'shared/modules/dateFilter';

const root = '/api/v1/usage/ec2';

const fetchEC2UsageApi = async ({
  startDate,
  endDate,
  currPeriodGranLevel = CostTrackingConstants.GRAN_LEVEL_DAILY,
  rawFiltersMap = new Map(),
}) => {
  const filtersMap = new Map();
  [...rawFiltersMap.entries()].forEach(([key, value]) => {
    if (!value || !value.length) {
      return;
    }
    filtersMap.set(
      key,
      value.map((v) => v.value),
    );
  });
  const filterParams = buildFilterParams(filtersMap);
  const apiQuery = sanitizeQuery(
    `${root}/instances?startDate=${startDate}&endDate=${endDate}&periodGranLevel=${currPeriodGranLevel}${filterParams}`,
  );
  const result = await API.get('billings', apiQuery);
  return prepareEC2TopPanelData(result);
};

const fetchRiCoverageApi = async (
  startDate = DateFilter.getDateStr(),
  endDate = DateFilter.getFirstDayOfCurrMonthDate(),
) => {
  const query = `${root}/ri-coverage?startDate=${startDate}&endDate=${endDate}`;
  return API.get('billings', query);
};

export const useEC2Usage = () => ({
  fetchEC2TotalInstancesAndCost: (startDate, endDate, granLevel, filters) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery({
      queryKey: [
        apiConstants.QUERY_KEYS.PRICING_MODE_CHANGED,
        apiConstants.QUERY_KEYS.EC2_USAGE,
        startDate,
        endDate,
        granLevel,
        JSON.stringify(filters),
      ],
      queryFn: () =>
        fetchEC2UsageApi({
          startDate,
          endDate,
          currPeriodGranLevel: granLevel,
          rawFiltersMap: filters,
        }),
    }),
  fetchRiCoverage: (startDate, endDate) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery({
      queryKey: [
        apiConstants.QUERY_KEYS.PRICING_MODE_CHANGED,
        apiConstants.QUERY_KEYS.EC2_USAGE_RI_COVERAGE,
        startDate,
        endDate,
      ],
      queryFn: () => fetchRiCoverageApi(startDate, endDate),
    }),
});
