import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation from '../baseRecommendation';
import { BASE_PROPERTIES } from 'recommendationsNew/components/detailedRecommendation/recommendationPropertyUtils.js';
import { ExternalLinks } from 'shared/enums/external-links.enum';

export const VPC_PROPERTIES = {
    VPC_ID: {
        label: 'VPC ID',
        getterFunction: (recommendation) => recommendation.recData?.vpc_id,
    },
    VPC_ENDPOINT_ID: {
        label: 'VPC Endpoint ID',
        getterFunction: (recommendation) => recommendation.recData?.vpc_endpoint_id,
    },
    SERVICE_NAME: {
        label: 'Service Name',
        getterFunction: (recommendation) => recommendation.recData?.service_name,
    },
    CREATION_TIME: {
        label: 'Creation Time',
        getterFunction: (recommendation) => recommendation.recData?.creation_time,
    },
    TOTAL_USAGE_QUANTITY: {
        label: 'Total Usage Quantity',
        getterFunction: (recommendation) => recommendation.recData?.total_usage_quantity,
    },
}


const VpcEndpointIdle = ({ recommendation }) => {

    const properties = {
        ...BASE_PROPERTIES,
        ...VPC_PROPERTIES,
    };

    const description = `Identifying VPC interface endpoints that are running but not actively transmitting data.
        This recommendation specifically evaluates the usage of VPC Interface Endpoints based on the
        'VpcEndpoint-Hours' and 'VpceEndpoint-Bytes' evaluated data signals.`;

    const descriptionUrl = {
        fullSentence: 'Click here for more details',
        url: ExternalLinks.RecommendationsForAwsSectionRds
    };


    return (
        <BaseRecommendation
            recommendation={recommendation}
            description={description}
            descriptionUrl={descriptionUrl}
            dynamicProps={properties}
        />
    );
};

VpcEndpointIdle.propTypes = {
    recommendation: PropTypes.object.isRequired,
};

export default VpcEndpointIdle;
