import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import { getInitialAccountsDataWithCounters } from 'users/containers/Organization/components/LinkedAccounts/dataAccessHelperFunctions';
import Spinner from 'shared/components/andtComponents/Spinner';
import LinkedAccountsComponentWrapper from 'users/containers/Organization/components/LinkedAccounts/LinkedAccountsComponentWrapper';
import {
  COST_CENTER_LINKED_ACCOUNTS_COLUMNS,
  COST_CENTER_PAYER_ACCOUNTS_COLUMNS,
} from 'users/containers/Organization/consts';
import { getCostCenterAccountsData } from 'users/containers/Organization/CostCenters/costCentersHelperFunctions';

import styles from './CostCenterAccountsData.module.scss';

const CostCenterAccountsData = ({ row, isSelectionEnabled }) => {
  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts();
  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchDataAccessPayerAccounts();

  const dataToDisplay = useMemo(() => {
    if (!accountsData || !row?.accounts) {
      return null;
    }
    const combinedCostCenterAccountsData = getCostCenterAccountsData(accountsData, row?.accounts, false);
    const combinedData = getInitialAccountsDataWithCounters(accountsData);

    return { ...combinedData, accountsData: combinedCostCenterAccountsData };
  }, [accountsData, row?.accounts]);

  return (
    <div className={styles.costCenterAccountsDataContainer}>
      {isAccountsDataLoading ? (
        <div className="position-relative">
          <Spinner />
        </div>
      ) : (
        <div className={styles.costCenterAccountsData}>
          <LinkedAccountsComponentWrapper
            entity="costCenter"
            allAccountsData={dataToDisplay}
            isViewOnly={!isSelectionEnabled}
            onRemoveLinkedAccount={() => { }}
            linkedAccountsColumns={Object.values(COST_CENTER_LINKED_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
            payerAccountsColumns={Object.values(COST_CENTER_PAYER_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
            additionalData={{ roleId: row?.id }}
          />
        </div>
      )}
    </div>
  );
};

CostCenterAccountsData.propTypes = {
  isSelectionEnabled: PropTypes.bool,
  row: PropTypes.object,
};

export default CostCenterAccountsData;
