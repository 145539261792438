import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import DivisionGroup from 'users/models/divisionGroup';
import { useRootStore } from 'app/contexts/RootStoreContext';

const root = '/api/v1/divisions';
const getDivisionsUrl = `${root}/i/`;

const prepareDivGroups = (rawDivisionGroups) => {
  const divGroups = [];
  if (rawDivisionGroups) {
    rawDivisionGroups.forEach((divGroup) => {
      const newGroup = new DivisionGroup(divGroup);
      divGroups.push(newGroup);
    });
  }
  return divGroups;
};

const getDivisionsData = async (includeEmpty) => {
  const { preparedRawDivisions: rawDivisionGroups, mapLinkedAccIdToDivisionName } = await API.get(
    'billings',
    `${getDivisionsUrl}?includeEmpty=${+includeEmpty}`,
  );
  const divisionGroups = prepareDivGroups(rawDivisionGroups);
  return {
    divisionGroups,
    rawDivisionGroups,
    mapLinkedAccIdToDivisionName: new Map(mapLinkedAccIdToDivisionName),
  };
};

const moveLinkedAccountsBetweenCostCenters = async ({ fromDivision, linkedAccounts, toDivision }) =>
  API.put('billings', `${root}/move-linked-accounts`, { body: { fromDivision, linkedAccounts, toDivision } });

export const useDivisions = () => {
  const { appStore } = useRootStore();
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.DIVISIONS];

  return {
    reset: () => queryClient.resetQueries({ queryKey }),
    getDivisions: (includeEmpty) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery({
        queryKey: [...queryKey, includeEmpty],
        queryFn: () => (appStore.isKeyCloakManagement ? {} : getDivisionsData(includeEmpty)),
        onError: () =>
          toast.error('Error fetching user data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
      }),
  };
};

/*
TODO- remove when move all the logic to use Accounts service and the new users management
Old version - will be deprecated on the new users management.
for new users management use the file /shared/hooks/react-query/useCostCenters */
export const useCostCenter = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.OLD_COST_CENTER];
  return {
    reset: () => queryClient.resetQueries({ queryKey }),
    moveLinkedAccounts: ({ onSuccess = () => {}, onError = () => {}, ...mutationSettings } = {}) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMutation({
        mutationFn: moveLinkedAccountsBetweenCostCenters,
        onSuccess,
        onError,
        ...mutationSettings,
      }),
  };
};

export const useCustomersByAccountId = (accountId) =>
  useQuery({
    queryKey: [apiConstants.QUERY_KEYS.DIVISIONS_BY_ACCOUNT_ID, accountId],
    queryFn: () => API.get('billings', `/api/v1/admin/customers/by-account-id?accountId=${accountId}`),
    enabled: !!accountId,
  });

export const useCustomer = ({ accountId, accountKey, divisionId }, config = {}) =>
  useQuery({
    queryKey: [apiConstants.QUERY_KEYS.CUSTOMER_ADMIN_PANEL, { accountId, accountKey, divisionId }],
    queryFn: () =>
      API.get(
        'billings',
        `/api/v1/admin/customers/customer?accountId=${accountId}&accountKey=${accountKey}&divisionId=${divisionId}`,
      ),
    enabled: !!(accountId && accountKey && divisionId),
    ...config,
  });
