import React from "react";

type Props = {
  className?: string;
}

const InstallationStep: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      InstallationStep
    </div>
  );
};

export default InstallationStep;
