import { ExternalLinks } from "shared/enums/external-links.enum";

const OnboardingLinks = {
  AWS: {
    customerType: ExternalLinks.AWSCustomerType,
    onboardingType: ExternalLinks.AWSOnboardingType,
    onboardingLinkedAccountsAutomatic: ExternalLinks.AWSOnboardingLinkedAccountsAutomatic,
    trustedAccess: ExternalLinks.AWSTrustedAccess,
    onboardingTypeEventNotification: ExternalLinks.AWSOnboardingTypeEventNotification,
  },
  AZURE: {
    application: ExternalLinks.AzureApplication,
    onboardingType:
      ExternalLinks.AzureOnboardingType,
    customerType: ExternalLinks.AzureCustomerType,
    eaGuide: ExternalLinks.AzureEaGuide,
    focusGuide: ExternalLinks.AzureFocusGuide,
  },
  GCP: {
    customerType: ExternalLinks.GcpCustomerType,
    onboardingType: ExternalLinks.GcpOnboardingType,
    k8sIntegration: ExternalLinks.GcpK8sIntegration,
  },
};

export default OnboardingLinks;
