import React, { useCallback, useState } from 'react';
import styles from './K8sOnboarding.module.scss';
import { FlatObject } from 'shared/types/commonTypes.ts';
import AddClusterForm from './AddClusterForm';
import CustomModal from 'shared/components/andtComponents/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { addClusterDefaultState, AddClusterFields } from "./config.ts";
import { validateAddClusterForm } from "./AddClusterForm/utils.ts";
import Button from 'shared/components/andtComponents/Button';

type Props = {
  className?: string;
};

const ClusterInformationStep: React.FC<Props> = () => {
  const [clusters, setClusters] = useState<FlatObject[]>([]);
  const [showClusterModal, setShowClusterModal] = useState(false);
  const onAddCluster = () => {
    setShowClusterModal(true);
  };
  const onClose = useCallback(() => setShowClusterModal(false), []);
  const onSave = () => {
    setClusters(s => [...s, methods.getValues()]);
  }

  const methods = useForm({
    defaultValues: addClusterDefaultState,
    resolver: async (formState) => {
      const errors = validateAddClusterForm(formState);
      return {
        values: Object.keys(errors).length === 0 ? formState : {},
        errors
      };
    },
    shouldFocusError: true,
  });

  return (
    <div className={styles.clusterInfoContainer}>
      <div className={styles.stepTitle}>ClusterInformationStep</div>
      <div className={styles.addButton}>
        <Button text={"+ Add Cluster"} onClick={onAddCluster} isTextButton />
      </div>
      {clusters.map((cluster, index) => (
        <div className={styles.clusterTile} key={index}>
          {cluster[AddClusterFields.clusterName]}
        </div>
      ))}
      {showClusterModal && (
        <CustomModal
          open={true}
          onClose={onClose}
          onCloseClick={onClose}
          headerMode={'add'}
          title="Add Cluster"
          saveTitle="Save"
          onSave={onSave}
          saveDisabled={Object.keys(methods?.formState?.errors || {}).length > 0}
          closeOnSave
          className={styles.bigModal}
          automationId={`addClusterModal`}
        >
          <FormProvider {...methods}>
            <AddClusterForm />
          </FormProvider>
        </CustomModal>
      )}
    </div>
  );
};

export default ClusterInformationStep;
